import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Accordion } from 'react-bootstrap';
import { Row } from 'reactstrap';
import { ActionsItem, FieldInput } from 'components/own';
import { cloneDeep, get } from 'lodash';
// import { getInfo } from 'services/general-info';
import produce from 'immer';
import { getAPI, putAPI, uploadImage } from 'services/common';
import { useActionNotification } from 'hook/useContextSelector';
import { Formik } from 'formik';
import { AuthenticationFeature } from 'constants/authentication';

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const Block = styled(Accordion)`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const fields = [
  { label: 'Ngân hàng', name: 'setting.bankInfo.bank' },
  { label: 'Số tài khoản', name: 'setting.bankInfo.accountNumber' },
  { label: 'Chủ tài khoản', name: 'setting.bankInfo.accountName' },
];
const initialState = { data: null };
const BankSetting = () => {
  const [{ data }, setState] = useState(initialState);
  const pushNotification = useActionNotification();

  const onSave = useCallback(
    async values => {
      const changed = cloneDeep(values);
      if (!changed.setting.logo) {
        pushNotification('warning', 'Logo không được để trống');
        return;
      }

      if (changed.setting.logo && typeof changed.setting.logo !== 'string') {
        const res = await uploadImage(changed.setting.logo);
        changed.setting.logo = res?.url;
      }

      putAPI('general-info/1', changed)
        .then(() => {
          pushNotification('success', 'Lưu thành công');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [pushNotification]
  );

  useEffect(() => {
    getAPI('general-info/1').then(res => {
      setState(
        produce(draft => {
          draft.data = get(res, ['data']);
        })
      );
    });
  }, []);
  if (!data) return <div />;

  return (
    <Formik initialValues={data} onSubmit={onSave}>
      {({ handleChange, handleSubmit, setValues, values }) => (
        <>
          <Block alwaysOpen defaultActiveKey={['info']}>
            <Accordion.Item eventKey={'info'}>
              <BlockHeader>Thông tin chuyển khoản</BlockHeader>
              <BlockContent>
                {fields.map((_field, i) => (
                  <Row key={i}>
                    <FieldInput {..._field} value={get(values, _field.name)} onChange={handleChange} />
                  </Row>
                ))}
                <ActionsItem onSave={handleSubmit} onCancel={() => setValues(data)} feature={AuthenticationFeature.INFO} />
              </BlockContent>
            </Accordion.Item>
          </Block>
        </>
      )}
    </Formik>
  );
};

export default BankSetting;
