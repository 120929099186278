import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import styled from 'styled-components';

const Text = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: rgba(31, 65, 115, 0.5);
`;

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>
              <Text>Ⓒ 2022 Vinh Cara – All Rights Reserved.</Text>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
