/* eslint-disable eqeqeq */
import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { cloneDeep, get } from 'lodash';
import { Accordion } from 'react-bootstrap';

import styled from 'styled-components';
import { Col, Input, Label, Row } from 'reactstrap';
import { ActionsItem, FieldInput } from 'components/own';
import { useActionNotification } from 'hook/useContextSelector';
import { AuthenticationFeature } from 'constants/authentication';
import { getAPI, putAPI, uploadImage } from 'services/common';
import { E_COMMERCE_URL } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { getChangedData } from 'utils/uti';

const initialState = {
  data: null,
  options: {},
  categories: [],
};

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const NameLabel = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  color: #000000;
`;

const LinkRow = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  height: 22px;
  align-items: center;
  margin-bottom: 16px;
`;

const LinkTitle = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #000000;
`;

const LinkValue = styled.a`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  text-decoration-line: underline;

  color: #0063f7;
  margin-left: 4px;

  &:hover {
    color: #0063f7;
  }
`;

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ $templateColumns }) => $templateColumns || '1fr'};
  grid-column-gap: 60px;
  grid-row-gap: 16px;
  background-color: #fff;
  border: 0;
`;

const NewBlockContainer = styled.div`
  width: 100%;
  height: 104px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px dashed #8f90a6;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

const AddBlockButton = styled.div`
  width: 178px;
  height: 56px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  border-radius: 4px;

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;
  text-transform: uppercase;

  /* Dark / Dark 0 */

  color: #1c1c28;
  i {
    margin-right: 4px;
  }
`;

export const initialBlock = {
  id: 1,
  desc: '',
  title: '',
  bannerPC: '',
  textColor: '#fafafc',
  urlVideoPC: null,
  bannerMobile: '',
  textPosition: 'left',
  urlVideoMobile: null,
};

const BannerSetting = () => {
  const { id } = useParams();
  const [{ data }, setState] = useState(initialState);
  const router = useHistory();
  const pushNotification = useActionNotification();

  useEffect(() => {
    getAPI(`page-setting-info?page=${id == 1 ? '' : id}`).then(res => {
      setState(
        produce(draft => {
          draft.data = res.data;
        })
      );
    });
  }, [id]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required('Vui lòng nhập tên trang.'),
      }),
    []
  );

  const onCancel = useCallback(() => {
    router.goBack();
  }, [router]);

  const onSave = useCallback(
    async values => {
      const changed = getChangedData(cloneDeep(values), data);
      if (!Object.keys(changed).length) return;
      if (changed.banner?.list) {
        for (const iterator of changed.banner?.list) {
          if (iterator?.bannerPC && typeof iterator?.bannerPC !== 'string') {
            const res = await uploadImage(iterator?.bannerPC);
            iterator.bannerPC = res?.url;
          }

          if (iterator?.bannerMobile && typeof iterator?.bannerMobile !== 'string') {
            const res = await uploadImage(iterator?.bannerMobile);
            iterator.bannerMobile = res?.url;
          }
        }
      }

      putAPI(`page-setting-info?page=${id === '1' ? '' : id}`, changed)
        .then(() => {
          pushNotification('success', 'Lưu thành công');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [data, id, pushNotification]
  );

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>{data.name}</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, submitCount, touched = {}, values = {} }) => (
          <>
            <Row className="mb-2 d-flex">
              <Col md={2} className="d-flex align-items-center">
                <NameLabel>Tên trang</NameLabel>
              </Col>
              <FieldInput
                inputMd={10}
                name="name"
                error={get(touched, 'name') || !!submitCount ? get(errors, 'name') : null}
                value={get(values, 'name')}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={'Tên trang'}
              />
            </Row>
            <LinkRow>
              <LinkTitle>Đường dẫn:</LinkTitle>
              <LinkValue target="_blank" href={`${E_COMMERCE_URL}${values?.link}`}>{`${E_COMMERCE_URL}${values?.link}`}</LinkValue>
            </LinkRow>
            {values?.banner?.list?.map((_b, i) => (
              <Accordion alwaysOpen defaultActiveKey={[i]} key={i}>
                <Accordion.Item className="mb-3" eventKey={i}>
                  <BlockHeader className="d-flex align-items-center justify-content-between">Banner {i + 1}</BlockHeader>
                  <BlockContent>
                    <Row>
                      <FieldInput
                        label="Lựa chọn option banner"
                        controlType={ControlTypes.SELECT_PICKER}
                        name={`banner.list.${i}.templateId`}
                        value={get(values, ['banner', 'list', i, 'templateId']) || '1'}
                        options={[{ label: 'Option 1', value: '1' }]}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <Col md={3}>Vị trí text</Col>
                      <Col md={9} className="d-flex">
                        <div
                          className="form-check"
                          onClick={() => handleChange({ target: { name: `banner.list.${i}.textPosition`, value: 'left' } })}
                        >
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            checked={get(values, ['banner', 'list', i, 'textPosition']) === 'left'}
                            onChange={() => false}
                          />
                          <Label className="form-check-label" htmlFor="defaultCheck1">
                            Trái
                          </Label>
                        </div>
                        <div
                          className="form-check mx-4"
                          onClick={() => handleChange({ target: { name: `banner.list.${i}.textPosition`, value: 'center' } })}
                        >
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck2"
                            checked={get(values, ['banner', 'list', i, 'textPosition']) === 'center'}
                            onChange={() => false}
                          />
                          <Label className="form-check-label" htmlFor="defaultCheck2">
                            Giữa
                          </Label>
                        </div>
                        <div
                          className="form-check"
                          onClick={() => handleChange({ target: { name: `banner.list.${i}.textPosition`, value: 'right' } })}
                        >
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck3"
                            checked={get(values, ['banner', 'list', i, 'textPosition']) === 'right'}
                            onChange={() => false}
                          />
                          <Label className="form-check-label" htmlFor="defaultCheck3">
                            Phải
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <FieldInput
                        label="Hình PC"
                        controlType={ControlTypes.FILE}
                        name={`banner.list.${i}.bannerPC`}
                        value={get(values, ['banner', 'list', i, 'bannerPC'], '')}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <FieldInput
                        label="Hình Mobile"
                        controlType={ControlTypes.FILE}
                        name={`banner.list.${i}.bannerMobile`}
                        value={get(values, ['banner', 'list', i, 'bannerMobile'], '')}
                        onChange={handleChange}
                      />
                    </Row>

                    <Row>
                      <FieldInput
                        label="Url video PC"
                        name={`banner.list.${i}.urlVideoPC`}
                        value={get(values, ['banner', 'list', i, 'urlVideoPC'], '')}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <FieldInput
                        label="Url video Mobile"
                        name={`banner.list.${i}.urlVideoMobile`}
                        value={get(values, ['banner', 'list', i, 'urlVideoMobile'], '')}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <FieldInput
                        label="Tiêu đề"
                        name={`banner.list.${i}.title`}
                        value={get(values, ['banner', 'list', i, 'title'], '')}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <FieldInput
                        type="textarea"
                        rows={6}
                        label="Đoạn văn"
                        name={`banner.list.${i}.desc`}
                        value={get(values, ['banner', 'list', i, 'desc'], '')}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <FieldInput
                        label="Button text"
                        name={`banner.list.${i}.buttonText`}
                        value={get(values, ['banner', 'list', i, 'buttonText'], '')}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <FieldInput
                        label="Link banner / button"
                        name={`banner.list.${i}.buttonLink`}
                        value={get(values, ['banner', 'list', i, 'buttonLink'], '')}
                        onChange={handleChange}
                      />
                    </Row>
                  </BlockContent>
                </Accordion.Item>
              </Accordion>
            ))}
            {id === '1' && (
              <NewBlockContainer>
                <AddBlockButton
                  onClick={() => {
                    const blocks = cloneDeep(values?.banner?.list || []);
                    blocks.push({ ...initialBlock, id: blocks.length + 1 });
                    handleChange({ target: { name: 'banner.list', value: blocks } });
                  }}
                >
                  <i className="mdi mdi-plus"></i>
                  Thêm block
                </AddBlockButton>
              </NewBlockContainer>
            )}
            <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.PRODUCT} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default BannerSetting;
