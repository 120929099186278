import axios from 'axios';
import { API_UPLOAD } from 'constants/common';

export const getAPI = url => axios({ url, method: 'GET' });

export const postAPI = (url, data) => axios({ url, method: 'POST', data });

export const putAPI = (url, data) => axios({ url, method: 'PUT', data });

export const deleteAPI = url => axios({ url, method: 'DELETE' });

export const uploadImage = image => {
  const formData = new FormData();
  formData.append('file', image);
  if (image.type.includes('image')) {
    formData.append('t', true);
  }

  return fetch(API_UPLOAD, {
    method: 'post',
    body: formData,
  })
    .then(response => {
      return new Response(response.body, { headers: { 'Content-Type': 'text/html' } }).text().then(res => {
        const respJson = JSON.parse(res);
        return respJson?.data;
      });
    })
    .catch(error => {
      return null;
    });
};
