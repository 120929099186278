import { AuthenticationFeature } from 'constants/authentication';
import { JewelTypes } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { JewellSerialTypeOptions, StockStatusOptions } from 'constants/options';
import { CellControlType } from 'constants/table';

export const getStockInfo = () => ({
  buttonAddTitle: '',
  feature: AuthenticationFeature.PRODUCT,
  columns: [
    {
      dataField: 'productOdooId',
      text: 'SkuCode',
      detailUrl: 'jewellery',
      keyId: 'generalInfo.productCode',
      color: '#0063F7',
      fontWeight: 700,
    },
    {
      dataField: 'generalInfo.productName',
      text: 'Mã sản phẩm',
      detailUrl: 'jewellery',
      keyId: 'generalInfo.productCode',
      color: '#0063F7',
      fontWeight: 700,
    },
    {
      dataField: 'generalInfo.productName',
      text: 'Tên sản phẩm',
      render: (value, data) => `${data.generalInfo?.mainCategory || ''} ${value}`,
    },
    // {
    //   dataField: 'quantity',
    //   text: 'Số lượng',
    // },
    {
      dataField: 'generalInfo.mediafiles.images.0.mainImage',
      text: 'Hình đại diện',
      controlType: CellControlType.Image,
    },

    {
      dataField: 'stockInfo.name',
      text: 'Tên kho',
    },
    {
      dataField: 'status',
      text: 'Trạng thái',
      render: value => (value === 1 ? 'Serial Thật' : 'Serial Ảo'),
    },
    {
      dataField: 'generalInfo.productCategory',
      text: 'Danh mục',
      custom: 'array',
    },
    {
      dataField: 'type',
      text: 'Loại sản phẩm',
      render: value =>
        value === JewelTypes.Single ? 'Đơn' : value === JewelTypes.Couple ? 'Đôi' : value === JewelTypes.Customize ? 'Đính kèm kim cương' : '',
    },
    {
      dataField: 'serial',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'jewellery',
    },
  ],
  columnTemplate: '100px 120px 200px 120px 100px 150px 1fr 100px 100px',
  filterElements: [
    {
      name: 'type',
      placeholder: 'Loại sản phẩm',
      controlType: ControlTypes.SELECT_PICKER,
      options: JewellSerialTypeOptions,
      width: '130px',
    },
    {
      name: 'stockStatus',
      placeholder: 'Trạng thái kho',
      controlType: ControlTypes.SELECT_PICKER,
      options: StockStatusOptions,
      width: '130px',
    },
  ],
  extraCount: [],
  selectable: true,
  title: 'Kho hàng',
  url: 'stock',
});
