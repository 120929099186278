import { Label, Col, Input } from 'reactstrap';
import styled from 'styled-components';

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
`;

const FieldCheckbox = ({ label, name, onChange = () => {}, value, disabled }) => {
  return (
    <>
      <LabelInput className="col-md-3 col-form-label d-flex align-items-center">{label}</LabelInput>
      <Col md={9} className="d-flex align-items-center">
        {/* <Switch
          className="me-1 mb-sm-8 mb-2"
          onColor="#38a4f8"
          name={name}
          onChange={checked => {
            onChange({ target: { name, value: checked } });
          }}
          checked={value || false}
        /> */}
        <Input
          type="checkbox"
          className="form-check-input"
          onChange={() => onChange({ target: { name, value: !value } })}
          checked={value || false}
          disabled={disabled}
        />
      </Col>
    </>
  );
};

export default FieldCheckbox;
