import { memo, useMemo } from 'react';
import { Accordion } from 'react-bootstrap';
import { Input, Label } from 'reactstrap';
import styled from 'styled-components';

import { guid, propsAreEqual } from 'utils/uti';

const BlockHeader = styled(Accordion.Header)`
  background-color: #fff;

  .accordion-button {
    background-color: transparent;
    padding: 0;
    color: #1f4173 !important;
    box-shadow: unset;
  }
  .form-check-label {
    margin-top: 0.25em;
     color: #1f4173 !important;
  }
  .form-label {
     color: #1f4173 !important;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 0 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 60px;
  background-color: #fff;
  border: 0;
`;

const Block = styled(Accordion)`
  background-color: #fff;
  .accordion-item {
    border: unset;
    color: #1f4173 !important;
  }
`;

const BlogCategory = ({ data, handleChange, name, selected, showCount }) => {
  const id = useMemo(() => guid(), []);
  if (data.id === 2) return <></>;

  if (data.subs && data.subs.length)
    return (
      <Block>
        <Accordion.Item>
          <BlockHeader>
            <div className="form-check">
              <Input
                className="form-check-input"
                type="radio"
                name={name}
                id={id}
                value={data.id}
                onChange={handleChange}
                defaultChecked={data.id === selected}
              />
              <Label className="form-check-label" htmlFor={id}>
                {data.name} {showCount && `(${data.countBlogs})`}
              </Label>
            </div>
          </BlockHeader>
          <BlockContent>
            {data.subs?.map(item => (
              <BlogCategory key={item.id} showCount={showCount} name={name} data={item} selected={selected} handleChange={handleChange} />
            ))}
          </BlockContent>
        </Accordion.Item>
      </Block>
    );
  return (
    <div className="form-check">
      <Input
        className="form-check-input"
        type="radio"
        name={name}
        id={id}
        value={data.id}
        onChange={handleChange}
        defaultChecked={data.id === selected}
      />
      <Label className="form-check-label" htmlFor={id}>
        {data.name} {showCount && `(${data.countBlogs})`}
      </Label>
    </div>
  );
};

export default memo(BlogCategory, propsAreEqual);
