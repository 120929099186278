import styled from 'styled-components';

const Root = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ $columnTemplate }) => $columnTemplate};
  justify-content: space-between;
  user-select: none;
  z-index: 9;
  background: #ffffff;
  box-sizing: border-box;
  padding: 0;
  min-height: 56px;
  padding-left: 12px & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const TableHeader = ({ children, columnTemplate }) => <Root $columnTemplate={columnTemplate}>{children}</Root>;

export default TableHeader;
