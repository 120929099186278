import { memo } from 'react';
import styled from 'styled-components';

import Text from 'components/own/Text';

import { propsAreEqual } from 'utils/uti';
import Authentication from 'components/own/Authentication';

const Root = styled.div``;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const ActionItem = styled.div`
  cursor: pointer;
  padding: 0 4px;
  border-right: 1px solid #757575;
  height: 13px;
  line-height: 13px;
  color: ${({ danger }) => (danger ? '#FF3B3B' : '#0063F7')};

  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    border-right: unset;
  }
`;

const CellTextAndActions = ({ actions, dataField, data, feature, actionType, ...rest }) => {
  return (
    <Root>
      <Text color="#0063F7" fontWeight={700}>
        {data[dataField]}
      </Text>
      <Actions>
        {actions.map(_action => (
          <Authentication key={_action.title} feature={feature} type={_action.actionType}>
            <ActionItem {..._action}>{_action.title}</ActionItem>
          </Authentication>
        ))}
      </Actions>
    </Root>
  );
};

export default memo(CellTextAndActions, propsAreEqual);
