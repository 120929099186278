import React, { Component } from 'react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

//i18n

import { connect } from 'react-redux';
import { changeLayout, changeLayoutWidth, changeSidebarTheme, changeSidebarType, changePreloader } from '../../store/actions';
import { menuMockup } from 'mockups/menu';
import { Authentication } from 'components/own';
import { AuthenticationTypes } from 'constants/authentication';
class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  initMenu() {
    new MetisMenu('#side-menu');

    var matchingMenuItem = null;
    var ul = document.getElementById('side-menu');
    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {menuMockup.map(({ link, title, icon, subs, feature }, i) => (
              <Authentication key={i} feature={feature} type={AuthenticationTypes.VIEW} defaultVal>
                <li>
                  <Link to={link} className={`${!!subs ? 'has-arrow' : ''} waves-effect color-primary`}>
                    <i className={icon}></i>
                    <span className="ms-1">{title}</span>
                  </Link>
                  {subs && (
                    <ul className="sub-menu">
                      {subs.map((_s, index) => (
                        <li key={index}>
                          <Link to={_s.link}>{_s.title}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              </Authentication>
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStateToProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(SidebarContent)
);
