export const SaveIcon = ({ color = 'white' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M13.5 5.20711V12.5C13.5 12.6326 13.4473 12.7598 13.3536 12.8536C13.2598 12.9473 13.1326 13 13 13H3C2.86739 13 2.74021 12.9473 2.64645 12.8536C2.55268 12.7598 2.5 12.6326 2.5 12.5V2.5C2.5 2.36739 2.55268 2.24021 2.64645 2.14645C2.74021 2.05268 2.86739 2 3 2H10.2929C10.3586 2 10.4236 2.01293 10.4842 2.03806C10.5449 2.06319 10.6 2.10002 10.6464 2.14645L13.3536 4.85355C13.4 4.89998 13.4368 4.9551 13.4619 5.01576C13.4871 5.07643 13.5 5.14145 13.5 5.20711Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 13.0005V9.00049C5 8.86788 5.05268 8.7407 5.14645 8.64693C5.24021 8.55317 5.36739 8.50049 5.5 8.50049H10.5C10.6326 8.50049 10.7598 8.55317 10.8536 8.64693C10.9473 8.7407 11 8.86788 11 9.00049V13.0005"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.5 4.00049H6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
