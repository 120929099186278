import { ControlTypes } from 'constants/field';
import DatePicker from './DatePicker';
import FitterLabel from './FitterLabel';
import CustomInput from './Input';
import SelectPicker from './SelectPicker';

const FilterInput = ({ controlType, ...rest }) => {
  switch (controlType) {
    case ControlTypes.SELECT_PICKER:
      return <SelectPicker {...rest} />;

    case ControlTypes.DATE_PICKER:
      return <DatePicker {...rest} />;

    case ControlTypes.LABEL:
      return <FitterLabel {...rest} />;

    default:
      return <CustomInput {...rest} />;
  }
};

export default FilterInput;
