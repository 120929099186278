import { AuthenticationFeature } from 'constants/authentication';
import { CellControlType } from 'constants/table';

export const getDiamondInfo = () => ({
  buttonAddTitle: 'Thêm kim cương',
  feature: AuthenticationFeature.PRODUCT,
  columns: [
    {
      dataField: 'generalInfo.mediafiles.images.0.mainImage',
      text: 'Hình ảnh',
      controlType: CellControlType.Image,
    },
    {
      dataField: 'generalInfo.productCode',
      text: 'Mã sản phẩm',
      orderKey: 'productCode',
      detailUrl: 'diamond-serials',
    },
    {
      dataField: 'generalInfo.productName',
      text: 'Tên sản phẩm',
      orderKey: 'name',
      detailUrl: 'diamond-serials',
    },
    {
      dataField: 'inStockCount',
      text: 'Kho',
    },
    {
      dataField: 'price',
      text: 'Giá (VNĐ)',
      custom: 'price',
    },
    {
      dataField: 'id',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'diamond-serials',
    },
  ],
  columnTemplate: '120px 1fr 1fr 1fr 1fr  120px',
  filterElements: [],
  selectable: false,
  title: 'Danh sách kim cương',
  url: 'diamond-serials',
});
