import React from 'react';

export const OrderIcon = () => (
  <svg viewBox="0 0 9 11" width="9px" height="11px" fill="none">
    <path
      fill="#1E1E1E"
      d="M1.37301 4.95836H7.62763C8.09455 4.95836 8.34263 4.40586 8.03226 4.05649L4.90522 0.538364C4.8545 0.481112 4.7922 0.435277 4.72245 0.403885C4.6527 0.372494 4.57708 0.356262 4.50059 0.356262C4.4241 0.356262 4.34849 0.372494 4.27874 0.403885C4.20899 0.435277 4.14669 0.481112 4.09597 0.538364L0.967843 4.05649C0.657468 4.40586 0.905551 4.95836 1.37301 4.95836ZM4.09543 10.4612C4.14615 10.5184 4.20844 10.5642 4.27819 10.5956C4.34794 10.627 4.42356 10.6433 4.50005 10.6433C4.57654 10.6433 4.65216 10.627 4.72191 10.5956C4.79166 10.5642 4.85395 10.5184 4.90468 10.4612L8.03172 6.94303C8.34263 6.5942 8.09455 6.0417 7.62709 6.0417H1.37301C0.906093 6.0417 0.658009 6.5942 0.968384 6.94357L4.09543 10.4612Z"
    />
  </svg>
);
