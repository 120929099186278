export const HandBagIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
    <rect width="52" height="52" rx="26" fill="#1C1C28" fill-opacity="0.5" />
    <path
      d="M39.8705 35.7792L38.0928 19.7791C38.0389 19.2898 37.8062 18.8375 37.4393 18.5092C37.0725 18.1809 36.5973 17.9995 36.105 18H31.9151C31.679 16.602 30.9554 15.3328 29.8726 14.4175C28.7898 13.5022 27.4178 13 26 13C24.5822 13 23.2102 13.5022 22.1274 14.4175C21.0446 15.3328 20.321 16.602 20.0849 18H15.895C15.4027 17.9995 14.9276 18.1809 14.5607 18.5092C14.1939 18.8376 13.9612 19.2898 13.9072 19.7792L12.1295 35.7792C12.0985 36.0587 12.1267 36.3417 12.2125 36.6096C12.2982 36.8775 12.4395 37.1243 12.6271 37.3339C12.8147 37.5434 13.0444 37.7111 13.3012 37.8259C13.5579 37.9407 13.8361 38 14.1173 38H37.8827C38.164 38 38.4421 37.9407 38.6989 37.8259C38.9557 37.7111 39.1854 37.5434 39.3729 37.3339C39.5605 37.1243 39.7018 36.8775 39.7875 36.6096C39.8733 36.3417 39.9015 36.0587 39.8705 35.7792H39.8705ZM26 15C26.8866 15.0011 27.7477 15.2962 28.4486 15.8391C29.1495 16.382 29.6506 17.1419 29.8734 18H22.1266C22.3494 17.1419 22.8505 16.382 23.5514 15.8391C24.2523 15.2962 25.1135 15.0011 26 15Z"
      fill="white"
    />
  </svg>
);
export const PlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="105px" height="105px" viewBox="0 0 105 105" version="1.1">
    <g id="surface1">
      <path
        style={{
          fillRule: 'nonzero',
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: 0.4,
          strokeWidth: 2,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          stroke: 'rgb(100%,100%,100%)',
          strokeOpacity: 1,
          strokeMiterlimit: 10,
        }}
        d="M 70 122.5 C 98.994792 122.5 122.5 98.994792 122.5 70 C 122.5 41.005208 98.994792 17.5 70 17.5 C 41.005208 17.5 17.5 41.005208 17.5 70 C 17.5 98.994792 41.005208 122.5 70 122.5 Z M 70 122.5 "
        transform="matrix(0.75,0,0,0.75,0,0)"
      />
      <path
        style={{
          fill: 'none',
          strokeWidth: 2,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          stroke: 'rgb(100%,100%,100%)',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        d="M 87.5 70 L 61.25 52.5 L 61.25 87.5 Z M 87.5 70 "
        transform="matrix(0.75,0,0,0.75,0,0)"
      />
    </g>
  </svg>
);
export const DataIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="105px" height="105px" viewBox="0 0 105 105" version="1.1">
    <g id="surface1">
      <path
        style={{
          fillRule: 'nonzero',
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: 0.4,
          strokeWidth: 2,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          stroke: 'rgb(100%,100%,100%)',
          strokeOpacity: 1,
          strokeMiterlimit: 10,
        }}
        d="M 70 122.5 C 98.994792 122.5 122.5 98.994792 122.5 70 C 122.5 41.005208 98.994792 17.5 70 17.5 C 41.005208 17.5 17.5 41.005208 17.5 70 C 17.5 98.994792 41.005208 122.5 70 122.5 Z M 70 122.5 "
        transform="matrix(0.75,0,0,0.75,0,0)"
      />
      <path
        style={{
          fill: 'none',
          strokeWidth: 2,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          stroke: 'rgb(100%,100%,100%)',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        d="M 87.5 70 L 61.25 52.5 L 61.25 87.5 Z M 87.5 70 "
        transform="matrix(0.75,0,0,0.75,0,0)"
      />
    </g>
  </svg>
);
