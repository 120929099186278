import styled from 'styled-components';
import BankSetting from './Banking';
import InfoSetting from './Info';
import StoreSetting from './Store';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const SettingPage = () => {
  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Thông tin page</Title>
      </div>
      <InfoSetting />
      <BankSetting />
      <StoreSetting />
    </div>
  );
};

export default SettingPage;
