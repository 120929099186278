import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  width: 76px;
  height: 32px;

  background: ${({ background }) => background || '#ffffff'};
  /* Primary/Main Color */

  border: 1px solid ${({ color }) => color || '#ffffff'};
  box-sizing: border-box;
  border-radius: 4px;

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  /* or 18px */

  text-transform: capitalize;
  text-align: center;

  /* Primary/Main Color */

  color: ${({ color }) => color || '#ffffff'};

  i {
    margin-right: 7px;
    font-size: 18px;
  }
`;
const NodeAction = ({ onCancel, onDelete, onSave }) => {
  return (
    <Container>
      <Button color="#E53535" onClick={onDelete}>
        <i className="mdi mdi-trash-can-outline"></i>
        Xóa
      </Button>
      <Button color="#007770" className="mx-2" onClick={onCancel}>
        <i className="mdi mdi-close"></i>
        Hủy
      </Button>
      <Button background="#007770" onClick={onSave}>
        <i className="mdi mdi-content-save-outline"></i>
        Lưu
      </Button>
    </Container>
  );
};

export default NodeAction;
