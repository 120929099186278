import { ControlTypes } from 'constants/field';
import Autocomplete from './Autocomplete';
import AutocompleteMulti from './AutocompleteMulti';
import FieldCheckbox from './Checkbox';
import FileInput from './FileInput';
import CustomInput from './Input';
import InputCurrency from './InputCurrency';
import InputDatePicker from './InputDatePicker';
import InputTimePicker from './InputTimePicker';
import RichText from './RichText';
import SelectPicker from './SelectPicker';

const FieldInput = ({ controlType, ...rest }) => {
  switch (controlType) {
    case ControlTypes.AUTOCOMPLETE:
      return <Autocomplete {...rest} />;

    case ControlTypes.AUTOCOMPLETE_MULTI:
      return <AutocompleteMulti {...rest} />;

    case ControlTypes.CHECKBOX:
      return <FieldCheckbox {...rest} />;

    case ControlTypes.CURRENCY:
      return <InputCurrency {...rest} />;

    case ControlTypes.FILE:
      return <FileInput {...rest} />;

    case ControlTypes.SELECT_PICKER:
      return <SelectPicker {...rest} />;

    case ControlTypes.DATE_PICKER:
      return <InputDatePicker {...rest} />;

    case ControlTypes.TIME_PICKER:
      return <InputTimePicker {...rest} />;

    case ControlTypes.RICH_TEXT:
      return <RichText {...rest} />;

    default:
      return <CustomInput {...rest} />;
  }
};

export default FieldInput;
