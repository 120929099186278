import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { UncontrolledCollapse, Col, Row, Label, Input } from 'reactstrap';
import produce from 'immer';
import { Formik } from 'formik';
import { get } from 'lodash';
import * as yup from 'yup';
import { useParams, useHistory } from 'react-router';

import { ActionsItem, FieldInput } from 'components/own';
import { ChevronUpIcon } from 'components/icons';
import { createTag } from 'services/tags';
import { AuthenticationFeature } from 'constants/authentication';
import { useActionNotification } from 'hook/useContextSelector';
import { getAPI } from 'services/common';
import { removeVietnameseTones } from 'utils/uti';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;
const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
`;
const InfoBlock = styled.div`
  background-color: #fff;
  padding: 16px;
`;
const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
  margin-top: 16px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
  cursor: pointer;
`;
const BlockContent = styled(Row)`
  padding: 16px;
`;
const IconButton = styled.div`
  cursor: pointer;
`;

const initialState = {
  title: '',
  link: '',
  desc: '',
  seoInfo: {
    _title: '',
    _link: '',
    _desc: '',
    _canonical: '',
  },
};
//
const TagItem = () => {
  const [state, setState] = useState({ data: null });
  const { id } = useParams();
  const { data } = state;

  const router = useHistory();
  const pushNotification = useActionNotification();
  useEffect(() => {
    if (id === 'new') {
      setState(
        produce(draft => {
          draft.data = initialState;
        })
      );
    } else {
      getAPI('tags/' + id)
        .then(res => {
          setState(
            produce(draft => {
              draft.data = res.data;
            })
          );
        })
        .catch(err => {
          console.log('Author:minh.lam , file: TagItem.jsx , line 104 , getAPI , err', err);
        });
    }
  }, [id]);

  const tagFields = useMemo(
    () => [
      {
        label: 'Tên thẻ',
        name: 'title',
        isRequired: true,
      },
      {
        label: 'Đường dẫn tĩnh',
        name: 'link',
        isRequired: true,
      },
      {
        label: 'Mô tả',
        name: 'desc',
        isRequired: true,
      },
    ],
    []
  );

  const seoFields = useMemo(
    () => [
      {
        label: 'Tiêu đề',
        name: '_title',
      },
      {
        label: 'Link',
        name: '_link',
      },
      {
        label: 'Đoạn văn',
        name: '_desc',
      },
      {
        label: 'Canonical URL',
        name: 'canonical',
      },
    ],
    []
  );
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required('Vui lòng nhập tên thẻ.'),
        link: yup.string().required('Vui lòng nhập đường dẫn tĩnh.'),
        desc: yup.string().required('Vui lòng nhập mô tả.'),
      }),
    []
  );
  const onSave = useCallback(
    values => {
      if (id === 'new') {
        createTag(values)
          .then(() => {
            pushNotification('success', 'Tạo thành công');
            router.replace('/tags');
          })
          .catch(error => {
            const message = error.message || error;
            pushNotification('error', message);
          });
      }
    },
    [id, pushNotification, router]
  );
  const onCancel = useCallback(() => {
    router.replace('/tags');
  }, [router]);

  if (!data) return <div />;
  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Thêm thẻ mới</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema} validateOnChange={false} validateOnBlur={false}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, setFieldValue, submitCount, touched = {}, values = {} }) => {
          const errorDesc = get(touched, 'desc') || !!submitCount ? get(errors, 'desc') : null;
          return (
            <>
              <InfoBlock>
                {tagFields.map((property, index) => {
                  const { label, name, placeholder } = property;
                  return (
                    <Row key={`infoFields-${name}-${index}`} style={{ marginTop: 16 }} className="align-items-center">
                      {name === 'desc' ? (
                        <CustomFieldInput errorDesc={errorDesc} setFieldValue={setFieldValue} handleBlur={handleBlur} {...property} />
                      ) : (
                        <FieldInput
                          {...property}
                          error={get(touched, name) || !!submitCount ? get(errors, name) : null}
                          value={get(values, name)}
                          autoComplete="new-password"
                          onBlur={handleBlur}
                          onChange={e => {
                            if (e.target.name === 'title') {
                              setFieldValue('link', removeVietnameseTones(e.target.value));
                            }
                            handleChange(e);
                          }}
                          // options={options[property.option]}
                          placeholder={placeholder || label}
                        />
                      )}
                    </Row>
                  );
                })}
              </InfoBlock>

              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between" id="seo_field">
                  SEO
                  <IconButton>
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <UncontrolledCollapse defaultOpen toggler="#seo_field">
                  <BlockContent>
                    {seoFields.map((property, index) => {
                      const { label, name, placeholder } = property;
                      return (
                        <Row
                          key={`seoFields-${name}-${index}`}
                          style={{ paddingLeft: 16, paddingRight: 16, marginTop: 16 }}
                          className="align-items-center"
                        >
                          {name === '_desc' ? (
                            <CustomFieldInput setFieldValue={setFieldValue} handleBlur={handleBlur} {...property} />
                          ) : (
                            <FieldInput
                              {...property}
                              error={get(touched, name) || !!submitCount ? get(errors, name) : null}
                              value={get(values?.seoInfo, name)}
                              autoComplete="new-password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              // options={options[property.option]}
                              placeholder={placeholder || label}
                            />
                          )}
                        </Row>
                      );
                    })}
                  </BlockContent>
                </UncontrolledCollapse>
              </Block>
              <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.BLOG} />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

const CustomFieldInput = ({ errorDesc, name, label, setFieldValue, handleBlur, values }) => (
  <>
    <LabelInput className="col-form-label" style={{ width: '25%' }}>
      {label}
    </LabelInput>
    <Col>
      <Input
        name={name}
        placeholder={label}
        value={get(values, name)}
        type="textarea"
        onChange={event => setFieldValue(name, event.target.value)}
        invalid={!!errorDesc}
        onBlur={handleBlur}
      />
      {errorDesc && (
        <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
          {errorDesc}
        </div>
      )}
    </Col>
  </>
);

export default TagItem;
