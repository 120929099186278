import { propsAreEqual } from 'utils/uti';
import { memo, useCallback, useState } from 'react';
import { Col, Label } from 'reactstrap';
import produce from 'immer';
import { getAPI } from 'services/common';
import Autocomplete from 'react-autocomplete';
import { get, omit } from 'lodash';

const initialState = {
  selectedValue: '',
  options: [],
};

const menuStyle = {
  borderRadius: '3px',
  zIndex: 2,
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  padding: '2px 0',
  fontSize: '90%',
  position: 'fixed',
  overflow: 'auto',
  maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
};

var timeout;
const CustomAutocomplete = ({
  autoFocus,
  error,
  touched,
  isRequired,
  label,
  name,
  onChange = () => {},
  onSelect = () => {},
  options: _opt,
  placeholder,
  value,
  url,
  optionLabel = 'label',
  optionValue = '',
  onBlur = () => {},
  onFocus = () => {},
  onKeyDown = () => {},
}) => {
  const [{ options }, setState] = useState(initialState);

  const onInputChange = useCallback(
    e => {
      onChange(e);
      if (!e.target.value) return;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        getAPI(url + e.target.value).then(res => {
          setState(
            produce(draft => {
              draft.options = get(res, ['data', 'list'], []).map(_r => ({ label: get(_r, optionLabel, ''), value: omit(_r, ['_id']) }));
            })
          );
        });
      }, 350);
    },
    [onChange, optionLabel, url]
  );
  // const onSelect = useCallback(val => {}, []);

  return (
    <>
      <Label className="col-md-3 col-form-label">
        {label} {isRequired && '*'}
      </Label>
      <Col md={9}>
        <Autocomplete
          getItemValue={item => get(item, ['value', optionValue])}
          items={options}
          renderItem={(item, isHighlighted) => (
            <div
              className="d-flex align-items-center px-2"
              key={item.label}
              style={{ background: isHighlighted ? 'lightgray' : 'white', height: 40 }}
            >
              {item.label}
            </div>
          )}
          value={value}
          inputProps={{
            onBlur,
            onFocus,
            onKeyDown,
            placeholder,
            name,
            className: 'form-control',
          }}
          menuStyle={menuStyle}
          wrapperStyle={{ width: '100%' }}
          onChange={onInputChange}
          onSelect={onSelect}
        />
        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
    </>
  );
};

export default memo(CustomAutocomplete, propsAreEqual);
