import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import ForgetPwd from '../pages/Authentication/ForgetPassword';

// Dashboard
import Dashboard from '../pages/Dashboard/index';

// Vinh Cara
import { UserItem } from 'pages/UserManagement';
import { BlogsItem } from 'pages/BlogsManagement';
import { CommonManagement } from 'components/own';
import { OrderItem } from 'pages/OrderItem';
import { CustomerItem } from 'pages/CustomerItem';
import { SettingFooter, SettingHeader, SettingMenu, SettingPage } from 'pages/SettingPages';
import { EditJewellery } from 'pages/EditJewellery';
import { EditDiamond } from 'pages/EditDiamond';
import { RoleItem } from 'pages/RoleItem';
import { ComboItem } from 'pages/ComboItem';
import { TagItem } from 'pages/TagItem';
import LuxuryJewelry from 'pages/LuxuryJewelry/LuxuryJewelry';
import CouponItem from 'pages/CouponItem/CouponItem';
import TitleMeta from 'pages/SEO/TitleMeta/TitleMeta';
import Redirect301 from 'pages/SEO/Redirect/Redirect301';
import Monitor404 from 'pages/SEO/Monitor/Monitor404';
import Sitemap from 'pages/SEO/Sitemap/Sitemap';
import ClientPage from 'pages/ClientPage/ClientPage';
import CollectionItem from 'pages/Collection/Collection';
import BannerSetting from 'pages/BannerSetting/BannerSetting';
import { AuthenticationFeature } from 'constants/authentication';
import { ImageItem } from 'pages/ImageItem';

const commonRoutes = [
  { path: '/users', exact: true, feature: AuthenticationFeature.USER, component: CommonManagement },
  { path: '/users/:id', feature: AuthenticationFeature.USER, component: UserItem },
  { path: '/user-types', exact: true, feature: AuthenticationFeature.USER, component: CommonManagement },
  { path: '/user-types/:id', feature: AuthenticationFeature.USER, component: RoleItem },
  { path: '/customers', exact: true, feature: AuthenticationFeature.USER, component: CommonManagement },
  { path: '/customers/:id', exact: true, feature: AuthenticationFeature.USER, component: CustomerItem },

  { path: '/blogs', exact: true, feature: AuthenticationFeature.BLOG, component: CommonManagement },
  { path: '/blogs/:id', feature: AuthenticationFeature.BLOG, component: BlogsItem },
  { path: '/tags', exact: true, feature: AuthenticationFeature.BLOG, component: CommonManagement },
  { path: '/tags/:id', exact: true, feature: AuthenticationFeature.BLOG, component: TagItem },

  { path: '/diamonds', exact: true, feature: AuthenticationFeature.PRODUCT, component: CommonManagement },
  { path: '/diamond-serials', exact: true, feature: AuthenticationFeature.PRODUCT, component: CommonManagement },
  { path: '/diamond-serials/:id', exact: true, feature: AuthenticationFeature.PRODUCT, component: EditDiamond },

  { path: '/jewellery', exact: true, feature: AuthenticationFeature.PRODUCT, component: CommonManagement },
  { path: '/jewellery/:id', exact: true, feature: AuthenticationFeature.PRODUCT, component: EditJewellery },
  { path: '/jewellery-serials', exact: true, feature: AuthenticationFeature.PRODUCT, component: CommonManagement },
  { path: '/jewellery-new', exact: true, feature: AuthenticationFeature.PRODUCT, component: CommonManagement },

  { path: '/categories', exact: true, feature: AuthenticationFeature.PRODUCT, component: CommonManagement },
  { path: '/attributes', exact: true, feature: AuthenticationFeature.PRODUCT, component: CommonManagement },
  { path: '/luxury-jewelleries', exact: true, feature: AuthenticationFeature.PRODUCT, component: CommonManagement },
  { path: '/luxury-jewelleries/:id', exact: true, feature: AuthenticationFeature.PRODUCT, component: LuxuryJewelry },
  { path: '/collections', exact: true, feature: AuthenticationFeature.PRODUCT, component: CommonManagement },
  { path: '/collections/:id', exact: true, feature: AuthenticationFeature.PRODUCT, component: CollectionItem },
  { path: '/combo', exact: true, feature: AuthenticationFeature.PRODUCT, component: CommonManagement },
  { path: '/combo/:id', exact: true, feature: AuthenticationFeature.PRODUCT, component: ComboItem },
  { path: '/warehouse', exact: true, feature: AuthenticationFeature.PRODUCT, component: CommonManagement },

  { path: '/banner-settings', exact: true, feature: AuthenticationFeature.PAGE, component: CommonManagement },
  { path: '/banner-detail/:id', exact: true, feature: AuthenticationFeature.PAGE, component: BannerSetting },
  { path: '/page-settings', exact: true, feature: AuthenticationFeature.PAGE, component: CommonManagement },
  { path: '/page-settings/:id', exact: true, feature: AuthenticationFeature.PAGE, component: ClientPage },

  { path: '/coupons', exact: true, feature: AuthenticationFeature.COUPON, component: CommonManagement },
  { path: '/coupons/:id', exact: true, feature: AuthenticationFeature.COUPON, component: CouponItem },

  { path: '/title-meta', exact: true, feature: AuthenticationFeature.SEO, component: TitleMeta },
  { path: '/redirect-301', exact: true, feature: AuthenticationFeature.SEO, component: Redirect301 },
  { path: '/404-monitor', exact: true, feature: AuthenticationFeature.SEO, component: Monitor404 },
  { path: '/sitemap', exact: true, feature: AuthenticationFeature.SEO, component: Sitemap },

  { path: '/orders', exact: true, feature: AuthenticationFeature.ORDER, component: CommonManagement },
  { path: '/orders/:id', exact: true, feature: AuthenticationFeature.ORDER, component: OrderItem },

  { path: '/images', exact: true, feature: AuthenticationFeature.MEDIAFILE, component: CommonManagement },
  { path: '/images/:id', exact: true, feature: AuthenticationFeature.MEDIAFILE, component: ImageItem },

  { path: '/setting-header', exact: true, feature: AuthenticationFeature.INFO, component: SettingHeader },
  { path: '/setting-footer', exact: true, feature: AuthenticationFeature.INFO, component: SettingFooter },
  { path: '/setting-menu', exact: true, feature: AuthenticationFeature.INFO, component: SettingMenu },
  { path: '/setting-page', exact: true, feature: AuthenticationFeature.INFO, component: SettingPage },

  // them-o-day-ne
];

const authProtectedRoutes = [
  ...commonRoutes,
  { path: '/dashboard', component: Dashboard },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
];

export { authProtectedRoutes, publicRoutes };
