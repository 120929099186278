import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { UncontrolledCollapse, Col, Row, Label, Input, Button } from 'reactstrap';
import produce from 'immer';
import { Formik } from 'formik';
import { get, isEmpty } from 'lodash';
import * as yup from 'yup';
import { useParams, useHistory } from 'react-router';

import { ChevronUpIcon } from 'components/icons';
import { ActionsItem, FieldInput } from 'components/own';
import { useActionNotification } from 'hook/useContextSelector';

import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';

import {
  getJewelleryById,
  updateJewelleryById,
  getNewJewelleryOrder,
  updateNewJewelleryOrder,
  deleteNewJewelleryOrder,
} from 'services/jewellery';
import { getComboList, addJewelleryToCombo, deleteJewelleryFromCombo } from 'services/combo';
import { getCollectionList, addJewelleryToCollection, deleteJewelleryFromCollection } from 'services/collections';

import CustomInput from './components/CustomInput';
import Thumbnail from './components/Thumbnail';
import CustomBadge from './components/CustomBadge';
import CustomModal from './components/CustomModal';
import { API_UPLOAD, E_COMMERCE_URL } from 'constants/common';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;
const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
  cursor: pointer;
`;

const BlockContent = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
`;
const IconButton = styled.div`
  cursor: pointer;
`;
const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
`;
const ImageCol = styled.div`
  width: 20%;
`;

const productTypes = [
  {
    value: 1,
    label: 'Sản phẩm đơn',
    path: 'san-pham-don',
  },
  {
    value: 2,
    label: 'Sản phẩm tuỳ biến',
    path: 'san-pham-tuy-bien',
  },
  {
    value: 3,
    label: 'Sản phẩm đôi',
    path: 'san-pham-doi',
  },
];
const showOnWebOptions = [
  {
    value: false,
    label: 'Ẩn',
  },
  {
    value: true,
    label: 'Hiện',
  },
];
const hiddenPriceOptions = [
  {
    value: true,
    label: 'Ẩn',
  },
  {
    value: false,
    label: 'Hiện',
  },
];
const ecommerceUrl = E_COMMERCE_URL;
// const ecommerceUrl = process.env.REACT_APP_E_COMMERCE_URL;
const generateLink = (ecommerceUrl, productType, types, productId) => {
  const foundType = types.find(type => type.value === productType);
  if (foundType) {
    return `${ecommerceUrl}${foundType.path}/${productId}`;
  }
  return null;
};

function EditJewellry() {
  const [state, setState] = useState({ data: null, options: { types: null, status: {} } });
  const [newJewelleryOrder, setNewJewelleryOrder] = useState([]);
  const [collections, setCollections] = useState([]);
  const [combo, setCombo] = useState([]);
  // State mediafiles -> Cho phép render đủ 10 ảnh kể cả ảnh trống
  const [mediafiles, setMediaFiles] = useState([]);
  const [isCollectionOpen, setIsCollectionOpen] = useState(false);
  const [isComboOpen, setIsComboOpen] = useState(false);
  const [inStockCount, setInStockCount] = useState(0);
  const { data, options } = state;
  const { id } = useParams();
  const router = useHistory();
  const {
    location: { state: stateLocation },
  } = router;

  const pushNotification = useActionNotification();
  useEffect(() => {
    getNewJewelleryOrder().then(res => {
      res.statusCode === 200 && setNewJewelleryOrder([...res.data]);
    });
    getJewelleryById(id).then(res => {
      res.statusCode === 200 &&
        setState(
          produce(draft => {
            // handle images
            const { images } = res.data.mediafiles;
            if (!!images) {
              const imagesLength = images.length;
              const limitImage = 10;
              const restLength = limitImage - imagesLength;
              const newMediaFiles = [...res.data.mediafiles.images];
              for (let index = 0; index < restLength; index++) {
                newMediaFiles.push({});
              }

              setMediaFiles(newMediaFiles);
            }
            Promise.all([getComboList(), getCollectionList()]).then(values => {
              const newCombo = values[0].data.list;
              const newCollections = values[1].data.list;

              // handle combo
              const comboResponse = res.data.comboList;
              comboResponse.forEach(combo => {
                const index = newCombo.findIndex(comb => comb.id === combo.id);
                if (index !== -1) {
                  newCombo[index].checked = true;
                  newCombo[index].isOld = true;
                }
              });

              // handle collections
              const collectionsResponse = res.data.collectionList;
              collectionsResponse.forEach(collection => {
                const index = newCollections.findIndex(coll => coll.id === collection.id);
                if (index !== -1) {
                  newCollections[index].checked = true;
                  newCollections[index].isOld = true;
                }
              });

              // handle inStock Count
              const serialListResponse = res.data.serialList;
              // type: 1 -> real, 0 -> fake
              const realSerialList = serialListResponse.filter(serial => serial.type === 1);

              setInStockCount(realSerialList.length);
              setCombo(newCombo);
              setCollections(newCollections);
            });
            draft.data = { ...res.data, isNewProduct: !!res.data.newProductInfo };
            draft.options.types = productTypes;
            draft.options.status.isShowOnWeb = showOnWebOptions;
            draft.options.status.isHiddenPrice = hiddenPriceOptions;
          })
        );
    });
  }, [id]);

  const validationSchema = useMemo(() => yup.object().shape({}), []);
  const link = useMemo(() => generateLink(ecommerceUrl, data?.type, productTypes, data?.slug), [data]);
  const infoFields = useMemo(
    () => ({
      id: 'product-info-block',
      title: 'Thông tin sản phẩm',
      products: [
        {
          label: 'Danh mục',
          name: 'mainCategory',
          disabled: true,
        },
        {
          label: 'Đường dẫn',
          name: '',
          disabled: true,
          value: link,
        },
        {
          label: 'Size',
          name: 'size',
          disabled: true,
        },
        {
          label: 'Mã sản phẩm',
          name: 'productCode',
          disabled: true,
        },
        {
          label: 'Giới tính',
          name: '',
          disabled: true,
        },
        {
          label: 'Mã sản phẩm cũ',
          name: 'productName',
          disabled: true,
        },
        {
          label: 'Loại sản phẩm',
          name: 'type',
          controlType: ControlTypes.SELECT_PICKER,
          option: 'types',
          isDisabled: true,
        },
        {
          label: 'Giá',
          name: 'price',
          disabled: true,
        },
        {
          label: 'Tổng sản phẩm',
          name: 'inStockCount',
          value: inStockCount,
          disabled: true,
        },
        {
          label: 'Serial',
          name: 'productCode',
          disabled: true,
        },
        {
          label: 'Mô tả sản phẩm',
          name: 'desc',
        },
      ],
    }),
    [inStockCount, link]
  );
  const propertyFields = useMemo(
    () => ({
      id: 'product-property-block',
      title: 'Thuộc tính sản phẩm',
      products: [
        {
          label: 'TSV Loại vàng',
          name: 'goldProperty',
          extraProperties: false,
          disabled: true,
        },
        {
          label: 'Thu mua',
          name: 'rebuyPrice',
          extraProperties: true,
          disabled: true,
        },
        {
          label: 'TSV Size sản phẩm',
          name: 'size',
          extraProperties: false,
          disabled: true,
        },
        {
          label: 'Đổi hàng',
          name: 'exchangePrice',
          extraProperties: true,
          disabled: true,
        },
        {
          label: 'TSV Kim cương chủ',
          name: 'stringDiamondSize',
          extraProperties: false,
          disabled: true,
        },
        {
          label: 'TSV Trọng lượng vàng (chỉ)',
          name: 'goldWeightThread',
          extraProperties: true,
          disabled: true,
        },
        {
          label: 'TSV Kim cương tấm',
          name: 'plateDiamond',
          extraProperties: true,
          disabled: true,
        },
        {
          label: 'TSV Trọng lượng vàng (gram)',
          name: 'goldWeightGram',
          extraProperties: true,
          disabled: true,
        },
        {
          label: 'TSV Hàm lượng vàng',
          name: 'goldType',
          extraProperties: true,
          disabled: true,
        },
        {
          label: 'TSV Ổ chủ',
          name: 'hasDiamond',
          extraProperties: false,
          disabled: true,
        },
        {
          label: 'Kiểu hiển thị',
          name: '',
          extraProperties: false,
          disabled: true,
        },
        {
          label: 'Ngày đăng',
          name: 'createdAt',
          controlType: ControlTypes.DATE_PICKER,
          extraProperties: false,
          disabled: true,
        },
        {
          label: 'Sản phẩm mới',
          name: 'isNewProduct',
          controlType: ControlTypes.CHECKBOX,
          extraProperties: false,
          // disabled: stateLocation !== 'new-jewellery',
        },
      ],
    }),
    []
  );
  const seoFields = useMemo(
    () => ({
      id: 'seo-product-block',
      title: 'SEO',
      products: [
        {
          label: 'Tiêu đề',
          name: 'title',
        },
        {
          label: 'Link',
          name: 'link',
        },
        {
          label: 'Canonical link',
          name: 'canonicalURL',
        },
        {
          label: 'Redirection',
          name: 'redirection',
        },
      ],
    }),
    []
  );
  const statusFields = useMemo(
    () => [
      {
        label: 'Trạng thái',
        name: 'isShowOnWeb',
        placeholder: 'Ẩn',
        controlType: ControlTypes.SELECT_PICKER,
      },
      {
        label: 'Hiển thị giá',
        name: 'isHiddenPrice',
        placeholder: 'Hiện',
        controlType: ControlTypes.SELECT_PICKER,
      },
    ],
    []
  );

  const handleAdd = useCallback(
    (list, add) => {
      const target = list.filter(item => item.checked && !item.isOld);
      for (const item of target) {
        add(item.id, id);
      }
    },
    [id]
  );

  const handleDelete = useCallback(
    (list, _delete) => {
      const target = list.filter(item => item.checked === false);
      for (const item of target) {
        _delete(item.id, id);
      }
    },
    [id]
  );

  const handleChangeOrder = (currentProduct, jewelleryOrderList) => {
    const { productCode, isNewProduct, newProductInfo } = currentProduct;
    if (isNewProduct === false) {
      // Xoá SP mới
      deleteNewJewelleryOrder(productCode);
    }
    // Update SP mới
    else if (newProductInfo) {
      const oldOrder = jewelleryOrderList.find(jewelleryOrder => jewelleryOrder.productCode === productCode);
      if (+newProductInfo.order === oldOrder?.order) {
        return;
      }
      const newJewelleryOrderBody = [
        {
          productCode,
          order: +newProductInfo.order,
        },
      ];
      updateNewJewelleryOrder({
        list: newJewelleryOrderBody,
      });
    }
    // Thêm mới SP mới
    else if (isNewProduct) {
      const order = newProductInfo?.order || 1;
      const newJewelleryOrderBody = [
        {
          productCode,
          order: +order,
        },
      ];
      updateNewJewelleryOrder({
        list: newJewelleryOrderBody,
      });
    }
  };

  const onSave = useCallback(
    values => {
      Promise.all([
        handleAdd(collections, addJewelleryToCollection),
        handleAdd(combo, addJewelleryToCombo),
        handleDelete(collections, deleteJewelleryFromCollection),
        handleDelete(combo, deleteJewelleryFromCombo),
        handleChangeOrder(values, newJewelleryOrder),
      ]).then(() => {
        updateJewelleryById(values.productCode, values)
          .then(() => {
            pushNotification('success', 'Cập nhật thành công');
            // router.replace(stateLocation === 'new-jewellery' ? '/jewellery-new' : '/jewellery');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      });
    },
    [pushNotification, collections, combo, handleAdd, handleDelete, newJewelleryOrder]
  );

  const onCancel = useCallback(() => {
    router.replace(stateLocation === 'new-jewellery' ? '/jewellery-new' : '/jewellery');
  }, [router, stateLocation]);

  const handleDeleteItem = useCallback((itemId, list, setState) => {
    const newList = [...list];
    const index = newList.findIndex(item => item.id === itemId);
    if (index !== -1) {
      newList[index].checked = false;
      setState(newList);
    }
  }, []);

  const handleSelectItem = useCallback((newData, setState, setCloseModal) => {
    setState(newData);
    setCloseModal(prevToggle => !prevToggle);
  }, []);

  const handleChangeImage = useCallback(
    ({ currentImage, event, setFieldValue }) => {
      const files = event.target.files;
      const index = event.target.id;
      let emptyObjectCount = 0;
      const filesLength = files.length;
      for (const file of mediafiles) {
        isEmpty(file) && emptyObjectCount++;
      }
      // Chọn nhiều hơn số lượng ảnh trống còn lại
      if (filesLength > emptyObjectCount) {
        pushNotification('error', 'Bạn chọn quá số lượng, ảnh còn lại: ' + emptyObjectCount);
      } else {
        const newMediafiles = [...mediafiles];
        for (const image of files) {
          const formData = new FormData();
          formData.append('file', image);
          formData.append('t', true);

          fetch(API_UPLOAD, {
            method: 'post',
            body: formData,
          })
            .then(response => {
              new Response(response.body, { headers: { 'Content-Type': 'text/html' } })
                .text()
                .then(res => {
                  const respJson = JSON.parse(res);
                  const emptyIndex = newMediafiles.findIndex(file => isEmpty(file));
                  const targetIndex = currentImage ? index : emptyIndex;
                  if (targetIndex !== -1) {
                    newMediafiles[targetIndex] = {
                      mainImage: respJson.data.url,
                      subImage: respJson.data.url,
                      urlVideo: null,
                    };

                    setFieldValue(`mediafiles.images[${targetIndex}]`, {
                      mainImage: respJson.data.url,
                      subImage: respJson.data.url,
                      urlVideo: null,
                    });
                  }
                })
                .catch(error => {
                  pushNotification('error', error);
                });
            })
            .catch(error => {
              pushNotification('error', error);
            });
        }
        setMediaFiles(newMediafiles);
      }
    },
    [pushNotification, mediafiles]
  );
  const handleDeleteImage = useCallback(
    ({ imageUrl, setFieldValue, urlVideo }) => {
      const newMediafiles = mediafiles.filter(file => file.subImage !== imageUrl || file.urlVideo !== urlVideo);
      while (newMediafiles.length < 10) {
        newMediafiles.push({});
      }
      const formikMediafiles = newMediafiles.filter(file => !isEmpty(file));

      setMediaFiles(newMediafiles);
      setFieldValue(`mediafiles.images`, formikMediafiles);
    },
    [mediafiles]
  );
  const handleChangeVideoUrl = useCallback(
    ({ urlVideo, currentVideoUrl, setFieldValue, index }) => {
      const newMediafiles = [...mediafiles];
      const emptyIndex = newMediafiles.findIndex(file => isEmpty(file));
      const targetIndex = currentVideoUrl ? index : emptyIndex;
      if (targetIndex !== -1) {
        newMediafiles[targetIndex] = {
          mainImage: null,
          subImage: null,
          urlVideo,
        };

        setFieldValue(`mediafiles.images[${targetIndex}]`, {
          mainImage: null,
          subImage: null,
          urlVideo,
        });
        setMediaFiles(newMediafiles);
      }
    },
    [mediafiles]
  );

  if (!data) return <div />;
  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chỉnh sửa thông tin sản phẩm</Title>
      </div>
      <div
        style={{
          border: '1px solid #C7C9D9',
          backgroundColor: '#FAFAFC',
          padding: '15.5px 8px',
          fontSize: 18,
        }}
      >
        {data.productName}
      </div>
      <div
        style={{
          paddingLeft: 8,
          paddingRight: 8,
          fontSize: 16,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        Đường dẫn:
        <a style={{ marginLeft: 5 }} href={link}>
          {link}
        </a>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, setFieldValue, submitCount, touched = {}, values = {} }) => {
          return (
            <>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between" id="info_field">
                  {infoFields.title}
                  <IconButton>
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <UncontrolledCollapse defaultOpen toggler="#info_field">
                  <BlockContent>
                    {infoFields.products.map((info, index) => (
                      <Row key={`infoFields-${info.name}-${index}`} className="align-items-center">
                        {info.name === 'price' ? (
                          <CustomInput
                            {...info}
                            error={get(touched, info.name) || !!submitCount ? get(errors, info.name) : null}
                            value={get(values, info.name)}
                            autoComplete="new-password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            // options={options[info.option]}
                            placeholder={info.placeholder || info.label}
                            onValueChange={values => setFieldValue('price', values.value)}
                          />
                        ) : (
                          <FieldInput
                            {...info}
                            error={get(touched, info.name) || !!submitCount ? get(errors, info.name) : null}
                            value={info.value ?? get(values, info.name)}
                            autoComplete="new-password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            options={options[info.option]}
                            placeholder={info.placeholder || info.label}
                          />
                        )}
                      </Row>
                    ))}
                  </BlockContent>
                  <BlockContent>
                    <Row className="align-items-center">
                      <LabelInput className="col-md-3 col-form-label">{'Hình thu nhỏ *'}</LabelInput>
                      <Col md={9}>
                        <Row className="justify-content-start">
                          {mediafiles.map((image, index) => (
                            <ImageCol className="mb-1" key={index}>
                              <Thumbnail
                                id={index}
                                avatarSrc={image.subImage}
                                urlVideo={image.urlVideo}
                                onChangeImage={event =>
                                  handleChangeImage({
                                    event,
                                    currentImage: image.subImage,
                                    setFieldValue,
                                  })
                                }
                                onDelete={() =>
                                  handleDeleteImage({
                                    imageUrl: image.subImage,
                                    urlVideo: image.urlVideo,
                                    setFieldValue,
                                  })
                                }
                                onChangeUrl={url =>
                                  handleChangeVideoUrl({
                                    urlVideo: url,
                                    currentVideoUrl: image.urlVideo,
                                    setFieldValue,
                                    index,
                                  })
                                }
                                multiple={!image.subImage}
                              />
                            </ImageCol>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </BlockContent>
                  <AddList
                    label={'Bộ sưu tập'}
                    labelButton={'Thêm Bộ sưu tập'}
                    type={'collection'}
                    list={collections}
                    setList={setCollections}
                    onDeleteItem={handleDeleteItem}
                    setOpenModal={setIsCollectionOpen}
                  />
                  <AddList
                    label={'Bộ trang sức'}
                    labelButton={'Thêm Bộ trang sức'}
                    type={'combo'}
                    list={combo}
                    setList={setCombo}
                    onDeleteItem={handleDeleteItem}
                    setOpenModal={setIsComboOpen}
                  />
                  <BlockContent>
                    {statusFields.map(field => (
                      <Row className="align-items-center" key={field.name}>
                        <FieldInput
                          {...field}
                          error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                          value={get(values, field.name)}
                          autoComplete="new-password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          options={options.status[field.name]}
                        />
                      </Row>
                    ))}
                  </BlockContent>
                </UncontrolledCollapse>
              </Block>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between" id="property_field">
                  {propertyFields.title}
                  <IconButton>
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <UncontrolledCollapse defaultOpen toggler="#property_field">
                  <BlockContent>
                    {propertyFields.products.map((property, index) => {
                      const { label, name, extraProperties, placeholder } = property;
                      return (
                        <Row key={`propertyFields-${property.name}-${index}`} className="align-items-center">
                          <FieldInput
                            {...property}
                            error={get(touched, name) || !!submitCount ? get(errors, name) : null}
                            value={get(extraProperties ? values.extraProperties : values, name)}
                            autoComplete="new-password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            // options={options[property.option]}
                            placeholder={placeholder || label}
                          />
                        </Row>
                      );
                    })}
                    <Row className="align-items-center">
                      <FieldInput
                        name="newProductInfo.order"
                        label="Thứ tự hiển thị"
                        error={get(touched, 'order') || !!submitCount ? get(errors, 'order') : null}
                        value={get(values.newProductInfo, 'order')}
                        autoComplete="new-password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // options={options[property.option]}
                        placeholder={'Thứ tự hiển thị'}
                      />
                    </Row>
                  </BlockContent>
                </UncontrolledCollapse>
              </Block>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between" id="seo_field">
                  {seoFields.title}
                  <IconButton>
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <UncontrolledCollapse defaultOpen toggler="#seo_field">
                  <BlockContent>
                    {seoFields.products.map(seo => {
                      return (
                        <Row key={`seoFields-${seo.name}`} className="align-items-center">
                          <FieldInput
                            {...seo}
                            error={get(touched, seo.name) || !!submitCount ? get(errors, seo.name) : null}
                            value={get(values.SEOInfo, seo.name)}
                            autoComplete="new-password"
                            onBlur={handleBlur}
                            onChange={event => setFieldValue(`SEOInfo.${seo.name}`, event.target.value)}
                            // options={options[property.option]}
                            placeholder={seo.placeholder || seo.label}
                          />
                        </Row>
                      );
                    })}
                  </BlockContent>
                  <Row style={{ padding: 16 }} className="align-items-center">
                    <LabelInput className="col-form-label" style={{ width: '12.5%' }}>
                      {'Mô tả (description)'}
                    </LabelInput>
                    <Col>
                      <Input
                        value={get(values.SEOInfo, 'desc')}
                        type="textarea"
                        onChange={event => setFieldValue('SEOInfo.desc', event.target.value)}
                      />
                    </Col>
                  </Row>
                </UncontrolledCollapse>
              </Block>
              <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.USER} />
            </>
          );
        }}
      </Formik>
      <CustomModal
        title="Danh sách bộ sưu tập"
        isOpen={isCollectionOpen}
        toggle={() => setIsCollectionOpen(prevToggle => !prevToggle)}
        data={collections}
        onChange={newData => handleSelectItem(newData, setCollections, setIsCollectionOpen)}
      />
      <CustomModal
        title="Danh sách bộ trang sức"
        isOpen={isComboOpen}
        toggle={() => setIsComboOpen(prevToggle => !prevToggle)}
        data={combo}
        onChange={newData => handleSelectItem(newData, setCombo, setIsComboOpen)}
      />
    </div>
  );
}

const AddList = ({ label, labelButton, list, type, setList, onDeleteItem, setOpenModal }) => {
  return (
    <Row style={{ padding: 16 }} className="align-items-center">
      <LabelInput className="col-form-label" style={{ width: '12.5%' }}>
        {label}
      </LabelInput>
      <div className="d-flex flex-wrap" style={{ width: '87.5%' }}>
        {list.map(
          item =>
            item.checked && <CustomBadge name={item.name} key={`${type}-${item.id}`} onDelete={() => onDeleteItem(item.id, list, setList)} />
        )}
        <Button outline color="primary" style={{ fontWeight: 700, fontSize: 14 }} className="mb-1" onClick={() => setOpenModal(true)}>
          {labelButton}
        </Button>
      </div>
    </Row>
  );
};

export default EditJewellry;
