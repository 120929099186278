import { getAttributesInfo } from './attributes';
import { getCategoryInfo } from './categories';
import { getCollectionInfo } from './collection';
import { getComboInfo } from './combo';
import { getCouponInfo } from './coupons';
import { getCustomerInfo } from './customer';
import { getDiamondInfo } from './diamond';
import { getJewelryInfo } from './jewelry';
import { getJewelryNewInfo } from './jewelry-new';
import { getLuxuryInfo } from './luxury-jewelleries';
import { getOrderInfo } from './orders';
import { getPagesInfo } from './pages';
import { getRoleInfo } from './roles';
import { getTagsInfo } from './tags';
import { getUserInfo } from './user';
import { getBlogInfo } from './blogs';
import { getImagesInfo } from './images';
import { getStockInfo } from './stock';
import { getBannerInfo } from './banner';
import { getCategoryProductInfo } from './category-product';
import { getStockDiamondInfo } from './stock-diamond';
import { getCouponProductInfo } from './coupon-product';

export const getInfoManagementByUrl = url => {
  switch (url) {
    case '/diamonds':
      return getDiamondInfo();

    case '/diamond-serials':
      return getStockDiamondInfo();

    case '/jewellery':
      return getJewelryInfo();

    case '/jewellery-new':
      return getJewelryNewInfo();

    //Bộ trang sức
    case '/combo':
      return getComboInfo();

    //Bộ sưu tập
    case '/collections':
      return getCollectionInfo();

    // Thuộc tính sản phẩm
    case '/attributes':
      return getAttributesInfo();

    case '/luxury-jewelleries':
      return getLuxuryInfo();

    case '/categories':
      return getCategoryInfo();

    case '/coupons':
      return getCouponInfo();

    case '/orders':
      return getOrderInfo();

    case '/customers':
      return getCustomerInfo();

    case '/user-types':
      return getRoleInfo();

    case '/page-settings':
      return getPagesInfo();

    case '/tags':
      return getTagsInfo();

    case '/users':
      return getUserInfo();

    case '/blogs':
      return getBlogInfo();

    case '/images':
      return getImagesInfo();

    case '/jewellery-serials':
      return getStockInfo();

    case '/banner-settings':
      return getBannerInfo();

    case 'category-product':
      return getCategoryProductInfo();

    case 'coupon-product':
      return getCouponProductInfo();

    case '/them-o-day-ne':
      return {};

    default:
      return {
        buttonAddTitle: '',
        columns: [],
        columnTemplate: '',
        filterElements: [],
        selectable: false,
        title: '',
        url: '',
      };
  }
};
