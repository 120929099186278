import axios from 'axios';

export const getAllBlogs = (query = '') => axios({ url: 'blogs?' + query, method: 'GET' });

export const getBlogById = id => axios({ url: 'blogs/' + id, method: 'GET' });

export const createBlog = data => axios({ url: 'blogs', method: 'POST', data });

export const updateBlog = (id, data) => axios({ url: 'blogs/' + id, method: 'PUT', data });

export const updateBlogSEOInfo = (id, data) => axios({ url: 'blogs/seo/' + id, method: 'PUT', data });

export const deleteBlog = id => axios({ url: 'blogs/' + id, method: 'DELETE' });

export const getAllCategory = (query = '') => axios({ url: 'blogType?' + query, method: 'GET' });

export const getAllTags = (query = '') => axios({ url: 'tags?' + query, method: 'GET' });
