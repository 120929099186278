import React, { useRef } from 'react';
import clsx from 'clsx';
import './node-renderer-default.css';
import { isDescendant } from 'react-sortable-tree';
import { find } from 'lodash';
import { Accordion } from 'react-bootstrap';
import NodeParent from './NodeParent';
import NodeChildren from './NodeChildren';
import styled from 'styled-components';
import { debounce } from 'lodash';

const AccordionContent = styled(Accordion.Body)`
  padding: 8px;
`;

const AccordionHeader = styled(Accordion.Header)`
  height: 52px;

  background-color: #fff;
  .sub-title {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */

    /* Dark / Dark 0 */

    color: #1c1c28;
    margin-left: 12px;
  }

  .category-type {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    /* or 18px */

    /* Dark / Dark 0 */

    color: #1c1c28;
    margin-right: 8px;
  }
  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
  }
`;

const NodeMenuRenderer = props => {
  const {
    scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    connectDragPreview,
    connectDragSource,
    isDragging,
    canDrop,
    canDrag,
    node,
    title,
    subtitle,
    draggedNode,
    path,
    treeIndex,
    isSearchMatch,
    isSearchFocus,
    buttons,
    className,
    style,
    didDrop,
    treeId,
    isOver, // Not needed, but preserved for other renderers
    parentNode, // Needed for dndManager
    rowDirection,

    lowerSiblingCounts,
    isExtend,

    categories,
    onChange,
    onMoveNode,
    onRefresh,
    onSave,
    onDelete,
    origin,
    updateNode,

    ...otherProps
  } = props;

  const nodeTitle = title || node.name;
  const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;
  const ref = useRef();
  const scrollIntoView = () => {
    if (isExtend)
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      }); // initiate scroll to the "AddMock" Accordion component
  };

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  return (
    <div style={{ height: '100%' }} {...otherProps}>
      <div className={clsx('rst__rowWrapper', rowDirectionClass)}>
        {/* Set the row preview to be used during drag and drop */}
        {connectDragPreview(
          <div
            className={clsx(
              'rst__row',
              isLandingPadActive && 'rst__rowLandingPad',
              isLandingPadActive && !canDrop && 'rst__rowCancelPad',
              rowDirectionClass,
              className
            )}
            style={{
              opacity: isDraggedDescendant ? 0.5 : 1,
              ...style,
            }}
          >
            <div className="d-none" onClick={scrollIntoView} id={`node_${node.id}`}></div>
            <Accordion activeKey={isExtend ? [node.id] : []}>
              <Accordion.Item eventKey={node.id}>
                <AccordionHeader
                  ref={ref}
                  onClick={() => {
                    updateNode(node.id);
                  }}
                >
                  {nodeTitle}
                  {!!node.parentId && <div className="sub-title">Chỉ mục con</div>}
                  <div className="flex-1"></div>
                  {<div className="category-type">{!!find(categories, ['link', node.link]) ? 'Dạng danh mục' : 'Dạng liên kết tự tạo'}</div>}
                </AccordionHeader>
                <AccordionContent>
                  {!node.parentId ? (
                    <NodeParent
                      data={node}
                      onDelete={onDelete}
                      origin={origin}
                      onRefresh={onRefresh}
                      onMoveNode={onMoveNode}
                      onChange={onChange}
                      path={path}
                      onSave={onSave}
                    />
                  ) : (
                    <NodeChildren
                      data={node}
                      origin={origin}
                      onDelete={onDelete}
                      onRefresh={onRefresh}
                      onMoveNode={onMoveNode}
                      onChange={debounce(onChange, 350)}
                      path={path}
                      onSave={onSave}
                      parentData={parentNode}
                    />
                  )}
                </AccordionContent>
              </Accordion.Item>
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
};

export default NodeMenuRenderer;
