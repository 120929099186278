import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import produce from 'immer';
import { useParams } from 'react-router';
import Select from 'react-select';
import clsx from 'clsx';

import styled from 'styled-components';
import { Button, CardText, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledCollapse } from 'reactstrap';
import { createBlog, getAllCategory, getAllTags, getBlogById, updateBlog, updateBlogSEOInfo } from 'services/blog';
import { Card, Col, CardBody, Container } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@lnminh0710/vcl-editor';
import styles from './BlogsItem.module.scss';
import { useHistory } from 'react-router';

import { ChevronUpIcon, LargerDropIcon } from 'components/icons';
import { FieldInput } from 'components/own';
import classnames from 'classnames';
import { useCallback } from 'react';
import { useActionNotification } from 'hook/useContextSelector';
import { API_UPLOAD, E_COMMERCE_URL } from 'constants/common';
import { getSlugFromCategory, parseDate, removeVietnameseTones } from 'utils/uti';
import { find, get } from 'lodash';
import { deleteAPI } from 'services/common';
import BlogCategory from './BlogCategory';

const initialBlogs = {
  status: 1,
  title: '',
  slug: '',
  type: 1,
  body: '',
  seoInfo: {
    title: '',
    link: '',
    desc: '',
    canonicalUrl: '',
  },
  mediaFiles: {
    image: '',
  },
};

const initialState = {
  data: null,
  options: {
    activeTab: '1',
  },
  categoryId: 0,
  tagIds: [],
  tagsResult: [],
  avatarUrl: '',
  newAvatarUrl: '',
};
const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;
const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
`;

const BlockContent = styled(UncontrolledCollapse)`
  padding: 16px;
  display: block;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const Link = styled.a`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-decoration: underline !important;

  /* Blue / Blue 1 */

  color: #0063f7;
  margin-right: 12px;
`;

const TextZone = styled.div`
  width: 100%;
  textarea {
    height: 100%;
  }
`;

const customSelect = {
  1: 'Lưu nháp',
  2: 'Hiển thị',
  '-1': 'Xóa',
};

const BlogsItem = () => {
  const router = useHistory();
  const [{ data, options, categoryId, tagIds, defaultTags, avatarUrl, newAvatarUrl }, setState] = useState(initialState);
  const [showOrigin, setShowOrigin] = useState(false);
  const [editingSlug, setEditingSlug] = useState(true);
  const { id } = useParams();

  const showPreview = useCallback(() => {
    var popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <style>img {width: 100%;}</style>
      <head>
        <title>Print tab</title>
      </head>
  <body><div style="position:relative">${data?.body}</div></body>
    </html>`);
  }, [data?.body]);

  const pushNotification = useActionNotification();

  const handleChange = useCallback(e => {
    setState(
      produce(draft => {
        draft.categoryId = e.target.value;
      })
    );
  }, []);

  const handleTags = useCallback(e => {
    let result = [];
    e.forEach(item => {
      result.push(item.value);
    });
    setState(
      produce(draft => {
        draft.tagIds = result;
      })
    );
  }, []);

  useEffect(() => {
    const callAPI = async () => {
      const res = await getAllCategory('');
      setState(
        produce(draft => {
          draft.options.category = res?.data;
        })
      );
    };
    callAPI();
  }, []);

  useEffect(() => {
    const callAPI = async () => {
      const resPopular = await getAllCategory('popular=true');
      setState(
        produce(draft => {
          draft.options.categoryPopular = resPopular?.data;
        })
      );
    };
    callAPI();
  }, []);

  useEffect(() => {
    const callAPI = async () => {
      const tags = await getAllTags('');
      let result = [];
      tags?.data?.rows.forEach(item => {
        result.push({ value: item.id, label: item.title });
      });
      setState(
        produce(draft => {
          draft.options.tags = result;
        })
      );
    };
    callAPI();
  }, []);

  useEffect(() => {
    if (id === 'new') {
      setState(
        produce(draft => {
          draft.data = initialBlogs;
        })
      );
    } else {
      getBlogById(id).then(res => {
        let result = [];
        let tagsResult = [];
        res.data?.tags?.forEach(item => {
          result.push(item.id);
          tagsResult.push({ value: item.id, label: item.title });
        });
        setEditingSlug(false);
        setState(
          produce(draft => {
            draft.data = res.data;
            draft.categoryId = res.data?.blogTypeInfo?.id;
            draft.tagIds = result;
            draft.defaultTags = tagsResult;
            draft.avatarUrl = res.data?.mediaFiles?.image;
          })
        );
      });
    }
  }, [id]);

  const content = useMemo(
    () => [
      {
        label: 'Tiêu đề',
        name: 'title',
      },
      {
        label: 'Link',
        name: 'link',
      },
      {
        label: 'Đoạn văn',
        name: 'desc',
      },
      {
        label: 'Canonical URL',
        name: 'canonicalUrl',
        type: 'url',
      },
      {
        label: 'Schema markup',
        name: 'schemaMarkup',
        type: 'textarea',
        rows: 4,
      },
    ],
    []
  );
  // const editorConfiguration = {
  //   toolbar: ['bold', 'italic'],
  // };
  const toggle = tab => {
    if (options.activeTab !== tab) {
      setState(
        produce(draft => {
          draft.options.activeTab = tab;
        })
      );
    }
  };

  const deleteBlog = useCallback(
    id => {
      deleteAPI('blogs/' + id).then(() => {
        pushNotification('success', 'Xoá thành công');
        router.replace('/blogs');
      });
    },
    [pushNotification, router]
  );

  const updateBlogs = useCallback(() => {
    let blogsDetail = {
      status: document.getElementById('blogsStatus').value,
      title: document.getElementById('blogsTitle').value,
      slug: document.getElementById('blogsSlug').value,
      type: categoryId,
      body: data?.body,
    };
    if (tagIds != null && tagIds !== []) {
      blogsDetail.tagIds = tagIds;
    }
    if (newAvatarUrl != null && newAvatarUrl !== '') {
      blogsDetail.mediaFiles = { image: newAvatarUrl };
      blogsDetail.publishAt = new Date();
    }
    let blogsSEO = {
      title: document.getElementById('title').value,
      link: document.getElementById('link').value,
      desc: document.getElementById('desc').value,
      canonicalUrl: document.getElementById('canonicalUrl').value,
      schemaMarkup: document.getElementById('schemaMarkup').value,
    };
    if (blogsDetail.title === '' || blogsDetail.slug === '' || blogsDetail.body === '') {
      pushNotification('warning', 'Vui lòng nhập đầy đủ thông tin');
    } else {
      if (id === 'new') {
        blogsDetail.seoInfo = blogsSEO;
        createBlog(blogsDetail)
          .then(() => {
            pushNotification('success', 'Tạo thành công');
            router.replace('/blogs');
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            pushNotification('error', error.message);
          });
      } else {
        updateBlog(id, blogsDetail)
          .then(() => {
            pushNotification('success', 'Update thành công');
          })
          .catch(error => {
            pushNotification('error', error.error);
          });
        updateBlogSEOInfo(id, { seoInfo: blogsSEO })
          .then(() => {
            pushNotification('success', 'Lưu thông tin SEO thành công');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      }
    }
  }, [categoryId, data?.body, tagIds, newAvatarUrl, pushNotification, id, router]);

  const activeFile = () => {
    // $('#uploadFile').trigger('click');
    document.getElementsByClassName('uploadFile')[0].click();
  };

  const handleAvatarChange = event => {
    let image = event.target.files[0];
    const formData = new FormData();

    // Update the formData object
    formData.append('file', image, image.name);
    formData.append('t', true);
    uploadImage(formData);
  };

  const uploadImage = useCallback(
    async data => {
      fetch(API_UPLOAD, {
        method: 'post',
        body: data,
      })
        .then(response => {
          new Response(response.body, { headers: { 'Content-Type': 'text/html' } })
            .text()
            .then(res => {
              const respJson = JSON.parse(res);
              setState(
                produce(draft => {
                  draft.avatarUrl = respJson?.data?.url;
                  draft.newAvatarUrl = respJson?.data?.url;
                })
              );
            })
            .catch(error => {
              pushNotification('error', error);
            });
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [pushNotification]
  );

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then(file => {
            console.log('files ', file);
            body.append('file', file);
            body.append('t', true);
            fetch(API_UPLOAD, {
              method: 'post',
              body,
            })
              .then(response => {
                new Response(response.body, { headers: { 'Content-Type': 'text/html' } }).text().then(res => {
                  const respJson = JSON.parse(res);
                  resolve({
                    default: respJson?.data?.url,
                  });
                });
              })
              .catch(error => {
                reject(error);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return uploadAdapter(loader);
    };
  }

  const slug = useMemo(() => getSlugFromCategory(options.category, data?.blogTypeInfo?.id), [data?.blogTypeInfo?.id, options.category]);
  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chi tiết bài viết</Title>
      </div>
      <div className={`page-content ${styles['blogs']} `}>
        <Container fluid>
          <Row>
            <Col xl={9}>
              <Card>
                <CardBody className={`${styles['blogs__content']}`}>
                  <Row className="mb-3">
                    <Label htmlFor="blogsTitle" className="col-md-2 col-form-label">
                      Tiêu đề
                    </Label>
                    <Col md={10}>
                      <Input
                        type="text"
                        name="title"
                        defaultValue={data?.title}
                        id="blogsTitle"
                        onChange={e => {
                          if (editingSlug) document.getElementById('blogsSlug').value = removeVietnameseTones(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label htmlFor="blogsSlug" className="col-md-2 col-form-label">
                      Link:
                    </Label>
                    <Col md={10}>
                      <Input type="url" defaultValue={data?.slug} id="blogsSlug" className={editingSlug ? '' : 'd-none'} />
                      <div className={clsx('d-flex align-items-center', { 'd-none': editingSlug })}>
                        <Link
                          href={`${E_COMMERCE_URL}${slug}${document.getElementById('blogsSlug')?.value || data?.slug}`}
                          target="_blank"
                        >{`${E_COMMERCE_URL}${slug}${document.getElementById('blogsSlug')?.value || data?.slug}`}</Link>
                        <Button color="success" outline className="waves-effect waves-light me-1" onClick={() => setEditingSlug(true)}>
                          Chỉnh sửa
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Nav tabs style={{ justifyContent: 'flex-end' }}>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: !showOrigin,
                        })}
                        onClick={() => setShowOrigin(false)}
                      >
                        Trực quan
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: showOrigin,
                        })}
                        onClick={() => setShowOrigin(true)}
                      >
                        Văn bản
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={!showOrigin ? 'ckeditor' : 'html'}>
                    <TabPane tabId="ckeditor" className="p-3">
                      {!showOrigin && (
                        <CKEditor
                          config={{
                            extraPlugins: [uploadPlugin],
                          }}
                          editor={ClassicEditor}
                          data={data.body}
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            setState(
                              produce(draft => {
                                draft.data.body = editor.getData();
                              })
                            );
                            // newBody = editor.getData();
                            //   const data = editor.getData();
                            //   console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        />
                      )}
                    </TabPane>
                    <TabPane tabId="html" className="p-3">
                      <TextZone>
                        <FieldInput
                          type="textarea"
                          value={data?.body}
                          name="body"
                          inputMd={12}
                          onChange={e => {
                            setState(
                              produce(draft => {
                                draft.data.body = e.target.value;
                              })
                            );
                          }}
                        />
                      </TextZone>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  SEO
                  <IconButton id="SEO">
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent defaultOpen toggler={`#SEO`}>
                  {content.map(field => (
                    <Row key={field.name} className="mt-2">
                      <FieldInput
                        {...field}
                        // error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                        defaultValue={id !== 'new' ? get(data, ['seoInfo', field?.name]) : ''}
                        // autoComplete="new-password"
                        // autoFocus={focusField === field.name}
                        // onKeyDown={handleKeyDown}
                        // onFocus={() => onChangeFocus(field.name)}
                        // onBlur={handleBlur}
                        // onChange={handleChange}
                        options={options[field.option]}
                        id={field.name}
                        placeholder={field.placeholder || field.label}
                      />
                    </Row>
                  ))}
                </BlockContent>
              </Block>
            </Col>

            <Col xl={3}>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  Đăng
                  <IconButton id="dang">
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent defaultOpen toggler={`#dang`} className={`${styles['blogs__submit']} p-0`}>
                  <div className={clsx(styles['blogs__submit--btn'], 'd-flex align-items-center justify-content-end')}>
                    <Button color="success" outline className="waves-effect waves-light me-1" onClick={() => showPreview()}>
                      Xem trước
                    </Button>
                  </div>
                  <Row className={`${styles['blogs__submit--label']}`}>
                    <span className="col-md-4 col-form-label">
                      <i className="mdi mdi-pencil"></i>&nbsp;&nbsp;Trạng thái
                    </span>
                    <Col md={8}>
                      <select className="form-select" id="blogsStatus" defaultValue={data.status}>
                        {Object.keys(customSelect).map(item => (
                          <option key={item} value={item}>
                            {customSelect[item]}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  <Row className={`${styles['blogs__submit--label']}`}>
                    <Col>
                      <i className="mdi mdi-calendar-month"></i> <span>&nbsp;&nbsp;Ngày đăng:&nbsp;&nbsp;</span>
                      <span>{parseDate(data.publishAt)}</span>
                    </Col>
                  </Row>
                  <div className={`${styles['blogs__submit--btn']} ${styles['blogs__submit--btn-bottom']} d-flex align-items-center`}>
                    {data?.id && (
                      <div className={styles['button-danger']} onClick={() => deleteBlog(data?.id)}>
                        Xóa
                      </div>
                    )}
                    <div className="flex-1"></div>
                    <Button color="success" className="waves-effect waves-light me-1" onClick={updateBlogs}>
                      Cập nhật
                    </Button>
                  </div>
                </BlockContent>
              </Block>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  Danh mục
                  <IconButton id="danh-muc">
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent defaultOpen toggler={`#danh-muc`}>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: options.activeTab === '1',
                        })}
                        onClick={() => {
                          toggle('1');
                        }}
                      >
                        Tất cả
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: options.activeTab === '2',
                        })}
                        onClick={() => {
                          toggle('2');
                        }}
                      >
                        Dùng nhiều
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={options.activeTab}>
                    <TabPane tabId="1" className="p-3">
                      <Row>
                        <Col sm="12">
                          <CardText>
                            {options.category?.map(item => (
                              <BlogCategory name="blogsType" key={item.id} data={item} selected={categoryId} handleChange={handleChange} />
                            ))}
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2" className="p-3">
                      <Row>
                        <Col sm="12">
                          <CardText>
                            {options.categoryPopular?.map(item => (
                              <BlogCategory
                                key={item.id}
                                name="blogsTypePopular"
                                data={item}
                                showCount
                                selected={categoryId}
                                handleChange={handleChange}
                              />
                            ))}
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </BlockContent>
              </Block>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  Thẻ
                  <IconButton id="the">
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent defaultOpen toggler={`#the`}>
                  <Row className={`${styles['blogs__submit--btn']}`}>
                    <Col md={8}>
                      <Select isMulti defaultValue={defaultTags} onChange={handleTags} className="basic-multi-select" options={options.tags} />
                    </Col>
                    <Col md={4}>
                      {' '}
                      <Button color="success" outline className="waves-effect waves-light me-1">
                        Thêm thẻ
                      </Button>
                    </Col>
                  </Row>
                </BlockContent>
              </Block>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  Ảnh đại diện
                  <IconButton id="avatar">
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent defaultOpen toggler={`#avatar`}>
                  {!avatarUrl ? (
                    <div onClick={activeFile}>
                      <LargerDropIcon />
                      <Input type="file" accept="image/*" className="uploadFile d-none" name="" onChange={handleAvatarChange} />
                    </div>
                  ) : (
                    <>
                      <span>Nhấn vào ảnh để chỉnh sửa</span>

                      <div className={`${styles['info__avatar-img']}`} onClick={activeFile}>
                        <img className="rounded" src={avatarUrl} alt="" width="100%" height="auto" />
                      </div>
                      <Input type="file" accept="image/*" className="uploadFile" name="" onChange={handleAvatarChange} />
                    </>
                  )}
                </BlockContent>
              </Block>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogsItem;
