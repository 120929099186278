import produce from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';

import axios from 'axios';
import { getProductAddonInfoByUrl } from './modules';
import { parseUrlQuery } from 'utils/uti';
import { deleteAPI, postAPI } from 'services/common';
import { useActionNotification } from 'hook/useContextSelector';
import { differenceBy, filter, get, includes, uniqBy } from 'lodash';

const initialState = {
  forceRender: 0,

  loading: true,

  selected: {
    data: [],
    searchValue: '',
    selectedAction: null,
    selectedRow: [],
    original: [],
    limit: 1000,
    hidePaging: false,
    order: { order: '', orderBy: '' },
    page: 1,
    total: 0,
    params: {},
  },
  other: {
    data: [],
    searchValue: '',
    selectedAction: null,
    selectedRow: [],
    limit: 10,
    hidePaging: false,
    order: { order: '', orderBy: '' },
    page: 1,
    total: 0,
    params: {},
  },
};

export const useAddProductHook = feature => {
  const pushNotification = useActionNotification();
  const ref = useRef();
  const [state, setState] = useState(initialState);
  const { id } = useParams();

  const info = useMemo(() => getProductAddonInfoByUrl(feature), [feature]);
  const keyword = useMemo(() => info.keyword || 'keyword', [info.keyword]);

  useEffect(() => {
    const callAPI = async () => {
      let query = parseUrlQuery(state.other?.params, state.other?.order, 'limit=' + state.other?.limit);
      if (state.other?.page) query += '&page=' + state.other?.page;
      if (state.other?.searchValue) query += `&${keyword}=` + state.other?.searchValue;
      try {
        const res = await axios.get('jewellery?' + query);
        setState(
          produce(draft => {
            draft.loading = false;
            draft.other.selectedRow = [];
            draft.other.data = res.data.list || res.data.rows || res.data || [];
            draft.other.total = typeof res.data.count === 'number' ? res.data.count : draft.other.data.length;
            draft.other.hidePaging = !res.data.count;
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.loading = false;
            draft.other.selectedRow = [];
            draft.other.data = [];
            draft.other.total = 0;
            draft.other.hidePaging = true;
          })
        );
      }
    };
    callAPI();
  }, [
    keyword,
    state.limit,
    state.order,
    state.other?.limit,
    state.other?.order,
    state.other?.page,
    state.other?.params,
    state.other?.searchValue,
    state.page,
    state.params,
    state.searchValue,
    state.forceRender,
  ]);
  useEffect(() => {
    const callAPI = async () => {
      let query = parseUrlQuery(state.selected?.params, state.selected?.order, 'limit=' + state.selected?.limit);
      if (state.selected?.page) query += '&page=' + state.selected?.page;
      if (state.selected?.searchValue) query += `&${keyword}=` + state.selected?.searchValue;
      try {
        const res = await axios.get(feature + '/' + id + '/jewellery?' + query);
        setState(
          produce(draft => {
            draft.loading = false;
            draft.selected.selectedRow = [];
            draft.selected.data = get(res, ['data', 'list']) || get(res, ['data', 'rows']) || get(res, ['data'], []);
            draft.selected.original = get(res, ['data', 'list']) || get(res, ['data', 'rows']) || get(res, ['data'], []);
            draft.selected.total = typeof res.data.count === 'number' ? res.data.count : draft.selected.data.length;
            draft.selected.hidePaging = !res.data.count;
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.selected.loading = false;
            draft.selected.data = [];
            draft.selected.selectedRow = [];
            draft.selected.original = [];
            draft.selected.total = 0;
            draft.selected.hidePaging = true;
          })
        );
      }
    };
    callAPI();
  }, [
    feature,
    id,
    keyword,
    state.selected?.limit,
    state.selected?.order,
    state.selected?.page,
    state.selected?.params,
    state.selected?.searchValue,
    state.forceRender,
  ]);

  const onChangePage = useCallback(({ selected }) => {
    setState(
      produce(draft => {
        draft.other.page = selected + 1;
        draft.other.loading = true;
      })
    );
  }, []);

  const onClickFilter = useCallback(force => {
    setState(
      produce(draft => {
        if (ref.current) {
          if (force) draft.forceRender += 1;
          draft.other.searchValue = ref.current.value;
          draft.other.loading = true;
          draft.other.page = 1;
        }
      })
    );
  }, []);

  const onSelectFilter = useCallback((e, level) => {
    setState(
      produce(draft => {
        draft[level].params[e.target.name] = e.target.value;
        draft.loading = true;
        draft[level].page = 1;
      })
    );
  }, []);

  const onKeypressFilter = useCallback(
    e => {
      if (e.which === 13) onClickFilter(true);
    },
    [onClickFilter]
  );

  const onOrder = useCallback((orderBy, order) => {
    setState(
      produce(draft => {
        if (order) draft.other.order = { order, orderBy };
        else draft.other.order = null;
      })
    );
  }, []);

  const onSelectRow = useCallback((id, isCheck, level) => {
    setState(
      produce(draft => {
        if (isCheck) draft[level].selectedRow.push(id);
        else draft[level].selectedRow = draft.selectedRow.filter(_r => _r !== id);
      })
    );
  }, []);

  const addToSelected = useCallback(() => {
    if (!state.other.selectedRow?.length) return;

    setState(
      produce(draft => {
        draft.selected.data.push(...filter(draft.other.data, _d => includes(state.other.selectedRow, _d.id)));
        draft.selected.data = uniqBy(draft.selected.data, 'id');
        draft.other.data = filter(draft.other.data, _d => !includes(state.other.selectedRow, _d.id));
        draft.other.selectedRow = [];
      })
    );
  }, [state.other.selectedRow]);

  const removeFromSelected = useCallback(() => {
    if (!state.selected.selectedRow?.length) return;

    setState(
      produce(draft => {
        draft.other.data.push(...filter(draft.selected.data, _d => includes(state.selected.selectedRow, _d.id)));
        draft.other.data = uniqBy(draft.other.data, 'id');
        draft.selected.data = filter(draft.selected.data, _d => !includes(state.selected.selectedRow, _d.id));
        draft.selected.selectedRow = [];
      })
    );
  }, [state.selected.selectedRow]);

  const handleSubmit = useCallback(() => {
    if (id === 'new') return;
    const removed = differenceBy(state.selected.original, state.selected.data, 'id');
    const added = differenceBy(state.selected.data, state.selected.original, 'id');

    const request = [];
    for (const iterator of removed) {
      request.push(deleteAPI(feature + '/' + id + '/' + iterator.id));
    }

    for (const iterator of added) {
      request.push(postAPI(feature + '/' + id + '/' + iterator.id));
    }

    Promise.all(request).then(res => {
      pushNotification('success', 'Lưu thành công');
      setState(
        produce(draft => {
          draft.forceRender += 1;
        })
      );
    });
  }, [feature, id, pushNotification, state.selected.data, state.selected.original]);

  const onCancel = useCallback(() => {
    setState(
      produce(draft => {
        draft.forceRender += 1;
      })
    );
  }, []);

  return {
    ...info,
    ...state,
    ref,
    onChangePage,
    onClickFilter,
    onKeypressFilter,
    onSelectRow,
    onOrder,
    onSelectFilter,
    addToSelected,
    removeFromSelected,
    handleSubmit,
    onCancel,
  };
};
