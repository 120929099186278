import { memo } from 'react';
import styled from 'styled-components';

import { OrderType } from 'constants/common';
import { OrderAscIcon, OrderDescIcon, OrderIcon } from 'components/icons';
import { Text } from '..';

const OrderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 2em;
  height: 2em;
  opacity: ${({ colorAlpha }) => (colorAlpha ? '0.5' : '1')};
`;

const ColumnOrder = ({ orderKey, onClick, orderType, text, ...rest }) => {
  return (
    <Text {...rest} onClick={() => onClick(orderKey, !orderType ? 'Asc' : orderType === OrderType.Asc ? 'Desc' : null)} cursor="pointer">
      {text}
      <OrderButton>{!orderType ? <OrderIcon /> : orderType === 1 ? <OrderAscIcon /> : <OrderDescIcon />}</OrderButton>
    </Text>
  );
};

export const checkOrderType = (dataField, param) => {
  if (param?.orderBy !== dataField) {
    return OrderType.NoOrder;
  }

  if (param.order === 'Asc') {
    return OrderType.Asc;
  }
  return OrderType.Desc;
};

export default memo(ColumnOrder);
