import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { ShowHideOptions } from 'constants/options';
import { CellControlType } from 'constants/table';

export const getCollectionInfo = () => ({
  buttonAddTitle: 'Thêm bộ sưu tập',
  feature: AuthenticationFeature.PRODUCT,
  columns: [
    {
      dataField: 'name',
      text: 'Danh sách bộ sưu tập',
      controlType: CellControlType.TextAction,
      orderKey: 'name',
      detailUrl: 'collections',
    },
    {
      dataField: 'totalProduct',
      text: 'Số lượng',
    },
    {
      dataField: 'mediafiles.image',
      text: 'Hình đại diện',
      controlType: CellControlType.Image,
    },
    {
      dataField: 'status',
      text: 'Trạng thái',
      controlType: CellControlType.Status,
      render: value => (value === 1 ? 'Đang hiển thị' : 'Không hiển thị'),
      color: value => (value === 1 ? '#007770' : '#E80000'),
      background: value => (value === 1 ? 'rgba(0, 119, 112, 0.1)' : 'rgba(232, 0, 0, 0.1)'),
    },
    {
      dataField: 'createdAt',
      text: 'Ngày tạo',
      sort: true,
      controlType: CellControlType.Date,
    },
    {
      dataField: 'action',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'collections',
      deleteUrl: 'collections',
    },
  ],
  columnTemplate: '1fr 100px 100px 150px 200px 100px',
  filterElements: [
    {
      name: 'status',
      placeholder: 'Trạng thái',
      controlType: ControlTypes.SELECT_PICKER,
      options: ShowHideOptions,
      width: '130px',
    },
  ],
  selectable: true,
  title: 'Danh sách bộ sưu tập',
  url: 'collections',
});
