import React, { Component } from 'react';
import { Container } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
// import MiniWidgets from './MiniWidgets';
// import RevenueAnalytics from './RevenueAnalytics';
// import SalesAnalytics from './SalesAnalytics';
// import EarningReports from './EarningReports';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: 'Vinh Cara', link: '/' },
        { title: 'Dashboard', link: '#' },
      ],
      reports: [
        { icon: 'ri-stack-line', title: 'Số lượng bán', value: '1452', rate: '120.4%', desc: 'So với kỳ trước' },
        { icon: 'ri-store-2-line', title: 'Doanh thu bán hàng', value: '38.452.000.000 VNĐ', rate: '82.4%', desc: 'So với kỳ trước' },
        { icon: 'ri-briefcase-4-line', title: 'Trung bình giá', value: '69.000.000 VNĐ', rate: '12.4%', desc: 'So với kỳ trước' },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
