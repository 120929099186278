import { memo, useEffect, useRef } from 'react';
import { Col, Input, Label } from 'reactstrap';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/uti';

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
  b {
    margin-left: 4px;
    color: #e63535;
  }
`;

const CustomInput = ({ autoFocus, error, touched, isRequired, label, name, inputMd = 9, ...rest }) => {
  const ref = useRef(name);

  useEffect(() => {
    if (autoFocus) ref?.current?.focus();
  }, [autoFocus]);

  return (
    <>
      {label && (
        <LabelInput className="col-md-3 col-form-label d-flex align-items-center">
          {label} {isRequired && <b>*</b>}
        </LabelInput>
      )}
      <Col md={inputMd}>
        <Input innerRef={ref} autoFocus={autoFocus} invalid={!!error} name={name} {...rest} />
        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
    </>
  );
};

export default memo(CustomInput, propsAreEqual);
