export const ControlTypes = {
  SELECT_PICKER: 'SELECT_PICKER',
  DATE_PICKER: 'DATE_PICKER',
  TIME_PICKER: 'TIME_PICKER',
  LABEL: 'LABEL',
  CHECKBOX: 'CHECKBOX',
  FILE: 'FILE',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  AUTOCOMPLETE_MULTI: 'AUTOCOMPLETE_MULTI',
  CURRENCY: 'CURRENCY',
  RICH_TEXT: 'RICH_TEXT',
};
