import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { cloneDeep, get, set } from 'lodash';
import { Accordion } from 'react-bootstrap';

import styled from 'styled-components';
import { Col, Label, Row } from 'reactstrap';
import { ActionsItem, FieldInput, SeoBlock, UploadFile } from 'components/own';
import { useActionNotification } from 'hook/useContextSelector';
import { AuthenticationFeature } from 'constants/authentication';
import { getAPI, postAPI, putAPI, uploadImage } from 'services/common';
import { API_ENDPOINT, E_COMMERCE_URL } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { GenderOptions, LuxuryTemplateOptions, OrderOptions } from 'constants/options';
import { AvatarDropIcon } from 'components/icons';
import { getChangedData, removeVietnameseTones } from 'utils/uti';

const TITLE_IMAGE_PC = 'Hình';

const InitialLuxury = {
  name: '',
  slug: '',
  productCode: '',
  gender: '',
  SEOInfo: {
    title: '',
    desc: '',
  },
  mediafiles: { image: '' },
  blocks: [],
};

const initialState = {
  data: null,
  options: {},
};

const initialBlock = {
  imageMobile: '',
  imagePC: '',
  isShow: true,
  order: 1,
  templateId: 1,
  text: '',
  title: '',
  list: [],
};

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const NameLabel = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  color: #000000;
`;

const LinkRow = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  height: 22px;
  align-items: center;
  margin-bottom: 16px;
`;

const LinkTitle = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #000000;
`;

const LinkValue = styled.a`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  text-decoration-line: underline;

  color: #0063f7;
  margin-left: 4px;

  &:hover {
    color: #0063f7;
  }
`;

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ $templateColumns }) => $templateColumns || '1fr'};
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const SpecialBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const NewBlockContainer = styled.div`
  width: 100%;
  height: 104px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px dashed #8f90a6;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

const AddBlockButton = styled.div`
  width: 178px;
  height: 56px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  border-radius: 4px;

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;
  text-transform: uppercase;

  /* Dark / Dark 0 */

  color: #1c1c28;
  i {
    margin-right: 4px;
  }
`;

const IconButton = styled.div`
  cursor: pointer;
  margin-right: 16px;
  i {
    color: #e53535;
  }
`;

const LuxuryJewelry = () => {
  const [{ data }, setState] = useState(initialState);
  const { id } = useParams();
  const router = useHistory();
  const pushNotification = useActionNotification();

  useEffect(() => {
    if (id === 'new') {
      setState(
        produce(draft => {
          draft.data = InitialLuxury;
        })
      );
    } else {
      getAPI('luxury-jewelleries/' + id).then(res => {
        setState(
          produce(draft => {
            draft.data = res.data;
            draft.data.productCodeSearch = get(res, ['data', 'jewelleryInfo', 'productName'], '');
          })
        );
      });
    }
  }, [id]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required('Vui lòng nhập tên trang.'),
        productCode: yup.string().required('Vui lòng nhập mã sản phẩm.'),
        gender: yup.string().required('Vui lòng chọn giới tính.'),
      }),
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/luxury-jewelleries');
  }, [router]);

  const onSave = useCallback(
    async values => {
      let request = cloneDeep(values);
      delete request.jewelleryInfo;
      if (request.mediafiles?.image && typeof request.mediafiles?.image !== 'string') {
        const res = await uploadImage(request.mediafiles?.image);
        set(request, 'mediafiles.image', res?.url);
      }

      for (const iterator of request?.blocks) {
        if (iterator?.imagePC && typeof iterator?.imagePC !== 'string') {
          const res = await uploadImage(iterator?.imagePC);
          iterator.imagePC = res?.url;
        }
        if (iterator?.imageMobile && typeof iterator?.imageMobile !== 'string') {
          const res = await uploadImage(iterator?.imageMobile);
          iterator.imageMobile = res?.url;
        }

        if (iterator?.urlVideo && typeof iterator?.urlVideo !== 'string') {
          const res = await uploadImage(iterator?.urlVideo);
          iterator.urlVideo = res?.url;
        }

        if (Array.isArray(iterator?.list) && iterator?.list.length > 0) {
          for (const _list of iterator?.list) {
            if (_list?.imagePC && typeof _list?.imagePC !== 'string') {
              const res = await uploadImage(_list?.imagePC);
              _list.imagePC = res?.url;
            }
            if (_list?.imageMobile && typeof _list?.imageMobile !== 'string') {
              const res = await uploadImage(_list?.imageMobile);
              _list.imageMobile = res?.url;
            }

            if (_list?.urlVideo && typeof _list?.urlVideo !== 'string') {
              const res = await uploadImage(_list?.urlVideo);
              _list.urlVideo = res?.url;
            }
          }
        }
      }

      if (values.id) {
        request = getChangedData(request, data);
        putAPI('luxury-jewelleries/' + values.id, request)
          .then(() => {
            pushNotification('success', 'Lưu thành công');
            router.replace('/luxury-jewelleries');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      } else {
        postAPI('luxury-jewelleries', request)
          .then(() => {
            pushNotification('success', 'Tạo thành công');
            router.replace('/luxury-jewelleries');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      }
    },
    [data, pushNotification, router]
  );

  const detailFields = useMemo(
    () => [
      { label: 'Trọng lượng (gram)', name: 'jewelleryInfo.weight' },
      { label: 'Kim cương trên vỏ (viên)', name: 'jewelleryInfo.extraProperties.stringDiamondSize' },
      { label: 'Chất liệu', name: 'jewelleryInfo.extraProperties.goldType' },
      { label: 'Kích thước viên chủ (ly)', name: 'jewelleryInfo.size' },
      { label: 'Giá', name: 'jewelleryInfo.price' },
    ],
    []
  );

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chi tiết danh sách sản phẩm cao cấp</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, setFieldValue, submitCount, touched = {}, values = {} }) => (
          <>
            <Row className="mb-2 d-flex">
              <Col md={2} className="d-flex align-items-center">
                <NameLabel>Tên sản phẩm cao cấp</NameLabel>
              </Col>
              <FieldInput
                inputMd={10}
                name="name"
                error={get(touched, 'name') || !!submitCount ? get(errors, 'name') : null}
                value={get(values, 'name')}
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue('slug', removeVietnameseTones(e.target.value));
                  handleChange(e);
                }}
                placeholder={'Tên sản phẩm cao cấp'}
              />
            </Row>
            <LinkRow>
              <LinkTitle>Đường dẫn:</LinkTitle>
              <LinkValue
                target="_blank"
                href={`${E_COMMERCE_URL}${
                  values?.gender === 1 ? 'trang-suc-cao-cap-nam' : values?.gender === 2 ? 'trang-suc-cao-cap-nu' : 'trang-suc-cao-cap'
                }/${values?.slug || ''}`}
              >{`${E_COMMERCE_URL}${
                values?.gender === 1 ? 'trang-suc-cao-cap-nam' : values?.gender === 2 ? 'trang-suc-cao-cap-nu' : 'trang-suc-cao-cap'
              }/${values?.slug || ''}`}</LinkValue>
            </LinkRow>
            <Accordion alwaysOpen defaultActiveKey={['info', 'detail']}>
              <Accordion.Item className="mb-3" eventKey="info">
                <BlockHeader className="d-flex align-items-center justify-content-between">Thông tin danh sách</BlockHeader>
                <BlockContent $templateColumns="1fr 1fr">
                  <Col>
                    <Row className="mb-2">
                      <FieldInput
                        name="productCodeSearch"
                        controlType={ControlTypes.AUTOCOMPLETE}
                        label="Mã sản phẩm"
                        error={get(touched, 'productCodeSearch') || !!submitCount ? get(errors, 'productCodeSearch') : null}
                        value={get(values, 'productCodeSearch')}
                        onBlur={handleBlur}
                        url={API_ENDPOINT + 'jewellery?keyword='}
                        optionLabel="productName"
                        optionValue="id"
                        onSelect={e => {
                          getAPI(API_ENDPOINT + 'jewellery/' + e).then(res => {
                            handleChange({ target: { name: 'jewelleryInfo', value: res.data } });
                            handleChange({ target: { name: 'productCode', value: get(res, ['data', 'productCode']) } });
                            handleChange({ target: { name: 'productCodeSearch', value: get(res, ['data', 'productName']) } });
                          });
                        }}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <FieldInput
                        name="gender"
                        label="Giới tính"
                        controlType={ControlTypes.SELECT_PICKER}
                        options={GenderOptions}
                        error={get(touched, 'gender') || !!submitCount ? get(errors, 'gender') : null}
                        value={get(values, 'gender')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col md={3} className="d-flex align-items-center">
                        Ảnh đại diện
                      </Col>
                      <Col md={9}>
                        <UploadFile name={'mediafiles.image'} src={values.mediafiles?.image} handleChange={handleChange}>
                          <AvatarDropIcon />
                        </UploadFile>
                      </Col>
                    </Row>
                  </Col>
                </BlockContent>
              </Accordion.Item>
              <Accordion.Item className="mb-3" eventKey="detail">
                <BlockHeader className="d-flex align-items-center justify-content-between">Chi tiết sản phẩm cao cấp</BlockHeader>
                <BlockContent $templateColumns="1fr 1fr">
                  {detailFields.map((_field, i) => (
                    <Row key={i}>
                      <FieldInput {..._field} readOnly value={get(values, _field.name)} placeholder={_field.label} />
                    </Row>
                  ))}
                </BlockContent>
              </Accordion.Item>
            </Accordion>
            <NameLabel className="mb-3">Giao diện trang sản phẩm cao cấp</NameLabel>
            <Accordion alwaysOpen defaultActiveKey={values?.blocks?.map((a, i) => i + '')}>
              {values?.blocks?.map((_b, index) => (
                <Accordion.Item eventKey={index + ''} className="mb-3" key={index}>
                  <BlockHeader className="d-flex align-items-center justify-content-between">
                    Block {index + 1}
                    <div className="flex-1" />
                    <IconButton
                      onClick={e => {
                        e.preventDefault();
                        handleChange({ target: { name: 'blocks', value: values?.blocks.filter((_v, i) => i !== index) } });
                      }}
                    >
                      <i className="mdi mdi-trash-can-outline" />
                    </IconButton>
                  </BlockHeader>
                  <BlockContent toggler={`#block_${_b.templateId}`}>
                    <Row>
                      <FieldInput
                        label="Ẩn"
                        controlType={ControlTypes.CHECKBOX}
                        name={`blocks.${index}.isShow`}
                        value={get(values, ['blocks', index, 'isShow'])}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <FieldInput
                        label="Thứ tự hiển thị"
                        controlType={ControlTypes.SELECT_PICKER}
                        name={`blocks.${index}.order`}
                        value={get(values, ['blocks', index, 'order'])}
                        options={OrderOptions}
                        inputMd={1}
                        onChange={handleChange}
                      />
                    </Row>
                    {get(values, ['blocks', index, 'templateId']) !== 6 && (
                      <Row>
                        <FieldInput
                          label="Tiêu đề"
                          name={`blocks.${index}.title`}
                          value={get(values, ['blocks', index, 'title'])}
                          onChange={handleChange}
                        />
                      </Row>
                    )}
                    {(get(values, ['blocks', index, 'templateId']) === 2 || get(values, ['blocks', index, 'templateId']) === 7) && (
                      <Row>
                        <FieldInput
                          label="Mô tả"
                          name={`blocks.${index}.text`}
                          value={get(values, ['blocks', index, 'text'])}
                          onChange={handleChange}
                        />
                      </Row>
                    )}
                    <Row>
                      <FieldInput
                        label="Chọn template"
                        controlType={ControlTypes.SELECT_PICKER}
                        name={`blocks.${index}.templateId`}
                        value={get(values, ['blocks', index, 'templateId'])}
                        options={LuxuryTemplateOptions}
                        onChange={handleChange}
                      />
                    </Row>
                    {get(values, ['blocks', index, 'templateId']) === 6 ? (
                      <></>
                    ) : get(values, ['blocks', index, 'templateId']) === 2 || get(values, ['blocks', index, 'templateId']) === 7 ? (
                      <SpecialBlock>
                        <Row>
                          <Label className="col-md-3 col-form-label d-flex align-items-center">Hình 1</Label>
                        </Row>
                        <Row>
                          <Label className="col-md-3 col-form-label d-flex align-items-center">Hình 2</Label>
                        </Row>
                        <Row>
                          <FieldInput
                            inputMd={6}
                            label={TITLE_IMAGE_PC}
                            controlType={ControlTypes.FILE}
                            accept="image/*"
                            name={`blocks.${index}.list.0.imagePC`}
                            value={get(values, ['blocks', index, 'list', 0, 'imagePC'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            inputMd={6}
                            label={TITLE_IMAGE_PC}
                            accept="image/*"
                            controlType={ControlTypes.FILE}
                            name={`blocks.${index}.list.1.imagePC`}
                            value={get(values, ['blocks', index, 'list', 1, 'imagePC'])}
                            onChange={handleChange}
                          />
                        </Row>
                        {/* <Row>
                          <FieldInput
                            inputMd={6}
                            label="Hình Mobile"
                            accept="image/*"
                            controlType={ControlTypes.FILE}
                            name={`blocks.${index}.list.0.imageMobile`}
                            value={get(values, ['blocks', index, 'list', 0, 'imageMobile'])}
                            onChange={handleChange}
                          />
                        </Row> */}
                        {/* <Row>
                          <FieldInput
                            inputMd={6}
                            label="Hình Mobile"
                            accept="image/*"
                            controlType={ControlTypes.FILE}
                            name={`blocks.${index}.list.1.imageMobile`}
                            value={get(values, ['blocks', index, 'list', 1, 'imageMobile'])}
                            onChange={handleChange}
                          />
                        </Row> */}
                        <Row>
                          <FieldInput
                            label="Tiêu đề"
                            name={`blocks.${index}.list.0.title`}
                            value={get(values, ['blocks', index, 'list', 0, 'title'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Tiêu đề"
                            name={`blocks.${index}.list.1.title`}
                            value={get(values, ['blocks', index, 'list', 1, 'title'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Đoạn văn"
                            rows={8}
                            type="textarea"
                            name={`blocks.${index}.list.0.text`}
                            value={get(values, ['blocks', index, 'list', 0, 'text'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Đoạn văn"
                            rows={8}
                            type="textarea"
                            name={`blocks.${index}.list.1.text`}
                            value={get(values, ['blocks', index, 'list', 1, 'text'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Link"
                            name={`blocks.${index}.list.0.link`}
                            value={get(values, ['blocks', index, 'list', 0, 'link'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Link"
                            name={`blocks.${index}.list.1.link`}
                            value={get(values, ['blocks', index, 'list', 1, 'link'])}
                            onChange={handleChange}
                          />
                        </Row>
                      </SpecialBlock>
                    ) : (
                      <>
                        {get(values, ['blocks', index, 'templateId']) === 1 && (
                          <Row>
                            <FieldInput
                              label="Video"
                              controlType={ControlTypes.FILE}
                              accept="video/*"
                              name={`blocks.${index}.urlVideo`}
                              value={get(values, ['blocks', index, 'urlVideo'])}
                              onChange={handleChange}
                            />
                          </Row>
                        )}
                        <Row>
                          <FieldInput
                            label={TITLE_IMAGE_PC}
                            accept="image/*"
                            controlType={ControlTypes.FILE}
                            name={`blocks.${index}.imagePC`}
                            value={get(values, ['blocks', index, 'imagePC'])}
                            onChange={handleChange}
                          />
                        </Row>
                        {/* <Row>
                          <FieldInput
                            label="Hình Mobile"
                            accept="image/*"
                            controlType={ControlTypes.FILE}
                            name={`blocks.${index}.imageMobile`}
                            value={get(values, ['blocks', index, 'imageMobile'])}
                            onChange={handleChange}
                          />
                        </Row> */}
                        <Row>
                          <FieldInput
                            label="Đoạn văn"
                            rows={8}
                            type="textarea"
                            name={`blocks.${index}.text`}
                            value={get(values, ['blocks', index, 'text'])}
                            onChange={handleChange}
                          />
                        </Row>
                      </>
                    )}
                  </BlockContent>
                </Accordion.Item>
              ))}
            </Accordion>
            <NewBlockContainer>
              <AddBlockButton
                onClick={() => {
                  const blocks = cloneDeep(values.blocks || []);
                  blocks.push(initialBlock);
                  handleChange({ target: { name: 'blocks', value: blocks } });
                }}
              >
                <i className="mdi mdi-plus"></i>
                Thêm block
              </AddBlockButton>
            </NewBlockContainer>
            <SeoBlock values={values} handleChange={handleChange} />
            <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.PRODUCT} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default LuxuryJewelry;
