import axios from 'axios';

export const getJewelleryById = id => axios({ url: 'jewellery/' + id, method: 'GET' });

export const getNewJewelleryOrder = () => axios({ url: 'new-jewellery-order', method: 'GET' });

export const updateNewJewelleryOrder = data => axios({ url: 'new-jewellery', method: 'POST', data });

export const deleteNewJewelleryOrder = id => axios({ url: 'new-jewellery/' + id, method: 'DELETE' });

export const updateJewelleryById = (id, data) => axios({ url: 'jewellery/' + id, method: 'PUT', data });
