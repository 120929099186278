import { AuthenticationFeature } from 'constants/authentication';
import { E_COMMERCE_URL, JewelTypes } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { JewellTypeOptions, StockStatusOptions } from 'constants/options';
import { CellControlType } from 'constants/table';
import { toUpper } from 'lodash';

export const getCouponProductInfo = () => ({
  buttonAddTitle: '',
  feature: AuthenticationFeature.PRODUCT,
  orderFunc: toUpper,
  columns: [
    {
      dataField: 'productCode',
      text: 'Mã sản phẩm',
      orderKey: 'productCode',
      color: '#0063F7',
      fontWeight: 500,
      controlType: CellControlType.TextCopy,
    },
    {
      dataField: 'productName',
      text: 'Tên sản phẩm',
      render: (value, data) => `${data.mainCategory || ''} ${value}`,
      orderKey: 'productName',
      color: '#0063F7',
      fontWeight: 500,
    },
    {
      dataField: 'inStockCount',
      text: 'Trạng thái',
      render: value => (value == '1' ? 'Còn hàng' : 'Hết hàng'),
      color: value => (value == '1' ? '#007770' : '#555770'),
      background: value => (value == '1' ? 'rgba(0, 119, 112, 0.1)' : 'rgba(85, 87, 112, 0.1)'),
      controlType: CellControlType.Status,
    },
    {
      dataField: 'price',
      text: 'Giá (VNĐ)',
      custom: 'price',
      orderKey: 'price',
    },
    {
      dataField: 'productCategory',
      text: 'Danh mục',
      custom: 'array',
    },
    {
      dataField: 'newProductInfo',
      text: 'Sản phẩm mới',
      controlType: CellControlType.Status,
      custom: 'circle',
    },
    {
      dataField: 'type',
      text: 'Loại sản phẩm',
      render: value =>
        value === JewelTypes.Single ? 'Đơn' : value === JewelTypes.Couple ? 'Đôi' : value === JewelTypes.Customize ? 'Đính kèm kim cương' : '',
    },
    {
      dataField: 'action',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'jewellery',
      deleteUrl: 'jewellery',
    },
  ],
  editConfig: {
    url: 'jewellery',
    fields: [{ label: 'Tên sản phẩm', name: 'productName' }],
  },
  columnTemplate: '1fr 1fr 150px 1fr 0.5fr 100px 0.5fr 0.55fr',
  filterElements: [
    {
      name: 'type',
      placeholder: 'Loại sản phẩm',
      controlType: ControlTypes.SELECT_PICKER,
      options: JewellTypeOptions,
      width: '130px',
    },
    {
      name: 'stockStatus',
      placeholder: 'Trạng thái kho',
      controlType: ControlTypes.SELECT_PICKER,
      options: StockStatusOptions,
      width: '130px',
    },
    {
      name: 'category',
      placeholder: 'Danh mục sản phẩm',
      controlType: ControlTypes.SELECT_PICKER,
      optionUrl: { url: 'categories', keyLabel: 'name', keyValue: 'link' },
      width: '170px',
    },
  ],
  selectable: true,
  title: '',
  url: 'jewellery',
  actions: [],
});
