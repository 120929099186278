import { getCollectionProductInfo } from './collections';

export const getProductAddonInfoByUrl = url => {
  switch (url) {
    case 'collections':
      return getCollectionProductInfo();

    case 'them-o-day-ne':
      return {};

    default:
      return {
        buttonAddTitle: '',
        columns: [],
        columnTemplate: '',
        filterElements: [],
        selectable: false,
        title: '',
        url: '',
      };
  }
};
