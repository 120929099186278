import { SaveIcon } from 'components/icons';
import { Authentication, CustomTable } from 'components/own';
import { FilterSelectPicker } from 'components/own/Filter';
import { getOrderState } from 'components/own/Management/modules/orders';
import { PaymentMethod } from 'constants/common';
import { OrderStatusOptions } from 'constants/orders';
import { CellControlType } from 'constants/table';
import { useActionNotification } from 'hook/useContextSelector';
import produce from 'immer';
import { get } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import { getCouponById } from 'services/coupon';
import { getOrderById, updateOrderById } from 'services/orders';
import styled from 'styled-components';
import { parseDate, parsePrice, propsAreEqual } from 'utils/uti';
import { useUserInfo } from 'hook/useContextSelector';
import { AuthenticationFeature, AuthenticationTypes } from 'constants/authentication';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;
const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr 374px;
  grid-gap: 16px;
  height: 100%;
`;

const ProductZone = styled.div`
  background: #ffffff;
`;

const CustomerZone = styled.div`
  background: #ffffff;
  padding: 16px;
`;

const Info = styled.div`
  padding: 16px;
  box-shadow: inset 0px -1px 0px #e6eaf0;
`;

const Label = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: 16px;
  /* identical to box height, or 114% */

  color: ${({ color }) => color || '#000000'};
`;

const Status = styled.div`
  height: 32px;
  padding: 0 8px;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  box-shadow: inset 0px -1px 0px #e6eaf0;
  width: 100%;
  height: 1px;
`;

const ButtonPrimary = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 4px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 74px;
  height: 32px;

  background: #007770;
  border-radius: 2px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  filter: ${({ disabled }) => (disabled ? 'grayscale(1)' : '')};

  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  /* or 18px */

  text-align: center;
  text-transform: capitalize;

  color: #ffffff;
`;

const initialState = { data: null, coupon: '' };

const getPaymentMethod = method => {
  switch (method) {
    case PaymentMethod.BANKING:
      return 'Chuyển khoản ngân hàng';
    case PaymentMethod.COD:
      return 'COD';
    case PaymentMethod.ONLINE:
      return 'Thanh toán online';
    case PaymentMethod.PHONE:
      return 'Điện thoại';
    default:
      return '';
  }
};

const getOrderProduct = items => {
  const products = [];

  for (const key in items) {
    if (Object.hasOwnProperty.call(items, key)) {
      const element = items[key];
      products.push({ ...element, itemInfo: { ...element.itemInfo, ...element?.itemInfo?.generalInfo } });
      if (element.withDiamond) {
        for (const keyD in element.withDiamond) {
          if (Object.hasOwnProperty.call(element.withDiamond, keyD)) {
            const diamond = element.withDiamond[keyD];
            products.push({ ...diamond, itemInfo: { ...diamond.itemInfo, ...diamond?.itemInfo?.generalInfo } });
          }
        }
      }
    }
  }

  return products;
};

const OrderItem = () => {
  const userInfo = useUserInfo();
  const [{ data, coupon }, setState] = useState(initialState);
  const { id } = useParams();
  const pushNotification = useActionNotification();

  useEffect(() => {
    getOrderById(id).then(res => {
      setState(
        produce(draft => {
          draft.data = { ...res.data, products: getOrderProduct(res.data.items), nextStatus: res.data?.status };
        })
      );
    });
  }, [id]);

  useEffect(() => {
    if (data?.couponId) {
      getCouponById(data.couponId).then(res => {
        setState(
          produce(draft => {
            draft.coupon = res.data?.code;
          })
        );
      });
    }
  }, [data?.couponId]);

  const tableProps = useMemo(
    () => ({
      columnTemplate: '100px 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
      data: data?.products,
      headers: [
        {
          dataField: 'itemInfo.mediafiles.images.0.mainImage',
          text: 'Hình ảnh',
          controlType: CellControlType.Image,
        },
        {
          dataField: 'itemInfo.id',
          text: 'Mã Sản Phẩm',
          color: '#0063F7',
          fontWeight: 700,
        },
        {
          dataField: 'itemInfo',
          text: 'Tên Sản Phẩm',
          color: '#0063F7',
          fontWeight: 700,
          render: value => `${value?.mainCategory || ''} ${value?.productName}`,
        },
        {
          dataField: 'size',
          text: 'Kích thước',
        },
        {
          dataField: 'price',
          text: 'Giá',
          custom: 'price',
        },
        {
          dataField: 'quantity',
          text: 'Số lượng',
          render: value => `x ${value}`,
        },
        {
          dataField: 'discount',
          text: 'Giảm giá',
          render: value => value || 0 + ' đ',
        },
        {
          dataField: 'price',
          text: 'Tổng',
          custom: 'price',
        },
      ],
      headerBackground: '#F2F2F5',
      rowHeight: 83,
    }),
    [data?.products]
  );

  const onSave = useCallback(() => {
    updateOrderById(id, { status: data?.nextStatus })
      .then(res => {
        pushNotification('success', 'Thay đổi trạng thái đơn hàng thành công.');
        getOrderById(id).then(res => {
          setState(
            produce(draft => {
              draft.data = { ...res.data, products: getOrderProduct(res.data.items), nextStatus: res.data?.status };
            })
          );
        });
      })
      .catch(err => {
        pushNotification('error', err.message || err);
      });
  }, [data?.nextStatus, id, pushNotification]);

  const orderStatus = useMemo(() => {
    if (userInfo?.userType === 1) {
      return OrderStatusOptions;
    }

    return OrderStatusOptions.filter(_i => !_i?.value || _i?.value === -1 || _i.value >= data?.status);
  }, [data?.status, userInfo?.userType]);
  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chi tiết đơn hàng</Title>
      </div>
      <Root>
        <ProductZone>
          <Info>
            <Row className="mb-2">
              <Label fontWeight="700" fontSize="16px" className="col-md-3 col-form-label">
                Mã đơn:
              </Label>
              <Col md="9" className="d-flex align-items-center">
                <Label fontWeight="700">{data?.code}</Label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Label className="col-md-3 col-form-label">Ngày tạo đơn:</Label>
              <Col md="9" className="d-flex align-items-center">
                <Label>{parseDate(data?.createdAt)}</Label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Label className="col-md-3 col-form-label">Trạng thái đơn hàng hiện tại:</Label>
              <Col md="9" className="d-flex align-items-center">
                <Status color={getOrderState(data?.status, 'color')} background={getOrderState(data?.status, 'background')}>
                  {getOrderState(data?.status, 'render')}
                </Status>
              </Col>
            </Row>
            <Row className="mb-2">
              <Label className="col-md-3 col-form-label">Trạng thái đơn hàng tiếp theo:</Label>
              <Authentication
                feature={AuthenticationFeature.ORDER}
                type={AuthenticationTypes.EDIT}
                title="You don't have permission to update this field"
              >
                <>
                  <Col md="4" className="d-flex align-items-center">
                    <FilterSelectPicker
                      label=""
                      name="status"
                      width="184px"
                      onChange={e =>
                        setState(
                          produce(draft => {
                            draft.data['nextStatus'] = e.target.value;
                          })
                        )
                      }
                      value={data?.nextStatus}
                      options={orderStatus}
                    />

                    <ButtonPrimary onClick={onSave} disabled={!data?.nextStatus}>
                      <SaveIcon />
                      Lưu
                    </ButtonPrimary>
                  </Col>
                </>
              </Authentication>
            </Row>
            <Row className="mb-2">
              <Label className="col-md-3 col-form-label">Thanh toán :</Label>
              <Col md="9" className="d-flex align-items-center">
                {getPaymentMethod(data?.paymentMethod)}
              </Col>
            </Row>
            <Row className="mb-2">
              <Label className="col-md-3 col-form-label">Coupon:</Label>
              <Col md="9" className="d-flex align-items-center">
                {data?.couponId ? (
                  <Status background="#FDAC42" color="#000000">
                    {coupon}
                  </Status>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </Info>
          <Info>
            <CustomTable {...tableProps}></CustomTable>
            <Row className="mb-2 d-flex">
              <Col md="10" className="d-flex align-items-center justify-content-end">
                <Label className="text-right"> Tạm tính:</Label>
              </Col>
              <Label className="col-md-2 col-form-label">{parsePrice(data?.totalCost)} đ</Label>
            </Row>
            <Row className="mb-2 d-flex">
              <Col md="10" className="d-flex align-items-center justify-content-end">
                <Label className="text-right"> Vận chuyển:</Label>
              </Col>
              <Label className="col-md-2 col-form-label">Free</Label>
            </Row>
            <Row className="mb-2 d-flex">
              <Col md="10" className="d-flex align-items-center justify-content-end">
                <Label className="text-right"> Thuế:</Label>
              </Col>
              <Label className="col-md-2 col-form-label">10%</Label>
            </Row>
            <Row className="mb-2 d-flex">
              <Col md="10" className="d-flex align-items-center justify-content-end">
                <Label className="text-right"> Tổng giá trị khuyến mãi:</Label>
              </Col>
              <Label className="col-md-2 col-form-label">{parsePrice(data?.totalDiscount)} đ</Label>
            </Row>
            <Row className="mb-2 d-flex">
              <Col md="10" className="d-flex align-items-center justify-content-end">
                <Label className="text-right" fontWeight="700">
                  Tổng tiền thanh Toán:
                </Label>
              </Col>
              <Label className="col-md-2 col-form-label" fontWeight="700">
                {parsePrice(data?.totalPrice)} đ
              </Label>
            </Row>
          </Info>
        </ProductZone>
        <CustomerZone>
          <Label fontWeight="700" color="#1F4173" className="mb-2">
            Khách hàng
          </Label>
          <Label className="mb-3">{data?.customerInfo?.name || data?.recieverName || '-'}</Label>
          <Label className="mb-2">Loại Thành Viên: {data?.customerInfo?.type || 'Welcome'}</Label>
          <Line className="mb-4" />
          <Label fontWeight="700" color="#1F4173" className="mb-2">
            Liên hệ
          </Label>
          <Label className="mb-2">{data?.phone || '-'}</Label>
          <Label className="mb-3">{data?.email || '-'}</Label>
          <Line className="mb-4" />
          <Label fontWeight="700" color="#1F4173" className="mb-2">
            Địa chỉ nhận hàng
          </Label>
          <Label className="mb-2">{`${data?.address} ${get(data, 'districtInfo.name', '')} ${get(data, 'cityInfo.name', '')} ${get(
            data,
            'providenceInfo.name',
            ''
          )}`}</Label>
          <Label className="mb-2">Loại Thành Viên: {data?.customerInfo?.type || 'Welcome'}</Label>
        </CustomerZone>
      </Root>
    </div>
  );
};

export default memo(OrderItem, propsAreEqual);
