import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Col, Input, Label } from 'reactstrap';
import styled from 'styled-components';

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
  b {
    margin-left: 4px;
    color: #e63535;
  }
`;

const CustomInputMulti = ({ autoFocus, error, touched, isRequired, label, name, inputMd = 9, onChange, value, ...rest }) => {
  const ref = useRef(name);

  const inputValue = useMemo(() => (Array.isArray(value) ? value.join(',') : value), [value]);

  const handleChange = useCallback(
    e => {
      onChange({ target: { name, value: e.target.value?.split(',') } });
    },
    [name, onChange]
  );
  useEffect(() => {
    if (autoFocus) ref?.current?.focus();
  }, [autoFocus]);

  return (
    <>
      {label && (
        <LabelInput className="col-md-3 col-form-label d-flex align-items-center">
          {label} {isRequired && <b>*</b>}
        </LabelInput>
      )}
      <Col md={inputMd}>
        <Input innerRef={ref} autoFocus={autoFocus} onChange={handleChange} invalid={!!error} name={name} {...rest} value={inputValue} />
        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
    </>
  );
};

export default CustomInputMulti;
