import { AuthenticationFeature } from 'constants/authentication';
import { CellControlType } from 'constants/table';

export const getRoleInfo = () => ({
  feature: AuthenticationFeature.USER,
  buttonAddTitle: '',
  columns: [
    {
      dataField: 'name',
      text: 'Tên quyền',
      orderKey: 'name',
      detailUrl: 'user-types',
    },
    {
      dataField: 'status',
      text: 'Tình Trạng',
      render: value => (value === 1 ? 'Áp dụng' : value === 2 ? 'Không áp dụng' : value === -1 ? 'Xóa' : ''),
    },
    {
      dataField: 'createdAt',
      text: 'Ngày tạo',
      controlType: CellControlType.Date,
    },
    {
      dataField: 'createdByInfo.name',
      text: 'Người tạo',
    },

    {
      dataField: 'action',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'user-types',
      deleteUrl: 'user-types',
    },
  ],
  columnTemplate: '1fr 1fr 1fr 1fr 100px',
  filterElements: [
    // {
    //   name: 'createdAt',
    //   placeholder: 'Tất cả các ngày',
    //   controlType: ControlTypes.DATE_PICKER,
    //   width: '140px',
    // },
  ],
  hideSearch: true,
  selectable: true,
  actions: [
    { label: 'Áp dụng', value: 1, key: 'status' },
    { label: 'Không áp dụng', value: 2, key: 'status' },
    { label: 'Xóa', value: -1, key: 'status' },
  ],
  title: 'Danh sách quyền',
  url: 'user-types',
});
