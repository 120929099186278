import React from 'react';
import styled from 'styled-components';
import { parsePrice } from 'utils/uti';
import CellOrderItem from './CellOrderItem';
import { get } from 'lodash';

const ContainerOrderItem = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #e4e4eb;
  margin-left: -0.8rem;
  margin-right: -0.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #f4f4f4;
  border-left: 4px solid #007770;
`;
const OrderItem = ({ item, orderNumber }) => {
  const { itemInfo, price, quantity, type } = item;
  let src = '';
  let productName = '';
  let mainCategory = '';
  // 1 là trang sức vàng - 2 là Kim cương
  if (type === 1) {
    src = itemInfo.mediafiles.images[0].mainImage;
    productName = itemInfo.productName;
    mainCategory = itemInfo.mainCategory;
  } else if (type === 2) {
    src = itemInfo.generalInfo.mediafiles.images[0].mainImage;
    productName = itemInfo.generalInfo.productName;
    mainCategory = 'Kim cương';
  }

  return (
    <ContainerOrderItem>
      <div className="px-3 my-auto">{orderNumber}</div>
      <div className="d-flex align-items-center">
        <img src={src} alt={`${mainCategory}-${productName}`} width={50} height={50} />
        <CellOrderItem
          containerStyle={{ marginLeft: 10 }}
          labelStyle={{ fontWeight: 'bold', color: '#000' }}
          label={mainCategory}
          value={productName}
        />
      </div>
      <CellOrderItem labelStyle={{ color: '#8F90A6' }} label={'Số lượng'} value={quantity} />
      <CellOrderItem labelStyle={{ color: '#8F90A6' }} label={'Đơn giá (VNĐ)'} value={parsePrice(price) + ' đ'} />
      <CellOrderItem labelStyle={{ color: '#8F90A6' }} label={'Mã giảm giá'} value={get(item, ['couponInfo'])} />
      <CellOrderItem labelStyle={{ color: '#8F90A6' }} label={'Tổng tiền (VNĐ)'} value={parsePrice(price) + ' đ'} />
    </ContainerOrderItem>
  );
};

export default OrderItem;
