import React from 'react';
import styled from 'styled-components';
import { Col } from 'reactstrap';

const CustomerSummaryValue = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12;
    ${({ color }) => color && `color: ${color};`}
`;
const CustomerSummaryLabel = styled.div`
    font-size: 14px;
    font-weight: 700;
`;
const CustomerSummaryItem = ({
    value,
    label,
    color,
    borderRight
}) =>
    <Col style={{
        margin: 16,
        borderRight: borderRight ? '1px solid #E4E4EB' : null
    }}>
        <CustomerSummaryValue color={color}>{value}</CustomerSummaryValue>
        <CustomerSummaryLabel>{label}</CustomerSummaryLabel>
    </Col>

export default CustomerSummaryItem;
