import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';

import { Input } from 'reactstrap';
import { ButtonOutline, ButtonPrimary, CustomTable, Paginate } from 'components/own';
import { getAllUsers } from 'services/auth';
import { DropDownIcon } from 'components/icons';
import { useHistory } from 'react-router';
import { CellControlType } from 'constants/table';
import { AuthenticationFeature } from 'constants/authentication';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-right: 20px;
`;

const SelectAction = styled(Select)`
  cursor: pointer;
  width: 100px;
  margin-right: 4px;
  font-size: 14px;
  .select2-selection__indicators {
    padding-right: 4px;
  }
`;

const DatePicker = styled(Flatpickr)`
  cursor: pointer;

  font-size: 14px;
  background: #fafafc;

  width: 100px;
  height: 32px;
  padding: 0 8px;

  border: 1px solid #c7c9d9;
  box-sizing: border-box;
  border-radius: 2px;
`;

const SearchBox = styled(Input)`
  width: 200px;
  height: 32px;
  background: #fafafc;
  input:after {
    border-bottom-width: 1px !important;
  }
`;

const Label = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  display: flex;
  align-items: center;

  /* Dark / Dark 0 */

  color: #1c1c28;
`;

const ContentTable = styled.div`
  background-color: #fff;
  margin-top: 16px;
`;

const columns = [
  {
    dataField: 'name',
    text: 'Tên Thành Viên',
    sort: true,
    controlType: CellControlType.TextWithAction,
  },
  {
    dataField: 'fullName',
    text: 'Họ và Tên',
    sort: true,
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
  },
  {
    dataField: 'phone',
    text: 'Số Điện Thoại',
    sort: true,
  },
  {
    dataField: 'typeInfo.name',
    text: 'Vai trò',
    sort: true,
  },
  {
    dataField: 'createdAt',
    text: 'Ngày tạo',
    sort: true,
    controlType: CellControlType.Date,
  },
  {
    dataField: 'action',
    text: 'Thao tác',
    controlType: CellControlType.Action,
    detailUrl: 'users',
    deleteUrl: 'users',
  },
];

const optionActions = [
  { label: 'Mustard', value: 'Mustard' },
  { label: 'Ketchup', value: 'Ketchup' },
  { label: 'Relish', value: 'Relish' },
];

const initialState = { page: 1, total: 0, columns, data: [], searchValue: '', selectedAction: null, limit: 10, loading: true };

const UserManagement = () => {
  const [{ columns, data, loading, limit, page, searchValue, selectedAction, total }, setState] = useState(initialState);
  const router = useHistory();
  const columnTemplate = useMemo(() => '2fr 1fr 1fr 1fr 0.75fr 0.5fr 0.5fr', []);
  const ref = useRef();

  useEffect(() => {
    const callAPI = async () => {
      let query = 'limit=' + limit;
      if (page) query += '&page=' + page;
      if (searchValue) query += '&name=' + searchValue;
      try {
        const res = await getAllUsers(query);
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = res.data.list;
            draft.total = res.data.count;
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = [];
            draft.total = 0;
          })
        );
      }
    };
    callAPI();
  }, [limit, page, searchValue]);

  const handleSelectGroup = useCallback(item => {
    setState(
      produce(draft => {
        draft.selectedAction = item;
      })
    );
  }, []);

  const onChangePage = useCallback(({ selected }) => {
    setState(
      produce(draft => {
        draft.page = selected + 1;
      })
    );
  }, []);

  const onClickFilter = useCallback(() => {
    setState(
      produce(draft => {
        if (ref.current) draft.searchValue = ref.current.value;
      })
    );
  }, []);

  const onAdd = useCallback(() => {
    router.push('/users/new');
  }, [router]);

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Danh sách thành viên</Title>
        <ButtonOutline onClick={onAdd}>Thêm thành viên</ButtonOutline>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <SearchBox innerRef={ref} type="text" className="form-control me-3" placeholder="Nhập keyword" />
        <ButtonOutline onClick={onClickFilter}>Tìm kiếm</ButtonOutline>
      </div>
      <div className="d-flex align-items-center mt-2">
        <SelectAction
          placeholder="Tác vụ"
          value={selectedAction}
          onChange={handleSelectGroup}
          options={optionActions}
          classNamePrefix="select2-selection"
          components={{
            DropdownIndicator: DropDownIcon,
            IndicatorSeparator: null,
          }}
        />
        <ButtonPrimary fontWeight={500} className="me-2">
          Áp dụng
        </ButtonPrimary>
        <Label className="me-2">Ngày</Label>
        <DatePicker
          className="me-2"
          placeholder="Từ ngày"
          options={{
            dateFormat: 'Y/m/d',
          }}
        />
        <Label className="me-2">-</Label>
        <DatePicker
          placeholder="Đến ngày"
          options={{
            dateFormat: 'Y/m/d',
          }}
        />

        <div className="flex-1"></div>
        <Label className="me-2">{total} mục</Label>
        <Paginate page={page} total={total} itemPerPage={limit} onChange={onChangePage} />
      </div>
      <ContentTable className="">
        <CustomTable
          selectable
          columnTemplate={columnTemplate}
          data={data}
          headers={columns}
          loading={loading}
          rowHeight={83}
          module={AuthenticationFeature.USER}
        />
      </ContentTable>
    </div>
  );
};

export default UserManagement;
