import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { UncontrolledCollapse, Col, Row, Label, Input } from 'reactstrap';
import produce from 'immer';
import { Formik } from 'formik';
import { get, isEmpty } from 'lodash';
import * as yup from 'yup';
import { useParams, useHistory } from 'react-router';

import { ChevronUpIcon } from 'components/icons';
import { ActionsItem, FieldInput } from 'components/own';
import { useActionNotification } from 'hook/useContextSelector';

import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';

import { getDiamondById, updateDiamond } from 'services/diamonds';

import CustomInput from '../EditJewellery/components/CustomInput';
import Thumbnail from '../EditJewellery/components/Thumbnail';
import { API_UPLOAD, E_COMMERCE_URL } from 'constants/common';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;
const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
  cursor: pointer;
`;

const BlockContent = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
`;
const IconButton = styled.div`
  cursor: pointer;
`;
const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
`;
const ImageCol = styled.div`
  width: 20%;
`;

const productTypes = [
  {
    value: 1,
    label: 'Sản phẩm đơn',
    path: 'san-pham-don',
  },
  {
    value: 2,
    label: 'Sản phẩm tuỳ biến',
    path: 'san-pham-tuy-bien',
  },
  {
    value: 3,
    label: 'Sản phẩm đôi',
    path: 'san-pham-doi',
  },
];
// const ecommerceUrl = process.env.REACT_APP_E_COMMERCE_URL;
const generateLink = productId => {
  return `${E_COMMERCE_URL}kim-cuong/${productId}`;
};

function EditDiamond() {
  const [state, setState] = useState({ data: null, options: { types: null } });
  // State mediafiles -> Cho phép render đủ 10 ảnh kể cả ảnh trống
  const [mediafiles, setMediaFiles] = useState([]);
  const { data, options } = state;
  const { id } = useParams();
  const router = useHistory();
  const pushNotification = useActionNotification();
  useEffect(() => {
    getDiamondById(id).then(res => {
      res.statusCode === 200 &&
        setState(
          produce(draft => {
            // handle images
            const imagesLength = res.data.generalInfo.mediafiles.images?.length;
            if (imagesLength > 0) {
              const limitImage = 10;
              const restLength = limitImage - imagesLength;
              const newMediaFiles = [...res.data.generalInfo.mediafiles.images];
              for (let index = 0; index < restLength; index++) {
                newMediaFiles.push({});
              }

              setMediaFiles(newMediaFiles);
            }
            const relatedProducts = res.data.jewelleryList?.map(product => product.productName).join(', ');
            draft.data = { ...res.data, relatedProducts };
            draft.options.types = productTypes;
          })
        );
    });
  }, [id]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        // name: yup.string().required('Vui lòng nhập tên tài khoản.'),
        // userType: yup.string().required('Vui lòng chọn loại tài khoản.'),
        // password: data.id
        //     ? yup.string().matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z])[A-Za-z\d].{7,}$/, ' ')
        //     : yup
        //         .string()
        //         .required('Vui lòng nhập mật khẩu.')
        //         .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z])[A-Za-z\d].{7,}$/, 'Mật khẩu có ít nhất 7 ký tự và có chứa ký tự in hoa và số.'),
        // confirmPassword: yup.string().when('yup', {
        //     is: val => !!val && !!val.length,
        //     then: yup
        //         .string()
        //         .required('Vui lòng xác nhận mật khẩu.')
        //         .oneOf([yup.ref('yup')], 'Xác nhận mật khẩu và mật khẩu phải giống nhau.'),
        // }),
        // status: yup.string(),
        // fullname: yup.string(),
        // phone: yup
        //     .string()
        //     .nullable()
        //     .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Vui lòng nhập đúng số điện thoại'),
        // email: yup.string().required('Vui lòng nhập địa chỉ email.').email('Email không hợp lệ.'),
      }),
    []
  );
  const link = useMemo(() => generateLink(id), [id]);
  const infoFields = useMemo(
    () => ({
      id: 'product-info-block',
      title: 'Thông tin sản phẩm',
      products: [
        {
          label: 'Danh mục',
          name: 'mainCategory',
          value: 'Kim cương',
          disabled: true,
        },
        {
          label: 'Đường dẫn',
          name: '',
          value: link,
          disabled: true,
        },
        {
          label: 'Size',
          name: 'size',
          disabled: true,
        },
        {
          label: 'Mã sản phẩm',
          name: 'productCode',
          generalInfo: true,
          disabled: true,
        },
        {
          label: 'Giới tính',
          name: '',
          disabled: true,
        },
        {
          label: 'Mã sản phẩm cũ',
          name: 'productName',
          generalInfo: true,
          disabled: true,
        },
        {
          label: 'Loại sản phẩm',
          name: 'type',
          controlType: ControlTypes.SELECT_PICKER,
          option: 'types',
          isDisabled: true,
        },
        {
          label: 'Giá',
          name: 'price',
          disabled: true,
        },
        {
          label: 'Tổng sản phẩm',
          name: '',
          disabled: true,
        },
        {
          label: 'Giá khuyến mãi',
          name: 'price',
          disabled: true,
        },
        {
          label: 'Sản phẩm liên quan',
          name: 'relatedProducts',
          // name: 'related-products',
          type: 'textarea',
          rows: '4',
          disabled: true,
        },
        {
          label: 'Serial',
          name: 'serial',
          disabled: true,
        },
      ],
    }),
    [link]
  );
  const propertyFields = useMemo(
    () => ({
      id: 'product-property-block',
      title: 'Thuộc tính sản phẩm',
      products: [
        {
          label: 'KCC GIA Report Number',
          name: 'GIAReportNumber',
          disabled: true,
        },
        {
          label: 'Link mã số GIA',
          name: 'linkGIAReportNumber',
          disabled: true,
        },
        {
          label: 'KCC Measurements',
          name: 'measurements',
          disabled: true,
        },
        {
          label: 'KCC Cut',
          name: 'cut',
          disabled: true,
        },
        {
          label: 'KCC Cara weight',
          name: 'caraWeight',
          disabled: true,
        },
        {
          label: 'KCC Polish',
          name: 'polish',
          extraProperties: true,
          disabled: true,
        },
        {
          label: 'KCC Clarity',
          name: 'clarity',
          disabled: true,
        },
        {
          label: 'KCC Symmetry',
          name: 'symmetry',
          extraProperties: true,
          disabled: true,
        },
        {
          label: 'KCC Color',
          name: 'color',
          disabled: true,
        },
        {
          label: 'Kiểu hiển thị',
          name: 'displayType',
          disabled: true,
        },
        {
          label: 'KCC Fluorescence',
          name: 'fluorescence',
          extraProperties: true,
          disabled: true,
        },
        {
          label: 'Ngày đăng',
          name: 'createdAt',
          disabled: true,
        },
      ],
    }),
    []
  );
  const seoFields = useMemo(
    () => ({
      id: 'seo-product-block',
      title: 'SEO',
      products: [
        {
          label: 'Tiêu đề',
          name: 'title',
        },
        {
          label: 'Link',
          name: 'link',
        },
        {
          label: 'Canonical link',
          name: 'canonicalURL',
        },
        {
          label: 'Redirection',
          name: 'redirection',
        },
      ],
    }),
    []
  );

  const onSave = useCallback(
    values => {
      const { bannerInfo, mediafiles, seoInfo } = values.generalInfo;
      const data = { bannerInfo, mediafiles, seoInfo };
      updateDiamond({ data })
        .then(response => {
          pushNotification('success', 'Cập nhật thành công');
          router.replace('/diamond-serials');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [pushNotification, router]
  );

  const onCancel = useCallback(() => {
    router.replace('/diamond-serials');
  }, [router]);

  const handleChangeImage = useCallback(
    ({ currentImage, event, setFieldValue }) => {
      const files = event.target.files;
      const index = event.target.id;
      let emptyObjectCount = 0;
      const filesLength = files.length;
      for (const file of mediafiles) {
        isEmpty(file) && emptyObjectCount++;
      }
      // Chọn nhiều hơn số lượng ảnh trống còn lại
      if (filesLength > emptyObjectCount) {
        pushNotification('error', 'Bạn chọn quá số lượng, ảnh còn lại: ' + emptyObjectCount);
      } else {
        const newMediafiles = [...mediafiles];
        for (const image of files) {
          const formData = new FormData();
          formData.append('file', image);
          formData.append('t', true);

          fetch(API_UPLOAD, {
            method: 'post',
            body: formData,
          })
            .then(response => {
              new Response(response.body, { headers: { 'Content-Type': 'text/html' } })
                .text()
                .then(res => {
                  const respJson = JSON.parse(res);
                  const emptyIndex = newMediafiles.findIndex(file => isEmpty(file));
                  const targetIndex = currentImage ? index : emptyIndex;
                  if (targetIndex !== -1) {
                    newMediafiles[targetIndex] = {
                      mainImage: respJson.data.url,
                      subImage: respJson.data.url,
                      urlVideo: null,
                    };

                    setFieldValue(`generalInfo.mediafiles.images[${targetIndex}]`, {
                      mainImage: respJson.data.url,
                      subImage: respJson.data.url,
                      urlVideo: null,
                    });
                  }
                })
                .catch(error => {
                  pushNotification('error', error);
                });
            })
            .catch(error => {
              pushNotification('error', error);
            });
        }
        setMediaFiles(newMediafiles);
      }
    },
    [pushNotification, mediafiles]
  );
  const handleDeleteImage = useCallback(
    ({ imageUrl, setFieldValue, urlVideo }) => {
      const newMediafiles = mediafiles.filter(file => file.subImage !== imageUrl || file.urlVideo !== urlVideo);
      while (newMediafiles.length < 10) {
        newMediafiles.push({});
      }
      const formikMediafiles = newMediafiles.filter(file => !isEmpty(file));

      setMediaFiles(newMediafiles);
      setFieldValue(`generalInfo.mediafiles.images`, formikMediafiles);
    },
    [mediafiles]
  );
  const handleChangeVideoUrl = useCallback(
    ({ urlVideo, currentVideoUrl, setFieldValue, index }) => {
      const newMediafiles = [...mediafiles];
      const emptyIndex = newMediafiles.findIndex(file => isEmpty(file));
      const targetIndex = currentVideoUrl ? index : emptyIndex;
      if (targetIndex !== -1) {
        newMediafiles[targetIndex] = {
          mainImage: null,
          subImage: null,
          urlVideo,
        };

        setFieldValue(`generalInfo.mediafiles.images[${targetIndex}]`, {
          mainImage: null,
          subImage: null,
          urlVideo,
        });
        setMediaFiles(newMediafiles);
      }
    },
    [mediafiles]
  );
  console.log('mediafiles', mediafiles);

  if (!data) return <div />;
  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chỉnh sửa sản phẩm mới kim cương</Title>
      </div>
      <div
        style={{
          border: '1px solid #C7C9D9',
          backgroundColor: '#FAFAFC',
          padding: '15.5px 8px',
          fontSize: 18,
        }}
      >
        {state.data?.generalInfo.productName}
      </div>
      <div
        style={{
          paddingLeft: 8,
          paddingRight: 8,
          fontSize: 16,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        Đường dẫn:
        <a style={{ marginLeft: 5 }} href={link} target="__blank">
          {link}
        </a>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, setFieldValue, submitCount, touched = {}, values = {} }) => {
          return (
            <>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between" id="info_field">
                  {infoFields.title}
                  <IconButton>
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <UncontrolledCollapse defaultOpen toggler="#info_field">
                  <BlockContent>
                    {infoFields.products.map((info, index) => (
                      <Row key={`infoFields-${info.name}-${index}`} className="align-items-center">
                        {info.name === 'price' ? (
                          <CustomInput
                            {...info}
                            error={get(touched, info.name) || !!submitCount ? get(errors, info.name) : null}
                            value={get(values, info.name)}
                            autoComplete="new-password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            // options={options[info.option]}
                            placeholder={info.placeholder || info.label}
                            onValueChange={values => setFieldValue('price', values.value)}
                          />
                        ) : (
                          <FieldInput
                            {...info}
                            error={get(touched, info.name) || !!submitCount ? get(errors, info.name) : null}
                            value={info.value ?? get(info.generalInfo ? values.generalInfo : values, info.name)}
                            autoComplete="new-password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            options={options[info.option]}
                            placeholder={info.placeholder || info.label}
                          />
                        )}
                      </Row>
                    ))}
                  </BlockContent>
                  <BlockContent>
                    <Row className="align-items-center">
                      <LabelInput className="col-md-3 col-form-label">{'Hình thu nhỏ *'}</LabelInput>
                      <Col md={9}>
                        <Row className="justify-content-start">
                          {mediafiles.map((image, index) => (
                            <ImageCol className="mb-1">
                              <Thumbnail
                                id={index}
                                avatarSrc={image.subImage}
                                urlVideo={image.urlVideo}
                                onChangeImage={event =>
                                  handleChangeImage({
                                    event,
                                    currentImage: image.subImage,
                                    setFieldValue,
                                  })
                                }
                                onDelete={() =>
                                  handleDeleteImage({
                                    imageUrl: image.subImage,
                                    urlVideo: image.urlVideo,
                                    setFieldValue,
                                  })
                                }
                                onChangeUrl={url =>
                                  handleChangeVideoUrl({
                                    urlVideo: url,
                                    currentVideoUrl: image.urlVideo,
                                    setFieldValue,
                                    index,
                                  })
                                }
                                multiple={!image.subImage}
                              />
                            </ImageCol>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </BlockContent>
                </UncontrolledCollapse>
              </Block>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between" id="property_field">
                  {propertyFields.title}
                  <IconButton>
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <UncontrolledCollapse defaultOpen toggler="#property_field">
                  <BlockContent>
                    {propertyFields.products.map((property, index) => {
                      const { label, name, extraProperties, placeholder } = property;
                      const linkGIAReportNumber = values.GIAReportNumber
                        ? `https://www.gia.edu/report-check?reportno=${values.GIAReportNumber}`
                        : null;
                      return (
                        <Row key={`propertyFields-${property.name}-${index}`} className="align-items-center">
                          <FieldInput
                            {...property}
                            error={get(touched, name) || !!submitCount ? get(errors, name) : null}
                            value={
                              property.name === 'linkGIAReportNumber'
                                ? linkGIAReportNumber
                                : get(extraProperties ? values.extraProperties : values, name)
                            }
                            autoComplete="new-password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            // options={options[property.option]}
                            placeholder={placeholder || label}
                          />
                        </Row>
                      );
                    })}
                  </BlockContent>
                </UncontrolledCollapse>
              </Block>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between" id="seo_field">
                  {seoFields.title}
                  <IconButton>
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <UncontrolledCollapse defaultOpen toggler="#seo_field">
                  <BlockContent>
                    {seoFields.products.map(seo => {
                      return (
                        <Row key={`seoFields-${seo.name}`} className="align-items-center">
                          <FieldInput
                            error={get(touched, seo.name) || !!submitCount ? get(errors, seo.name) : null}
                            value={get(values.generalInfo.SEOInfo, seo.name)}
                            autoComplete="new-password"
                            onBlur={handleBlur}
                            onChange={event => setFieldValue(`generalInfo.SEOInfo.${seo.name}`, event.target.value)}
                            // options={options[property.option]}
                            placeholder={seo.placeholder || seo.label}
                            {...seo}
                          />
                        </Row>
                      );
                    })}
                  </BlockContent>
                  <Row style={{ padding: 16 }} className="align-items-center">
                    <LabelInput className="col-form-label" style={{ width: '12.5%' }}>
                      {'Mô tả (description)'}
                    </LabelInput>
                    <Col>
                      <Input
                        value={get(values.generalInfo.SEOInfo, 'desc')}
                        type="textarea"
                        onChange={event => setFieldValue('generalInfo.SEOInfo.desc', event.target.value)}
                      />
                    </Col>
                  </Row>
                </UncontrolledCollapse>
              </Block>
              <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.USER} />
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export default EditDiamond;
