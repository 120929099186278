import React from 'react';

const CustomBadge = ({ name, onDelete }) => {
    return (
        <div className='mb-1'>
            <div className="badge badge-soft-secondary font-size-14 me-1" style={{ padding: '10px 6px' }}>
                {name}
                <i onClick={onDelete} className='mdi mdi-close font-size-16' style={{ cursor: 'pointer', color: '#FF3B3B' }} />
            </div>
        </div>
    );
};

export default CustomBadge;
