import { useCallback, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { Row } from 'reactstrap';
import { FieldInput, UploadFile } from 'components/own';

import { get } from 'lodash';
import { ControlTypes } from 'constants/field';
import { MenuTemplateOptions } from 'constants/options';
import styled from 'styled-components';
import NodeAction from './NodeAction';
import { AvatarDropIcon } from 'components/icons';
import { MenuOrderType } from 'constants/common';

const RowAction = styled.div`
  display: flex;
  margin-top: 12px;
`;
const LinkButton = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  text-decoration-line: underline;

  /* Blue / Blue 1 */

  color: #0063f7;
  margin-left: 8px;

  cursor: pointer;
`;
const NodeParent = ({ data, onDelete, onSave, onRefresh, origin, onMoveNode, onChange, path }) => {
  const buttonRef = useRef();
  useEffect(() => {
    try {
      buttonRef.current.click();
    } catch (error) {}
  }, [data.id]);
  const updateValue = useCallback(
    e => {
      onChange(path, { ...data, [e.target.name]: e.target.value });
    },
    [data, onChange, path]
  );
  return (
    <Formik initialValues={data} onSubmit={onSave}>
      {({ handleChange, handleSubmit, values, setValues }) => (
        <>
          <Row>
            <FieldInput
              value={get(values, ['type'], '')}
              inputMd={12}
              label="Chọn kiểu cho sub menu"
              name={`type`}
              onChange={e => {
                handleChange(e);
                updateValue(e);
              }}
              controlType={ControlTypes.SELECT_PICKER}
              options={MenuTemplateOptions}
            />
          </Row>
          <Row>
            <FieldInput
              value={get(values, ['link'], '')}
              inputMd={12}
              label="URL"
              name={`link`}
              onChange={e => {
                handleChange(e);
                updateValue(e);
              }}
            />
          </Row>
          <Row>
            <FieldInput
              value={get(values, ['name'])}
              inputMd={12}
              label="Nhãn điều hướng"
              name={`name`}
              onChange={e => {
                handleChange(e);
                updateValue(e);
              }}
            />
          </Row>
          {values?.type !== 1 && (
            <>
              <div className="mb-2"></div>
              <UploadFile showEdit name={'mediafile.image'} src={values.mediafile?.image} handleChange={handleChange}>
                <AvatarDropIcon />
              </UploadFile>
            </>
          )}
          <RowAction>
            Di chuyển :<LinkButton onClick={() => onMoveNode(data, MenuOrderType.MOVE_UP)}>Lên một bậc</LinkButton>
            <LinkButton onClick={() => onMoveNode(data, MenuOrderType.MOVE_DOWN)}>Xuống một bậc</LinkButton>
            <LinkButton onClick={() => onMoveNode(data, MenuOrderType.MOVE_TOP)}>Lên trên cùng</LinkButton>
            <LinkButton onClick={() => onMoveNode(data, MenuOrderType.MOVE_BOTTOM)}>Xuống dưới cùng</LinkButton>
          </RowAction>
          <div className="d-none" ref={buttonRef} onClick={() => setValues(data)}></div>
          <NodeAction
            onCancel={() => {
              setValues(origin);
              onChange(path, origin);
            }}
            onSave={handleSubmit}
            onDelete={() => onDelete(path, data.id)}
          />
        </>
      )}
    </Formik>
  );
};

export default NodeParent;
