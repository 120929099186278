import styled from 'styled-components';
import Select from 'react-select';

import { Input } from 'reactstrap';
import { Authentication, ButtonOutline, ButtonPrimary, CustomTable, Paginate } from 'components/own';
import { DropDownIcon } from 'components/icons';
import { useCommonManagementHook } from './useCommonHook';
import { FilterInput } from '../Filter';
import React from 'react';
import { get, has, sum, values } from 'lodash';
import { AuthenticationTypes } from 'constants/authentication';
import PopupQuickEdit from './PopupQuickEdit';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-right: 20px;
`;

const SelectAction = styled(Select)`
  cursor: pointer;
  width: 100px;
  margin-right: 4px;
  font-size: 14px;
  .select2-selection__indicators {
    padding-right: 4px;
  }
`;

const SearchBox = styled(Input)`
  width: 200px;
  height: 32px;
  background: #fafafc;
  input:after {
    border-bottom-width: 1px !important;
  }
`;

const Label = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  display: flex;
  align-items: center;

  /* Dark / Dark 0 */

  color: #1c1c28;
`;

const ContentTable = styled.div`
  background-color: #fff;
  margin-top: 16px;
`;

const SummaryText = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: ${({ selected }) => (selected ? '700' : '400')};
  font-size: 13px;
  line-height: 16px;
  /* or 123% */

  color: ${({ selected }) => (selected ? '#1e1e1e' : '#0063F7')};
  margin-right: 5px;
`;

const SummaryCount = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* or 123% */

  color: #1e1e1e;
`;

const LineBreak = styled.div`
  width: 1px;
  height: 14px;
  background-color: #757575;
  margin: 0 6px;
`;

const isTabSelected = (params, tab) => {
  const item = get(params, [tab.keyword]);
  if ((!has(params, [tab.keyword]) || item === '') && tab.value === '') return true;

  return item === tab.value;
};

const CommonManagement = ({ module, initParams }) => {
  const {
    actions = [],
    buttonAddTitle,
    columns,
    columnTemplate,
    data,
    dataEdit,
    editConfig,
    filterElements = [],
    hidePaging,
    hideSearch,
    loading,
    limit,
    order,
    page,
    params,
    ref,
    selectedAction,
    selectable,
    title,
    total,
    upperFilterElements = [],
    selectedRow = [],
    extraCount = [],
    tabSummary,
    feature,

    applyAction,
    handleSelectGroup,
    onAddNew,
    onChangePage,
    onClickFilter,
    onEdit,
    onKeypressFilter,
    onOrder,
    onQuickEditSuccess,
    onSelectFilter,
    onSelectRow,
  } = useCommonManagementHook(module, initParams);

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>{title}</Title>
        {buttonAddTitle && (
          <Authentication feature={feature} type={AuthenticationTypes.CREATE}>
            <ButtonOutline onClick={onAddNew}>{buttonAddTitle}</ButtonOutline>
          </Authentication>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end">
        {extraCount?.map((_tab, i) => (
          <div
            role="button"
            className="d-flex align-items-center"
            key={i}
            onClick={() => onSelectFilter({ target: { name: _tab.keyword, value: _tab.value } })}
          >
            {i > 0 && <LineBreak />}
            <SummaryText selected={isTabSelected(params, _tab)}>{_tab?.text}</SummaryText>
            <SummaryCount>({get(tabSummary, [_tab.name], _tab?.name === 'totalCount' ? sum(values(tabSummary)) : 0)})</SummaryCount>
          </div>
        ))}
        <div className="flex-1"></div>
        {upperFilterElements.map((_f, index) => (
          <FilterInput
            key={index}
            {..._f}
            onChange={onSelectFilter}
            value={params[_f.name]}
            minDate={params[_f.minDate]}
            maxDate={params[_f.maxDate]}
          />
        ))}
        {!hideSearch && (
          <>
            <SearchBox innerRef={ref} type="text" className="form-control me-3" onKeyPress={onKeypressFilter} placeholder="Nhập keyword" />
            <ButtonOutline onClick={onClickFilter}>Tìm kiếm</ButtonOutline>
          </>
        )}
      </div>
      <div className="d-flex align-items-center mt-2">
        {selectable && (
          <Authentication feature={feature} type={AuthenticationTypes.EDIT}>
            <>
              <SelectAction
                placeholder="Tác vụ"
                value={selectedAction}
                onChange={handleSelectGroup}
                options={actions}
                classNamePrefix="select2-selection"
                components={{
                  DropdownIndicator: DropDownIcon,
                  IndicatorSeparator: null,
                }}
              />
              <ButtonPrimary fontWeight={500} width="75px" className="me-2" onClick={applyAction}>
                Áp dụng
              </ButtonPrimary>
            </>
          </Authentication>
        )}
        {filterElements.map((_f, index) => (
          <FilterInput
            key={index}
            {..._f}
            onChange={onSelectFilter}
            value={params[_f.name]}
            minDate={params[_f.minDate]}
            maxDate={params[_f.maxDate]}
          />
        ))}

        <div className="flex-1"></div>
        <Label className="me-2">{total || 0} mục</Label>
        {!!total && !hidePaging && <Paginate page={page} total={total} itemPerPage={limit} onChange={onChangePage} />}
      </div>
      <ContentTable className="">
        <CustomTable
          selectable={selectable}
          columnTemplate={columnTemplate}
          rowSelected={selectedRow}
          onChecked={onSelectRow}
          data={data}
          headers={columns}
          feature={feature}
          order={order}
          loading={loading}
          rowHeight={83}
          dataEdit={dataEdit}
          onOrder={onOrder}
          onEdit={onEdit}
          onRefresh={() => onClickFilter(true)}
        />
      </ContentTable>
      <PopupQuickEdit data={dataEdit} config={editConfig} feature={feature} onClose={onEdit} onSaveSuccess={onQuickEditSuccess} />
    </div>
  );
};

export default CommonManagement;
