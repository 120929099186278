import React, { Component } from 'react';

import { Row, Button, Container, Alert } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import logo from '../../assets/images/vinh-cara/logo/login-logo.png';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { checkLogin, apiError } from '../../store/actions';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;
// import images

const Root = styled(Container)`
  font-family: 'Roboto', sans-serif;
  background-color: #f5f7fb;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 412px;
  margin: auto;
  height: 500px;
`;

const Form = styled(AvForm)`
  margin-top: 60px;
  background-color: #fff;
  border-radius: 5px;
  padding: 46px;
`;

const InputGroup = styled.div`
  height: 60px;
  .form-control {
    background-color: #f5f7fb;
    border-width: 0;
    border-radius: 5px;
    height: 60px;
  }
`;

const ForgetPass = styled.a`
  color: #0063f7 !important;
  font-size: 20px;
  font-weight: 400;
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = process.env.REACT_APP_DEVELOPE_MODE
      ? { username: '0000000000', password: '123456a@', captchaToken: null }
      : { username: '', password: '', captchaToken: null };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleSubmit(event, values) {
    if (!this.state.captchaToken) return;
    this.props.checkLogin(values, this.props.history);
  }

  onChange(event) {
    this.setState({ captchaToken: event });
  }

  componentDidMount() {
    this.props.apiError('');
    document.body.classList.add('auth-body-bg');
  }

  componentWillUnmount() {
    document.body.classList.remove('auth-body-bg');
  }

  render() {
    return (
      <Root fluid className="p-0">
        <Content>
          <Row className="justify-content-center">
            <div className="d-flex align-items-center justify-content-center">
              <img src={logo} alt="" height="42" width="346" />
            </div>

            {this.props.loginError && <Alert color="danger">{this.props.loginError?.message}</Alert>}

            <Form className="form-horizontal" onValidSubmit={this.handleSubmit}>
              <div htmlFor="username" className="font-size-20">
                <i className="mdi mdi-email-outline me-1"></i>
                Email address
              </div>
              <InputGroup className="mb-4 mt-1">
                <AvField
                  name="username"
                  value={this.state.username}
                  type="text"
                  className="form-control"
                  id="username"
                  validate={{ required: true }}
                  placeholder="Enter username"
                />
              </InputGroup>
              <div htmlFor="userpassword" className="font-size-20">
                <i className="mdi mdi-lock-outline me-1"></i>
                Password
              </div>
              <InputGroup className="mb-4 mt-1">
                <AvField
                  name="password"
                  value={this.state.password}
                  type="password"
                  className="form-control"
                  id="userpassword"
                  placeholder="Enter password"
                />
              </InputGroup>
              <ReCAPTCHA type="image" sitekey={captchaKey} onChange={this.onChange} />
              <div className="w-100 mt-4 text-center d-flex align-items-center justify-content-between">
                <ForgetPass href="/forgot-password" className="text-muted">
                  Forgot password?
                </ForgetPass>
                <Button color="primary" disabled={!this.state.captchaToken} className="w-md waves-effect waves-light" type="submit">
                  ĐĂNG NHẬP
                </Button>
              </div>
            </Form>
          </Row>
        </Content>
      </Root>
    );
  }
}

const mapStateToProps = state => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(connect(mapStateToProps, { checkLogin, apiError })(Login));
