import { EditIcon, TrashIcon } from 'components/icons';
import { Authentication } from 'components/own';
import { AuthenticationTypes } from 'constants/authentication';
import { get } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { deleteAPI } from 'services/common';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/uti';

const Root = styled.div`
  display: flex;
`;

const Button = styled.div`
  cursor: pointer;
  margin-right: 12px;
`;

const CellAction = ({ editMode, data, dataField, feature, link, deleteUrl, onRefresh }) => {
  const router = useHistory();

  const value = useMemo(() => data.id || get(data, dataField), [data, dataField]);

  const onEdit = useCallback(() => {
    router.push(link + '/' + value);
  }, [link, router, value]);

  const onDelete = useCallback(async () => {
    try {
      await deleteAPI(deleteUrl + '/' + value);
      onRefresh();
    } catch (error) {}
  }, [deleteUrl, onRefresh, value]);

  return (
    <Root>
      <Authentication feature={feature} type={AuthenticationTypes.VIEW}>
        <Button onClick={onEdit}>
          <EditIcon />
        </Button>
      </Authentication>
      <Authentication feature={feature} type={AuthenticationTypes.DELETE}>
        <Button onClick={onDelete}>
          <TrashIcon />
        </Button>
      </Authentication>
    </Root>
  );
};

export default memo(CellAction, propsAreEqual);
