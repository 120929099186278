export const CellControlType = {
  MenuAction: 'MenuAction',
  TextNonAction: 'TextNonAction',
  TextAction: 'TextAction', // edit , copy
  TextWithAction: 'TextWithAction', // edit , fast edit , copy
  TextWithActionDelete: 'TextWithActionDelete', //edit , fast edit ,delete, copy
  TextWithQuickEditDelete: 'TextWithQuickEditDelete', //fast edit ,delete
  TextWithActionLink: 'TextWithActionLink', // https://www.figma.com/file/ouQFCjPU5ZqNoGu1FdM0SD/Vinh_Cara?node-id=4927%3A164167
  TextWithActionDeleteLink: 'TextWithActionDeleteLink', // https://www.figma.com/file/ouQFCjPU5ZqNoGu1FdM0SD/Vinh_Cara?node-id=4927%3A164167
  TextWithActionEditDeleteLink: 'TextWithActionEditDeleteLink', // https://www.figma.com/file/ouQFCjPU5ZqNoGu1FdM0SD/Vinh_Cara?node-id=4927%3A164167
  TextWithActionEditQuickEditDelete: 'TextWithActionEditQuickEditDelete', // https://www.figma.com/file/ouQFCjPU5ZqNoGu1FdM0SD/Vinh_Cara?node-id=4927%3A164167
  Date: 'Date',
  DateTime: 'DateTime',
  Action: 'Action',
  Image: 'Image',
  Status: 'Status',
  TextList: 'TextList',
  Link: 'Link',
  TextCopy: 'TextCopy',
};
