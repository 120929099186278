import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { cloneDeep, get, map, set } from 'lodash';
import { Accordion } from 'react-bootstrap';

import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { ActionsItem, AddonProductTable, FieldInput, SeoBlock, UploadFile } from 'components/own';
import { useActionNotification } from 'hook/useContextSelector';
import { AuthenticationFeature } from 'constants/authentication';
import { getAPI, postAPI, putAPI, uploadImage } from 'services/common';
import { ControlTypes } from 'constants/field';
import { AvatarDropIcon, LargerDropIcon } from 'components/icons';
import { getChangedData } from 'utils/uti';

const InitialLuxury = {
  SEOInfo: {},
  bannerInfo: { list: [] },
  link: '',
  mediafiles: { image: '' },
  meta: {},
  name: '',
  status: 1,
};

const initialState = {
  data: null,
  options: {},
};

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const NameLabel = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  color: #000000;
`;

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ $templateColumns }) => $templateColumns || '1fr'};
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const CollectionItem = () => {
  const [{ data }, setState] = useState(initialState);
  const { id } = useParams();
  const router = useHistory();
  const ref = useRef();
  const pushNotification = useActionNotification();

  useEffect(() => {
    if (id === 'new') {
      setState(
        produce(draft => {
          draft.data = InitialLuxury;
        })
      );
    } else {
      getAPI('collections/' + id).then(res => {
        setState(
          produce(draft => {
            draft.data = res.data;
          })
        );
      });
    }
  }, [id]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required('Vui lòng nhập tên trang.'),
        link: yup.string().required('Vui lòng nhập đường dẫn trang.'),
      }),
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/collections');
  }, [router]);

  const onSave = useCallback(
    async values => {
      let request = getChangedData(cloneDeep(values), data);
      if (request.mediafiles?.image && typeof request.mediafiles?.image !== 'string') {
        const res = await uploadImage(request.mediafiles?.image);
        set(request, 'mediafiles.image', res?.url);
      }

      if (get(request, ['bannerInfo', 'list', 0, 'bannerPC']) && typeof get(request, ['bannerInfo', 'list', 0, 'bannerPC']) !== 'string') {
        const res = await uploadImage(get(request, ['bannerInfo', 'list', 0, 'bannerPC']));
        set(request, ['bannerInfo', 'list', 0, 'bannerPC'], res?.url);
      }

      if (values.id) {
        putAPI('collections/' + values.id, request)
          .then(() => {
            pushNotification('success', 'Lưu thành công');
            router.replace('/collections');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      } else {
        const productCode = ref.current.getSelected();
        request.productCode = map(productCode, 'id');
        postAPI('collections', Object.assign(cloneDeep(values), request))
          .then(() => {
            pushNotification('success', 'Tạo thành công');
            router.replace('/collections');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      }
    },
    [data, pushNotification, router]
  );

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chi tiết bộ sưu tập</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, submitCount, touched = {}, values = {} }) => (
          <>
            <Row className="mb-2 d-flex">
              <Col md={2} className="d-flex align-items-center">
                <NameLabel>Tên danh sách</NameLabel>
              </Col>
              <FieldInput
                inputMd={10}
                name="name"
                error={get(touched, 'name') || !!submitCount ? get(errors, 'name') : null}
                value={get(values, 'name')}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={'Tên sản phẩm cao cấp'}
              />
            </Row>
            <Accordion alwaysOpen defaultActiveKey={['info']}>
              <Accordion.Item className="mb-3" eventKey="info">
                <BlockHeader className="d-flex align-items-center justify-content-between">Thông tin bộ sưu tập</BlockHeader>
                <BlockContent $templateColumns="1fr 1fr">
                  <Col>
                    <Row className="mb-3">
                      <FieldInput
                        name="status"
                        label="Hiển thị"
                        controlType={ControlTypes.CHECKBOX}
                        value={get(values, 'status') === 1}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <Col md={3} className="d-flex align-items-center">
                        Banner
                      </Col>
                      <Col md={9}>
                        <UploadFile
                          name={'bannerInfo.list.0.bannerPC'}
                          src={get(values, 'bannerInfo.list.0.bannerPC')}
                          handleChange={handleChange}
                        >
                          <LargerDropIcon />
                        </UploadFile>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="mb-3">
                      <FieldInput
                        name="link"
                        label="Đường dẫn"
                        error={get(touched, 'link') || !!submitCount ? get(errors, 'link') : null}
                        value={get(values, 'link')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <Col md={3} className="d-flex align-items-center">
                        Ảnh đại diện
                      </Col>
                      <Col md={9}>
                        <UploadFile name={'mediafiles.image'} src={values.mediafiles?.image} handleChange={handleChange}>
                          <AvatarDropIcon />
                        </UploadFile>
                      </Col>
                    </Row>
                  </Col>
                </BlockContent>
              </Accordion.Item>
            </Accordion>
            <AddonProductTable feature="collections" ref={ref} />
            <SeoBlock values={values} handleChange={handleChange} />
            <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.PRODUCT} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default CollectionItem;
