import styled from 'styled-components';

const Root = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ margin: 'auto', background: 'transparent', display: 'block' }}
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <path
      fill="none"
      stroke="#007770"
      strokeWidth="8"
      strokeDasharray="153.95335693359377 102.63557128906248"
      d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
      strokeLinecap="round"
      style={{ transformOrigin: '50px 50px' }}
    >
      <animate
        attributeName="stroke-dashoffset"
        repeatCount="indefinite"
        dur="1.4492753623188404s"
        keyTimes="0;1"
        values="0;256.58892822265625"
      ></animate>
    </path>
  </svg>
);

const LoadingPage = () => (
  <Root>
    <Icon />
  </Root>
);

export default LoadingPage;
