export const AuthenticationTypes = {
  ALL: 'ALL',
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

export const AuthenticationFeature = {
  ALL: 'ALL',
  PRODUCT: 'PRODUCT',
  COUPON: 'COUPON',
  MEDIAFILE: 'MEDIAFILE',
  BLOG: 'BLOG',
  PAGE: 'PAGE',
  INFO: 'INFO',
  ORDER: 'ORDER',
  SEO: 'SEO',
  USER: 'USER',
  ROLE: 'ROLE',
};
