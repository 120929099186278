/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import { E_COMMERCE_URL } from 'constants/common';
import { isEqual, find, map, orderBy, some, toLower } from 'lodash';

export const propsAreEqual = (oldProps, newProps, keys) => {
  if (!keys) keys = Object.keys(oldProps);
  for (const key in keys) {
    if (Object.hasOwnProperty.call(keys, key)) {
      const element = keys[key];
      if (typeof oldProps[element] !== 'function' && !isEqual(oldProps[element], newProps[element])) return false;
    }
  }

  return true;
};

export const parseUrlQuery = (params, orderParams, prefix = '', orderFnc) => {
  let query = prefix;
  for (const key in params) {
    if (Object.hasOwnProperty.call(params, key)) {
      const element = params[key];
      if (!element || element == 'null' || key === 'operatorId' || key === 'totalResults') continue;

      if (key === 'filterValue') {
        query += `&keyword=${element}`;
      } else query += `&${key}=${Array.isArray(element) ? map(element, 'value').join(',') : element}`;
    }
  }

  if (orderParams && orderParams?.order) {
    const order = orderFnc && typeof orderFnc === 'function' ? orderFnc(orderParams?.order) : orderParams?.order;
    query += `&orderBy=${orderParams.orderBy}${order}`;
  }

  return query;
};

export const hasNewParams = (oldParams, newParams) => {
  for (const key in newParams) {
    if (Object.hasOwnProperty.call(newParams, key)) {
      if (newParams[key] !== oldParams[key]) return true;
    }
  }

  return false;
};

export const getChangedData = (data, oldData, keys = Object.keys(data)) => {
  const result = {};

  for (const key in keys) {
    if (Object.hasOwnProperty.call(keys, key)) {
      const element = keys[key];
      if (Object.hasOwnProperty.call(data, element) && !isEqual(data[element], oldData[element])) {
        result[element] = data[element];
      }
    }
  }

  return result;
};

export const parseDate = (date, defaultValue = '') => (date ? new Date(date).toLocaleDateString('en-GB') : defaultValue);
export const parseTime = (date, defaultValue = '') =>
  date ? `at ${new Date(date).getHours()}:${new Date(date).getMinutes()}:${new Date(date).getSeconds()}` : defaultValue;

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export const parsePrice = price => {
  if (!price) return 0;
  price = typeof price === 'number' ? price : parseInt(price);

  return price.toLocaleString('it-IT', {
    currency: 'VND',
  });
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
export const removeVietnameseTones = str => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
  str = str.split(' ').join('-');
  return toLower(str);
};

export function getTreeFromFlatData({ flatData, getKey = node => node.id, getParentKey = node => node.parentId, rootKey }) {
  if (!flatData) {
    return [];
  }

  const childrenToParents = {};
  flatData.forEach(child => {
    const parentKey = getParentKey(child);

    if (parentKey in childrenToParents) {
      childrenToParents[parentKey].push(child);
    } else {
      childrenToParents[parentKey] = [child];
    }
  });

  if (!(rootKey in childrenToParents)) {
    return [];
  }

  const trav = parent => {
    const parentKey = getKey(parent);
    if (parentKey in childrenToParents) {
      return {
        ...parent,
        children: orderBy(
          childrenToParents[parentKey].map(child => trav(child)),
          ['order']
        ),
      };
    }

    return { ...parent };
  };

  return childrenToParents[rootKey].map(child => trav(child));
}

export const moveItemInArray = (array, fromIndex, toIndex) => {
  array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
};

export const getSlugFromCategory = (categories, id) => {
  if (!id || !Array.isArray(categories) || !categories.length) return '';
  let item = find(categories, ['id', id]);
  if (item) return item.slug + '/';
  const parent = find(categories, _c => some(_c.subs, ['id', id]));
  if (!parent) return '';
  item = find(parent.subs, ['id', id]);
  return `${parent.slug}/${item?.slug}/`;
};

export const getSlugFromType = data => {
  if (!data) return '';
  let url = data.slug;
  if (data.blogTypeInfo?.slug) {
    url = data.blogTypeInfo?.slug + '/' + url;
    if (data.blogTypeInfo?.parent?.slug) {
      url = data.blogTypeInfo?.parent?.slug + '/' + url;
    }
  }
  return `${E_COMMERCE_URL}${url}`;
};
