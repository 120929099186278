import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalBody, Row } from 'reactstrap';
import Checkbox from './Checkbox';

const BlockContent = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
`;
const CustomModal = ({ title, toggle, isOpen, data, onChange }) => {
    const [list, setList] = useState([]);
    const handleSelectItem = (value, id) => {
        const newList = [...list];
        const index = list.findIndex(item => item.id === id);
        if (index !== -1) {
            newList[index].checked = value;

            setList(newList);
        }
    }
    useEffect(() => {
        const clonedData = JSON.parse(JSON.stringify(data));
        setList(clonedData);
    }, [data]);
    return (
        <div>
            <Modal centered isOpen={isOpen} toggle={() => {
                // Revert state when close modal
                const clonedData = JSON.parse(JSON.stringify(data));
                setList(clonedData);
                toggle();
            }}>
                <ModalBody>
                    <span className='font-size-16' style={{ fontWeight: 700 }}>
                        {title}
                    </span>
                    <BlockContent>
                        {
                            list.map(({ id, name, checked }) => (
                                <Checkbox
                                    key={`collection-${id}`}
                                    listName="collection"
                                    id={id}
                                    name={name}
                                    checked={checked}
                                    onChange={(value) => handleSelectItem(value, id)}
                                />
                            ))
                        }
                    </BlockContent>
                    <Row style={{ padding: 16 }}>
                        <Button
                            color="primary"
                            onClick={() => {
                                onChange(list)
                            }}
                        >
                            THÊM
                        </Button>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default CustomModal;