import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { ShowHideOptions } from 'constants/options';
import { CellControlType } from 'constants/table';

export const getLuxuryInfo = () => ({
  buttonAddTitle: 'Thêm sản phẩm',
  feature: AuthenticationFeature.PRODUCT,
  actions: [
    { label: 'Hiển thị', value: 1, key: 'status' },
    { label: 'Không hiển thị', value: -1, key: 'status' },
  ],
  columns: [
    {
      dataField: 'name',
      text: 'Tên danh sách',
      controlType: CellControlType.TextAction,
      orderKey: 'name',
      detailUrl: 'luxury-jewelleries',
    },
    {
      dataField: 'productCode',
      text: 'Mã sản phẩm',
      orderKey: 'productCode',
      detailUrl: 'luxury-jewelleries',
    },
    {
      dataField: 'totalProduct',
      text: 'Số lượng',
    },
    {
      dataField: 'mediafiles.image',
      text: 'Hình đại diện',
      controlType: CellControlType.Image,
    },
    {
      dataField: 'status',
      text: 'Trạng thái',
      controlType: CellControlType.Status,
      render: value => (value === 1 ? 'Đang hiển thị' : 'Không hiển thị'),
      color: value => (value === 1 ? '#007770' : '#E80000'),
      background: value => (value === 1 ? 'rgba(0, 119, 112, 0.1)' : 'rgba(232, 0, 0, 0.1)'),
    },
    {
      dataField: 'gender',
      text: 'Giới tính',
      render: value => (value === 1 ? 'Nam' : value === 2 ? 'Nữ' : 'Không xác định'),
    },
    {
      dataField: 'createdAt',
      text: 'Ngày tạo',
      controlType: CellControlType.Date,
    },
    {
      dataField: 'id',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'luxury-jewelleries',
      deleteUrl: 'luxury-jewelleries',
    },
  ],
  columnTemplate: '1fr  1fr 100px 100px 150px 100px 100px 100px',
  filterElements: [
    {
      name: 'status',
      placeholder: 'Trạng thái',
      controlType: ControlTypes.SELECT_PICKER,
      options: ShowHideOptions,
      width: '110px',
    },
    {
      name: 'gender',
      placeholder: 'Giới tính',
      controlType: ControlTypes.SELECT_PICKER,
      options: [
        { label: 'Nam', value: 1 },
        { label: 'Nữ', value: 2 },
      ],
      width: '90px',
    },
  ],
  selectable: true,
  title: 'Danh sách sản phẩm cao cấp',
  url: 'luxury-jewelleries',
});
