import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import Select from 'react-select';
import { CustomTable, Paginate } from 'components/own';
import { CellControlType } from 'constants/table';
import { AuthenticationFeature } from 'constants/authentication';
import { getAllLinkSiteMap } from 'services/SEO';
import { ControlTypes } from 'constants/field';
import styles from './Sitemap.module.scss';
import { Button, CardBody, Col, Input, Row } from 'reactstrap';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-right: 20px;
`;
const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
`;

const SelectAction = styled(Select)`
  cursor: pointer;
  width: 100px;
  margin-right: 4px;
  font-size: 14px;
  .select2-selection__indicators {
    padding-right: 4px;
  }
`;

const Label = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  display: flex;
  align-items: center;

  /* Dark / Dark 0 */

  color: #1c1c28;
`;

const ContentTable = styled.div`
  background-color: #fff;
  margin-top: 16px;
`;

const columns = [
  {
    dataField: 'null',
    text: 'Cấu Trúc',
    sort: true,
    controlType: CellControlType.LABEL,
    link: 'blog',
    linkPreview: '',
  },
  {
    dataField: 'name',
    text: 'Site pages',
    sort: true,
    controlType: ControlTypes.LABEL,
  },
  {
    dataField: 'null',
    text: 'Mỗi file 200 link',
    sort: true,
    controlType: ControlTypes.LABEL,
  },
  {
    dataField: 'null',
    text: 'Cấu trúc tham khảo',
    sort: true,
    controlType: ControlTypes.LABEL,
  },
];

const optionActions = [
  { label: 'Mustard', value: 'Mustard' },
  { label: 'Ketchup', value: 'Ketchup' },
  { label: 'Relish', value: 'Relish' },
];
// const ecommerceUrl = E_COMMERCE_URL;
const initialState = { page: 1, total: 0, columns, data: [], searchValue: '', selectedAction: null, limit: 10, loading: true };

const Sitemap = () => {
  const [{ columns, data, loading, limit, page, searchValue, selectedAction, total }, setState] = useState(initialState);
  const columnTemplate = useMemo(() => '2fr 2fr 2fr 2fr', []);

  useEffect(() => {
    const callAPI = async () => {
      try {
        const res = await getAllLinkSiteMap('');
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = res?.data?.list || [];
            draft.total = res.data?.count || 0;
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = [];
            draft.total = 0;
          })
        );
      }
    };
    callAPI();
  }, [limit, page, searchValue]);

  const handleSelectGroup = useCallback(item => {
    setState(
      produce(draft => {
        draft.selectedAction = item;
      })
    );
  }, []);

  const onChangePage = useCallback(({ selected }) => {
    setState(
      produce(draft => {
        draft.page = selected + 1;
      })
    );
  }, []);

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Cấu hình SEO 404 Monitor</Title>
      </div>
      <div className="d-flex align-items-center mt-2">
        <div className="flex-1"></div>
        <Label className="me-2">{total} mục</Label>
        <Paginate page={page} total={total} itemPerPage={limit} onChange={onChangePage} />
      </div>
      <span className={`${styles['blogs__span-info']}`}>Link site map và nội dung sẽ cập nhật khi có bài mới</span>
      <ContentTable className="">
        <CustomTable
          selectable
          columnTemplate={columnTemplate}
          data={data}
          headers={columns}
          loading={loading}
          rowHeight={83}
          module={AuthenticationFeature.USER}
        />
      </ContentTable>
      <Row>
            <Col xl={12}>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">Demo</BlockHeader>
                <CardBody className={`${styles['blogs__content']}`} defaultOpen>
                  <Row className="mb-12">
                    <Label htmlFor="pagesTitle" className="col-md-12 col-form-label">
                      This XML file does not appear to have any style information associated with it. The document tree is shown below.
                    </Label>
                    <hr className={`${styles['blogs__hr']} col-md-12`}></hr>
                  </Row>
                </CardBody>
              </Block>
            </Col>
          </Row>
    </div>
  );
};

export default Sitemap;
