import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Form, FormGroup, InputGroup, Input, Button } from 'reactstrap';

// Import menuDropdown
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

//Import i18n

// Redux Store
import styled from 'styled-components';
import { E_COMMERCE_URL } from 'constants/common';

//Import logo Images
// import logoSmLight from '../../assets/images/vinh-cara/logo/logo-sm.png';
// import logoLight from '../../assets/images/vinh-cara/logo/logo.png';

//Import Social Profile Images

const ButtonTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-left: 8px;
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
    };
    this.routerToProduction = this.routerToProduction.bind(this);
  }

  routerToProduction() {
    window.open(E_COMMERCE_URL, '_blank');
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <Button size="sm" color="none" type="button" className="px-3 font-size-24 header-item" onClick={this.routerToProduction}>
              <i className="mdi mdi-home-outline align-middle"></i>
              <ButtonTitle>Vào Website Vĩnh Cara</ButtonTitle>
            </Button>
            <div className="flex-1" />

            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ms-2">
                <button
                  type="button"
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch });
                  }}
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                >
                  <i className="ri-search-line"></i>
                </button>
                <div
                  className={
                    this.state.isSearch === true
                      ? 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show'
                      : 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <Form className="p-3">
                    <FormGroup className="m-0">
                      <InputGroup>
                        <Input type="text" className="form-control" placeholder={'Search'} />
                        {/* <InputGroupAddon addonType="append">
                          <Button color="primary" type="submit">
                            <i className="ri-search-line"></i>
                          </Button>
                        </InputGroupAddon> */}
                      </InputGroup>
                    </FormGroup>
                  </Form>
                </div>
              </div>

              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStateToProps)(Header);
