import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { CellControlType } from 'constants/table';

export const getCouponInfo = () => ({
  buttonAddTitle: 'Thêm coupon',
  feature: AuthenticationFeature.COUPON,
  columns: [
    {
      dataField: 'code',
      text: 'Mã coupon',
      color: '#007770',
      detailUrl: 'coupons',
    },
    {
      dataField: 'couponType',
      text: 'Loại ưu đãi',
      render: value => (value === 1 ? 'Giảm giá' : value === 2 ? 'Quà' : 'Giảm giá + Quà'),
    },
    {
      dataField: 'showValue',
      text: 'Hạn mức giảm',
      custom: 'price',
    },
    {
      dataField: 'status',
      text: 'Tình trạng',
      controlType: CellControlType.Status,
      render: value => (value === -1 ? 'Chưa áp dụng' : value === 1 ? 'Đang áp dụng' : 'Đã hết hạn'),
      color: value => (value === -1 ? '#FDAC42' : value === 1 ? '#007770' : '#555770'),
      background: value => (value === -1 ? 'rgba(253, 172, 66, 0.1)' : value === 1 ? 'rgba(0, 119, 112, 0.1)' : 'rgba(85, 87, 112, 0.1)'),
    },
    {
      dataField: 'startTime',
      text: 'Ngày bắt đầu',
      sort: true,
      controlType: CellControlType.Date,
    },
    {
      dataField: 'endTime',
      text: 'Ngày kết thúc',
      sort: true,
      controlType: CellControlType.Date,
    },
    {
      dataField: 'userType',
      text: 'Áp dụng cho thành viên',
      render: value => (value === 1 ? 'Cho tất cả' : 'Cho thành viên'),
    },
    {
      dataField: 'discountPercent.jewellery.list',
      text: 'Áp dụng cho sản phẩm',
      custom: 'array',
    },
    {
      dataField: 'action',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'coupons',
      deleteUrl: 'coupons',
    },
  ],
  columnTemplate: '150px 150px 150px 150px 150px 150px 200px 1fr 100px',
  filterElements: [
    {
      name: 'couponType',
      placeholder: 'Loại ưu đãi',
      controlType: ControlTypes.SELECT_PICKER,
      options: [
        { label: 'Loại ưu đãi', value: 0 },
        { label: 'Giảm giá', value: 1 },
        { label: 'Quà tặng', value: 3 },
        { label: 'Giảm giá + Quà', value: 2 },
      ],
      width: '150px',
    },
    {
      name: 'minimumRequirement',
      placeholder: 'Hạn mức',
      controlType: ControlTypes.SELECT_PICKER,
      options: [],
      width: '120px',
    },
  ],
  upperFilterElements: [
    {
      label: 'Ngày',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'dateFrom',
      maxDate: 'dateTo',
      controlType: ControlTypes.DATE_PICKER,
    },
    {
      label: '-',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'dateTo',
      controlType: ControlTypes.DATE_PICKER,
      minDate: 'dateFrom',
    },
  ],
  selectable: true,
  title: 'Danh sách Coupon',
  url: 'coupons',
  extraCount: [
    {
      text: 'Tất cả',
      name: 'totalCount',
      keyword: 'status',
      value: '',
    },
    {
      text: 'Đang áp dụng',
      name: 'totalActive',
      keyword: 'status',
      value: 1,
    },
    {
      text: 'Chưa áp dụng',
      name: 'totalInactive',
      keyword: 'status',
      value: -1,
    },
    { text: 'Hết hạn áp dụng', name: 'totalStop', keyword: 'status', value: 2 },
  ],
  actions: [
    { label: 'Áp dụng', value: 1, key: 'status' },
    { label: 'Không áp dụng', value: 2, key: 'status' },
    { label: 'Xóa', value: 3, key: 'status' },
  ],
});
