import { memo, useCallback, useMemo } from 'react';
import { Col, Input, Label } from 'reactstrap';
import { uploadImage } from 'services/common';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/uti';
import UploadFile from '../UploadFile';

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
  b {
    margin-left: 4px;
    color: #e63535;
  }
`;

const FileActions = styled(Col)`
  display: grid;
  grid-template-columns: 1fr 32px;
  grid-gap: 8px;
`;

const FileButton = styled.div`
  background: #ffffff;
  height: 38px;
  cursor: pointer;
  /* Primary/Main Color */

  border: 1px solid ${({ color }) => (color === 'primary' ? '#007770' : '#E53535')};
  box-sizing: border-box;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  text-align: center;

  /* Primary/Main Color */

  color: ${({ color }) => (color === 'primary' ? '#007770' : '#E53535')};
`;

const FileInput = ({
  accept,
  autoFocus,
  error,
  touched,
  onChange = () => {},
  isRequired,
  label,
  name,
  inputMd = 7,
  hiddenDelete = false,
  previewIcon = false,
  value,
  readOnly,
  ...rest
}) => {
  const fileName = useMemo(() => (typeof value !== 'string' ? value?.name : value), [value]);
  const onUploadFile = useCallback(
    async e => {
      const file = e.target.value;

      if (!file) {
        onChange(e);
        return;
      }
      const res = await uploadImage(file);

      onChange({ target: { name, value: res?.url } });
    },
    [name, onChange]
  );

  return (
    <>
      {previewIcon && (
        <div className="col-md-1 d-flex align-items-center">
          <img src={typeof value === 'string' ? value : URL.createObjectURL(value)} alt="" width={24} height={24} />
        </div>
      )}
      {label && (
        <LabelInput className="col-md-3 col-form-label d-flex align-items-center">
          {label} {isRequired && <b>*</b>}
        </LabelInput>
      )}
      <Col md={previewIcon ? inputMd - 1 : inputMd}>
        <Input readOnly={readOnly} autoFocus={autoFocus} invalid={!!error} name={name} onChange={onChange} value={fileName} {...rest} />
        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
      <FileActions md={12 - inputMd - 3}>
        <UploadFile accept={accept} src={value} name={name} handleChange={onUploadFile} hiddenPreview>
          <FileButton color="primary">Đổi ảnh</FileButton>
        </UploadFile>
        {!hiddenDelete && (
          <FileButton color="danger" onClick={() => onChange({ target: { name, value: '' } })}>
            <i className="mdi mdi-trash-can-outline"></i>
          </FileButton>
        )}
      </FileActions>
    </>
  );
};

export default memo(FileInput, propsAreEqual);
