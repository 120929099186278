import { AuthenticationFeature } from 'constants/authentication';
import { JewelTypes } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { JewellTypeOptions, StockStatusOptions } from 'constants/options';
import { CellControlType } from 'constants/table';

export const getCategoryProductInfo = () => ({
  buttonAddTitle: '',
  feature: AuthenticationFeature.PRODUCT,
  columns: [
    {
      dataField: 'productCode',
      text: 'Mã sản phẩm',
      detailUrl: 'jewellery',
      orderKey: 'productCode',
    },
    {
      dataField: 'productName',
      text: 'Tên sản phẩm',
      render: (value, data) => `${data.mainCategory || ''} ${value}`,
      orderKey: 'name',
      detailUrl: 'jewellery',
    },
    {
      dataField: 'inStockCount',
      text: 'Trạng thái',
      controlType: CellControlType.Status,
      render: value => (value > 0 ? 'Còn hàng' : 'Hết hàng'),
      background: value => (value > 0 ? '#0077701A' : '#E800001A'),
      color: value => (value > 0 ? '#007770' : '#E80000'),
    },
    {
      dataField: 'price',
      text: 'Giá (VNĐ)',
      custom: 'price',
      orderKey: 'price',
    },
    {
      dataField: 'productCategory',
      text: 'Danh mục',
      custom: 'array',
    },
    {
      dataField: 'newProductInfo',
      text: 'Sản phẩm nổi bậc',
      controlType: CellControlType.Status,
      custom: 'circle',
    },
    {
      dataField: 'type',
      text: 'Loại sản phẩm',
      render: value =>
        value === JewelTypes.Single ? 'Đơn' : value === JewelTypes.Couple ? 'Đôi' : value === JewelTypes.Customize ? 'Đính kèm kim cương' : '',
    },
    {
      dataField: 'id',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'jewellery',
    },
  ],
  columnTemplate: '1fr 1fr 120px 200px 150px 120px 120px 100px',
  filterElements: [
    {
      name: 'type',
      placeholder: 'Loại sản phẩm',
      controlType: ControlTypes.SELECT_PICKER,
      options: JewellTypeOptions,
      width: '130px',
    },
    {
      name: 'stockStatus',
      placeholder: 'Trạng thái kho',
      controlType: ControlTypes.SELECT_PICKER,
      options: StockStatusOptions,
      width: '130px',
    },
  ],
  extraCount: [
    {
      text: 'Tất cả',
      name: 'totalCount',
      keyword: 'isShowOnWeb',
      value: '',
    },
    {
      text: 'Hiển thị',
      name: 'totalShow',
      keyword: 'isShowOnWeb',
      value: true,
    },
    { text: 'Ẩn', name: 'totalHide', keyword: 'isShowOnWeb', value: false },
  ],
  selectable: true,
  title: 'Danh sách sản phẩm',
  url: 'jewellery',
});
