import React, { useCallback, useMemo } from 'react';
import styles from './ProductHighlightTemplate2.module.scss';
import { HandBagIcon, PlayIcon } from 'components/icons';

const ProductHighlightTemplate2 = ({ item }) => {
  const src = useMemo(() => (typeof item.image === 'string' ? item.image : URL.createObjectURL(item.image)), [item.image]);
  const onClick = useCallback(() => {
    window.open(item.link, '_blank');
  }, [item.link]);
  return (
    <>
      <div className={styles['container']}>
        <div className={styles['highlight']}>
          <div className={styles['highlight__image']} id={`image-${item.id}`}>
            <div className={styles['thumbnail']}>
              <div className={styles['thumbnail__icon-play']}>
                <PlayIcon />
              </div>
              <img src={src} alt="" width={300} height={300} />
              <div className={styles['thumbnail__icon-hand-bag']}>
                <HandBagIcon />
              </div>
            </div>
          </div>
          <section className={styles['highlight__content']}>
            <h3 className={styles['content__title']}>{item.title}</h3>
            <p className={styles['content__desc']} dangerouslySetInnerHTML={{ __html: item.text }} />
            {item.link && (
              <div className={styles['content__button']} onClick={onClick}>
                {item.buttonText || 'Xem thêm'}
              </div>
            )}
          </section>
        </div>
      </div>
    </>
  );
};
export default React.memo(ProductHighlightTemplate2);
