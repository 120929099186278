import axios from 'axios';

export const getCollectionList = () => axios({ url: 'collections', method: 'GET' });

export const addJewelleryToCollection = (collectionId, jewelleryId) =>
  axios({
    url: `collections/${collectionId}/${jewelleryId}`,
    method: 'POST',
  });

export const deleteJewelleryFromCollection = (collectionId, jewelleryId) =>
  axios({
    url: `collections/${collectionId}/${jewelleryId}`,
    method: 'DELETE',
  });
