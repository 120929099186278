/* eslint-disable eqeqeq */
import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { BlogStatusOptions, ByDateOptions } from 'constants/options';
import { CellControlType } from 'constants/table';
import { map } from 'lodash';
import { getSlugFromType, parseDate } from 'utils/uti';

export const getBlogInfo = () => ({
  buttonAddTitle: 'Thêm bài viết',
  feature: AuthenticationFeature.BLOG,
  keyword: 'name',
  columns: [
    {
      dataField: 'title',
      text: 'Tiêu đề',
      sort: true,
      controlType: CellControlType.TextWithActionEditDeleteLink,
      detailUrl: 'blogs',
      copy: {
        url: 'blogs',
        omitFields: ['id', 'createdBy', 'createdAt', 'updatedAt'],
        customFields: {
          tagIds: data => map(data.tags, 'id'),
          slug: data => data.slug + '_new',
        },
      },
      deleteUrl: 'blogs',
      previewLink: getSlugFromType,
    },
    {
      dataField: 'seoInfo.title',
      text: 'SEO Title',
      sort: true,
    },
    {
      dataField: 'seoInfo.desc',
      text: 'SEO Desc',
      sort: true,
    },
    {
      dataField: 'creatorInfo.name',
      text: 'Tác giả',
      sort: true,
    },
    {
      dataField: 'blogTypeInfo.name',
      text: 'Danh Mục',
      sort: true,
    },
    {
      dataField: 'tags',
      text: 'Thẻ',
      sort: true,
      controlType: CellControlType.TextList,
    },
    {
      dataField: 'publishAt',
      text: 'Trạng thái',
      render: (value, data) => `${data.status == 2 ? 'Đã xuất bản' : data.status == 1 ? 'Nháp' : 'Xoá'} ${value ? parseDate(value) : ''}`,
      sort: true,
    },
    {
      dataField: 'id',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'blogs',
      deleteUrl: 'blogs',
    },
  ],
  columnTemplate: '1fr 150px 120px 200px 200px 100px 100px 100px',
  filterElements: [
    {
      name: 'dateFrom',
      placeholder: 'Tất cả các ngày',
      controlType: ControlTypes.SELECT_PICKER,
      options: ByDateOptions,
      width: '150px',
    },
    {
      name: 'category',
      placeholder: 'Tất cả chuyên mục',
      controlType: ControlTypes.SELECT_PICKER,
      optionUrl: { url: 'blogType', keyLabel: 'name', keyValue: 'slug' },
      width: '150px',
    },
    {
      name: 'status',
      placeholder: 'Trạng thái',
      controlType: ControlTypes.SELECT_PICKER,
      options: BlogStatusOptions,
      width: '150px',
    },
  ],
  upperFilterElements: [
    {
      label: 'Ngày',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'dateFrom',
      maxDate: 'dateTo',
      controlType: ControlTypes.DATE_PICKER,
    },
    {
      label: '-',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'dateTo',
      controlType: ControlTypes.DATE_PICKER,
      minDate: 'dateFrom',
    },
  ],
  extraCount: [
    {
      text: 'Tất cả',
      name: 'totalCount',
      keyword: 'status',
      value: '',
    },
    {
      text: 'Xuất bản',
      name: 'active',
      keyword: 'status',
      value: 2,
    },
    { text: 'Nháp', name: 'inactive', keyword: 'status', value: 1 },
    { text: 'Xoá', name: 'inactive', keyword: 'status', value: -1 },
  ],
  tabs: [],
  selectable: true,
  actions: [],
  title: 'Danh sách bài viết',
  url: 'blogs',
});
