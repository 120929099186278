import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { ActionsItem, FieldInput, UploadFile } from 'components/own';
import { cloneDeep, get, upperFirst } from 'lodash';
// import { getInfo } from 'services/general-info';
import produce from 'immer';
import { getAPI, putAPI, uploadImage } from 'services/common';
import { useActionNotification } from 'hook/useContextSelector';
import { Formik } from 'formik';
import { AvatarDropIcon } from 'components/icons';
import { AuthenticationFeature } from 'constants/authentication';

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const Block = styled(Accordion)`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const social = ['facebook', 'zalo', 'youtube', 'tiktok', 'instagram'];
const initialState = { data: null };
const InfoSetting = () => {
  const [{ data }, setState] = useState(initialState);
  const pushNotification = useActionNotification();

  const onSave = useCallback(
    async values => {
      const changed = cloneDeep(values);
      if (!changed.setting.logo) {
        pushNotification('warning', 'Logo không được để trống');
        return;
      }

      if (changed.setting.logo && typeof changed.setting.logo !== 'string') {
        const res = await uploadImage(changed.setting.logo);
        changed.setting.logo = res?.url;
      }

      // if (changed.setting.social) {
      //   for (const key in changed.setting.social) {
      //     if (Object.hasOwnProperty.call(changed.setting.social, key)) {
      //       const element = changed.setting.social[key];
      //       if (element.icon && typeof element.icon !== 'string') {
      //         const res = await uploadImage(element.icon);
      //         element.icon = res?.url;
      //       }
      //     }
      //   }
      // }

      putAPI('general-info/1', changed)
        .then(() => {
          pushNotification('success', 'Lưu thành công');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [pushNotification]
  );

  useEffect(() => {
    getAPI('general-info/1').then(res => {
      setState(
        produce(draft => {
          draft.data = get(res, ['data']);
        })
      );
    });
  }, []);
  if (!data) return <div />;

  return (
    <Formik initialValues={data} onSubmit={onSave}>
      {({ handleChange, handleSubmit, setValues, values }) => (
        <>
          <Block alwaysOpen defaultActiveKey={['info']}>
            <Accordion.Item eventKey={'info'}>
              <BlockHeader>Thông tin</BlockHeader>
              <BlockContent>
                <Row>
                  <FieldInput
                    type="textarea"
                    rows={3}
                    value={get(values, ['setting', 'address'])}
                    label={'Địa chỉ'}
                    name={`setting.address`}
                    onChange={handleChange}
                  />
                </Row>
                <Row>
                  <FieldInput value={get(values, ['setting', 'email'])} label={'Email'} name={`setting.email`} onChange={handleChange} />
                </Row>
                <Row>
                  <FieldInput
                    value={get(values, ['setting', 'phone'])}
                    label={'Số điện thoại'}
                    name={`setting.phone`}
                    onChange={handleChange}
                  />
                </Row>
                <Row>
                  <Col md={3} className="d-flex align-items-center">
                    Logo
                  </Col>
                  <div style={{ width: 226 }}>
                    <UploadFile
                      showEdit
                      name={'setting.logo'}
                      src={get(values, 'setting.logo')}
                      handleChange={handleChange}
                      width={184}
                      height={124}
                    >
                      <AvatarDropIcon width="184" height="124" />
                    </UploadFile>
                  </div>
                </Row>
                {social.map((_s, i) => (
                  <React.Fragment key={i}>
                    <Row>
                      <FieldInput
                        value={get(values, ['setting', 'social', _s, 'link'])}
                        label={upperFirst(_s)}
                        name={`setting.social.${_s}.link`}
                        onChange={handleChange}
                      />
                    </Row>
                    {/* <Row>
                      <FieldInput
                        value={get(values, ['setting', 'social', _s, 'icon'])}
                        label={upperFirst(_s) + ' icon'}
                        name={`setting.social.${_s}.icon`}
                        controlType={ControlTypes.FILE}
                        onChange={handleChange}
                      />
                    </Row> */}
                  </React.Fragment>
                ))}
                <ActionsItem onSave={handleSubmit} onCancel={() => setValues(data)} feature={AuthenticationFeature.INFO} />
              </BlockContent>
            </Accordion.Item>
          </Block>
        </>
      )}
    </Formik>
  );
};

export default InfoSetting;
