import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { cloneDeep, get } from 'lodash';

import { getAllRoles } from 'services/role';
import styled from 'styled-components';
import { Row, UncontrolledCollapse } from 'reactstrap';
import { ChevronUpIcon } from 'components/icons';
import { ActionsItem, Authentication, FieldInput, PermissionMatrix } from 'components/own';
import { createUser, getUserById, updateUser } from 'services/auth';
import { useActionNotification } from 'hook/useContextSelector';
import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { getChangedData } from 'utils/uti';
import { getAPI } from 'services/common';

const initialUser = {
  name: '',
  email: '',
  phone: '',
  userType: null,
  password: '',
  confirmPassword: '',
  permission: [],
};

const initialState = {
  data: null,
  options: {},
};

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
`;

const BlockContent = styled(UncontrolledCollapse)`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const UserItem = () => {
  const [{ data, options }, setState] = useState(initialState);
  const { id } = useParams();
  const router = useHistory();
  const pushNotification = useActionNotification();

  useEffect(() => {
    const callAPI = async () => {
      const res = await getAllRoles('');
      setState(
        produce(draft => {
          draft.options.roles = res?.data?.list.map(_r => ({ value: _r.id, label: _r.name }));
        })
      );
    };
    callAPI();
  }, []);

  useEffect(() => {
    if (id === 'new') {
      setState(
        produce(draft => {
          draft.data = initialUser;
        })
      );
    } else {
      getUserById(id).then(res => {
        setState(
          produce(draft => {
            draft.data = res.data;
          })
        );
      });
    }
  }, [id]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required('Vui lòng nhập tên tài khoản.'),
        userType: yup.string().required('Vui lòng chọn loại tài khoản.'),
        password: data?.id
          ? yup.string().matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z])[A-Za-z\d].{7,}$/, ' ')
          : yup
              .string()
              .required('Vui lòng nhập mật khẩu.')
              .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z])[A-Za-z\d].{7,}$/, 'Mật khẩu có ít nhất 7 ký tự và có chứa ký tự in hoa và số.'),
        confirmPassword: yup.string().when('yup', {
          is: val => !!val && !!val.length,
          then: yup
            .string()
            .required('Vui lòng xác nhận mật khẩu.')
            .oneOf([yup.ref('yup')], 'Xác nhận mật khẩu và mật khẩu phải giống nhau.'),
        }),
        status: yup.string(),
        fullname: yup.string(),
        phone: yup
          .string()
          .nullable()
          .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Vui lòng nhập đúng số điện thoại'),
        email: yup.string().required('Vui lòng nhập địa chỉ email.').email('Email không hợp lệ.'),
      }),
    [data]
  );

  const fields = useMemo(
    () => [
      {
        id: 'block-1',
        title: 'Thông tin tài khoản',
        content: [
          {
            label: 'Tên Tài Khoản',
            name: 'name',
          },
          {
            label: 'Loại tài khoản',
            name: 'userType',
            controlType: ControlTypes.SELECT_PICKER,
            option: 'roles',
          },
          {
            label: 'Mật khẩu',
            name: 'password',
            type: 'password',
          },
          {
            label: 'Nhập lại mật khẩu',
            name: 'confirmPassword',
            type: 'password',
          },
          {
            label: 'Status',
            name: 'status',
          },
        ],
      },
      {
        id: 'block-2',
        title: 'Thông tin thành viên',
        content: [
          // {
          //   label: 'Họ và tên',
          //   name: 'fullName',
          // },
          {
            label: 'Số điện thoại',
            name: 'phone',
          },
          {
            label: 'Email',
            name: 'email',
          },
        ],
      },
    ],
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/users');
  }, [router]);

  const onSave = useCallback(
    values => {
      let request = cloneDeep(values);
      delete request.confirmPassword;
      delete request.id;
      if (values.id) {
        if (!request.password) delete request.password;
        updateUser(values.id, getChangedData(request, data))
          .then(res => {
            pushNotification('success', 'Lưu thành công');
            router.replace('/users');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      } else {
        createUser(request)
          .then(res => {
            pushNotification('success', 'Lưu thành công');
            router.replace('/users');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      }
    },
    [data, pushNotification, router]
  );

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chi tiết thành viên</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, setFieldValue, submitCount, touched = {}, values = {} }) => (
          <>
            {fields.map(block => (
              <Block key={block.id}>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  {block.title}
                  <IconButton id={block.id}>
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent defaultOpen toggler={`#${block.id}`}>
                  {block.content.map(field => (
                    <Row key={field.name}>
                      <FieldInput
                        {...field}
                        error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                        value={get(values, field.name)}
                        autoComplete="new-password"
                        // autoFocus={focusField === field.name}
                        // onKeyDown={handleKeyDown}
                        // onFocus={() => onChangeFocus(field.name)}
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e);
                          if (e.target.name === 'userType') {
                            getAPI('user-types/' + e.target.value).then(res => {
                              setFieldValue('permission', res?.data?.permission || []);
                            });
                          }
                        }}
                        options={options[field.option]}
                        placeholder={field.placeholder || field.label}
                      />
                    </Row>
                  ))}
                </BlockContent>
              </Block>
            ))}
            <Authentication>
              <PermissionMatrix values={get(values, 'permission')} handleChange={handleChange} />
            </Authentication>
            <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.USER} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default UserItem;
