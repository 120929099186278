import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Accordion } from 'react-bootstrap';
import { Label, Row } from 'reactstrap';
import { ActionsItem, FieldInput } from 'components/own';
import { cloneDeep, find, get } from 'lodash';
// import { getInfo } from 'services/general-info';
import produce from 'immer';
import { getAPI, putAPI, postAPI, deleteAPI } from 'services/common';
import { useActionNotification } from 'hook/useContextSelector';
import { Formik } from 'formik';
import * as yup from 'yup';

import { ControlTypes } from 'constants/field';
import { AddBlockButton } from 'components/icons';
import { getChangedData } from 'utils/uti';
import { AuthenticationFeature } from 'constants/authentication';

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
  b {
    margin-left: 4px;
    color: #e63535;
  }
`;

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const Block = styled(Accordion)`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const StoreContainer = styled.div`
  display: grid;
  grid-column-gap: 60px;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 8px;
`;

const StoreItem = styled.div`
  margin-bottom: 40px;
`;

const StoreTitle = styled.div`
  width: 497.5px;
  height: 17px;

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  color: #1f4173;
`;

const AddBlockContainer = styled.div`
  border: 1px dashed #8f90a6;
  box-sizing: border-box;
  width: 100%;
  height: 112px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const IconButton = styled.div`
  cursor: pointer;
  color: #e53535;
  margin-right: 16px;
`;
const initialBlock = {
  name: '',
  providenceId: null,
  cityId: null,
  districtId: null,
  address: '',
  phone: ['', ''],
  openTime: [
    {
      from: '',
      to: '',
      days: [1, 2, 3, 4, 5, 6, 0],
    },
  ],
  stockId: 1,
  mediafiles: {
    imagePC: '',
    imageMobile: '',
  },
  directionLink: '',
};

const AddressComponent = ({ errors, submitCount, values, handleChange, i }) => {
  const [{ province, city, district }, setOptions] = useState({
    province: [],
    city: [],
    district: [],
  });
  const providenceId = useMemo(() => get(values, [i, 'providenceId']), [i, values]);
  const cityId = useMemo(() => get(values, [i, 'cityId']), [i, values]);

  useEffect(() => {
    getAPI('locations?type=1').then(res => {
      setOptions(
        produce(draft => {
          draft.province = get(res, 'data', []).map(_d => ({ label: _d.name, value: _d.id, origin: _d }));
        })
      );
    });
  }, []);

  useEffect(() => {
    if (providenceId)
      getAPI('locations?type=2&parentId=' + providenceId).then(res => {
        setOptions(
          produce(draft => {
            draft.city = get(res, 'data', []).map(_d => ({ label: _d.name, value: _d.id, origin: _d }));
          })
        );
      });
    else
      setOptions(
        produce(draft => {
          draft.city = [];
        })
      );
  }, [providenceId]);

  useEffect(() => {
    if (cityId)
      getAPI('locations?type=3&parentId=' + cityId).then(res => {
        setOptions(
          produce(draft => {
            draft.district = get(res, 'data', []).map(_d => ({ label: _d.name, value: _d.id, origin: _d }));
          })
        );
      });
    else
      setOptions(
        produce(draft => {
          draft.district = [];
        })
      );
  }, [cityId]);

  return (
    <>
      <Row style={{ paddingLeft: 24, marginBottom: 8 }}>
        <FieldInput
          error={!!submitCount && get(errors, [i, 'providenceId'])}
          label={'Tỉnh & Thành phố'}
          name={`${i}.providenceId`}
          value={get(values, [i, 'providenceId'])}
          onChange={e => {
            handleChange(e);
            handleChange({ target: { name: `${i}.providenceInfo`, value: find(province, ['value', e.target.value]).origin } });
          }}
          controlType={ControlTypes.SELECT_PICKER}
          options={province}
        />
      </Row>
      <Row style={{ paddingLeft: 24, marginBottom: 8 }}>
        <FieldInput
          error={!!submitCount && get(errors, [i, 'cityId'])}
          label={'Quận & Huyện'}
          name={`${i}.cityId`}
          value={get(values, [i, 'cityId'])}
          onChange={e => {
            handleChange(e);
            handleChange({ target: { name: `${i}.cityInfo`, value: find(city, ['value', e.target.value]).origin } });
          }}
          controlType={ControlTypes.SELECT_PICKER}
          options={city}
        />
      </Row>
      <Row style={{ paddingLeft: 24, marginBottom: 8 }}>
        <FieldInput
          error={!!submitCount && get(errors, [i, 'districtId'])}
          label={'Phường & Xã'}
          name={`${i}.districtId`}
          value={get(values, [i, 'districtId'])}
          onChange={e => {
            handleChange(e);
            handleChange({ target: { name: `${i}.districtInfo`, value: find(district, ['value', e.target.value]).origin } });
          }}
          controlType={ControlTypes.SELECT_PICKER}
          options={district}
        />
      </Row>
      <Row style={{ paddingLeft: 24, marginBottom: 8 }}>
        <FieldInput
          error={!!submitCount && get(errors, [i, 'address'])}
          label={'Số nhà tên đường'}
          name={`${i}.address`}
          value={get(values, [i, 'address'])}
          onChange={handleChange}
          placeholder="Nhập Số nhà tên đường"
        />
      </Row>
    </>
  );
};

const initialState = { data: null, loading: false };
const StoreSetting = () => {
  const [{ data, loading }, setState] = useState(initialState);
  const pushNotification = useActionNotification();

  const callAPIGetStore = useCallback(callback => {
    getAPI('store-contacts').then(res => {
      if (callback) callback(get(res, ['data', 'list'], []));
      setState(
        produce(draft => {
          draft.loading = false;
          draft.data = get(res, ['data', 'list'], []);
        })
      );
    });
  }, []);

  const onSave = useCallback(
    async values => {
      const changed = cloneDeep(values);
      const req = [];

      for (const key in changed) {
        if (Object.hasOwnProperty.call(changed, key)) {
          const element = changed[key];
          if (!element.id) {
            req.push(postAPI('store-contacts', element));
          } else {
            const diff = getChangedData(element, get(data, key));
            if (!!Object.keys(diff).length) {
              req.push(putAPI('store-contacts/' + element.id, diff));
            }
          }
        }
      }
      if (req.length)
        setState(
          produce(draft => {
            draft.loading = true;
          })
        );
      Promise.all(req)
        .then(res => {
          pushNotification('success', 'Lưu thành công');
          callAPIGetStore();
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [callAPIGetStore, data, pushNotification]
  );

  const onDelete = useCallback(
    (id, callback) => {
      deleteAPI('store-contacts/' + id).then(res => {
        pushNotification('success', 'Xóa thành công');
        callAPIGetStore(callback);
      });
    },
    [callAPIGetStore, pushNotification]
  );

  useEffect(() => {
    callAPIGetStore();
  }, [callAPIGetStore]);

  const validationSchema = useMemo(
    () =>
      yup.array().of(
        yup.object().shape({
          name: yup.string().required('Vui lòng nhập tên.'),
          providenceId: yup.string().required('Vui lòng chọn tỉnh / thành phố.').nullable(),
          cityId: yup.string().required('Vui lòng chọn quận / huyện.').nullable(),
          districtId: yup.string().required('Vui lòng chọn phường / xã').nullable(),
          address: yup.string().required('Vui lòng nhập số nhà tên đường.'),
          phone: yup.array().of(yup.string().required('Vui lòng nhập số điện thoại')),
          openTime: yup.array().of(
            yup.object().shape({
              from: yup.string().required('Vui lòng chọn giờ mở cửa'),
              to: yup.string().required('Vui lòng chọn giờ đóng cửa'),
            })
          ),
          directionLink: yup.string().required('Vui lòng nhập link chỉ đường'),
        })
      ),
    []
  );

  if (!data) return <div />;

  if (loading) return <div className="d-flex align-items-center justify-content-center">Loading...</div>;

  return (
    <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
      {({ errors = {}, touched = {}, handleChange, handleSubmit, setValues, submitCount, values }) => (
        <>
          <Block alwaysOpen defaultActiveKey={['info']}>
            <Accordion.Item eventKey={'info'}>
              <BlockHeader>Các thông tin cửa hàng</BlockHeader>
              <BlockContent>
                <StoreContainer className="mb-3">
                  {values.map((_store, i) => (
                    <StoreItem key={i}>
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <StoreTitle>Cửa hàng {i + 1}</StoreTitle>
                        <IconButton
                          onClick={e => {
                            e.preventDefault();
                            if (!_store.id) {
                              setValues(values.filter((_st, index) => i !== index));
                            } else {
                              onDelete(_store.id, setValues);
                            }
                          }}
                        >
                          <i className="mdi mdi-trash-can-outline" />
                        </IconButton>
                      </div>
                      <Row style={{ paddingLeft: 24, marginBottom: 8 }}>
                        <FieldInput
                          error={!!submitCount && get(errors, [i, 'name'])}
                          label={'Tên cửa hàng'}
                          placeholder="Nhập tên cửa hàng"
                          name={`${i}.name`}
                          value={get(values, [i, 'name'])}
                          onChange={handleChange}
                        />
                      </Row>
                      <Row style={{ paddingLeft: 24, marginBottom: 8 }}>
                        <FieldInput
                          error={!!submitCount && get(errors, [i, 'mediafiles', 'imagePC'])}
                          label={'Hình PC'}
                          accept="image/*"
                          name={`${i}.mediafiles.imagePC`}
                          placeholder="Chọn hình cho PC"
                          value={get(values, [i, 'mediafiles', 'imagePC'])}
                          controlType={ControlTypes.FILE}
                          onChange={handleChange}
                        />
                      </Row>
                      <Row style={{ paddingLeft: 24, marginBottom: 8 }}>
                        <FieldInput
                          error={!!submitCount && get(errors, [i, 'mediafiles', 'imageMobile'])}
                          accept="image/*"
                          label={'Hình Mobile'}
                          placeholder="Chọn hình cho Mobile"
                          name={`${i}.mediafiles.imageMobile`}
                          value={get(values, [i, 'mediafiles', 'imageMobile'])}
                          controlType={ControlTypes.FILE}
                          onChange={handleChange}
                        />
                      </Row>
                      <AddressComponent values={values} handleChange={handleChange} i={i} errors={errors} submitCount={submitCount} />
                      <Row style={{ paddingLeft: 24, marginBottom: 8 }}>
                        <LabelInput className="col-md-3 col-form-label d-flex align-items-center">Số điện thoại</LabelInput>
                        <FieldInput
                          error={!!submitCount && get(errors, [i, 'phone', 0])}
                          inputMd={4}
                          name={`${i}.phone.0`}
                          value={get(values, [i, 'phone', 0])}
                          onChange={handleChange}
                          placeholder="Nhập số điện thoại"
                        />
                        <div className="col-md-1 d-flex align-items-center justify-content-center">&</div>
                        <FieldInput
                          error={!!submitCount && get(errors, [i, 'phone', 1])}
                          inputMd={4}
                          name={`${i}.phone.1`}
                          value={get(values, [i, 'phone', 1])}
                          onChange={handleChange}
                          placeholder="Nhập số điện thoại"
                        />
                      </Row>

                      <Row style={{ paddingLeft: 24, marginBottom: 8 }}>
                        <LabelInput className="col-md-3 col-form-label d-flex align-items-center">Giờ làm việc</LabelInput>
                        <FieldInput
                          error={!!submitCount && get(errors, [i, 'openTime', 0, 'from'])}
                          inputMd={4}
                          name={`${i}.openTime.0.from`}
                          value={get(values, [i, 'openTime', 0, 'from'])}
                          onChange={handleChange}
                          controlType={ControlTypes.TIME_PICKER}
                        />
                        <div className="col-md-1 d-flex align-items-center justify-content-center">-</div>
                        <FieldInput
                          error={!!submitCount && get(errors, [i, 'openTime', 0, 'to'])}
                          inputMd={4}
                          name={`${i}.openTime.0.to`}
                          value={get(values, [i, 'openTime', 0, 'to'])}
                          onChange={handleChange}
                          controlType={ControlTypes.TIME_PICKER}
                        />
                      </Row>
                      <Row style={{ paddingLeft: 24, marginBottom: 8 }}>
                        <FieldInput
                          error={!!submitCount && get(errors, [i, 'directionLink'])}
                          label={'Link'}
                          name={`${i}.directionLink`}
                          value={get(values, [i, 'directionLink'])}
                          onChange={handleChange}
                          placeholder="Nhập chỉ đường"
                        />
                      </Row>
                      <Row style={{ paddingLeft: 24, marginBottom: 8 }}>
                        <FieldInput
                          error={!!submitCount && get(errors, [i, 'stockInfo', 'name'])}
                          label={'Tên kho'}
                          name={`${i}.stockInfo.name`}
                          value={get(values, [i, 'stockInfo', 'name'])}
                          onChange={handleChange}
                          placeholder="Chọn kho"
                        />
                      </Row>
                    </StoreItem>
                  ))}
                  <AddBlockContainer
                    onClick={() => {
                      const store = cloneDeep(values);
                      store.push({ ...initialBlock });
                      setValues(store);
                    }}
                  >
                    <AddBlockButton />
                  </AddBlockContainer>
                </StoreContainer>
                <ActionsItem onSave={handleSubmit} onCancel={() => setValues(data)} feature={AuthenticationFeature.INFO} />
              </BlockContent>
            </Accordion.Item>
          </Block>
        </>
      )}
    </Formik>
  );
};

export default StoreSetting;
