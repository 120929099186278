import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { cloneDeep, get } from 'lodash';
import { Accordion } from 'react-bootstrap';

import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { ActionsItem, FieldInput } from 'components/own';
import { AuthenticationFeature } from 'constants/authentication';
import { getAPI, putAPI, uploadImage } from 'services/common';
import { E_COMMERCE_URL } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { getChangedData } from 'utils/uti';
import { useActionNotification } from 'hook/useContextSelector';

const initialState = {
  data: null,
  options: {},
};

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const NameLabel = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;

  color: #000000;
`;

const LinkRow = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  height: 22px;
  align-items: center;
  margin-bottom: 16px;
`;

const LinkTitle = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #000000;
`;

const LinkValue = styled.a`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  text-decoration-line: underline;

  color: #0063f7;

  &:hover {
    color: #0063f7;
  }
`;

const Block = styled(Accordion)`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;
const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
`;

const EventPageSetting = () => {
  const [{ data }, setState] = useState(initialState);
  const { id } = useParams();
  const router = useHistory();
  const pushNotification = useActionNotification();

  useEffect(() => {
    getAPI(`page-setting-info?page=${id}`).then(res => {
      setState(
        produce(draft => {
          draft.data = res.data;
        })
      );
    });
  }, [id]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required('Vui lòng nhập tên trang.'),
      }),
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/users');
  }, [router]);

  const onSave = useCallback(
    async values => {
      const changed = getChangedData(cloneDeep(values), data);
      if (!Object.keys(changed).length) return;
      if (changed.setting) {
        if (changed.setting.rightMenu?.image && typeof changed.setting.rightMenu.image !== 'string') {
          const res = await uploadImage(changed.setting.rightMenu.image);
          changed.setting.rightMenu.image = res?.url;
        }
      }

      putAPI('page-setting-info?page=' + id, changed)
        .then(() => {
          pushNotification('success', 'Lưu thành công');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [data, id, pushNotification]
  );

  const url = useMemo(() => E_COMMERCE_URL + id, [id]);

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chi tiết sự kiện</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, submitCount, touched = {}, values = {} }) => (
          <>
            <Row className="mb-2">
              <Col md={2}>
                <NameLabel>Tên trang</NameLabel>
              </Col>
              <FieldInput
                inputMd={10}
                name="name"
                error={get(touched, 'name') || !!submitCount ? get(errors, 'name') : null}
                value={get(values, 'name')}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={'Tên trang'}
              />
            </Row>
            <LinkRow>
              <LinkTitle>Link</LinkTitle>
              <LinkValue href={url}>{url}</LinkValue>
            </LinkRow>
            {}
            <Block alwaysOpen defaultActiveKey={[1]}>
              <Accordion.Item eventKey={1} className="mb-3">
                <BlockHeader className="d-flex align-items-center">Banner right</BlockHeader>
                <BlockContent>
                  <Row>
                    <FieldInput
                      label={'Hình'}
                      accept="image/*"
                      controlType={ControlTypes.FILE}
                      name={`setting.rightMenu.image`}
                      value={get(values, ['setting', 'rightMenu', 'image'])}
                      onChange={handleChange}
                    />
                  </Row>
                  <Row>
                    <FieldInput
                      label={'Link banner'}
                      name={`setting.rightMenu.link`}
                      value={get(values, ['setting', 'rightMenu', 'link'])}
                      onChange={handleChange}
                    />
                  </Row>
                </BlockContent>
              </Accordion.Item>
            </Block>

            <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.USER} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default EventPageSetting;
