import { AuthenticationFeature } from 'constants/authentication';

export const getAttributesInfo = () => ({
  buttonAddTitle: '',
  keyword: 'showName',
  feature: AuthenticationFeature.PRODUCT,
  columns: [
    {
      dataField: 'name',
      text: 'Tên thuộc tính',
      fontWeight: 700,
      color: '#0063F7',
      orderKey: 'name',
    },
    {
      dataField: 'attributeCode',
      text: 'Thông tin',
    },
    {
      dataField: 'desc',
      text: 'Mô tả',
    },
    {
      dataField: 'type',
      text: 'TSV/KC',
      render: value => (value === 1 ? 'TSV' : 'KC'),
    },
    {
      dataField: 'showName',
      text: 'Tên hiển thị',
    },
  ],
  columnTemplate: '1fr 100px 100px 200px 1fr',
  filterElements: [],
  selectable: true,
  title: 'Thuộc tính sản phẩm',
  url: 'attributes',
});
