import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { CellControlType } from 'constants/table';

export const getUserInfo = () => ({
  buttonAddTitle: 'Thêm thành viên',
  feature: AuthenticationFeature.USER,
  columns: [
    {
      dataField: 'name',
      text: 'Tên Thành Viên',
      orderKey: 'name',
      detailUrl: 'users',
      copy: {
        url: 'users',
        omitFields: ['id', 'createdBy', 'createdAt', 'updatedAt'],
        customFields: {
          password: () => '12345a@',
        },
      },
    },
    // {
    //   dataField: 'fullName',
    //   text: 'Họ và Tên',
    //   sort: true,
    // },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
    },
    {
      dataField: 'phone',
      text: 'Số Điện Thoại',
      sort: true,
    },
    {
      dataField: 'typeInfo.name',
      text: 'Vai trò',
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Ngày tạo',
      sort: true,
      controlType: CellControlType.Date,
    },
    {
      dataField: 'action',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'users',
      deleteUrl: 'users',
    },
  ],
  detailUrl: 'users',
  columnTemplate: '2fr 1fr 1fr 0.75fr 0.5fr 0.5fr',
  filterElements: [
    {
      label: 'Ngày',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'createdFrom',
      controlType: ControlTypes.DATE_PICKER,
    },
    {
      label: '-',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'createdTo',
      controlType: ControlTypes.DATE_PICKER,
    },
    {
      name: 'userType',
      placeholder: 'Tất cả vai trò',
      controlType: ControlTypes.SELECT_PICKER,
      optionUrl: { url: 'user-types', responseKey: 'data.list', keyLabel: 'name', keyValue: 'id' },
      width: '150px',
    },
  ],
  selectable: true,
  title: 'Danh sách thành viên',
  url: 'users',
});
