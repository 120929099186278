import { memo, useMemo } from 'react';

const Text = ({
  className,
  children,
  color,
  cursor = '',
  fontFamily,
  fontWeight,
  fontSize,
  lineClamp,
  lineHeight,
  marginX,
  marginY,
  variant,
  ...rest
}) => {
  const style = useMemo(() => {
    let _d = { color, cursor, fontFamily, fontWeight, fontSize, lineHeight };

    if (marginX) {
      _d.marginTop = marginX;
      _d.marginBottom = marginX;
    }
    if (marginY) {
      _d.marginRight = marginY;
      _d.marginLeft = marginY;
    }
    _d.wordBreak = 'break-word';

    if (variant === 'clamped') {
      _d.display = '-webkit-box';
      _d.WebkitLineClamp = lineClamp || 1;
      _d.WebkitBoxOrient = 'vertical';
      _d.overflow = 'hidden';
    }

    return _d;
  }, [color, cursor, fontFamily, fontWeight, fontSize, lineHeight, marginX, marginY, variant, lineClamp]);

  return (
    <div className={className} style={style} {...rest}>
      {children}
    </div>
  );
};

Text.defaultProps = {
  fontFamily: 'Quicksand',
  fontWeight: 400,
  fontSize: 14,
  color: '#1C1C28',
};

export default memo(Text);
