import { LOCAL_STORAGE__JWT, LOCAL_STORAGE__USER } from 'constants/common';

export const setToken = token => {
  localStorage.setItem(LOCAL_STORAGE__JWT, token);
};

export const removeToken = () => {
  localStorage.removeItem(LOCAL_STORAGE__JWT);
};

export const getUserToken = () => {
  return localStorage.getItem(LOCAL_STORAGE__JWT);
};

export const getUserLocal = () => {
  try {
    return JSON.parse(sessionStorage.getItem(LOCAL_STORAGE__USER));
  } catch (error) {
    return null;
  }
};

export const setUserLocal = user => {
  sessionStorage.setItem(LOCAL_STORAGE__USER, JSON.stringify(user));
};
