import { memo, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { useHistory } from 'react-router';

import { CopyIcon, MenuIcon, PencilIcon, PencilLineIcon, TrashIcon } from 'components/icons';

import { propsAreEqual } from 'utils/uti';

const IconButton = styled.button`
  background-color: transparent;
  border: unset;
`;

const MenuBody = styled(PopoverBody)`
  padding: 12px;
  color: white;
  width: 148px;
`;

const MenuItem = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 12px 1fr;
  grid-gap: 10px;
  margin-bottom: 12px;
`;

const MenuTitle = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
`;

const CellMenuAction = ({ data, routerToDetail, onFastEdit }) => {
  const ref = useRef(data.id);

  return (
    <div>
      <IconButton ref={ref}>
        <MenuIcon />
      </IconButton>
      <UncontrolledPopover trigger="focus" placement="bottom" target={ref}>
        <MenuBody>
          <MenuItem onClick={routerToDetail}>
            <PencilLineIcon />
            <MenuTitle>Chỉnh sửa</MenuTitle>
          </MenuItem>
          <MenuItem onClick={onFastEdit}>
            <PencilIcon />
            <MenuTitle>Chỉnh sửa nhanh</MenuTitle>
          </MenuItem>
          {/* <MenuItem>
            <CopyIcon />
            <MenuTitle>Sao chép</MenuTitle>
          </MenuItem> */}
          {/* <MenuItem onClick={onDelete}>
            <TrashIcon color="#fff" />
            <MenuTitle>Xoá</MenuTitle>
          </MenuItem> */}
        </MenuBody>
      </UncontrolledPopover>
    </div>
  );
};

export default memo(CellMenuAction, propsAreEqual);
