import React, { memo } from 'react';

import Text from 'components/own/Text';
import { propsAreEqual } from 'utils/uti';

const CellTextList = ({ editMode, dataField, data, lineClamp = 3, ...rest }) => {
  return (
    <Text {...rest} variant="clamped" lineClamp={lineClamp}>
      {data[dataField]?.map((_action, i) => (
        <React.Fragment key={i}>
          {i > 0 && ','}
          {_action.title}
        </React.Fragment>
      ))}
    </Text>
  );
};

export default memo(CellTextList, propsAreEqual);
