import { AuthenticationFeature } from 'constants/authentication';

export const menuMockup = [
  { link: '/dashboard', title: 'Trang chủ', icon: 'mdi mdi-view-grid-outline' },
  { link: '/orders', title: 'Đơn hàng', icon: 'mdi mdi-cart-outline', feature: AuthenticationFeature.ORDER },
  { link: '/coupons', title: 'Coupon', icon: 'mdi mdi-ticket-confirmation-outline', feature: AuthenticationFeature.COUPON },
  {
    link: '/#',
    title: 'Sản phẩm',
    icon: 'mdi mdi-package-variant-closed',
    subs: [
      { link: '/jewellery', title: 'Tất cả sản phẩm' },
      { link: '/diamonds', title: 'Tất cả kim cương' },
      { link: '/attributes', title: 'Thuộc tính sản phẩm' },
      { link: '/luxury-jewelleries', title: 'Sản phẩm cao cấp' },
      { link: '/collections', title: 'Bộ sưu tập' },
      { link: '/combo', title: 'Bộ trang sức' },
      { link: '/jewellery-new', title: 'Sản phẩm mới' },
      { link: '/jewellery-serials', title: 'Kho hàng' },
      { link: '/diamond-serials', title: 'Kho hàng kim cương' },
    ],
    feature: AuthenticationFeature.PRODUCT,
  },

  {
    link: '/#',
    title: 'Trang',
    icon: 'mdi mdi-view-compact-outline',
    subs: [
      { link: '/page-settings', title: 'Danh sách trang' },
      { link: '/banner-settings', title: 'Banner' },
    ],
    feature: AuthenticationFeature.PAGE,
  },
  {
    link: '/#',
    title: 'Blog',
    icon: 'mdi mdi-pin-outline',
    subs: [
      { link: '/blogs', title: 'Danh sách bài viết' },
      { link: '/tags', title: 'Thẻ' },
    ],
    feature: AuthenticationFeature.BLOG,
  },

  { link: '/images', title: 'Hình ảnh', icon: 'mdi mdi-image-multiple-outline', feature: AuthenticationFeature.MEDIAFILE },
  {
    link: '/seo',
    title: 'SEO',
    icon: 'mdi mdi-vector-polyline',
    subs: [
      { link: '/title-meta', title: 'Title & Meta' },
      { link: '/redirect-301', title: 'Redirect 301' },
      { link: '/404-monitor', title: '404 Monitor' },
      { link: '/sitemap', title: 'Sitemap' },
    ],
    feature: AuthenticationFeature.SEO,
  },
  {
    link: '/#',
    title: 'Cấu hình TT Page',
    icon: 'mdi mdi-cog-outline',
    subs: [
      { link: '/setting-header', title: 'Header' },
      { link: '/setting-footer', title: 'Footer' },
      { link: '/setting-page', title: 'Thông tin page' },
      { link: '/setting-menu', title: 'Menu' },
    ],
    feature: AuthenticationFeature.INFO,
  },
  {
    link: '/#',
    title: 'Khách hàng',
    icon: 'mdi mdi-account-details-outline',
    subs: [
      { link: '/customers', title: 'Danh sách khách hàng' },
      // { link: '/setting-footer', title: 'Footer' },
    ],
    feature: AuthenticationFeature.USER,
  },
  {
    link: '/#',
    title: 'Thành viên',
    icon: 'mdi mdi-account-multiple-outline',
    subs: [
      { link: '/users', title: 'Danh sách thành viên' },
      { link: '/user-types', title: 'Danh sách quyền' },
    ],
    feature: AuthenticationFeature.USER,
  },
  {
    link: '/logout',
    title: 'Đăng xuất',
    icon: 'mdi mdi-logout-variant',
  },
];
