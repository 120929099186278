import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { CellControlType } from 'constants/table';

export const getPagesInfo = () => ({
  buttonAddTitle: '',
  feature: AuthenticationFeature.PAGE,
  editConfig: {
    url: 'page-setting-info',
    queryParam: 'page',
    keyField: 'link',
    fields: [{ label: 'Tên trang', name: 'name' }],
  },
  columns: [
    {
      dataField: 'mediafiles.image',
      text: 'Ảnh đại diện',
      controlType: CellControlType.Image,
    },
    {
      dataField: 'name',
      text: 'Trang',
      controlType: CellControlType.TextWithActionLink,
      detailUrl: 'page-settings',
      detailValue: 'data.link',
      previewLink: 'link',
    },
    {
      dataField: 'banner.list.0.bannerPC',
      text: 'Banner',
      controlType: CellControlType.Image,
      width: 100,
      height: 53,
    },
    {
      dataField: 'desc',
      text: 'Tác giả',
    },
    {
      dataField: 'status',
      text: 'Trạng thái',
    },
  ],
  columnTemplate: '100px 1fr 120px 100px 200px ',
  filterElements: [
    {
      name: 'createdAt',
      placeholder: 'Tất cả các ngày',
      controlType: ControlTypes.DATE_PICKER,
      width: '140px',
    },
    {
      name: 'category',
      placeholder: 'Tất cả danh mục',
      controlType: ControlTypes.SELECT_PICKER,
      optionUrl: { url: 'categories', keyLabel: 'name', keyValue: 'link' },
      width: '150px',
    },
  ],
  selectable: true,
  title: 'Danh sách trang',
  url: 'page-settings',
});
