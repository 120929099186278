/* eslint-disable eqeqeq */

import { get } from 'lodash';
import { Accordion } from 'react-bootstrap';

import styled from 'styled-components';
import { Label, Row } from 'reactstrap';
import { FieldInput } from 'components/own';

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ $templateColumns }) => $templateColumns || '1fr'};
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-column-gap: 60px;
`;

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  width: 12.3%;
  color: #000000;
  b {
    margin-left: 4px;
    color: #e63535;
  }
`;

const SeoBlock = ({ values, handleChange }) => (
  <Accordion alwaysOpen defaultActiveKey={['seo']} className="my-3">
    <Accordion.Item className="mb-3" eventKey="seo">
      <BlockHeader className="d-flex align-items-center justify-content-between">SEO</BlockHeader>
      <BlockContent>
        <ItemRow>
          <Row>
            <FieldInput name="SEOInfo.title" label="Tiêu đề" value={get(values, 'SEOInfo.title')} onChange={handleChange} />
          </Row>
          <Row>
            <FieldInput name="SEOInfo.slug" label="Link" value={get(values, 'SEOInfo.slug')} onChange={handleChange} />
          </Row>
        </ItemRow>
        <ItemRow>
          <Row>
            <FieldInput
              name="SEOInfo.canonicalURL"
              label="Canonical link"
              value={get(values, 'SEOInfo.canonicalURL')}
              onChange={handleChange}
            />
          </Row>
          <Row>
            <FieldInput name="SEOInfo.redirection" label="Redirection" value={get(values, 'SEOInfo.redirection')} onChange={handleChange} />
          </Row>
        </ItemRow>
        <Row>
          <LabelInput>Mô tả</LabelInput>
          <FieldInput type="textarea" rows={4} inputMd={12} name="SEOInfo.desc" value={get(values, 'SEOInfo.desc')} onChange={handleChange} />
        </Row>
      </BlockContent>
    </Accordion.Item>
  </Accordion>
);

export default SeoBlock;
