import React, { useMemo } from 'react';
import styles from './ProductHighlightTemplate1.module.scss';
import { HandBagIcon } from 'components/icons';

const ProductHighlightTemplate1 = ({ item }) => {
  const src = useMemo(() => (typeof item.image === 'string' ? item.image : URL.createObjectURL(item.image)), [item.image]);
  return (
    <>
      <div className={styles['container']}>
        <div className={styles['highlight']}>
          <div className={styles['highlight__image']}>
            <img src={src} alt="" width={300} height={300} />
            <div className={styles['highlight__icon-hand-bag']}>
              <HandBagIcon />
            </div>
          </div>

          {!!item.title && (
            <section className={styles['highlight__content']}>
              <h3 className={styles['content__title']}>{item.title}</h3>
              <div className={styles['content__desc']} dangerouslySetInnerHTML={{ __html: item.text }} />
              {item.link && <div className={styles['content__button']}>{item.buttonText || 'Xem thêm'}</div>}
            </section>
          )}
        </div>
      </div>
    </>
  );
};
export default React.memo(ProductHighlightTemplate1);
