import { useCallback, useEffect, useMemo, useState } from 'react';
// import { ToastContainer } from 'react-toastify';
import { produce } from 'immer';

import { AuthenticationContext } from './context';
import { getCurrentInfo } from 'services/auth';
import VerticalLayout from 'components/VerticalLayout';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { getUserLocal, setUserLocal } from 'utils/localStorage';

export const AuthenticationProvider = ({ children }) => {
  const [{ userInfo }, setState] = useState({
    userInfo: getUserLocal() || null,
  });

  useEffect(() => {
    const callAPI = async () => {
      try {
        const res = await getCurrentInfo();
        const data = res.data;
        setUserLocal(data);
        setState(
          produce(draft => {
            draft.userInfo = res.data;
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.userInfo = null;
          })
        );
      }
    };
    callAPI();
  }, []);

  const pushNotification = useCallback((type, message) => {
    const option = {
      progressBar: true,
      preventDuplicates: true,
      showEasing: true,
      hideEasing: true,
    };

    switch (type) {
      case 'error':
        toastr.error(message, option);
        break;
      case 'warning':
        toastr.warning(message, option);
        break;
      case 'success':
        toastr.success(message, option);
        break;
      default:
        toastr(message, option);
        break;
    }
  }, []);

  const value = useMemo(
    () => ({
      pushNotification,
      userInfo,
    }),
    [pushNotification, userInfo]
  );

  return (
    <>
      {/* <ToastContainer /> */}
      <AuthenticationContext.Provider value={value}>
        <VerticalLayout>{children}</VerticalLayout>
      </AuthenticationContext.Provider>
    </>
  );
};
