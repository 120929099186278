import { get } from 'lodash';
import { memo, useMemo } from 'react';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/uti';

const Root = styled.div`
  height: ${({ height }) => height + 'px'};
  width: ${({ width }) => width + 'px'};
  background-color: #f2f2f5;
`;

const Image = styled.img`
  object-fit: cover;
`;

const CellImage = ({ data, dataField, width = 50, height = 40, ...rest }) => {
  const imgSrc = useMemo(() => get(data, dataField), [data, dataField]);
  return (
    <Root width={width} height={height}>
      {imgSrc && <Image width={width} height={height} src={imgSrc} alt={''} />}
    </Root>
  );
};

export default memo(CellImage, propsAreEqual);
