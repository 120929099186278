import produce from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import axios from 'axios';
import { getInfoManagementByUrl } from './modules';
import { parseUrlQuery } from 'utils/uti';
import { deleteAPI, putAPI } from 'services/common';
import { useActionNotification } from 'hook/useContextSelector';
import { cloneDeep, get } from 'lodash';
import { API_UPLOAD } from 'constants/common';

const initialState = {
  page: 1,
  total: 0,
  data: [],
  searchValue: '',
  selectedAction: null,
  selectedRow: [],
  forceRender: 0,
  tabSummary: {},

  limit: 10,
  loading: true,
  hidePaging: false,
  order: { order: '', orderBy: '' },
  params: {},

  firstInit: true,
};

export const useCommonManagementHook = (url, initParams = {}) => {
  const history = useHistory();
  const location = useLocation();
  const pushNotification = useActionNotification();
  const pathname = url || location.pathname;
  const ref = useRef();
  const [state, setState] = useState(initialState);
  const [dataEdit, setDataEdit] = useState(null);
  const apiUrl = useMemo(() => {
    switch (pathname) {
      case '/diamonds':
        return 'diamond-serials';

      case '/jewellery-new':
        return 'new-jewellery';

      case '/banner-settings':
        return 'page-settings';

      case 'category-product':
      case 'coupon-product':
        return 'jewellery';

      case '/images':
        return API_UPLOAD;

      default:
        return pathname;
    }
  }, [pathname]);

  const info = useMemo(() => getInfoManagementByUrl(pathname), [pathname]);
  const keyword = useMemo(() => info.keyword || 'keyword', [info.keyword]);

  useEffect(() => {
    const callAPI = async () => {
      let query = parseUrlQuery(Object.assign(cloneDeep(initParams), state.params), state.order, 'limit=' + state.limit, info.orderFunc);
      if (state.page) query += '&page=' + state.page;
      if (state.searchValue) query += `&${keyword}=` + state.searchValue;
      try {
        const res = await axios.get(apiUrl + '?' + query);
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = res.data.list || res.data.rows || res.data || [];
            draft.total = typeof res.data.count === 'number' ? res.data.count : draft.data.length;
            draft.hidePaging = !res.data.count;
            draft.tabSummary = get(res, ['data', 'extraCount'], {});
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = [];
            draft.total = 0;
            draft.hidePaging = true;
            draft.tabSummary = {};
          })
        );
      }
    };
    if (apiUrl) callAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl, keyword, state.limit, state.order, state.page, state.params, state.searchValue, state.forceRender]);

  const onChangePage = useCallback(({ selected }) => {
    setState(
      produce(draft => {
        draft.page = selected + 1;
        draft.loading = true;
      })
    );
  }, []);

  const onClickFilter = useCallback(force => {
    setState(
      produce(draft => {
        if (ref.current) {
          if (force) draft.forceRender += 1;
          draft.searchValue = ref.current.value;
          draft.loading = true;
          draft.page = 1;
        }
      })
    );
  }, []);

  const onAddNew = useCallback(() => {
    history.push(pathname + '/new');
  }, [history, pathname]);

  const onSelectFilter = useCallback(e => {
    setState(
      produce(draft => {
        if (draft.params[e.target.name] === e.target.value) return;
        draft.params[e.target.name] = e.target.value;
        draft.loading = true;
        draft.page = 1;
      })
    );
  }, []);

  const onKeypressFilter = useCallback(
    e => {
      if (e.which === 13) onClickFilter(true);
    },
    [onClickFilter]
  );

  const onOrder = useCallback((orderBy, order) => {
    setState(
      produce(draft => {
        if (order) draft.order = { order, orderBy };
        else draft.order = null;
      })
    );
  }, []);

  const handleSelectGroup = useCallback(val => {
    setState(
      produce(draft => {
        draft.selectedAction = val;
      })
    );
  }, []);

  const onSelectRow = useCallback((id, isCheck) => {
    setState(
      produce(draft => {
        if (isCheck) draft.selectedRow.push(id);
        else draft.selectedRow = draft.selectedRow.filter(_r => _r !== id);
      })
    );
  }, []);

  const applyAction = useCallback(() => {
    if (!state.selectedRow.length || !state.selectedAction) return;
    let request = [];
    switch (state.selectedAction.method) {
      case 'DELETE':
        request = state.selectedRow.map(async id => await deleteAPI(`${state.selectedAction.url || apiUrl}/${id}`));
        break;

      default:
        request = state.selectedRow.map(
          async id => await putAPI(`${state.selectedAction.url || apiUrl}/${id}`, { [state.selectedAction.key]: state.selectedAction.value })
        );
        break;
    }
    Promise.all(request)
      .then(res => {
        pushNotification('success', 'Thay đổi thành công');
        setState(
          produce(draft => {
            draft.selectedRow = [];
            draft.forceRender += 1;
          })
        );
      })
      .catch(err => {
        pushNotification('error', get(err, 'message', err));
      });
  }, [apiUrl, pushNotification, state.selectedAction, state.selectedRow]);

  const onQuickEditSuccess = useCallback(() => {
    setDataEdit(null);
    setState(
      produce(draft => {
        draft.forceRender += 1;
      })
    );
  }, []);

  return {
    ...info,
    ...state,
    dataEdit,
    ref,
    applyAction,
    handleSelectGroup,
    onAddNew,
    onChangePage,
    onClickFilter,
    onKeypressFilter,
    onSelectRow,
    onOrder,
    onSelectFilter,
    onQuickEditSuccess,
    onEdit: setDataEdit,
  };
};
