import React from 'react';

const Checkbox = ({ id, listName, name, checked, onChange }) => {
    return <div>
        <div className="form-check">
            <input className="form-check-input" type="checkbox" id={`${listName}-${id}`} checked={checked} onChange={(e) => onChange(e.target.checked)} />
            <label className="form-check-label" htmlFor={`${listName}-${id}`} style={{ color: '#000' }}>
                {name}
            </label>
        </div>
    </div>;
};

export default Checkbox;
