import { JewelTypes } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { JewellTypeOptions, StockStatusOptions } from 'constants/options';
import { CellControlType } from 'constants/table';

export const getCollectionProductInfo = () => ({
  columns: [
    {
      dataField: 'mediafiles.images.0.mainImage',
      text: 'Hình đại diện',
      controlType: CellControlType.Image,
    },
    {
      dataField: 'productCode',
      text: 'Mã sản phẩm',
      color: '#0063F7',
      fontWeight: 700,
    },
    {
      dataField: 'productName',
      text: 'Tên sản phẩm',
      render: (value, data) => `${data.mainCategory || ''} ${value}`,
      color: '#0063F7',
      fontWeight: 700,
    },
    {
      dataField: 'inStockCount',
      text: 'Trạng thái',
      controlType: CellControlType.Status,
      render: value => (value > 0 ? 'Còn hàng' : 'Hết hàng'),
      background: value => (value > 0 ? '#0077701A' : '#E800001A'),
      color: value => (value > 0 ? '#007770' : '#E80000'),
    },
    {
      dataField: 'price',
      text: 'Giá (VNĐ)',
      custom: 'price',
    },
    {
      dataField: 'productCategory',
      text: 'Danh mục',
      custom: 'array',
    },
    {
      dataField: 'newProductInfo',
      text: 'Sản phẩm mới',
      controlType: CellControlType.Status,
      custom: 'circle',
    },
    {
      dataField: 'type',
      text: 'Loại sản phẩm',
      render: value =>
        value === JewelTypes.Single ? 'Đơn' : value === JewelTypes.Couple ? 'Đôi' : value === JewelTypes.Customize ? 'Đính kèm kim cương' : '',
    },

    {
      dataField: 'type',
      text: 'Bộ sưu tập',
    },
  ],
  columnTemplate: '120px 120px 200px 120px 250px 1fr 120px 120px 200px ',
  selectedFilterElements: [
    {
      name: 'stockStatus',
      placeholder: 'Trạng thái',
      controlType: ControlTypes.SELECT_PICKER,
      options: StockStatusOptions,
      width: '130px',
    },
  ],

  filterElements: [
    {
      name: 'stockStatus',
      placeholder: 'Trạng thái',
      controlType: ControlTypes.SELECT_PICKER,
      options: StockStatusOptions,
      width: '130px',
    },
    {
      name: 'type',
      placeholder: 'Loại sản phẩm',
      controlType: ControlTypes.SELECT_PICKER,
      options: JewellTypeOptions,
      width: '130px',
    },
    {
      name: 'category',
      placeholder: 'Danh mục sản phẩm',
      controlType: ControlTypes.SELECT_PICKER,
      optionUrl: { url: 'categories', keyLabel: 'name', keyValue: 'link' },
      width: '170px',
    },
  ],
  selectable: true,
  title: 'bộ sưu tập',
  url: 'collections',
});
