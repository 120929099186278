import React, { Component } from 'react';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';

//Simple bar
import SimpleBar from 'simplebar-react';

import SidebarContent from './SidebarContent';
//Import logo Images
import logoSmLight from '../../assets/images/vinh-cara/logo/logo-sm.png';
import logoLight from '../../assets/images/vinh-cara/logo/logo.png';

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  margin: ${({ $condensed }) => ($condensed ? '' : '10px')};
  justify-content: ${({ $condensed }) => ($condensed ? 'center' : 'flex-start')};
`;

const ToggleIcon = styled(Button)`
  position: absolute;
  right: 0;
  top: 70px;
  z-index: 1004;
  background-color: #007770;
  color: #fff;
  transform: ${({ $revert }) => ($revert ? 'rotate(180deg)' : '')};

  i {
    color: #fff;
  }
`;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div data-simplebar className="h-100 pt-1 position-relative">
            <Logo to="#" className="logo" $condensed={this.props.type === 'condensed'}>
              <span className="logo-sm">
                <img src={logoSmLight} alt="" height="40" />
              </span>
              <span className="logo-lg">
                <img src={logoLight} alt="" height="76" />
              </span>
            </Logo>
            <ToggleIcon
              size="sm"
              color="none"
              type="button"
              onClick={this.toggleMenu}
              className="px-3 font-size-24 "
              id="vertical-menu-btn"
              $revert={this.props.type === 'condensed'}
            >
              <i className="mdi mdi-menu-open align-middle"></i>
            </ToggleIcon>
            {this.props.type !== 'condensed' ? (
              <SimpleBar style={{ maxHeight: '100%' }}>
                <SidebarContent />
              </SimpleBar>
            ) : (
              <SidebarContent />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStateToProps, {})(withRouter(Sidebar));
