import axios from 'axios';

export const getAllProducts = (url, query = '') => axios({ url: url + '?' + query, method: 'GET' });

export const getProductById = id => axios({ url: 'blogs/' + id, method: 'GET' });

export const createProduct = data => axios({ url: 'blogs', method: 'POST', data });

export const updateProduct = (id, data) => axios({ url: 'blogs/' + id, method: 'PUT', data });

export const updateProductSEOInfo = (id, data) => axios({ url: 'blogs/seo/' + id, method: 'PUT', data });

export const deleteProduct = id => axios({ url: 'blogs/' + id, method: 'DELETE' });

export const getAllCategory = (query = '') => axios({ url: 'blogType?' + query, method: 'GET' });
