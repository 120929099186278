import { CellControlType } from 'constants/table';
import { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { get, omit } from 'lodash';

import CellAction from './CellAction';
import CellDate from './CellDate';
import CellImage from './CellImage';
import CellMenuAction from './CellMenuAction';
import CellText from './CellText';
import CellTextAndActions from './CellTextAndActions';
import CellTextList from './CellTextList';
import CellStatus from './CellStatus';
import CellDateTime from './CellDateTime';
import CellTextCopy from './CellTextCopy';
import CellLink from './CellLink';

import { deleteAPI, getAPI, postAPI } from 'services/common';
import { propsAreEqual } from 'utils/uti';

import { E_COMMERCE_URL } from 'constants/common';
import { AuthenticationTypes } from 'constants/authentication';

const CellRendering = ({
  copy = {},
  controlType,
  deleteUrl,
  detailValue = 'data.id',
  previewLink = 'link',
  detailUrl,
  onRefresh,
  changeItem,
  onEdit,
  ...rest
}) => {
  const history = useHistory();

  const routerToDetail = useCallback(() => {
    history.push(`${detailUrl}/${get(rest, detailValue) || get(rest, 'data.id')}`);
  }, [detailValue, history, detailUrl, rest]);

  const routerToPreview = useCallback(() => {
    if (typeof previewLink === 'function') {
      const url = previewLink(rest?.data);
      window.open(url, '_blank');
      return;
    }
    const itemPreviewLink = get(rest, ['data', previewLink]);
    if (!itemPreviewLink) {
      if (rest?.data?.seoInfo?.link) {
        window.open(rest?.data?.seoInfo?.link, '_blank');
      }
      return;
    }
    window.open(E_COMMERCE_URL + itemPreviewLink, '_blank');
  }, [previewLink, rest]);

  const onDelete = useCallback(async () => {
    try {
      await deleteAPI(deleteUrl + '/' + rest.data?.id || get(rest.data, rest.dataField));
      onRefresh();
    } catch (error) {}
  }, [deleteUrl, onRefresh, rest.data, rest.dataField]);

  const onFastEdit = useCallback(() => {
    onEdit(get(rest, 'data'));
  }, [onEdit, rest]);

  const onCopy = useCallback(async () => {
    try {
      if (!copy?.url) return;
      const res = await getAPI(copy?.url + '/' + rest.data?.id || get(rest.data, rest.dataField));
      const copyData = res.data;
      for (const key in copy?.customFields) {
        if (Object.hasOwnProperty.call(copy?.customFields, key)) {
          const element = copy?.customFields[key];
          copyData[key] = element(copyData);
        }
      }
      await postAPI(copy?.url, omit(copyData, copy?.omitFields || []));
      onRefresh();
      // navigator.clipboard.writeText(get(rest.data, rest.dataField));
    } catch (error) {}
  }, [copy, onRefresh, rest.data, rest.dataField]);

  let actions = [
    { title: 'Chỉnh sửa', onClick: routerToDetail },
    { title: 'Sao chép', onClick: onCopy, actionType: AuthenticationTypes.CREATE },
  ]; // { title: 'Chỉnh sửa nhanh' }, { title: 'Sao chép' }

  switch (controlType) {
    case CellControlType.TextAction:
      actions.splice(1, 1);
      return <CellTextAndActions actions={actions} link={detailUrl} {...rest} />;

    case CellControlType.TextNonAction:
      actions.splice(1, 1);
      return <CellTextAndActions actions={[{ title: 'Chỉnh sửa', onClick: () => changeItem(rest.data) }]} link={detailUrl} {...rest} />;

    case CellControlType.TextWithAction:
      return <CellTextAndActions actions={actions} link={detailUrl} {...rest} />;

    case CellControlType.TextWithQuickEditDelete:
      actions[0] = { title: 'Chỉnh sửa nhanh', onClick: onFastEdit, actionType: AuthenticationTypes.EDIT };
      actions[1] = { title: 'Xóa', danger: true, onClick: onDelete, actionType: AuthenticationTypes.DELETE };
      return <CellTextAndActions actions={actions} link={detailUrl} {...rest} />;

    case CellControlType.TextWithActionDelete:
      actions[1] = { title: 'Chỉnh sửa nhanh', onClick: onFastEdit, actionType: AuthenticationTypes.EDIT };
      actions[2] = { title: 'Xóa', danger: true, onClick: onDelete, actionType: AuthenticationTypes.DELETE };
      return <CellTextAndActions actions={actions} link={detailUrl} {...rest} />;

    case CellControlType.TextWithActionLink:
      actions[1] = { title: 'Chỉnh sửa nhanh', onClick: onFastEdit, actionType: AuthenticationTypes.EDIT };
      actions[2] = { title: 'Link Xem', onClick: routerToPreview };
      return <CellTextAndActions actions={actions} link={detailUrl} {...rest} />;

    case CellControlType.TextWithActionDeleteLink:
      actions[2] = { title: 'Xóa', danger: true, onClick: onDelete, actionType: AuthenticationTypes.DELETE };
      actions.push({ title: 'Link Xem', onClick: routerToPreview });
      return <CellTextAndActions actions={actions} link={detailUrl} {...rest} />;

    case CellControlType.TextWithActionEditDeleteLink:
      actions[1] = { title: 'Xóa', danger: true, onClick: onDelete, actionType: AuthenticationTypes.DELETE };
      actions.push({ title: 'Link Xem', onClick: routerToPreview });
      return <CellTextAndActions actions={actions} link={detailUrl} {...rest} />;

    case CellControlType.TextWithActionEditQuickEditDelete:
      actions[1] = { title: 'Chỉnh sửa nhanh', onClick: onFastEdit, actionType: AuthenticationTypes.EDIT };
      actions.push({ title: 'Xóa', danger: true, onClick: onDelete, actionType: AuthenticationTypes.DELETE });
      return <CellTextAndActions actions={actions} link={detailUrl} {...rest} />;

    case CellControlType.Date:
      return <CellDate link={detailUrl} {...rest} />;

    case CellControlType.DateTime:
      return <CellDateTime link={detailUrl} {...rest} />;

    case CellControlType.Action:
      return <CellAction link={detailUrl} deleteUrl={deleteUrl} onRefresh={onRefresh} {...rest} />;

    case CellControlType.TextList:
      return <CellTextList link={detailUrl} {...rest} />;

    case CellControlType.Image:
      return <CellImage link={detailUrl} {...rest} />;

    case CellControlType.MenuAction:
      return <CellMenuAction routerToDetail={routerToDetail} onDelete={onDelete} onFastEdit={onFastEdit} {...rest} />;

    case CellControlType.Status:
      return <CellStatus link={detailUrl} {...rest} />;

    case CellControlType.Link:
      return <CellLink {...rest} />;

    case CellControlType.TextCopy:
      return <CellTextCopy link={detailUrl} {...rest} />;

    default:
      return <CellText link={detailUrl} {...rest} />;
  }
};

export default memo(CellRendering, propsAreEqual);
