export const OrderStatusOptions = [
  { label: 'Trạng thái đơn hàng', value: null },
  { label: 'Mới', value: 1 },
  { label: 'Chờ thanh toán', value: 2 },
  { label: 'Thanh toán thành công', value: 3 },
  { label: 'Đang xử lý', value: 4 },
  { label: 'Giao/giữ hàng', value: 5 },
  { label: 'Hoàn thành', value: 6 },
  { label: 'Đơn hủy', value: -1 },
];
