import { useCallback } from 'react';
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from 'reactstrap';
import { Formik } from 'formik';
import { putAPI } from 'services/common';
import styled from 'styled-components';
import ActionsItem from '../ActionsItem';
import { get } from 'lodash';
import { FieldInput } from '../Field';
import { getChangedData } from 'utils/uti';
import { useActionNotification } from 'hook/useContextSelector';

const Content = styled.div`
  height: calc(100% - 60px);
`;

const PopupQuickEdit = ({ data, feature, config = { url: '', fields: [], keyField: 'id' }, onClose, onSaveSuccess }) => {
  const pushNotification = useActionNotification();
  const handleSave = useCallback(
    values => {
      if (!config.url || !data?.id) return;
      const req = getChangedData(values, data);
      let url = `${config.url}/${get(data, [config.keyField || 'id'])}`;

      if (config.queryParam) {
        url = `${config.url}?${config.queryParam}=${get(data, [config.keyField || 'id'])}`;
      }
      putAPI(url, req)
        .then(res => {
          pushNotification('success', 'Lưu thành công');
          onSaveSuccess();
        })
        .catch(err => {
          pushNotification('error', err.message);
        });
    },
    [config.url, data, onSaveSuccess, pushNotification]
  );
  return (
    <Offcanvas style={{ width: 600 }} scrollable={false} direction="end" toggle={() => onClose(null)} isOpen={!!data}>
      <OffcanvasHeader toggle={() => onClose(null)}>Chỉnh sửa nhanh</OffcanvasHeader>
      <OffcanvasBody>
        <Formik initialValues={data} onSubmit={handleSave}>
          {({ errors = {}, handleSubmit, handleBlur, handleChange, submitCount, touched = {}, values = {} }) => (
            <>
              <Content>
                {config?.fields?.map((_f, index) => (
                  <Row key={index} className="mb-3">
                    <FieldInput
                      {..._f}
                      error={_f.required && (get(touched, _f.name) || !!submitCount) ? 'Trường này không được để trống' : null}
                      value={get(values, _f.name)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Row>
                ))}
              </Content>
              <ActionsItem feature={feature} onCancel={() => onClose(null)} onSave={handleSubmit}></ActionsItem>
            </>
          )}
        </Formik>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default PopupQuickEdit;
