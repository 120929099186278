import { propsAreEqual } from 'utils/uti';
import { memo } from 'react';
import { Col, Label } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
  b {
    margin-left: 4px;
    color: #e63535;
  }
`;

const InputCurrency = ({ autoFocus, error, touched, isRequired, label, name, onChange = () => {}, ...rest }) => {
  return (
    <>
      {label && (
        <LabelInput className="col-md-3 col-form-label d-flex align-items-center">
          {label} {isRequired && <b>*</b>}
        </LabelInput>
      )}
      <Col md={9}>
        <CurrencyInput
          name={name}
          defaultValue={1000}
          className="form-control"
          decimalsLimit={2}
          onValueChange={(value, name) => onChange({ target: { value: value ? parseInt(value) : value, name } })}
          {...rest}
        />
        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
    </>
  );
};

export default memo(InputCurrency, propsAreEqual);
