import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import Select from 'react-select';
import { ButtonPrimary, CustomTable, Paginate } from 'components/own';
import { DropDownIcon } from 'components/icons';
import { useHistory } from 'react-router';
import { CellControlType } from 'constants/table';
import { AuthenticationFeature } from 'constants/authentication';
import { deleteLink404, getAllLink404 } from 'services/SEO';
import { useActionNotification } from 'hook/useContextSelector';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-right: 20px;
`;

const SelectAction = styled(Select)`
  cursor: pointer;
  width: 100px;
  margin-right: 4px;
  font-size: 14px;
  .select2-selection__indicators {
    padding-right: 4px;
  }
`;

const Label = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  display: flex;
  align-items: center;

  /* Dark / Dark 0 */

  color: #1c1c28;
`;

const ContentTable = styled.div`
  background-color: #fff;
  margin-top: 16px;
`;

const columns = [
  {
    dataField: 'link',
    text: 'From',
    sort: true,
    controlType: Label,
    color: '#0063F7',
  },
  {
    dataField: 'updatedAt',
    text: 'Last Accessed',
    sort: true,
    controlType: CellControlType.DateTime,
  },
];

const optionActions = [
  { label: 'Delete', value: 'Delete' }
];
// const ecommerceUrl = E_COMMERCE_URL;
const initialState = { page: 1, total: 0, columns, data: [], searchValue: '', selectedAction: null, limit: 10, loading: true,   selectedRow: [], };

const Monitor404 = () => {
  const [{ columns, data, loading, limit, page, searchValue, selectedAction, total, selectedRow }, setState] = useState(initialState);
  const router = useHistory();
  const columnTemplate = useMemo(() => '2fr 2fr', []);
  const ref = useRef();
  const pushNotification = useActionNotification();
  useEffect(() => {
    const callAPI = async () => {
        let query = 'limit=' + limit;
        if (page) query += '&page=' + page;
        if (searchValue) query += '&name=' + searchValue;
      try {
        const res = await getAllLink404(query);
        console.log('list 404:' + JSON.stringify(res.data[0]));
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = res.data || [];
            draft.total = res.data?.length || 0;
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = [];
            draft.total = 0;
          })
        );
      }
    };
    callAPI();
  }, [limit, page, searchValue]);

  const deleteLink = useCallback(
    id => {
        deleteLink404(id).then(() => {
        pushNotification('success', 'Xoá thành công: ' + id);
      });
    },
    [pushNotification]
  );
  const handleSelectGroup = useCallback(item => {
    setState(
      produce(draft => {
        draft.selectedAction = item;
      })
    );
  }, []);

  const applySelectGroup = useCallback(item => {
    console.log('selectedAction: ' + JSON.stringify(selectedAction));
    if (selectedAction.value === "Delete") {
      selectedRow.forEach(id => {
        deleteLink(id);
     });
    }
    setTimeout(() => {
      const callAPI = async () => {
        let query = 'limit=' + limit;
        if (page) query += '&page=' + page;
        if (searchValue) query += '&name=' + searchValue;
      try {
        const res = await getAllLink404(query);
        console.log('list 404:' + JSON.stringify(res.data[0]));
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = res.data || [];
            draft.total = res.data?.length || 0;
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = [];
            draft.total = 0;
          })
        );
      }
    };
    callAPI();
    }, 1000);
    
  }, [selectedRow, deleteLink, selectedAction, limit, page, searchValue]);

  const onChangePage = useCallback(({ selected }) => {
    setState(
      produce(draft => {
        draft.page = selected + 1;
      })
    );
  }, []);

  const onClickFilter = useCallback(() => {
    setState(
      produce(draft => {
        if (ref.current) draft.searchValue = ref.current.value;
      })
    );
  }, []);
  const onSelectRow = useCallback(
    (id, isCheck) => {
      console.log('check' + id + 'id' + isCheck);
      setState(
        produce(draft => {
          if (isCheck) draft.selectedRow.push(id);
          else draft.selectedRow = draft.selectedRow.filter(_r => _r !== id);
        })
      );
    },
    []
  );
  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Cấu hình SEO 404 Monitor</Title>
      </div>
      <div className="d-flex align-items-center mt-2">
        <SelectAction
          placeholder="Tác vụ"
          value={selectedAction}
          onChange={handleSelectGroup}
          options={optionActions}
          classNamePrefix="select2-selection"
          components={{
            DropdownIndicator: DropDownIcon,
            IndicatorSeparator: null,
          }}
        />
        <ButtonPrimary fontWeight={500} onClick={() => applySelectGroup()} className="me-2">
          Áp dụng
        </ButtonPrimary>

        <div className="flex-1"></div>
        <Label className="me-2">{total} mục</Label>
        <Paginate page={page} total={total} itemPerPage={limit} onChange={onChangePage} />
      </div>
      <ContentTable className="">
        <CustomTable
           selectable={true}
          columnTemplate={columnTemplate}
          rowSelected={selectedRow}
          onChecked={onSelectRow}
          data={data}
          headers={columns}
          loading={loading}
          rowHeight={83}
          feature={AuthenticationFeature.USER}
          onRefresh={() => onClickFilter(true)}
        />
      </ContentTable>
    </div>
  );
};

export default Monitor404;
