import { AuthenticationTypes } from 'constants/authentication';
import { useUserInfo } from 'hook/useContextSelector';
import { get, some } from 'lodash';
import { useMemo } from 'react';

const Authentication = ({ children, title = '', feature, type, defaultVal = false }) => {
  // check auth here
  const userInfo = useUserInfo();
  const isValid = useMemo(() => {
    if (!userInfo) return defaultVal;

    if (!feature || !type) return true;

    const permission = get(userInfo, ['permission'], []);

    if (permission[0] === 'ALL_ALL') return true;

    if (type === AuthenticationTypes.VIEW && some(permission, _p => _p.includes(feature) || _p.includes(`ALL_`))) return true;

    if (some(permission, _p => _p.includes(`${feature}_ALL`) || _p.includes(`${feature}_${type}`) || _p.includes(`ALL_${type}`))) return true;

    return false;
  }, [defaultVal, feature, type, userInfo]);

  if (!isValid) return title;

  return children;
};

export default Authentication;
