import { CaretDoubleLeftIcon, CaretDoubleRightIcon, CaretLeftIcon, CaretRightIcon } from 'components/icons';
import { useMemo } from 'react';
import ReactPaginate from 'react-paginate';

import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconButton = styled.div`
  background: #ffffff;
  /* Dark / Dark 4 */
  width: 32px;
  height: 32px;

  box-sizing: border-box;
  border-radius: 2px;
  border: ${({ active }) => (active ? '1px solid #007770' : '1px solid #C7C9D9')};
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  cursor: pointer;
  pointer-events: ${({ active }) => (active ? '' : 'none')};
`;

const Paginate = ({ page, total, itemPerPage, onChange }) => {
  const pageCount = useMemo(() => Math.ceil(total / itemPerPage), [itemPerPage, total]);
  return (
    <Root>
      <IconButton active={page > 1} onClick={() => onChange({ selected: 0 })}>
        <CaretDoubleLeftIcon color={page > 1 ? '#007770' : '#C7C9D9'} />
      </IconButton>
      <ReactPaginate
        forcePage={page - 1}
        nextLabel={<CaretRightIcon color={page < pageCount ? '#007770' : '#C7C9D9'} />}
        onPageChange={onChange}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={<CaretLeftIcon color={page > 1 ? '#007770' : '#C7C9D9'} />}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-icon"
        nextClassName="page-item"
        nextLinkClassName="page-icon"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      <IconButton active={page < pageCount} onClick={() => onChange({ selected: pageCount - 1 })}>
        <CaretDoubleRightIcon color={page < pageCount ? '#007770' : '#C7C9D9'} />
      </IconButton>
    </Root>
  );
};

export default Paginate;
