import styled from 'styled-components';

import { AutoSizer, List } from 'react-virtualized';
import CellRendering from './CellRendering';
import { useCallback, useMemo } from 'react';
import { Input } from 'reactstrap';
import { LoadingIcon } from 'components/icons';
import ColumnOrder, { checkOrderType } from './ColumnOrder';
import { get } from 'lodash';
import QuickEdit from './QuickEdit';
// import QuickEdit from './QuickEdit';

const Root = styled.div`
  flex: 1 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
`;

const TableContent = styled.div`
  height: 100%;
  border: ${({ noBorder }) => (noBorder ? '' : ' 1px solid #ecedf2')};
  box-sizing: border-box;
  border-radius: 0px 0px 2px 2px;
  min-height: ${({ rowHeight }) => rowHeight + 'px'};
`;

const TableEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-family: Quicksand;
  height: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const TableHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ columnTemplate, selectable }) => (selectable ? `20px ${columnTemplate}` : columnTemplate)};
  justify-content: space-between;
  user-select: none;
  background: ${({ background }) => background};
  box-sizing: border-box;
  padding: 0;
  min-height: 56px;
  grid-gap: 24px;
  padding-left: 12px;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const HeaderText = styled.div`
  font-family: Quicksand;
  font-style: normal;
  line-height: 140%;
  /* or 18px */
  display: flex;
  align-items: center;
  /* Gutenberg/Gray 900 */
  color: ${({ color }) => color || '#1e1e1e'};
  font-size: ${({ fontSize }) => fontSize || '13px'};
  font-weight: ${({ fontWeight }) => fontWeight || '500'};
`;

const Item = styled.div`
  height: 100%;
  display: grid;
  align-items: flex-start;
  padding-top: 12px;
  grid-template-columns: ${({ columnTemplate, selectable }) => (selectable ? `20px ${columnTemplate}` : columnTemplate)};
  justify-content: space-between;
  user-select: none;
  z-index: 9;
  box-sizing: border-box;
  padding-left: 12px;
  grid-gap: 24px;
  box-shadow: 0px -1px 0px 0px rgba(230, 234, 240, 1) inset;
`;

const CustomGrid = ({
  columnTemplate,
  headers,
  headerBackground = '#ffffff',
  headerText = {},
  data = [],
  dataEdit,
  loading,
  noBorder,
  order,
  rowSelected = [],
  rowHeight = 60,
  selectable,
  onOrder,
  onChecked,
  feature,
  onRefresh,
  changeItem,
  onEdit = () => {},
  ...rest
}) => {
  const _rowRenderer = useCallback(
    ({ index, key, style }) => (
      <div key={key} style={style}>
        <Item columnTemplate={columnTemplate} selectable={selectable}>
          {selectable && (
            <Input
              className="form-check-input"
              type="checkbox"
              onChange={e => {
                onChecked(get(data, [index, 'id']), e.target.checked);
              }}
              checked={rowSelected?.indexOf(get(data, [index, 'id'])) > -1}
            />
          )}
          {headers.map((_column, i) => (
            <CellRendering
              key={i}
              {..._column}
              feature={feature}
              data={data[index]}
              module={module}
              dataset={data}
              onRefresh={onRefresh}
              onEdit={onEdit}
              changeItem={changeItem}
            />
          ))}
        </Item>
        {/* {dataEdit?.id && dataEdit?.id === get(data, [index, 'id']) && <QuickEdit style={style} data={data[index]} headers={headers} />} */}
      </div>
    ),
    [changeItem, columnTemplate, data, feature, headers, onChecked, onEdit, onRefresh, rowSelected, selectable]
  );

  const totalRow = useMemo(() => (loading || !data.length ? 6 : data.length), [data.length, loading]);

  return (
    <Root>
      <TableHeader columnTemplate={columnTemplate} selectable={selectable} background={headerBackground}>
        {selectable && <div />}
        {headers.map(_column =>
          _column.orderKey ? (
            <ColumnOrder {..._column} onClick={onOrder} orderType={checkOrderType(_column.orderKey, order)} />
          ) : (
            <HeaderText key={_column.text} {...headerText}>
              {_column.text}
            </HeaderText>
          )
        )}
      </TableHeader>
      <TableContent noBorder={noBorder} rowHeight={rowHeight * totalRow + 2}>
        {loading ? (
          <LoadingContainer>
            <LoadingIcon />
          </LoadingContainer>
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <>
                <List
                  height={height}
                  rowCount={data.length}
                  autoHeight={!!dataEdit}
                  noRowsRenderer={() => (
                    <TableEmpty color="variantGray" fontSize={6} paddingY={4} textAlign="center">
                      Không có dữ liệu nào được tìm thấy
                    </TableEmpty>
                  )}
                  rowRenderer={_rowRenderer}
                  width={width}
                  rowHeight={rowHeight}
                />
              </>
            )}
          </AutoSizer>
        )}
      </TableContent>
    </Root>
  );
};

export default CustomGrid;
