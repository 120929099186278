import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { Input } from 'reactstrap';
import { ButtonOutline, ButtonPrimary, CustomTable, Paginate } from 'components/own';
import { DropDownIcon } from 'components/icons';
import { useJewelleryHook } from '../hooks/useJewelleryHook';
import { FilterInput } from 'components/own/Filter';
import { deleteAPI, postAPI } from 'services/common';

const SelectAction = styled(Select)`
  cursor: pointer;
  width: 175px;
  margin-right: 4px;
  font-size: 14px;
  .select2-selection__indicators {
    padding-right: 4px;
  }
`;

const SearchBox = styled(Input)`
  width: 200px;
  height: 32px;
  background: #fafafc;
  input:after {
    border-bottom-width: 1px !important;
  }
`;

const Label = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  display: flex;
  align-items: center;

  /* Dark / Dark 0 */

  color: #1c1c28;
`;

const ContentTable = styled.div`
  background-color: #fff;
  margin-top: 16px;
`;
const optionActions = [{ label: 'Thêm vào bộ sưu tập', value: 'add-jewellery-to-combo' }];

const CustomJewelleryManagement = ({ comboId, getRefetchAPI, onSubmit }) => {
  const {
    actions = optionActions,
    columns,
    columnTemplate,
    data,
    filterElements = [],
    hidePaging,
    loading,
    limit,
    order,
    page,
    params,
    ref,
    selectedAction,
    selectable,
    total,
    upperFilterElements = [],
    selectedRow = [],
    forceRender,

    applyAction,
    handleSelectGroup,
    onChangePage,
    onClickFilter,
    onKeypressFilter,
    onOrder,
    onSelectFilter,
    onSelectRow,
    clearSelectedRow,
  } = useJewelleryHook('/jewellery');

  const handleApplyAction = useCallback(
    ({ applyAction, selectedAction, comboId }) => {
      switch (selectedAction?.value) {
        case 'delete-jewellery-from-combo': {
          applyAction(deleteAPI, comboId);
          break;
        }
        case 'add-jewellery-to-combo': {
          if (comboId !== 'new') {
            applyAction(postAPI, comboId);
          } else {
            clearSelectedRow();
            onSubmit(selectedRow);
          }
          break;
        }
        default:
          return;
      }
    },
    [selectedRow, onSubmit, clearSelectedRow]
  );
  useEffect(() => {
    forceRender > 0 && getRefetchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRender]);
  return (
    <div>
      <div className="d-flex align-items-center justify-content-end">
        {upperFilterElements.map((_f, index) => (
          <FilterInput
            key={index}
            {..._f}
            onChange={onSelectFilter}
            value={params[_f.name]}
            minDate={params[_f.minDate]}
            maxDate={params[_f.maxDate]}
          />
        ))}
        <SearchBox innerRef={ref} type="text" className="form-control me-3" onKeyPress={onKeypressFilter} placeholder="Nhập keyword" />
        <ButtonOutline onClick={onClickFilter}>Tìm kiếm</ButtonOutline>
      </div>
      <div className="d-flex align-items-center mt-2">
        {selectable && (
          <>
            <SelectAction
              placeholder="Tác vụ"
              value={selectedAction}
              onChange={handleSelectGroup}
              options={actions}
              classNamePrefix="select2-selection"
              components={{
                DropdownIndicator: DropDownIcon,
                IndicatorSeparator: null,
              }}
            />
            <ButtonPrimary
              fontWeight={500}
              width="75px"
              className="me-2"
              onClick={() => handleApplyAction({ applyAction, selectedAction, comboId })}
            >
              Áp dụng
            </ButtonPrimary>
          </>
        )}
        {filterElements.map((_f, index) => (
          <FilterInput
            key={index}
            {..._f}
            onChange={onSelectFilter}
            value={params[_f.name]}
            minDate={params[_f.minDate]}
            maxDate={params[_f.maxDate]}
          />
        ))}

        <div className="flex-1"></div>
        <Label className="me-2">{total || 0} mục</Label>
        {!!total && !hidePaging && <Paginate page={page} total={total} itemPerPage={limit} onChange={onChangePage} />}
      </div>
      <ContentTable className="">
        <CustomTable
          selectable={selectable}
          columnTemplate={columnTemplate}
          rowSelected={selectedRow}
          onChecked={onSelectRow}
          data={data}
          headers={columns}
          order={order}
          loading={loading}
          rowHeight={83}
          onOrder={onOrder}
        />
      </ContentTable>
    </div>
  );
};

export default CustomJewelleryManagement;
