import React, { useCallback, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import defaultAvatar from 'assets/images/default-avatar.png';

//i18n
import { useUserInfo } from 'hook/useContextSelector';

// users
import produce from 'immer';

const ProfileMenu = () => {
  const [open, setOpen] = useState(false);
  const userInfo = useUserInfo();

  const toggle = useCallback(() => {
    setOpen(produce(draft => !draft));
  }, []);

  return (
    <Dropdown isOpen={open} toggle={toggle} className="d-inline-block user-dropdown">
      <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
        <img className="rounded-circle header-profile-user me-1" src={userInfo?.avatar || defaultAvatar} alt="" />
        <span className="d-none d-xl-inline-block ms-1 text-transform">{userInfo?.name ?? 'Admin'}</span>
        <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        {/* <DropdownItem href="#">
          <i className="ri-user-line align-middle me-1"></i> {'Profile'}
        </DropdownItem>
        <DropdownItem divider /> */}
        <DropdownItem className="text-danger" href="/logout">
          <i className="ri-shut-down-line align-middle me-1 text-danger"></i> {'Logout'}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
export default ProfileMenu;
