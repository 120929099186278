import { Authentication } from 'components/own';
import { AuthenticationTypes } from 'constants/authentication';
import { LOCAL_STORAGE__JWT } from 'constants/common';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, feature, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem(LOCAL_STORAGE__JWT)) {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
      }

      if (!isAuthProtected) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }

      return (
        <Layout>
          <Authentication feature={feature} type={AuthenticationTypes.VIEW}>
            <Component {...props} />
          </Authentication>
        </Layout>
      );
    }}
  />
);

export default AppRoute;
