import axios from 'axios';

import _get from 'lodash/get';

import { API_ENDPOINT, HTTP_CODE } from 'constants/common';
import { getUserToken, removeToken } from './localStorage';

function formatResponse(response) {
  return response.data;
}

export function handleDataError(error) {
  let message;

  if (error.response) {
    if (error.response.status === HTTP_CODE.MethodNotAllow) {
      message = 'Phương thức không được phép.';
    } else if (error.response.status === HTTP_CODE.Unauthorized) {
      removeToken();
      message = 'Unauthorized';
    } else if (error.response.status === HTTP_CODE.InternalServerError) {
      message = 'Internal Server Error';
    } else {
      message = _get(error.response, ['data', 'data', 0, 'message']) || _get(error.response, ['data', 'error']);
    }
  }

  return { message };
}

function handleBeforeCallApi() {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      const token = getUserToken();

      if (token) config.headers.Authorization = `Bearer ${token}`;

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
}

function handleAfterCallApi() {
  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      return formatResponse(response);
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      return Promise.reject(handleDataError(error));
    }
  );
}

export function setUpApi() {
  axios.defaults.baseURL = API_ENDPOINT;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  handleBeforeCallApi();

  handleAfterCallApi();
}
