import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';
import { Button, CardBody, Input, Label, Row, UncontrolledCollapse } from 'reactstrap';
import { Col, Container } from 'reactstrap';
import styles from './Redirect301.module.scss';

import { DropDownIcon } from 'components/icons';
import { CellControlType } from 'constants/table';
import Select from 'react-select';
import { ButtonPrimary, CustomTable, Paginate } from 'components/own';
import { AuthenticationFeature } from 'constants/authentication';
import { useHistory } from 'react-router-dom';
import produce from 'immer';
import { createLinkRedirect, getAllLinkRedirect, updateLinkRedirect } from 'services/SEO';
import { useActionNotification } from 'hook/useContextSelector';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;
const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
`;

const SelectAction = styled(Select)`
  cursor: pointer;
  width: 100px;
  margin-right: 4px;
  font-size: 14px;
  .select2-selection__indicators {
    padding-right: 4px;
  }
`;
const LabelCustom = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  display: flex;
  align-items: center;

  /* Dark / Dark 0 */

  color: #1c1c28;
`;
const ContentTable = styled.div`
  background-color: #fff;
  margin-top: 16px;
`;

const columns = [
  {
    dataField: 'from',
    text: 'From',
    sort: true,
    controlType: CellControlType.TextNonAction,
    linkPreview: '',
  },
  {
    dataField: 'to',
    text: 'To',
    sort: true,
    controlType: CellControlType.Text,
  },
  {
    dataField: 'lastAccessed',
    text: 'Last Accessed',
    sort: true,
    controlType: CellControlType.DateTime,
  },
];

const optionActions = [
  { label: 'Mustard', value: 'Mustard' },
  { label: 'Ketchup', value: 'Ketchup' },
  { label: 'Relish', value: 'Relish' },
];
// const ecommerceUrl = E_COMMERCE_URL;
const initialState = {
  page: 1,
  total: 0,
  columns,
  data: [],
  searchValue: '',
  selectedAction: null,
  limit: 10,
  loading: true,
  redirectData: {
    id: 0,
    from: '',
    to: '',
  },
};

const Redirect301 = () => {
  const [{ columns, data, loading, limit, page, searchValue, selectedAction, total, redirectData }, setState] = useState(initialState);
  const router = useHistory();
  const pushNotification = useActionNotification();
  const columnTemplate = useMemo(() => '2fr 2fr 2fr', []);
  const ref = useRef();

  useEffect(() => {
    const callAPI = async () => {
      //   let query = 'limit=' + limit;
      //   if (page) query += '&page=' + page;
      //   if (searchValue) query += '&name=' + searchValue;
      try {
        const res = await getAllLinkRedirect('');
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = res.data || [];
            draft.total = res.data?.length || 0;
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = [];
            draft.total = 0;
          })
        );
      }
    };
    callAPI();
  }, [limit, page, searchValue]);

  const handleSelectGroup = useCallback(item => {
    setState(
      produce(draft => {
        draft.selectedAction = item;
      })
    );
  }, []);

  const onChangePage = useCallback(({ selected }) => {
    setState(
      produce(draft => {
        draft.page = selected + 1;
      })
    );
  }, []);
  const changeItem = useCallback(_d => {
    console.log(_d);
    setState(
      produce(draft => {
        draft.redirectData = _d;
      })
    );
  }, []);

  const changeLinkRedirect = useCallback(() => {
    let id = document.getElementById('linkId').value;
    let blogsDetail = {
      from: document.getElementById('from').value,
      to: document.getElementById('to').value,
    };

    if (blogsDetail.title === '' || blogsDetail.slug === '' || blogsDetail.body === '') {
      pushNotification('warning', 'Vui lòng nhập đầy đủ thông tin');
    } else {
      if (id === null || id === '' || id === '0') {
        createLinkRedirect(blogsDetail)
          .then(res => {
            pushNotification('success', 'Lưu thành công');
            router.replace('/redirect-301');
          })
          .catch(error => {
            console.log(JSON.stringify(error));
            pushNotification('error', error.message);
          });
      } else {
        updateLinkRedirect(id, blogsDetail)
          .then(res => {
            pushNotification('success', 'Update thành công');
            document.getElementById('linkId').value = 0;
            router.replace('/redirect-301');
          })
          .catch(error => {
            pushNotification('error', error.error);
          });
      }
    }
  }, [pushNotification, router]);

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Cấu hình SEO Redirect 301</Title>
      </div>
      <div className={`page-content ${styles['blogs']} `}>
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">Thông tin Redirect 301</BlockHeader>
                <CardBody className={`${styles['blogs__content']}`} defaultOpen>
                  <Row className="mb-3">
                    <Label htmlFor="blogsTitle" className="col-md-2 col-form-label">
                      From
                    </Label>
                    <Col md={10}>
                      <Input type="hidden" name="linkId" id="linkId" defaultValue={redirectData.id} />
                      <Input type="text" name="from" placeholder="Nhập link" id="from" defaultValue={redirectData.from} />
                      <span className={`${styles['blogs__span-info']}`}>Nhập link</span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label htmlFor="pagesTitle" className="col-md-2 col-form-label">
                      To
                    </Label>
                    <Col md={10}>
                      <Input type="text" name="to" placeholder={'Nhập link đích'} id="to" defaultValue={redirectData.to} />
                      <span className={`${styles['blogs__span-info']}`}>Nhập link đích</span>
                    </Col>
                  </Row>
                  <Row className={`${styles['blogs__submit--btn']} ${styles['blogs__submit--btn-bottom']}`}>
                    <Col xl={2}>
                      <Button color="light" className="waves-effect me-1">
                        <i className="ri-close-line align-middle me-2"></i>Hủy
                      </Button>
                    </Col>
                    <Col xl={2}>
                      <Button color="success" onClick={changeLinkRedirect} className="waves-effect waves-light me-1">
                        <i className="ri-check-line align-middle me-2"></i>Lưu
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Block>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <div className="d-flex align-items-center mt-2">
                <SelectAction
                  placeholder="Tác vụ"
                  value={selectedAction}
                  onChange={handleSelectGroup}
                  options={optionActions}
                  classNamePrefix="select2-selection"
                  components={{
                    DropdownIndicator: DropDownIcon,
                    IndicatorSeparator: null,
                  }}
                />
                <ButtonPrimary fontWeight={500} className="me-2">
                  Áp dụng
                </ButtonPrimary>

                <div className="flex-1"></div>
                <LabelCustom className="me-2">{total} mục</LabelCustom>
                <Paginate page={page} total={total} itemPerPage={limit} onChange={onChangePage} />
              </div>
              <ContentTable className="">
                <CustomTable
                  selectable
                  columnTemplate={columnTemplate}
                  data={data}
                  headers={columns}
                  loading={loading}
                  changeItem={changeItem}
                  rowHeight={83}
                  module={AuthenticationFeature.USER}
                />
              </ContentTable>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Redirect301;
