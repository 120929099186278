import { memo, useCallback, useMemo } from 'react';

import Text from 'components/own/Text';
import { includes, get } from 'lodash';
import { parsePrice, propsAreEqual } from 'utils/uti';

const CellLink = ({ editMode, dataField, data, keyId = 'id', custom, render, link, lineClamp = 3, dataset, state, ...rest }) => {
  const value = useMemo(() => {
    const paths = dataField.split(' ?? ');
    let _v = get(data, paths[0]);
    if (_v === undefined && !!paths[1]) {
      _v = get(data, paths[1]);
    }
    if (render) return render(_v, data);
    switch (custom) {
      case 'price':
        if (includes(_v, '%')) return _v;
        return _v ? (parseInt(_v) ? parsePrice(_v) + ' đ' : _v) : 'Giá liên hệ';
      case 'array':
        return _v?.join(', ');
      case 'index': {
        const index = dataset.findIndex(d => d.id === data.id);
        return index !== -1 ? index + 1 : _v;
      }
      default:
        return _v;
    }
  }, [custom, data, dataField, render, dataset]);

  const onClick = useCallback(() => {
    window.open(value, '_blank');
  }, [value]);

  return (
    <Text {...rest} variant="clamped" lineClamp={lineClamp} onClick={onClick} cursor={'pointer'}>
      {value}
    </Text>
  );
};

export default memo(CellLink, propsAreEqual);
