function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff)).toLocaleDateString('fr-CA');
}

function getFirstDay() {
  const d = new Date();
  return new Date(d.setDate(1)).toLocaleDateString('fr-CA');
}

export const ByDateOptions = [
  { label: 'Tất cả các ngày', value: null },
  { label: 'Theo ngày', value: new Date().toLocaleDateString('fr-CA') },
  { label: 'Theo tuần', value: getMonday(new Date()) },
  { label: 'Theo tháng', value: getFirstDay() },
];

export const CustomerAccTypeOptions = [
  { label: 'Lọc theo tài khoản khách hàng', value: null },
  { label: 'Welcome', value: 1 },
  { label: 'Standard', value: 2 },
  { label: 'Deluxe', value: 3 },
  { label: 'President', value: 4 },
];

export const StatusOptions = [
  { label: 'Áp dụng', value: 1 },
  { label: 'Không áp dụng', value: 2 },
  { label: 'Xóa', value: -1 },
];

export const JewellTypeOptions = [
  { label: 'Loại sản phẩm', value: '' },
  { label: 'Đơn', value: 1 },
  { label: 'Đôi', value: 3 },
  { label: 'Kèm kim cương', value: 2 },
];

export const JewellSerialTypeOptions = [
  { label: 'Loại sản phẩm', value: '' },
  { label: 'Đơn', value: 1 },
  { label: 'Kèm kim cương', value: 2 },
];
export const JewellStockTypeOptions = [
  { label: 'Theo kho', value: '' },
  { label: 'Kho thật', value: 1 },
  { label: 'Kho ảo', value: 2 },
];

export const StockStatusOptions = [
  { label: 'Trạng thái kho', value: '' },
  { label: 'Còn hàng', value: 1 },
  { label: 'Hết hàng', value: 0 },
];

export const DraftStatusOptions = [
  { label: 'Trạng thái', value: '' },
  { label: 'Đang hiển thị', value: 1 },
  { label: 'Nháp', value: 0 },
];

export const BlogStatusOptions = [
  { label: 'Trạng thái', value: '' },
  { label: 'Đã xuất bản', value: 2 },
  { label: 'Nháp', value: 1 },
];

export const ShowHideOptions = [
  {
    label: 'Trạng thái',
    value: '',
  },
  {
    label: 'Đang hiển thị',
    value: 1,
  },
  {
    label: 'Không hiển thị',
    value: -1,
  },
];

export const OrderOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
];

export const GenderOptions = [
  {
    label: 'Chọn giới tính',
    value: '',
  },
  {
    label: 'Nam',
    value: 1,
  },
  {
    label: 'Nữ',
    value: 2,
  },
];

export const LuxuryTemplateOptions = [
  {
    label: 'Template 1 (Banner)',
    value: 1,
  },
  {
    label: 'Template 2 (Sản phẩm)',
    value: 2,
  },
  {
    label: 'Template 3 (Banner + Text)',
    value: 3,
  },
  {
    label: 'Template 4  (Chi tiết sản phẩm)',
    value: 4,
  },
  {
    label: 'Template 5 (Banner + Text bên trong)',
    value: 5,
  },
  {
    label: 'Template 6 (Share)',
    value: 6,
  },
  {
    label: 'Template 7 (Sản phẩm khác)',
    value: 7,
  },
];

export const LuxuryBlockOptions = [
  {
    label: 'Option 1 (Left + Bottom)',
    value: 1,
  },
  {
    label: 'Option 2 (Right)',
    value: 2,
  },
  {
    label: 'Option 3 (Left + Center)',
    value: 3,
  },
  {
    label: 'Option 4  (Right + Center)',
    value: 4,
  },
  {
    label: 'Option 5 (2 side)',
    value: 5,
  },
];
export const ProductOptionBanner = [
  { label: 'Option 1', value: 1 },
  { label: 'Option 2', value: 2 },
  { label: 'Option 3', value: 3 },
];
export const MenuTemplateOptions = [
  { label: 'Option 1 (2 hình)', value: 1 },
  { label: 'Option 2 (4 cột + 1 hình)', value: 2 },
  { label: 'Option 3 (2 cột + 1 hình)', value: 3 },
];
