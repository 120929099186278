import { memo } from 'react';

import Text from 'components/own/Text';

import { parseDate, parseTime, propsAreEqual } from 'utils/uti';
import styled from 'styled-components';

const Root = styled.div``;

const CellDateTime = ({ editMode, dataField, data, ...rest }) => {
  return (
    <Root>
      <Text {...rest}>{data[dataField] ? parseDate(data[dataField]) : ''}</Text>
      <Text {...rest}>{data[dataField] ? parseTime(data[dataField]) : ''}</Text>
    </Root>
  );
};

export default memo(CellDateTime, propsAreEqual);
