import { AuthenticationFeature } from 'constants/authentication';
import { CellControlType } from 'constants/table';

export const getTagsInfo = () => ({
  buttonAddTitle: 'Thêm thẻ',
  feature: AuthenticationFeature.BLOG,
  columns: [
    {
      dataField: 'title',
      text: 'Danh sách thẻ',
      controlType: CellControlType.TextWithQuickEditDelete,
      detailUrl: 'tags',
      orderKey: 'title',
      deleteUrl: 'tags',
      editable: true,
    },
    {
      dataField: 'desc',
      text: 'Mô tả',
    },
    {
      dataField: 'link',
      text: 'Link',
      editable: true,
    },
  ],
  columnTemplate: '1fr 1fr 1fr',
  filterElements: [],
  editConfig: {
    url: 'tags',
    fields: [
      { label: 'Tên thẻ', name: 'title' },
      { label: 'Mô tả', name: 'desc' },
    ],
  },
  selectable: true,
  title: 'Thẻ',
  url: 'tags',
});
