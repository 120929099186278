import { useCallback, useMemo } from 'react';

import Text from 'components/own/Text';
import { includes, get } from 'lodash';
import { parsePrice } from 'utils/uti';
import { useHistory } from 'react-router';
import Authentication from 'components/own/Authentication';
import { AuthenticationTypes } from 'constants/authentication';
import { CopyIcon } from 'components/icons';

const CellTextCopy = ({ editMode, dataField, data, feature, keyId = 'id', custom, render, link, lineClamp = 3, dataset, state, ...rest }) => {
  const history = useHistory();
  const value = useMemo(() => {
    const paths = dataField.split(' ?? ');
    let _v = get(data, paths[0]);
    if (_v === undefined && !!paths[1]) {
      _v = get(data, paths[1]);
    }
    if (render) return render(_v, data);
    switch (custom) {
      case 'price':
        if (includes(_v, '%')) return _v;
        // eslint-disable-next-line eqeqeq
        return _v && _v != 0 ? (parseInt(_v) ? parsePrice(_v) + ' đ' : _v) : 'Giá liên hệ';
      case 'array':
        return _v?.join(', ');
      case 'index': {
        const index = dataset.findIndex(d => d.id === data.id);
        return index !== -1 ? index + 1 : _v;
      }
      default:
        return _v;
    }
  }, [custom, data, dataField, render, dataset]);

  const cursor = useMemo(() => (!!link ? 'pointer' : ''), [link]);

  const onClick = useCallback(() => {
    if (link) {
      history.push(link + '/' + get(data, keyId, ''), state);
    }
  }, [data, history, keyId, link, state]);

  if (!link) {
    return (
      <div className="d-flex align-items-center">
        <Text {...rest} variant="clamped" lineClamp={lineClamp}>
          {value}
        </Text>
        <div
          role={'button'}
          onClick={() => {
            navigator.clipboard.writeText(value);
          }}
        >
          <CopyIcon />
        </div>
      </div>
    );
  }

  return (
    <Authentication feature={feature} type={AuthenticationTypes.VIEW} title={value}>
      <Text {...rest} variant="clamped" lineClamp={lineClamp} onClick={onClick} cursor={cursor}>
        {value}
      </Text>
    </Authentication>
  );
};

export default CellTextCopy;
