import produce from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import axios from 'axios';
import { getInfoManagementByUrl } from 'components/own/Management/modules';
import { parseUrlQuery } from 'utils/uti';
import { useActionNotification } from 'hook/useContextSelector';
import { get } from 'lodash';

const initialState = {
  page: 1,
  total: 0,
  data: [],
  searchValue: '',
  selectedAction: null,
  selectedRow: [],
  forceRender: 0,

  limit: 10,
  loading: true,
  hidePaging: false,
  order: { order: '', orderBy: '' },
  params: {},
};

export const useJewelleryHook = url => {
  const history = useHistory();
  const location = useLocation();
  const pushNotification = useActionNotification();
  const pathname = url || location.pathname;
  const ref = useRef();
  const [state, setState] = useState(initialState);
  const _apiUrl = useMemo(() => {
    switch (pathname) {
      case '/jewellery-new':
        return 'jewellery/new';

      default:
        return pathname;
    }
  }, [pathname]);

  const apiUrl = url || _apiUrl;
  const info = useMemo(() => getInfoManagementByUrl(pathname), [pathname]);
  const keyword = useMemo(() => info.keyword || 'keyword', [info.keyword]);

  useEffect(() => {
    const callAPI = async () => {
      let query = parseUrlQuery(state.params, state.order, 'limit=' + state.limit);
      if (state.page) query += '&page=' + state.page;
      if (state.searchValue) query += `&${keyword}=` + state.searchValue;
      try {
        const res = await axios.get(apiUrl + '?' + query);
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = res.data.list || res.data.rows || res.data || [];
            draft.total = typeof res.data.count === 'number' ? res.data.count : draft.data.length;
            draft.hidePaging = !res.data.count;
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = [];
            draft.total = 0;
            draft.hidePaging = true;
          })
        );
      }
    };
    if (apiUrl) callAPI();
  }, [apiUrl, keyword, state.limit, state.order, state.page, state.params, state.searchValue, state.forceRender]);

  const onChangePage = useCallback(({ selected }) => {
    setState(
      produce(draft => {
        draft.page = selected + 1;
        draft.loading = true;
      })
    );
  }, []);

  const onClickFilter = useCallback(() => {
    setState(
      produce(draft => {
        if (ref.current) {
          draft.searchValue = ref.current.value;
          draft.loading = true;
          draft.page = 1;
        }
      })
    );
  }, []);

  const onAddNew = useCallback(() => {
    history.push(pathname + '/new');
  }, [history, pathname]);

  const onSelectFilter = useCallback(e => {
    setState(
      produce(draft => {
        draft.params[e.target.name] = e.target.value;
        draft.loading = true;
        draft.page = 1;
      })
    );
  }, []);

  const onKeypressFilter = useCallback(
    e => {
      if (e.which === 13) onClickFilter();
    },
    [onClickFilter]
  );

  const onOrder = useCallback((orderBy, order) => {
    setState(
      produce(draft => {
        if (order) draft.order = { order, orderBy };
        else draft.order = null;
      })
    );
  }, []);

  const handleSelectGroup = useCallback(val => {
    setState(
      produce(draft => {
        draft.selectedAction = val;
      })
    );
  }, []);

  const onSelectRow = useCallback((id, isCheck) => {
    setState(
      produce(draft => {
        if (isCheck) draft.selectedRow.push(id);
        else draft.selectedRow = draft.selectedRow.filter(_r => _r !== id);
      })
    );
  }, []);

  const applyAction = useCallback(
    async (actionAPI, comboId) => {
      if (!state.selectedRow.length || !state.selectedAction) return;
      let isSuccess = true;
      for (const id of state.selectedRow) {
        let shouldBreak = false;
        try {
          await actionAPI(`combo/${comboId}/${id}`);
        } catch (err) {
          pushNotification('error', get(err, 'message', err));
          shouldBreak = true;
        }
        if (shouldBreak) {
          isSuccess = false;
          break;
        }
      }
      isSuccess && pushNotification('success', 'Thay đổi thành công');
      setState(
        produce(draft => {
          draft.selectedRow = [];
          draft.forceRender += 1;
        })
      );
    },
    [pushNotification, state.selectedAction, state.selectedRow]
  );
  const clearSelectedRow = useCallback(() => {
    setState(
      produce(draft => {
        draft.selectedRow = [];
      })
    );
  }, []);

  return {
    ...info,
    ...state,
    ref,
    applyAction,
    handleSelectGroup,
    onAddNew,
    onChangePage,
    onClickFilter,
    onKeypressFilter,
    onSelectRow,
    onOrder,
    onSelectFilter,
    clearSelectedRow,
  };
};
