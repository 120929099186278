import { Component, useCallback, useEffect, useState } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { cloneDeep, differenceWith, isEqual, filter, find, findIndex, get, map, orderBy } from 'lodash';

import { deleteAPI, getAPI, postAPI, putAPI, uploadImage } from 'services/common';
import { Input, Label, Row } from 'reactstrap';
import {
  SortableTreeWithoutDndContext as SortableTree,
  changeNodeAtPath,
  getFlatDataFromTree,
  removeNodeAtPath,
  map as _mapTree,
  insertNode,
} from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import NodeMenuRenderer from './Component/NodeContent';
import { getChangedData, getTreeFromFlatData, moveItemInArray } from 'utils/uti';
import { MenuOrderType } from 'constants/common';
import { LoadingIcon, PlusCircleIcon } from 'components/icons';
import { useActionNotification } from 'hook/useContextSelector';
import { ActionsItem, Authentication, FieldInput } from 'components/own';
import { AuthenticationFeature, AuthenticationTypes } from 'constants/authentication';
import { omit } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragSource } from 'react-dnd';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const SubTitle = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  color: #1f4173;
`;

const Content = styled.div`
  display: grid;

  grid-template-columns: 350px 1fr;
  grid-gap: 10px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Page = styled.div`
  background-color: #fff;
  width: 100%;
`;

const PageMenu = styled.div`
  background-color: #fff;
  width: 350px;
  position: sticky;
  top: 0;
`;

const PageHeader = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  /* Primary */

  color: #1f4173;

  border-bottom: 1px solid #e4e4eb;

  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

const CategoryContent = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 12px 16px;
`;

const LineBreak = styled.div`
  border-bottom: 1px solid #e4e4eb;
`;

const AddButton = styled.div`
  width: 318px;
  height: 44px;
  left: 16px;
  top: 16px;

  /* Primary/Main Color */

  background: ${({ $disabled }) => ($disabled ? '#bdbdbd' : '#007770')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : '')};
  border-radius: 4px;

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  /* or 18px */

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const externalNodeType = 'menuNode';

const externalNodeSpec = {
  beginDrag: componentProps => {
    return { node: { ...componentProps.node } };
  },
};
const externalNodeCollect = (connect /* , monitor */) => ({
  connectDragSource: connect.dragSource(),
});

class ExternalNodeBaseComponent extends Component {
  render() {
    const { connectDragSource, disabled } = this.props;

    return connectDragSource(
      <div>
        <Authentication feature={AuthenticationFeature.INFO} type={AuthenticationTypes.EDIT}>
          <AddButton $disabled={disabled}>
            <PlusCircleIcon /> <div className="me-1" /> THÊM VÀO MENU
          </AddButton>
        </Authentication>
      </div>,
      { dropEffect: 'copy' }
    );
  }
}
const ExternalDragButton = DragSource(externalNodeType, externalNodeSpec, externalNodeCollect)(ExternalNodeBaseComponent);

const initialState = { menu: [], loading: true, categories: [], extendIds: {}, origin: [] };
const initialNode = {
  link: '',
  meta: {},
  name: '',
  status: 1,
  parentId: null,
  type: 1,
  buttonText: '',
  order: 1,
  mediafile: {},
};

const SettingMenu = () => {
  const [{ categories, loading, menu, extendIds, origin }, setState] = useState(initialState);
  const [nodeLink, setNodeLink] = useState({ link: '', name: '' });
  const [touched, setTouched] = useState({});
  const pushNotification = useActionNotification();
  useEffect(() => {
    Promise.all([getAPI('menu'), getAPI('categories')]).then(res => {
      setState(
        produce(draft => {
          const treeData = orderBy(
            getTreeFromFlatData({
              flatData: get(res, [0, 'data'], []).map(_d => ({ ..._d, expanded: true })), //
              getKey: item => item.id,
              getParentKey: item => item.parentId,
              rootKey: null,
            }),
            ['order'],
            ['asc']
          );
          draft.loading = false;
          draft.menu = treeData;
          draft.categories = get(res, [1, 'data']);
          draft.origin = orderBy(get(res, [0, 'data'], []), ['id']);
        })
      );
    });
  }, []);

  const onRefresh = useCallback(() => {
    getAPI('menu').then(res => {
      setState(
        produce(draft => {
          const treeData = orderBy(
            getTreeFromFlatData({
              flatData: get(res, ['data'], []).map(_d => ({ ..._d, expanded: true })), //
              getKey: item => item.id,
              getParentKey: item => item.parentId,
              rootKey: null,
            }),
            ['order'],
            ['asc']
          );
          draft.menu = treeData;
          draft.loading = false;
          draft.origin = orderBy(get(res, ['data'], []), ['id']);
        })
      );
    });
  }, []);

  const onMoveNode = useCallback(
    (node, type, parentData) => {
      // let request = [];
      const children = !node.parentId ? cloneDeep(menu) : cloneDeep(parentData?.children || []);
      const currentIndex = findIndex(children, ['id', node.id]);
      const scroll = () => {
        const ele = document.getElementById('node_' + node.id);
        if (ele) ele.click();
      };
      switch (type) {
        case MenuOrderType.MOVE_UP:
          if (currentIndex <= 0) break;

          moveItemInArray(children, currentIndex, currentIndex - 1);
          break;

        case MenuOrderType.MOVE_DOWN:
          if (currentIndex === children.length - 1) break;

          moveItemInArray(children, currentIndex, currentIndex + 1);
          break;

        case MenuOrderType.MOVE_TOP:
          if (currentIndex <= 0) break;

          moveItemInArray(children, currentIndex, 0);
          break;

        case MenuOrderType.MOVE_BOTTOM:
          if (currentIndex === children.length - 1) break;

          moveItemInArray(children, currentIndex, children.length - 1);
          break;

        case MenuOrderType.MOVE_OUTSIDE:
          const flatData = cloneDeep(map(getFlatDataFromTree({ treeData: menu, getNodeKey: node => node.treeIndex }), 'node'));
          const item = find(flatData, ['id', node.id]);
          item.parentId = parentData.parentId || null;
          const totalNodeSameParent = !parentData.parentId ? menu.length : find(menu, ['id', parentData.parentId])?.children?.length;
          item.order = totalNodeSameParent;
          const treeData = orderBy(
            getTreeFromFlatData({
              flatData, //
              getKey: item => item.id,
              getParentKey: item => item.parentId,
              rootKey: null,
            }),
            ['order'],
            ['asc']
          );
          setState(
            produce(draft => {
              draft.menu = treeData;
            })
          );
          setTimeout(() => {
            scroll();
          });
          return;
        default:
          break;
      }

      // if (!request.length) return;
      if (!node.parentId) {
        setState(
          produce(draft => {
            draft.menu = children.map((_c, i) => ({ ..._c, order: i + 1 }));
          })
        );
      } else if (!parentData.parentId) {
        setState(
          produce(draft => {
            const parent = find(draft.menu, ['id', parentData.id]);
            if (parent) parent.children = children.map((_c, i) => ({ ..._c, order: i + 1 }));
          })
        );
      } else
        setState(
          produce(draft => {
            const parent = find(draft.menu, ['id', parentData.parentId]);
            if (parent) {
              const item = find(parent.children, ['id', parentData.id]);
              if (item) item.children = children.map((_c, i) => ({ ..._c, order: i + 1 }));
            }
          })
        );
      setTimeout(() => {
        scroll();
      });
    },
    [menu]
  );

  const onChange = useCallback((path, newNode) => {
    setState(
      produce(draft => {
        draft.menu = changeNodeAtPath({ treeData: draft.menu, path, newNode, getNodeKey: item => item.treeIndex });
      })
    );
  }, []);

  const onDelete = useCallback(
    (path, id) => {
      deleteAPI('menu/' + id).then(res => {
        pushNotification('success', 'Xóa thành công');
        setState(
          produce(draft => {
            draft.menu = removeNodeAtPath({ treeData: draft.menu, path, getNodeKey: item => item.treeIndex });
            draft.origin = filter(draft.origin, _o => _o.id !== id);
          })
        );
      });
    },
    [pushNotification]
  );

  const onChangeTree = useCallback(
    treeData => {
      const newItem = [];
      const createReq = [];
      treeData = _mapTree({
        treeData,
        getNodeKey: node => node.treeIndex,
        callback: d => {
          if (!d.parentNode) {
            d.node.order = findIndex(treeData, _d => isEqual(_d, d.node)) + 1;
          } else {
            d.node.order = findIndex(d.parentNode.children, _d => isEqual(_d, d.node)) + 1;
          }
          if (!d.node?.id && d?.treeIndex !== -1 && !d.node?.categories) {
            const req = Object.assign(cloneDeep(initialNode), { ...d.node, parentId: d.parentNode?.id || null });
            req.link = req.link || '#';
            newItem.push({ ...req, path: d.path });
            createReq.push(postAPI('menu', req));
            return req;
          }

          return d.node;
        },
      });
      if (!!createReq.length)
        Promise.all(createReq).then(response => {
          for (const key in response) {
            if (Object.hasOwnProperty.call(response, key)) {
              const element = response[key];
              treeData = changeNodeAtPath({ treeData, path: newItem[key].path, newNode: element.data, getNodeKey: item => item.treeIndex });
            }
          }
          pushNotification('success', 'Tạo thành công');
          setState(
            produce(draft => {
              draft.menu = treeData;
              draft.origin.push(...newItem);
              draft.categories.forEach(_t => {
                if (findIndex(draft.origin, ['link', _t.link]) > -1) _t.checked = false;
                return _t;
              });
            })
          );
        });
      else
        setState(
          produce(draft => {
            draft.menu = treeData;
            draft.origin.push(...newItem);
          })
        );
    },
    [pushNotification]
  );

  const onSaveItem = useCallback(
    async values => {
      const item = find(origin, ['id', values.id]);
      if (!item) return;

      const req = getChangedData(values, item);
      delete req.expanded;
      delete req.children;
      if (!Object.keys(req).length) return;
      if (!!req.mediafile && !!req.mediafile.image && typeof req.mediafile.image !== 'string') {
        const res = await uploadImage(req.mediafile.image);
        req.mediafile.image = res?.url;
      }
      if (values.isNew) {
        postAPI('menu', omit({ ...values, ...req }), ['id', 'isNew']).then(res => {
          pushNotification('success', 'Lưu thành công');
          setState(
            produce(draft => {
              const index = findIndex(draft.origin, ['id', values.id]);

              draft.origin[index] = res.data;
            })
          );
        });
      } else
        putAPI('menu/' + values.id, req).then(res => {
          pushNotification('success', 'Lưu thành công');
          setState(
            produce(draft => {
              const index = findIndex(draft.origin, ['id', values.id]);

              draft.origin[index] = res.data;
            })
          );
        });
    },
    [origin, pushNotification]
  );

  const onSave = useCallback(async () => {
    setState(
      produce(draft => {
        draft.loading = true;
      })
    );
    const flatData = cloneDeep(orderBy(map(getFlatDataFromTree({ treeData: menu, getNodeKey: node => node.treeIndex }), 'node'), ['id'])).map(
      _c => omit(_c, ['children', 'expanded'])
    );
    const changedData = differenceWith(flatData, origin, isEqual);
    const request = [];
    for (const iterator of changedData) {
      if (!!iterator.mediafile && !!iterator.mediafile.image && typeof iterator.mediafile.image !== 'string') {
        const res = await uploadImage(iterator.mediafile.image);
        iterator.mediafile.image = res?.url;
      } else if (!!iterator.mediafile && !iterator.mediafile.image) {
        delete iterator.mediafile.image;
      }
      const changed = getChangedData(iterator, find(origin, ['id', iterator.id]));
      if (!!Object.keys(changed).length) request.push(putAPI('menu/' + iterator.id, changed));
    }

    if (!request.length) {
      setState(
        produce(draft => {
          draft.loading = false;
        })
      );
      return;
    }
    Promise.all(request).then(res => {
      pushNotification('success', 'Lưu thành công');
      onRefresh();
    });
  }, [menu, onRefresh, origin, pushNotification]);
  // if (true) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Menu</Title>
      </div>
      <DndProvider backend={HTML5Backend}>
        <Content>
          <div>
            <PageMenu>
              <PageHeader>Danh mục</PageHeader>
              <CategoryContent>
                {categories.map((_cat, i) => (
                  <div className="form-check" key={i}>
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value={_cat}
                      id={_cat.name}
                      checked={!!_cat.checked}
                      onChange={() =>
                        setState(
                          produce(draft => {
                            draft.categories[i].checked = !draft.categories[i].checked;
                          })
                        )
                      }
                    />
                    <Label className="form-check-label" htmlFor={_cat.name}>
                      {_cat.name}
                    </Label>
                  </div>
                ))}
                <ExternalDragButton node={{ categories: true }} disabled={!categories.filter(_c => _c.checked).length} />
                <LineBreak />
                <SubTitle>Liên kết tự tạo</SubTitle>
                <Row>
                  <FieldInput
                    name="name"
                    value={nodeLink.name}
                    label="Nội dung"
                    onChange={e =>
                      setNodeLink(
                        produce(draft => {
                          draft[e.target.name] = e.target.value;
                        })
                      )
                    }
                  />
                </Row>
                <Row>
                  <FieldInput
                    name="link"
                    value={nodeLink.link}
                    label="Link"
                    error={
                      !touched.link
                        ? ''
                        : !nodeLink.link
                        ? 'Đường dẫn là trường bắt buộc'
                        : findIndex(categories, ['link', nodeLink.link]) > -1
                        ? 'Đường dẫn bị trùng'
                        : ''
                    }
                    onBlur={() =>
                      setTouched(
                        produce(draft => {
                          draft.link = true;
                        })
                      )
                    }
                    onChange={e =>
                      setNodeLink(
                        produce(draft => {
                          draft[e.target.name] = e.target.value;
                        })
                      )
                    }
                  />
                </Row>
                <ExternalDragButton node={nodeLink} disabled={!nodeLink.link || !nodeLink.name} />
              </CategoryContent>
            </PageMenu>
          </div>
          <Page>
            <PageHeader>Menu</PageHeader>
            <div className="p-2">
              <div>Kéo các mục tới vị trí bạn mong muốn. Nhấp chuột vào mũi tên bên phải để thiết lập tuỳ chỉnh cho mỗi mục.</div>
            </div>
            <SortableTree
              treeData={menu}
              maxDepth={3}
              generateNodeProps={data => {
                return {
                  ...data,
                  origin: find(origin, ['id', data?.node?.id]),
                  isExtend: extendIds[data?.node?.id],
                  categories,
                  onMoveNode,
                  onRefresh,
                  onChange,
                  onDelete,
                  onSave: onSaveItem,
                  updateNode: (id, reset) => {
                    setState(
                      produce(draft => {
                        if (draft.extendIds[id] || reset) delete draft.extendIds[id];
                        else draft.extendIds[id] = true;
                      })
                    );
                  },
                };
              }}
              rowHeight={info => {
                if (!extendIds[info.node?.id]) return 62;
                if (!info.node?.parentId) {
                  if (info?.node?.type === 1) {
                    return 375;
                  }

                  return 485;
                }
                const parent = find(menu, ['id', info.node?.parentId]);
                if (parent?.type === 1) {
                  return 505;
                }

                return 320;
              }}
              onMoveNode={({ node, treeData, treeIndex, path }) => {
                if (node.categories) {
                  const catSelected = categories.filter(_c => _c.checked);
                  for (let index = 0; index < catSelected.length; index++) {
                    const element = catSelected[index];
                    const newNode = {
                      name: element.name,
                      link: element.link,
                    };
                    if (index === 0) {
                      treeData = changeNodeAtPath({ treeData, path, newNode, getNodeKey: item => item.treeIndex });
                    } else {
                      const t = insertNode({
                        getNodeKey: node => node.treeIndex,
                        treeData,
                        newNode,
                        depth: path.length - 1,
                        minimumTreeIndex: treeIndex + index,
                      });
                      treeData = t.treeData;
                    }
                  }
                  onChangeTree(treeData);
                }
              }}
              onChange={onChangeTree}
              isVirtualized={false}
              nodeContentRenderer={NodeMenuRenderer}
              dndType={externalNodeType}
            />
            {loading && (
              <LoadingContainer>
                <LoadingIcon />
              </LoadingContainer>
            )}
            <div className="d-flex align-item-center justify-content-end p-3">
              <ActionsItem onSave={onSave} feature={AuthenticationFeature.INFO}>
                <div className="flex-1"></div>
              </ActionsItem>
            </div>
          </Page>
        </Content>
      </DndProvider>
    </div>
  );
};

export default SettingMenu;
