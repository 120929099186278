import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { ByDateOptions } from 'constants/options';
import { OrderStatusOptions } from 'constants/orders';
import { CellControlType } from 'constants/table';

export const getOrderState = (value, type) => {
  switch (value) {
    case -1:
      if (type === 'render') return 'Đơn hủy';
      if (type === 'background') return 'rgba(232, 0, 0, 0.1)';
      if (type === 'color') return '#E80000';
      break;
    case 1:
      if (type === 'render') return 'Mới';
      if (type === 'background') return 'rgba(85, 87, 112, 0.1)';
      if (type === 'color') return '#555770';
      break;
    case 2:
      if (type === 'render') return 'Chờ thanh toán';
      if (type === 'background') return 'rgba(85, 87, 112, 0.1)';
      if (type === 'color') return '#555770';
      break;
    case 3:
      if (type === 'render') return 'Thanh toán thành công';
      if (type === 'background') return 'rgba(29, 186, 186,0.1)';
      if (type === 'color') return '#1DBABA';
      break;
    case 4:
      if (type === 'render') return 'Đang xử lý';
      if (type === 'background') return 'rgba(6, 194, 112, 0.1)';
      if (type === 'color') return '#06C270';
      break;
    case 5:
      if (type === 'render') return 'Giao/giữ hàng';
      if (type === 'background') return 'rgba(253, 172, 66, 0.1)';
      if (type === 'color') return '#FDAC42';
      break;
    case 6:
      if (type === 'render') return 'Hoàn thành';
      if (type === 'background') return 'rgba(0, 119, 112, 0.1)';
      if (type === 'color') return '#007770';
      break;
    default:
      break;
  }
};

export const getOrderInfo = () => ({
  buttonAddTitle: '',
  feature: AuthenticationFeature.ORDER,
  columns: [
    {
      dataField: 'code',
      text: 'Mã đơn hàng',
      color: '#007770',
      detailUrl: 'orders',
    },
    {
      dataField: 'recieverName',
      text: 'Tên Người Nhận',
      detailUrl: 'orders',
      fontWeight: 500,
      color: '#1E1E1E',
    },
    {
      dataField: 'phone',
      text: 'Số Điện Thoại',
    },
    {
      dataField: 'status',
      text: 'Tình trạng',
      controlType: CellControlType.Status,
      render: value => getOrderState(value, 'render'),
      background: value => getOrderState(value, 'background'),
      color: value => getOrderState(value, 'color'),
      width: '170px',
      orderKey: 'status',
    },
    {
      dataField: 'address',
      text: 'Giao hàng đến',
    },
    {
      dataField: 'createdAt',
      text: 'Ngày đặt',
      controlType: CellControlType.DateTime,
    },
    {
      dataField: 'totalPrice',
      text: 'Tổng tiền',
      custom: 'price',
      orderKey: 'price',
    },
    {
      dataField: 'action',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'orders',
      deleteUrl: 'orders',
    },
  ],
  columnTemplate: '120px 150px 120px 200px 1fr 100px 100px 100px',
  filterElements: [
    {
      name: 'dateFrom',
      placeholder: 'Tất cả các ngày',
      controlType: ControlTypes.SELECT_PICKER,
      options: ByDateOptions,
      width: '150px',
    },
    // {
    //   name: 'customer',
    //   placeholder: 'Lọc theo tài khoản khách hàng',
    //   controlType: ControlTypes.SELECT_PICKER,
    //   options: CustomerAccTypeOptions,
    //   width: '240px',
    // },
    {
      name: 'status',
      placeholder: 'Trạng thái đơn hàng',
      controlType: ControlTypes.SELECT_PICKER,
      options: OrderStatusOptions,
      width: '170px',
    },
  ],
  tabs: [{ name: '', label: '' }],
  upperFilterElements: [
    {
      label: 'Ngày',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'dateFrom',
      maxDate: 'dateTo',
      controlType: ControlTypes.DATE_PICKER,
    },
    {
      label: '-',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'dateTo',
      controlType: ControlTypes.DATE_PICKER,
      minDate: 'dateFrom',
    },
  ],
  selectable: true,
  actions: [
    { label: 'Mới', value: 1, key: 'status' },
    { label: 'Chờ thanh toán', value: 2, key: 'status' },
    { label: 'Thanh toán thành công', value: 3, key: 'status' },
    { label: 'Đang xử lý', value: 4, key: 'status' },
    { label: 'Giao/giữ hàng', value: 5, key: 'status' },
    { label: 'Hoàn thành', value: 6, key: 'status' },
    { label: 'Đơn hủy', value: -1, key: 'status' },
  ],
  title: 'Danh sách đơn hàng',
  url: 'orders',
});
