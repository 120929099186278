/* eslint-disable eqeqeq */
import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { cloneDeep, get, map } from 'lodash';
import { Accordion } from 'react-bootstrap';

import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { ActionsItem, CommonManagement, FieldInput, SeoBlock } from 'components/own';
import { useActionNotification } from 'hook/useContextSelector';
import { AuthenticationFeature } from 'constants/authentication';
import { getAPI, putAPI, uploadImage } from 'services/common';
import { E_COMMERCE_URL } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { ProductOptionBanner } from 'constants/options';
import { getChangedData } from 'utils/uti';
import { ProductHighlightTemplate1 } from './ProductTemplate/Template-1';
import { ProductHighlightTemplate2 } from './ProductTemplate/Template-2';
import { ProductHighlightTemplate3 } from './ProductTemplate/Template-3';

const initialState = {
  data: null,
  options: {},
  categories: [],
};

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const SubTitle = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  color: #1f4173;
`;

const NameLabel = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  color: #000000;
`;

const LinkRow = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  height: 22px;
  align-items: center;
  margin-bottom: 16px;
`;

const LinkTitle = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #000000;
`;

const LinkValue = styled.a`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  text-decoration-line: underline;

  color: #0063f7;
  margin-left: 4px;

  &:hover {
    color: #0063f7;
  }
`;

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ $templateColumns }) => $templateColumns || '1fr'};
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: ${({ $templateColumns }) => $templateColumns || '1fr'};
  grid-column-gap: 60px;
  grid-row-gap: 16px;
  background-color: #fff;
  border: 0;
`;

const WrapperTable = styled.div`
  .page-content {
    padding: 0 !important;
  }
`;

const ProductPage = () => {
  const { id } = useParams();
  const [{ data }, setState] = useState(initialState);
  const router = useHistory();
  const pushNotification = useActionNotification();

  useEffect(() => {
    const callAPI = async () => {
      const res = await getAPI(`page-setting-info?page=${id}`);
      for (const key in res?.data?.setting?.productHighlight) {
        if (Object.hasOwnProperty.call(res?.data?.setting?.productHighlight, key)) {
          const _prod = res?.data?.setting?.productHighlight[key];
          if (!Array.isArray(_prod.productList) || !_prod.productList.length) continue;

          const item = await getAPI(`jewellery?productCode=${_prod.productList.join(',')}`);
          res.data.setting.productHighlight[key].productListName = map(item.data.list, 'productName');
        }
      }

      setState(
        produce(draft => {
          draft.data = res.data;
        })
      );
    };
    callAPI();
  }, [id]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required('Vui lòng nhập tên trang.'),
      }),
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/page-settings');
  }, [router]);

  const onSave = useCallback(
    async values => {
      const changed = getChangedData(cloneDeep(values), data);
      if (!Object.keys(changed).length) return;
      if (changed.setting) {
        for (const iterator of changed?.setting?.productHighlight) {
          if (iterator?.image && typeof iterator?.image !== 'string') {
            const res = await uploadImage(iterator?.image);
            iterator.image = res?.url;
          }
          iterator.productList = [];
          if (Array.isArray(iterator.productListName) && !!iterator.productListName.length) {
            for (const name of iterator.productListName) {
              if (!!name) {
                const item = await getAPI(`jewellery?keyword=${name}`);
                if (item.data.list.length === 1) {
                  iterator.productList.push(get(item, ['data', 'list', 0, 'productCode']));
                }
              }
            }
          }
          delete iterator.productListName;
        }
      }

      putAPI('page-setting-info?page=' + id, changed)
        .then(() => {
          pushNotification('success', 'Lưu thành công');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [data, id, pushNotification]
  );

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>{data.name}</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, submitCount, touched = {}, values = {} }) => (
          <>
            <Row className="mb-2 d-flex">
              <Col md={2} className="d-flex align-items-center">
                <NameLabel>Tên trang</NameLabel>
              </Col>
              <FieldInput
                inputMd={10}
                name="name"
                error={get(touched, 'name') || !!submitCount ? get(errors, 'name') : null}
                value={get(values, 'name')}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={'Tên trang'}
              />
            </Row>
            <LinkRow>
              <LinkTitle>Đường dẫn:</LinkTitle>
              <LinkValue target="_blank" href={`${E_COMMERCE_URL}${values?.link}`}>{`${E_COMMERCE_URL}${values?.link}`}</LinkValue>
            </LinkRow>
            <Accordion alwaysOpen defaultActiveKey={values?.setting?.productHighlight?.map((a, i) => i + '')}>
              {values?.setting?.productHighlight?.map((_b, index) => (
                <Accordion.Item eventKey={index + ''} className="mb-3" key={index}>
                  <BlockHeader className="d-flex align-items-center justify-content-between">Block {index + 1}</BlockHeader>
                  <BlockContent $templateColumns="1fr 400px" toggler={`#block_${_b.templateId}`}>
                    <Content>
                      <Row>
                        <FieldInput
                          label="Ẩn"
                          controlType={ControlTypes.CHECKBOX}
                          name={`setting.productHighlight.${index}.isShow`}
                          value={get(values, ['setting', 'productHighlight', index, 'isShow'])}
                          onChange={handleChange}
                        />
                      </Row>
                      <Row>
                        <FieldInput
                          label="Lựa chọn option banner"
                          controlType={ControlTypes.SELECT_PICKER}
                          name={`setting.productHighlight.${index}.templateId`}
                          value={get(values, ['setting', 'productHighlight', index, 'templateId'])}
                          options={ProductOptionBanner}
                          onChange={handleChange}
                        />
                      </Row>
                      {get(values, ['setting', 'productHighlight', index, 'templateId']) !== 3 && (
                        <>
                          <Row>
                            <FieldInput
                              label="Tiêu đề"
                              name={`setting.productHighlight.${index}.title`}
                              value={get(values, ['setting', 'productHighlight', index, 'title'])}
                              onChange={handleChange}
                            />
                          </Row>

                          <Row>
                            <FieldInput
                              label="Đoạn văn"
                              controlType={ControlTypes.RICH_TEXT}
                              name={`setting.productHighlight.${index}.text`}
                              value={get(values, ['setting', 'productHighlight', index, 'text'])}
                              onChange={handleChange}
                            />
                          </Row>
                        </>
                      )}
                      <Row className="mb-3">
                        <FieldInput
                          inputMd={6}
                          label="Hình"
                          controlType={ControlTypes.FILE}
                          accept="image/*"
                          name={`setting.productHighlight.${index}.image`}
                          value={get(values, ['setting', 'productHighlight', index, 'image'])}
                          onChange={handleChange}
                        />
                      </Row>
                      {get(values, ['setting', 'productHighlight', index, 'templateId']) === 2 && (
                        <Row className="mb-3">
                          <FieldInput
                            inputMd={9}
                            label="Video"
                            name={`setting.productHighlight.${index}.video`}
                            value={get(values, ['setting', 'productHighlight', index, 'video'])}
                            onChange={handleChange}
                          />
                        </Row>
                      )}
                      {get(values, ['setting', 'productHighlight', index, 'templateId']) !== 3 && (
                        <>
                          <Row>
                            <FieldInput
                              label="Button text"
                              name={`setting.productHighlight.${index}.buttonText`}
                              value={get(values, ['setting', 'productHighlight', index, 'buttonText'])}
                              onChange={handleChange}
                            />
                          </Row>
                          <Row>
                            <FieldInput
                              label="Link banner/button"
                              name={`setting.productHighlight.${index}.link`}
                              value={get(values, ['setting', 'productHighlight', index, 'link'])}
                              onChange={handleChange}
                            />
                          </Row>
                        </>
                      )}
                      <Row>
                        <FieldInput
                          label="Sản phẩm trong hình"
                          name={`setting.productHighlight.${index}.productListName`}
                          controlType={ControlTypes.AUTOCOMPLETE_MULTI}
                          value={get(values, ['setting', 'productHighlight', index, 'productListName'])}
                          onChange={handleChange}
                          type="textarea"
                          rows={4}
                        />
                      </Row>
                    </Content>
                    <Col>
                      <SubTitle>Hình Demo</SubTitle>
                      {get(values, ['setting', 'productHighlight', index, 'templateId']) === 1 ? (
                        <ProductHighlightTemplate1 item={get(values, ['setting', 'productHighlight', index])} />
                      ) : get(values, ['setting', 'productHighlight', index, 'templateId']) === 2 ? (
                        <ProductHighlightTemplate2 item={get(values, ['setting', 'productHighlight', index])} />
                      ) : get(values, ['setting', 'productHighlight', index, 'templateId']) === 3 ? (
                        <ProductHighlightTemplate3 item={get(values, ['setting', 'productHighlight', index])} />
                      ) : (
                        <div />
                      )}
                    </Col>
                  </BlockContent>
                </Accordion.Item>
              ))}
            </Accordion>
            <WrapperTable>
              <CommonManagement module={'category-product'} initParams={{ category: id }} />
            </WrapperTable>
            <SeoBlock values={values} handleChange={handleChange} />

            <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.PRODUCT} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ProductPage;
