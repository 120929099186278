import { useMemo } from 'react';
import { useParams } from 'react-router';

import DashboardPage from './DashboardPage';
import LuxuryPageSetting from './LuxyryPage';
import OtherPage from './OtherPage';
import ProductPage from './ProductPage';
import EventPageSetting from './EventPage';

// const ComingSoon = () => <div className="d-flex align-items-center justify-content-center h-100">Coming soon</div>;

const ClientPage = () => {
  const { id } = useParams();
  const Component = useMemo(() => {
    switch (id) {
      case '1':
        return DashboardPage;
      case 'bong-tai':
      case 'danh-sach-san-pham':
      case 'day-nam':
      case 'day-chuyen-nu':
      case 'lac-nam':
      case 'lac-nu':
      case 'nhan-cuoi':
      case 'nhan-dinh-hon':
      case 'nhan-nam':
      case 'nhan-nu':
      case 'san-pham-nam':
      case 'san-pham-nu':
      case 'trang-suc-vang':
      case 'vong-nu':
        return ProductPage;

      case 'trang-suc-cao-cap-nu':
      case 'trang-suc-cao-cap-nam':
      case 'trang-suc-cao-cap':
        return LuxuryPageSetting;

      case 'chi-tiet-su-kien':
        return EventPageSetting;

      default:
        return OtherPage;
    }
  }, [id]);

  return <Component />;
};

export default ClientPage;
