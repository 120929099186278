import { useRef } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

const PreviewActions = styled.div`
  display: none;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #00000080;
`;

const PreviewZone = styled.div`
  position: relative;
  border-radius: 4px;

  &:hover {
    ${PreviewActions} {
      display: flex;
    }
  }
`;

const PreviewButton = styled.div`
  cursor: pointer;
  margin: 0 4px;
  i {
    color: #fff;
  }
`;

const UploadFile = ({ accept = 'image/*,video/*', hiddenPreview, name, src, handleChange, children, showEdit, width = 100, height = 100 }) => {
  const ref = useRef();
  const handleAcceptedFiles = files => {
    handleChange({ target: { name, value: files[0] } });
  };

  return (
    <>
      <div
        style={{
          display: src && !hiddenPreview ? '' : 'none',
          border: ' 1px solid #C7C9D9',
          boxSizing: 'border-box',
          padding: 8,
          width: width,
          height: height,
        }}
      >
        <PreviewZone style={{ width: width - 18, height: height - 18 }}>
          <img
            src={typeof src === 'string' ? src : !!src && !!src.type ? URL.createObjectURL(src) : ''}
            width={width - 18}
            height={height - 18}
            alt="seoimage"
          />
          <PreviewActions>
            {showEdit && (
              <PreviewButton
                onClick={() => {
                  ref.current.click();
                }}
              >
                <i className="mdi mdi-image-edit-outline"></i>
              </PreviewButton>
            )}
            <PreviewButton onClick={() => handleChange({ target: { name, value: '' } })}>
              <i className="mdi mdi-trash-can-outline"></i>
            </PreviewButton>
          </PreviewActions>
        </PreviewZone>
      </div>
      <div style={{ display: src && !hiddenPreview ? 'none' : '' }}>
        <Dropzone maxFiles={1} styles={{ width: 100 + 18 }} accept={accept} onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} role="button">
              <input {...getInputProps()} ref={ref} />
              {children}
            </div>
          )}
        </Dropzone>
      </div>
    </>
  );
};

export default UploadFile;
