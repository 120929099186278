import React from 'react';

export const OrderDescIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M9.1434 11.71L11.7334 14.3C12.1234 14.69 12.7534 14.69 13.1434 14.3L15.7334 11.71C16.3634 11.08 15.9134 10 15.0234 10H9.8434C8.9534 10 8.5134 11.08 9.1434 11.71Z"
      fill="#1E1E1E"
    />
  </svg>
);
