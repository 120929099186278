import React, { useMemo } from 'react';
import styles from './ProductHighlightTemplate3.module.scss';
import { HandBagIcon } from 'components/icons';
const ProductHighlightTemplate3 = ({ item }) => {
  const src = useMemo(() => (typeof item.image === 'string' ? item.image : URL.createObjectURL(item.image)), [item.image]);
  return (
    <div className={styles['container']}>
      <div className={styles['highlight']}>
        <div className={styles['highlight__image']}>
          <img src={src} alt="" width={400} height={300} />
          <div className={styles['highlight__icon-hand-bag']}>
            <HandBagIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(ProductHighlightTemplate3);
