import React from 'react';
import styled from 'styled-components';
import { UncontrolledCollapse, Table } from 'reactstrap';
import { DropDownIcon } from 'components/icons';
import { getOrderState } from 'components/own/Management/modules/customer';
import { parsePrice, parseDate } from 'utils/uti';

import OrderItem from './OrderItem';


const CursorTableRow = styled.tr`
    ${({ cursor }) => cursor && `cursor: ${cursor};`}
`;
const Status = styled.div`
  padding: 4px 8px;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
`;
const TableEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-family: Quicksand;
  height: 100%;
`;

const OrderTable = ({ orders }) => {
    const hasOrders = orders.length > 0;
    const cursor = hasOrders ? 'pointer' : 'none';
    return (
        <Table>
            <thead>
                <tr>
                    <th>Mã đơn hàng</th>
                    <th>Ngày</th>
                    <th>Trạng thái</th>
                    <th>Tổng đơn (VNĐ)</th>
                    {hasOrders && <th></th>}
                </tr>
            </thead>
            <tbody>
                {
                    hasOrders ? orders.map(order => {
                        const hasItems = order.items.length > 0;
                        return (
                            <React.Fragment key={order.id}>
                                <CursorTableRow cursor={cursor} id={`order-${order.id}`}>
                                    <td>
                                        <div style={{ color: '#0063F7', fontWeight: 'bold' }}>#{order.code}</div>
                                    </td>
                                    <td>
                                        <div>{parseDate(order.createdAt)}</div>
                                    </td>
                                    <td>
                                        <Status
                                            background={getOrderState(order.status, 'background')}
                                            color={getOrderState(order.status, 'color')}
                                        >
                                            {getOrderState(order.status, 'render')}
                                        </Status>
                                    </td>
                                    <td>
                                        <div>{parsePrice(order.totalPrice) + ' đ'}</div>
                                    </td>
                                    {hasOrders && <td>
                                        <DropDownIcon />
                                    </td>}
                                </CursorTableRow>
                                {
                                    hasItems && (
                                        <td
                                            colSpan={hasOrders ? 5 : 4}
                                            className='py-0 m-auto'
                                        >
                                            <UncontrolledCollapse
                                                toggler={`#order-${order.id}`}
                                                className='py-0'
                                            >
                                                {
                                                    order.items.map((item, index) => (
                                                        <OrderItem key={item.id} item={item} orderNumber={index + 1} />
                                                    ))
                                                }
                                            </UncontrolledCollapse>
                                        </td>
                                    )
                                }
                            </React.Fragment>
                        )
                    }) : (
                        <td colSpan={hasOrders ? 5 : 4} className='py-0'>
                            <TableEmpty color="variantGray" fontSize={6} paddingY={4} textAlign="center">
                                Không có dữ liệu nào được tìm thấy
                            </TableEmpty>
                        </td>
                    )}
            </tbody>
        </Table>
    )
}

export default OrderTable;
