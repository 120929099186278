import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap';
import defaultImage from 'assets/images/upload-image.png';

const AvatarImage = styled.img`
  cursor: pointer;
  padding: 7px;
  border: 1px solid #c4c4c4;
  width: 100%;
  &:hover {
    opacity: 0.7;
  }
`;
const Container = styled.div`
  position: relative;
`;
const ButtonClose = styled.button`
  position: absolute;
  top: 2px;
  right: -5px;
  transform: translate(-50%);
  display: none;

  ${Container}:hover & {
    display: block;
  }
`;
const Link = styled.a`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Thumbnail = ({ avatarSrc = defaultImage, urlVideo, onChangeImage, onChangeUrl, onDelete, ...props }) => {
  const fileRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isUrlChosen, setIsUrlChosen] = useState(false);
  const [clientUrl, setClientUrl] = useState('');

  const handleImageChosen = () => {
    fileRef.current.click();
    setIsOpen(false);
  };

  const handleSubmitUrl = () => {
    onChangeUrl(clientUrl);
    setIsOpen(false);

    setTimeout(() => {
      setIsUrlChosen(false);
    }, 300);
  };

  useEffect(() => {
    if (urlVideo) {
      setClientUrl(urlVideo);
    }
  }, [urlVideo]);
  return (
    <Container>
      <AvatarImage onClick={() => setIsOpen(true)} src={avatarSrc ?? defaultImage} alt="thumbnail" />
      <input onChange={onChangeImage} style={{ display: 'none' }} ref={fileRef} type={'file'} accept="image/*, video/*" {...props} />
      {(avatarSrc !== defaultImage || urlVideo) && <ButtonClose type="button" className="btn-close" aria-label="Close" onClick={onDelete} />}

      <Link href={urlVideo} target="_blank" rel="noreferrer">
        {urlVideo}
      </Link>
      <Modal toggle={() => setIsOpen(state => !state)} isOpen={isOpen}>
        <ModalHeader toggle={() => setIsOpen(false)}>Hình ảnh thu nhỏ</ModalHeader>
        <ModalBody>
          {isUrlChosen ? (
            <div className="form-group">
              <label htmlFor="youtube-video-url">Đường dẫn video Youtube</label>
              <input
                className="form-control"
                id="youtube-video-url"
                value={clientUrl}
                placeholder="Đường dẫn video Youtube"
                onChange={event => setClientUrl(event.target.value)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    handleSubmitUrl();
                  }
                }}
              />
            </div>
          ) : (
            <p>Vui lòng chọn định dạng muốn đăng tải.</p>
          )}
        </ModalBody>
        <ModalFooter>
          {!isUrlChosen ? (
            <>
              <Button color="primary" onClick={handleImageChosen}>
                Ảnh
              </Button>
              <Button color="danger" onClick={() => setIsUrlChosen(true)}>
                Link Youtube
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => setIsUrlChosen(false)}>Trở lại</Button>
              <Button color="primary" onClick={handleSubmitUrl}>
                Lưu
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default Thumbnail;
