export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const API_UPLOAD = process.env.REACT_APP_API_UPLOAD_IMAGE;
export const E_COMMERCE_URL = process.env.REACT_APP_E_COMMERCE_URL;

export const LOCAL_STORAGE__JWT = 'Admin_VCR__token';
export const LOCAL_STORAGE__MENU = 'Admin_VCR__menu';
export const LOCAL_STORAGE__USER = 'Admin_VCR__user';

export const HTTP_CODE = {
  InternalServerError: 500,
  Unauthorized: 401,
  MethodNotAllow: 405,
  Succeeded: 200,
  Created: 201,
};

export const OrderType = {
  NoOrder: 0,
  Asc: 1,
  Desc: 2,
};

export const PaymentMethod = {
  ONLINE: 1,
  BANKING: 2,
  COD: 3,
  PHONE: 4,
};

export const JewelTypes = {
  Customize: 2,
  Single: 1,
  Couple: 3,
};

export const MenuOrderType = {
  MOVE_UP: 'MOVE_UP',
  MOVE_DOWN: 'MOVE_DOWN',
  MOVE_TOP: 'MOVE_TOP',
  MOVE_BOTTOM: 'MOVE_BOTTOM',
  MOVE_OUTSIDE: 'MOVE_OUTSIDE',
};
