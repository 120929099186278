import axios from 'axios';

export const getComboList = () => axios({ url: 'combo', method: 'GET' });

export const getComboById = id => axios({ url: 'combo/' + id, method: 'GET' });

export const updateComboById = (id, data) => axios({ url: 'combo/' + id, method: 'PUT', data });

export const addJewelleryToCombo = (comboId, jewelleryId) =>
  axios({
    url: `combo/${comboId}/${jewelleryId}`,
    method: 'POST',
  });

export const deleteJewelleryFromCombo = (comboId, jewelleryId) =>
  axios({
    url: `combo/${comboId}/${jewelleryId}`,
    method: 'DELETE',
  });

export const createCombo = data => axios({ url: 'combo', method: 'POST', data });
