import { memo, useEffect, useRef } from 'react';
import { Col, Label } from 'reactstrap';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/uti';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@lnminh0710/vcl-editor';

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
  b {
    margin-left: 4px;
    color: #e63535;
  }
`;

const RichText = ({
  autoFocus,
  error,
  isRequired,
  inputMd = 9,
  label,
  name,
  onChange = () => {},
  options: _opt,
  placeholder,
  value,
  onBlur = () => {},
  onFocus = () => {},
}) => {
  const ref = useRef(name);

  useEffect(() => {
    if (autoFocus) ref?.current?.focus();
  }, [autoFocus]);

  return (
    <>
      {label && (
        <LabelInput className="col-md-3 col-form-label d-flex align-items-center">
          {label} {isRequired && <b>*</b>}
        </LabelInput>
      )}
      <Col md={inputMd}>
        <CKEditor
          editor={ClassicEditor}
          row={8}
          data={value}
          placeholder={placeholder}
          onChange={(event, editor) => {
            onChange({ target: { name, value: editor.getData() } });
          }}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
    </>
  );
};

export default memo(RichText, propsAreEqual);
