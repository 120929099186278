import { memo } from 'react';
import { Col, Label } from 'reactstrap';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/uti';

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
`;

const CustomInput = ({ isRequired, label, error, onValueChange, ...props }) => {
    return (
        <>
            <LabelInput className="col-md-3 col-form-label">
                {label} {isRequired && '*'}
            </LabelInput>
            <Col md={9}>
                <div className='input-group'>
                    <NumberFormat
                        className="form-control"
                        renderText={(value, props) => <div {...props}>{value}</div>}
                        thousandSeparator={true}
                        onValueChange={onValueChange}
                        {...props}
                    />
                    <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">VNĐ</span>
                    </div>

                </div>
                {error && (
                    <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
                        {error}
                    </div>
                )}
            </Col>
        </>
    );
};

export default memo(CustomInput, propsAreEqual);
