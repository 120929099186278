import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Accordion } from 'react-bootstrap';
import { Row } from 'reactstrap';
import { ActionsItem, FieldInput } from 'components/own';
import { cloneDeep, get } from 'lodash';
import { getSettingHeader } from 'services/general-info';
import produce from 'immer';
import { putAPI, uploadImage } from 'services/common';
import { useActionNotification } from 'hook/useContextSelector';
import { Formik } from 'formik';
import { ControlTypes } from 'constants/field';
import { AuthenticationFeature } from 'constants/authentication';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const Block = styled(Accordion)`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const headers = ['search', 'contact', 'store', 'account', 'wishlist', 'cart'];
const title = { search: 'Tìm kiếm', contact: 'Liên hệ', store: 'Cửa hàng', account: 'Tài khoản', wishlist: 'Yêu thích', cart: 'Giỏ hàng' };
const initialState = { data: null };
const SettingHeader = () => {
  const [{ data }, setState] = useState(initialState);
  const pushNotification = useActionNotification();

  const onSave = useCallback(
    async values => {
      const changed = cloneDeep(values);
      for (const key in changed.setting) {
        if (Object.hasOwnProperty.call(changed.setting, key)) {
          const iterator = changed.setting[key];
          if (iterator?.icon && typeof iterator?.icon !== 'string') {
            const res = await uploadImage(iterator?.icon);
            iterator.icon = res?.url;
          }
          if (iterator?.iconActive && typeof iterator?.iconActive !== 'string') {
            const res = await uploadImage(iterator?.iconActive);
            iterator.iconActive = res?.url;
          }
        }
      }

      putAPI('general-info/2', changed)
        .then(() => {
          pushNotification('success', 'Lưu thành công');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [pushNotification]
  );

  useEffect(() => {
    getSettingHeader().then(res => {
      setState(
        produce(draft => {
          draft.data = get(res, ['data']);
        })
      );
    });
  }, []);
  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Cấu hình Header</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave}>
        {({ handleChange, handleSubmit, values }) => (
          <>
            <Block alwaysOpen defaultActiveKey={['info']}>
              <Accordion.Item eventKey={'info'}>
                <BlockHeader>Thông tin header</BlockHeader>
                <BlockContent>
                  {headers.map((item, index) => (
                    <React.Fragment key={index}>
                      <Row>
                        <FieldInput
                          value={get(values, ['setting', item, 'text'])}
                          label={title[item]}
                          name={`setting.${item}.text`}
                          onChange={handleChange}
                        />
                      </Row>
                      <Row>
                        <FieldInput
                          hiddenDelete
                          previewIcon
                          inputMd={9}
                          value={get(values, ['setting', item, 'icon'])}
                          name={`setting.${item}.icon`}
                          onChange={handleChange}
                          controlType={ControlTypes.FILE}
                        />
                      </Row>
                      <Row></Row>
                      <Row>
                        <FieldInput
                          hiddenDelete
                          previewIcon
                          inputMd={9}
                          value={get(values, ['setting', item, 'iconActive'])}
                          name={`setting.${item}.iconActive`}
                          onChange={handleChange}
                          controlType={ControlTypes.FILE}
                        />
                      </Row>
                    </React.Fragment>
                  ))}
                </BlockContent>
              </Accordion.Item>
            </Block>
            <ActionsItem onSave={handleSubmit} feature={AuthenticationFeature.INFO} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default SettingHeader;
