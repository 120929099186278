import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { cloneDeep, get } from 'lodash';
import { Accordion } from 'react-bootstrap';

import styled from 'styled-components';
import { Col, Label, Row } from 'reactstrap';
import { ActionsItem, FieldInput, SeoBlock } from 'components/own';
import { useActionNotification } from 'hook/useContextSelector';
import { AuthenticationFeature } from 'constants/authentication';
import { getAPI, putAPI, uploadImage } from 'services/common';
import { E_COMMERCE_URL } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { LuxuryBlockOptions, OrderOptions } from 'constants/options';
import { initialBlock } from 'pages/BannerSetting';
import { getChangedData } from 'utils/uti';
const TITLE_IMAGE_PC = 'Hình';

const initialState = {
  data: null,
  options: {},
};

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const NameLabel = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;

  color: #000000;
`;

const LinkRow = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  height: 22px;
  align-items: center;
  margin-bottom: 16px;
`;

const LinkTitle = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #000000;
`;

const LinkValue = styled.a`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  text-decoration-line: underline;

  color: #0063f7;

  &:hover {
    color: #0063f7;
  }
`;

const Block = styled(Accordion)`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;
const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
`;

const SpecialBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const IconButton = styled.div`
  cursor: pointer;
  color: #e53535;
  margin-right: 16px;
`;

const NewBlockContainer = styled.div`
  width: 100%;
  height: 104px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px dashed #8f90a6;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

const AddBlockButton = styled.div`
  width: 178px;
  height: 56px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  border-radius: 4px;

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;
  text-transform: uppercase;

  /* Dark / Dark 0 */

  color: #1c1c28;
  i {
    margin-right: 4px;
  }
`;

const LuxuryPageSetting = () => {
  const [{ data }, setState] = useState(initialState);
  const { id } = useParams();
  const router = useHistory();
  const pushNotification = useActionNotification();

  useEffect(() => {
    getAPI(`page-setting-info?page=${id}`).then(res => {
      setState(
        produce(draft => {
          draft.data = res.data;
        })
      );
    });
  }, [id]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required('Vui lòng nhập tên trang.'),
      }),
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/users');
  }, [router]);

  const onSave = useCallback(
    async values => {
      const changed = getChangedData(cloneDeep(values), data);
      if (!Object.keys(changed).length) return;
      if (changed.setting) {
        for (const iterator of changed?.setting?.blocks) {
          if (iterator?.imageMobile && typeof iterator?.imageMobile !== 'string') {
            const res = await uploadImage(iterator?.imageMobile);
            iterator.imageMobile = res?.url;
          }
          if (iterator?.imagePC && typeof iterator?.imagePC !== 'string') {
            const res = await uploadImage(iterator?.imagePC);
            iterator.imagePC = res?.url;
          }

          if (Array.isArray(iterator?.list)) {
            if (iterator?.list[0].imagePC && typeof iterator?.list[0].imagePC !== 'string') {
              const res = await uploadImage(iterator?.list[0].imagePC);
              iterator.list[0].imagePC = res?.url;
            }
            if (iterator?.list[1].imagePC && typeof iterator?.list[1].imagePC !== 'string') {
              const res = await uploadImage(iterator?.list[1].imagePC);
              iterator.list[1].imagePC = res?.url;
            }
          }
        }
      }

      putAPI('page-setting-info?page=' + id, changed)
        .then(() => {
          pushNotification('success', 'Lưu thành công');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [data, id, pushNotification]
  );

  const url = useMemo(() => E_COMMERCE_URL + id, [id]);

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Sản phẩm cao cấp</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, submitCount, touched = {}, values = {} }) => (
          <>
            <Row className="mb-2">
              <Col md={2}>
                <NameLabel>Tên trang</NameLabel>
              </Col>
              <FieldInput
                inputMd={10}
                name="name"
                error={get(touched, 'name') || !!submitCount ? get(errors, 'name') : null}
                value={get(values, 'name')}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={'Tên trang'}
              />
            </Row>
            <LinkRow>
              <LinkTitle>Link</LinkTitle>
              <LinkValue href={url}>{url}</LinkValue>
            </LinkRow>
            {values?.setting?.blocks?.map((_b, index) => (
              <Block alwaysOpen defaultActiveKey={[index]} key={index}>
                <Accordion.Item eventKey={index} className="mb-3">
                  <BlockHeader className="d-flex align-items-center justify-content-between">
                    Block {index + 1}
                    <div className="flex-1" />
                    <IconButton
                      onClick={e => {
                        e.preventDefault();
                        handleChange({ target: { name: 'setting.blocks', value: values?.setting.blocks.filter((_v, i) => i !== index) } });
                      }}
                    >
                      <i className="mdi mdi-trash-can-outline" />
                    </IconButton>
                  </BlockHeader>
                  <BlockContent>
                    <Row>
                      <FieldInput
                        label="Ẩn"
                        controlType={ControlTypes.CHECKBOX}
                        name={`setting.blocks.${index}.isShow`}
                        value={get(values, ['setting', 'blocks', index, 'isShow'])}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <FieldInput
                        label="Thứ tự hiển thị"
                        controlType={ControlTypes.SELECT_PICKER}
                        name={`setting.blocks.${index}.order`}
                        value={get(values, ['setting', 'blocks', index, 'order'])}
                        options={OrderOptions}
                        inputMd={1}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <FieldInput
                        label={TITLE_IMAGE_PC}
                        accept="image/*"
                        controlType={ControlTypes.FILE}
                        name={`setting.blocks.${index}.imagePC`}
                        value={get(values, ['setting', 'blocks', index, 'imagePC'])}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row>
                      <FieldInput
                        label="Lựa chọn option banner"
                        controlType={ControlTypes.SELECT_PICKER}
                        name={`setting.blocks.${index}.option`}
                        value={get(values, ['setting', 'blocks', index, 'option'])}
                        options={LuxuryBlockOptions}
                        onChange={handleChange}
                      />
                    </Row>
                    {get(values, ['setting', 'blocks', index, 'option']) !== 5 && (
                      <>
                        <Row>
                          <FieldInput
                            label="Tiêu đề"
                            name={`setting.blocks.${index}.title`}
                            value={get(values, ['setting', 'blocks', index, 'title'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Đoạn văn"
                            controlType={ControlTypes.RICH_TEXT}
                            name={`setting.blocks.${index}.text`}
                            value={get(values, ['setting', 'blocks', index, 'text'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Button text"
                            name={`setting.blocks.${index}.buttonText`}
                            value={get(values, ['setting', 'blocks', index, 'buttonText'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Link banner/button"
                            name={`setting.blocks.${index}.luxuryId`}
                            value={get(values, ['setting', 'blocks', index, 'luxuryId'])}
                            onChange={handleChange}
                          />
                        </Row>
                      </>
                    )}

                    {get(values, ['setting', 'blocks', index, 'option']) === 5 && (
                      <SpecialBlock>
                        <Row>
                          <Label className="col-md-3 col-form-label d-flex align-items-center">Hình 1</Label>
                        </Row>
                        <Row>
                          <Label className="col-md-3 col-form-label d-flex align-items-center">Hình 2</Label>
                        </Row>
                        <Row>
                          <FieldInput
                            inputMd={6}
                            label={TITLE_IMAGE_PC}
                            controlType={ControlTypes.FILE}
                            accept="image/*"
                            name={`setting.blocks.${index}.list.0.imagePC`}
                            value={get(values, ['setting', 'blocks', index, 'list', 0, 'imagePC'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            inputMd={6}
                            label={TITLE_IMAGE_PC}
                            accept="image/*"
                            controlType={ControlTypes.FILE}
                            name={`setting.blocks.${index}.list.1.imagePC`}
                            value={get(values, ['setting', 'blocks', index, 'list', 1, 'imagePC'])}
                            onChange={handleChange}
                          />
                        </Row>
                        {/* <Row>
                          <FieldInput
                            inputMd={6}
                            label="Hình Mobile"
                            accept="image/*"
                            controlType={ControlTypes.FILE}
                            name={`setting.blocks.${index}.list.0.imageMobile`}
                            value={get(values, ['setting','blocks', index, 'list', 0, 'imageMobile'])}
                            onChange={handleChange}
                          />
                        </Row> */}
                        {/* <Row>
                          <FieldInput
                            inputMd={6}
                            label="Hình Mobile"
                            accept="image/*"
                            controlType={ControlTypes.FILE}
                            name={`setting.blocks.${index}.list.1.imageMobile`}
                            value={get(values, ['setting','blocks', index, 'list', 1, 'imageMobile'])}
                            onChange={handleChange}
                          />
                        </Row> */}
                        <Row>
                          <FieldInput
                            label="Tiêu đề"
                            name={`setting.blocks.${index}.list.0.title`}
                            value={get(values, ['setting', 'blocks', index, 'list', 0, 'title'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Tiêu đề"
                            name={`setting.blocks.${index}.list.1.title`}
                            value={get(values, ['setting', 'blocks', index, 'list', 1, 'title'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Đoạn văn"
                            controlType={ControlTypes.RICH_TEXT}
                            name={`setting.blocks.${index}.list.0.text`}
                            value={get(values, ['setting', 'blocks', index, 'list', 0, 'text'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Đoạn văn"
                            controlType={ControlTypes.RICH_TEXT}
                            name={`setting.blocks.${index}.list.1.text`}
                            value={get(values, ['setting', 'blocks', index, 'list', 1, 'text'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Link"
                            name={`setting.blocks.${index}.list.0.link`}
                            value={get(values, ['setting', 'blocks', index, 'list', 0, 'link'])}
                            onChange={handleChange}
                          />
                        </Row>
                        <Row>
                          <FieldInput
                            label="Link"
                            name={`setting.blocks.${index}.list.1.link`}
                            value={get(values, ['setting', 'blocks', index, 'list', 1, 'link'])}
                            onChange={handleChange}
                          />
                        </Row>
                      </SpecialBlock>
                    )}
                  </BlockContent>
                </Accordion.Item>
              </Block>
            ))}
            <NewBlockContainer>
              <AddBlockButton
                onClick={() => {
                  const blocks = cloneDeep(values?.setting?.blocks || []);
                  blocks.push({ ...initialBlock, id: blocks.length + 1 });
                  handleChange({ target: { name: 'setting.blocks', value: blocks } });
                }}
              >
                <i className="mdi mdi-plus"></i>
                Thêm block
              </AddBlockButton>
            </NewBlockContainer>
            <SeoBlock values={values} handleChange={handleChange} />
            <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.USER} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default LuxuryPageSetting;
