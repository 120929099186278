import { AuthenticationFeature } from 'constants/authentication';
import { API_UPLOAD } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { ByDateOptions, DraftStatusOptions } from 'constants/options';
import { CellControlType } from 'constants/table';

export const getImagesInfo = () => ({
  buttonAddTitle: 'Thêm banner/hình ảnh',
  feature: AuthenticationFeature.MEDIAFILE,
  columns: [
    {
      dataField: 'url',
      text: 'Image',
      controlType: CellControlType.Image,
    },
    {
      dataField: 'originalName',
      text: 'Tên',
      detailUrl: 'images',
      controlType: CellControlType.TextWithActionEditQuickEditDelete,
      deleteUrl: API_UPLOAD,
    },
    {
      dataField: 'fileName',
      text: 'Banner/Image URL',
      controlType: CellControlType.TextCopy,
    },
    {
      dataField: 'updatedAt',
      text: 'Date',
      controlType: CellControlType.Date,
    },
    {
      dataField: 'status',
      text: 'Tình trạng',
      controlType: CellControlType.Status,
      render: value => (value === 1 ? 'Đang hiển thị' : 'Nháp'),
      color: value => (value === 1 ? '#06C270' : '#555770'),
      background: value => (value === 1 ? '#06C2701A' : '#5557701A'),
    },
  ],
  editConfig: {
    url: API_UPLOAD,
    fields: [{ label: 'Tên', name: 'originalName' }],
  },
  columnTemplate: '120px 1fr 1fr 200px 200px',
  filterElements: [
    {
      label: 'Ngày',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'dateFrom',
      maxDate: 'dateTo',
      controlType: ControlTypes.DATE_PICKER,
    },
    {
      label: '-',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'dateTo',
      controlType: ControlTypes.DATE_PICKER,
      minDate: 'dateFrom',
    },
    {
      name: 'dateFrom',
      placeholder: 'Tất cả các ngày',
      controlType: ControlTypes.SELECT_PICKER,
      options: ByDateOptions,
      width: '150px',
    },
    {
      name: 'status',
      placeholder: 'Trạng thái',
      controlType: ControlTypes.SELECT_PICKER,
      options: DraftStatusOptions,
      width: '120px',
    },
  ],
  tabs: [{ name: '', label: '' }],
  upperFilterElements: [],
  selectable: true,
  actions: [
    // { label: 'Mới', value: 1, key: 'status' },
    // { label: 'Chờ thanh toán', value: 2, key: 'status' },
    // { label: 'Thanh toán thành công', value: 3, key: 'status' },
    // { label: 'Đang xử lý', value: 4, key: 'status' },
    // { label: 'Giao/giữ hàng', value: 5, key: 'status' },
    // { label: 'Hoàn thành', value: 6, key: 'status' },
    // { label: 'Đơn hủy', value: -1, key: 'status' },
  ],
  title: 'Danh sách banner/hình ảnh',
  url: 'images',
});
