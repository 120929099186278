import { find } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { Col, Label } from 'reactstrap';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/uti';

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
  b {
    margin-left: 4px;
    color: #e63535;
  }
`;

const SelectPicker = ({
  autoFocus,
  error,
  inputMd = 9,
  isRequired,
  label,
  name,
  onBlur = () => {},
  onChange = () => {},
  options,
  value,
  ...rest
}) => {
  const selectedValue = useMemo(() => (value ? find(options, ['value', value]) : null), [options, value]);
  const handleSelectGroup = useCallback(
    item => {
      onBlur({ target: { name } });
      onChange({ target: { name, value: item.value } });
    },
    [onBlur, name, onChange]
  );
  const customStyles = useMemo(
    () => ({
      control: (provided, state) => ({
        ...provided,
        borderColor: !!error ? '#ff3d60 !important' : 'hsl(0, 0%, 80%)',
        boxShadow: !!error ? 'unset !important' : '',
      }),
    }),
    [error]
  );

  return (
    <>
      <LabelInput className="col-md-3 col-form-label">
        {label} {isRequired && <b>*</b>}
      </LabelInput>
      <Col md={inputMd}>
        <Select
          classNamePrefix="select2-selection"
          styles={customStyles}
          onChange={handleSelectGroup}
          options={options}
          value={selectedValue}
          {...rest}
        />
        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
    </>
  );
};

export default memo(SelectPicker, propsAreEqual);
