import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';

import { ActionsItem, FieldInput, UploadFile } from 'components/own';
import { Col, Label, Row, UncontrolledCollapse } from 'reactstrap';
import styled from 'styled-components';
import { AvatarDropIcon, ChevronUpIcon } from 'components/icons';
import { ControlTypes } from 'constants/field';
import { AuthenticationFeature } from 'constants/authentication';
import { cloneDeep, get } from 'lodash';
import { useActionNotification } from 'hook/useContextSelector';
import { StatusOptions } from 'constants/options';
import { getAPI, uploadImage } from 'services/common';
import { API_UPLOAD } from 'constants/common';

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
  b {
    margin-left: 4px;
    color: #e63535;
  }
`;

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
`;

const BlockContent = styled(UncontrolledCollapse)`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const initialState = { data: null, coupon: '' };

const ImageItem = () => {
  const [{ data }, setState] = useState(initialState);
  const { id } = useParams();
  const router = useHistory();
  const pushNotification = useActionNotification();

  const fields = useMemo(
    () => [
      {
        id: 'block-1',
        title: 'Thông tin hình ảnh',
        content: [
          {
            label: 'Tên',
            name: 'originalName',
          },
          {
            label: 'Image url',
            name: 'path',
            readOnly: true,
          },
          {
            label: 'Ngày tạo',
            name: 'createdAt',
            readOnly: true,
            controlType: ControlTypes.DATE_PICKER,
          },
          {
            label: 'Ngày edit',
            name: 'updatedAt',
            readOnly: true,
            controlType: ControlTypes.DATE_PICKER,
          },
        ],
      },
    ],
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/images');
  }, [router]);

  const onSave = useCallback(
    values => {
      let request = cloneDeep(values);
      if (values.id) {
        router.replace('/images');
      } else {
        uploadImage(request.url)
          .then(() => {
            pushNotification('success', 'Tạo thành công');
            router.replace('/images');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      }
    },
    [pushNotification, router]
  );

  useEffect(() => {
    if (id === 'new') {
      setState(
        produce(draft => {
          draft.data = {
            url: '',
            name: '',
            createdAt: new Date(),
          };
        })
      );
    } else
      getAPI(API_UPLOAD + '/' + id + '/info').then(res => {
        setState(
          produce(draft => {
            draft.data = res.data;
          })
        );
      });
  }, [id]);

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chi tiết hình ảnh</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, submitCount, touched = {}, values = {} }) => (
          <>
            {fields.map(block => (
              <Block key={block.id}>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  {block.title}
                  <IconButton id={block.id}>
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent defaultOpen toggler={`#${block.id}`}>
                  <Row className="mb-4">
                    <LabelInput className="col-md-3 col-form-label d-flex align-items-center">Image</LabelInput>
                    <Col md={9}>
                      <UploadFile width={150} height={150} name={'url'} src={values.url} handleChange={handleChange}>
                        <AvatarDropIcon />
                      </UploadFile>
                    </Col>
                  </Row>
                  <div></div>
                  {block.content.map(field => (
                    <Row key={field.name}>
                      <FieldInput
                        {...field}
                        error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                        value={get(values, field.name)}
                        autoComplete="new-password"
                        options={StatusOptions}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={field.placeholder || field.label}
                      />
                    </Row>
                  ))}
                </BlockContent>
              </Block>
            ))}
            <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.MEDIAFILE} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ImageItem;
