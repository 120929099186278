import React, { useRef } from 'react';
import styled from 'styled-components';
import defaultImage from 'assets/images/upload-image.png';

const Image = styled.img`
  margin-left: 50%;
  /* width: 100%; */
  transform: translateX(-50%);
  &:hover {
    opacity: 0.7;
  }
`;
const ImageContainer = styled.div`
  cursor: pointer;
  padding: 7px;
  border: 1px solid #c4c4c4;
`;
const Container = styled.div`
  position: relative;
`;
const ButtonClose = styled.button`
  position: absolute;
  top: 2px;
  right: -5px;
  transform: translate(-50%);
  display: none;

  ${Container}:hover & {
    display: block;
  }
`;
const UploadImage = ({ src = defaultImage, onChangeImage, onDelete, width, height, ...props }) => {
  const fileRef = useRef(null);
  return (
    <Container>
      <ImageContainer>
        <Image width={width} height={height} onClick={() => fileRef.current.click()} src={src} alt="Image" />
      </ImageContainer>
      <input onChange={onChangeImage} style={{ display: 'none' }} ref={fileRef} type={'file'} accept="image/*" {...props} />
      {src !== defaultImage && <ButtonClose type="button" className="btn-close" aria-label="Close" onClick={onDelete} />}
    </Container>
  );
};

export default UploadImage;
