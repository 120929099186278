import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Col, Row, UncontrolledCollapse, Label, Input } from 'reactstrap';
import { useParams, useHistory } from 'react-router';
import produce from 'immer';
import { Formik } from 'formik';
import * as yup from 'yup';
import { get } from 'lodash';
import Select from 'react-select';
import { ChevronUpIcon, DropDownIcon } from 'components/icons';
import { ActionsItem, FieldInput, CustomTable, ButtonPrimary } from 'components/own';
import { FilterInput } from 'components/own/Filter';
import { getComboById, updateComboById, createCombo } from 'services/combo';
import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { CellControlType } from 'constants/table';
import { StockStatusOptions } from 'constants/options';
import { parsePrice } from 'utils/uti';
import { useActionNotification } from 'hook/useContextSelector';

import { API_UPLOAD } from 'constants/common';
import UploadImage from './components/UploadImage';
import Checkbox from './components/Checkbox';
import JewelleryManagement from './components/CustomJewelleryManagement';
import { deleteAPI } from 'services/common';
import defaultImage from 'assets/images/upload-image.png';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
  cursor: pointer;
`;

const BlockContent = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
`;
const IconButton = styled.div`
  cursor: pointer;
`;
const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
`;
const ContentTable = styled.div`
  background-color: #fff;
  margin-top: 16px;
`;
const ContainerActions = styled.div`
  background-color: #f1f5f7;
  padding-top: 16px;
`;
const ComboName = styled.div`
  flex: 1;
  /* border: 1px solid #c7c9d9; */
  padding: 10px;
  font-size: 18px;
`;
const ComboLabel = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-right: 15px;
`;
const SelectAction = styled(Select)`
  cursor: pointer;
  width: 175px;
  margin-right: 4px;
  font-size: 14px;
  .select2-selection__indicators {
    padding-right: 4px;
  }
`;
const Price = styled.span`
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 700;
  color: #000;
`;
const optionActions = [{ label: 'Xoá khỏi bộ sưu tập', value: 'delete-jewellery-from-combo' }];
const initialCombo = {
  link: '',
  mediafiles: {
    images: [
      {
        subImage: '',
        urlVideo: null,
        mainImage: '',
      },
    ],
  },
  meta: {},
  name: '',
  productCode: [],
  SEOInfo: {},
  status: 1,
  bannerInfo: [
    {
      desc: '',
      title: '',
      bannerPC: '',
      textColor: '#fafafc',
      bannerMobile: '',
      textPosition: 'left',
    },
  ],
  createdBy: 0,
  desc: '',
  jewelleryList: [],
};
const ComboItem = () => {
  const [state, setState] = useState({ data: null });
  const [jewelleries, setJewelleries] = useState([]);
  const [action, setAction] = useState(null);
  const [forceRerender, setForceRerender] = useState(0);
  const [rowSelected, setRowSelected] = useState([]);
  const [filter, setFilter] = useState(null);
  const { id } = useParams();
  const router = useHistory();
  const formikRef = useRef(null);
  const { data } = state;
  const pushNotification = useActionNotification();
  const jewelleryTableProps = useMemo(
    () => ({
      columnTemplate: '1fr 1fr 1fr 1fr 1fr 1fr',
      data: jewelleries,
      headers: [
        {
          dataField: 'mediafiles.images.0.mainImage',
          text: 'Hình đại diện',
          controlType: CellControlType.Image,
        },
        {
          dataField: 'productCode',
          text: 'Mã sản phẩm',
        },
        {
          dataField: 'productName',
          text: 'Tên sản phẩm',
        },
        {
          dataField: 'isInStock',
          text: 'Trạng thái kho',
          controlType: CellControlType.Status,
          render: value => (value ? 'Còn hàng' : 'Hết hàng'),
          color: value => (value === 1 ? '#007770' : '#FDAC42'),
          background: value => (value === 1 ? 'rgba(0, 119, 112, 0.1)' : 'rgba(253, 172, 66, 0.1)'),
        },
        {
          dataField: 'price',
          text: 'Giá (VNĐ)',
          custom: 'price',
        },
        {
          dataField: 'mainCategory',
          text: 'Danh mục',
          custom: 'price',
        },
      ],
      // headerBackground: '#F2F2F5',
      rowHeight: 83,
      selectable: true,
      onChecked: (id, isCheck) => {
        let newRowSelected = [...rowSelected];
        if (isCheck) {
          newRowSelected.push(id);
        } else {
          newRowSelected = rowSelected.filter(row => row !== id);
        }
        setRowSelected(newRowSelected);
      },
      rowSelected,
    }),
    [jewelleries, rowSelected]
  );
  const filterElements = useMemo(
    () => [
      {
        name: 'stockStatus',
        placeholder: 'Trạng thái kho',
        controlType: ControlTypes.SELECT_PICKER,
        options: StockStatusOptions,
        width: '130px',
      },
    ],
    []
  );
  const seoFields = useMemo(
    () => ({
      id: 'seo-product-block',
      title: 'SEO',
      products: [
        {
          label: 'Tiêu đề',
          name: 'title',
        },
        {
          label: 'Link',
          name: 'link',
        },
        {
          label: 'Canonical link',
          name: 'canonicalURL',
        },
        {
          label: 'Redirection',
          name: 'redirection',
        },
      ],
    }),
    []
  );
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required('Vui lòng nhập danh sách.'),
        link: yup.string().required('Vui lòng nhập đường dẫn.'),
      }),
    []
  );
  const handleChangeImage = useCallback(
    ({ event, setFieldValue, target, fields, currentValue }) => {
      const image = event.currentTarget.files[0];
      const formData = new FormData();
      formData.append('file', image);
      formData.append('t', true);

      fetch(API_UPLOAD, {
        method: 'post',
        body: formData,
      })
        .then(response => {
          new Response(response.body, { headers: { 'Content-Type': 'text/html' } })
            .text()
            .then(res => {
              const respJson = JSON.parse(res);
              const values = {};
              fields.forEach(field => {
                values[field] = respJson.data.url;
              });
              setFieldValue(target, {
                ...currentValue,
                ...values,
              });
              setFieldValue(target, values);
            })
            .catch(error => {
              pushNotification('error', error);
            });
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [pushNotification]
  );
  const handleDeleteImage = useCallback(({ setFieldValue, target, fields, currentValue }) => {
    const values = {};
    fields.forEach(field => {
      values[field] = null;
    });
    setFieldValue(target, {
      ...currentValue,
      ...values,
    });
    setFieldValue(target, values);
  }, []);

  useEffect(() => {
    if (id === 'new') {
      setState(
        produce(draft => {
          draft.data = { ...initialCombo };
        })
      );
    } else {
      getComboById(id).then(res => {
        res.statusCode === 200 &&
          setState(
            produce(draft => {
              const newJewelleryList = res.data.jewelleryList.map(jewellery => {
                // type = 1 -> hàng thật, 2 -> hàng giả
                const serials = jewellery.serialList.filter(serial => serial.type === 1);
                return {
                  ...jewellery,
                  isInStock: serials.length > 0 ? 1 : 0,
                };
              });

              draft.data = { ...res.data, jewelleryList: newJewelleryList };
            })
          );
      });
    }
  }, [id, forceRerender]);
  const onSave = useCallback(
    values => {
      if (values.id) {
        updateComboById(values.id, values)
          .then(() => {
            pushNotification('success', 'Lưu thành công');
            router.replace('/combo');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      } else {
        createCombo(values)
          .then(newJewelleryResponse => {
            pushNotification('success', 'Tạo thành công');
            router.replace(`/combo/${newJewelleryResponse.data.id}`);
          })
          .catch(error => {
            const message = error.message || error;
            pushNotification('error', message);
          });
      }
    },
    [pushNotification, router]
  );
  const onCancel = useCallback(() => {
    router.replace('/combo');
  }, [router]);
  const handleApplyAction = useCallback(
    async actionAPI => {
      if (!rowSelected.length || !action) return;
      let isSuccess = true;
      for (const jewelleryId of rowSelected) {
        let shouldBreak = false;
        try {
          await actionAPI(`combo/${id}/${jewelleryId}`);
        } catch (err) {
          pushNotification('error', get(err, 'message', err));
          shouldBreak = true;
        }
        if (shouldBreak) {
          isSuccess = false;
          break;
        }
      }
      isSuccess && pushNotification('success', 'Thay đổi thành công');
      setRowSelected([]);
      setForceRerender(forceRerender + 1);
    },
    [rowSelected, action, pushNotification, forceRerender, id]
  );
  const handleRefetchAPI = () => {
    setForceRerender(forceRerender + 1);
  };

  const calculateTotalPrice = (jewelleries = []) => {
    return jewelleries.reduce((previousValue, currentValue) => +currentValue.price + previousValue, 0);
  };

  useEffect(() => {
    const filerValue = filter?.target.value;
    if (filerValue === '') {
      setJewelleries(data?.jewelleryList);
    } else {
      const newJewelleries = data?.jewelleryList.filter(jewellery => jewellery.isInStock === +filerValue);
      setJewelleries(newJewelleries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.target.value]);

  useEffect(() => {
    if (data?.jewelleryList) {
      setJewelleries(data?.jewelleryList);
    }
  }, [data?.jewelleryList]);

  if (!data) return <div />;

  return (
    <>
      <div className="page-content">
        <div className="d-flex align-items-center">
          <Title>Chi tiết trang sức</Title>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={data}
          onSubmit={onSave}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors = {}, handleSubmit, handleBlur, handleChange, setFieldValue, submitCount, touched = {}, values = {} }) => {
            return (
              <>
                <div className="d-flex align-items-center mb-3">
                  <ComboLabel>Tên bộ trang sức</ComboLabel>
                  <ComboNameInput
                    name="name"
                    error={get(touched, 'name') || !!submitCount ? get(errors, 'name') : null}
                    value={get(values, 'name')}
                    autoComplete="new-password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={'Nhập tên trang sức'}
                  />
                </div>
                {/* Begin of General Info */}
                <Block>
                  <BlockHeader className="d-flex align-items-center justify-content-between" id="heo">
                    Thông tin bộ trang sức
                    <IconButton>
                      <ChevronUpIcon />
                    </IconButton>
                  </BlockHeader>
                  <UncontrolledCollapse defaultOpen toggler="#heo">
                    <BlockContent>
                      <Row className="align-items-center">
                        <LabelInput className="col-md-3 col-form-label">{'Hiển thị'}</LabelInput>
                        <Col md={9}>
                          <Checkbox
                            checked={get(values, 'status') === 1}
                            onChange={value => {
                              setFieldValue('status', value ? 1 : -1);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <FieldInput
                          name="link"
                          label={'Đường dẫn'}
                          error={get(touched, 'link') || !!submitCount ? get(errors, 'link') : null}
                          value={get(values, 'link')}
                          autoComplete="new-password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder={'Đường dẫn'}
                        />
                      </Row>
                      <Row className="align-items-center">
                        <LabelInput className="col-md-3 col-form-label">{'Banner'}</LabelInput>
                        <Col md={9}>
                          <UploadImage
                            height={100}
                            src={get(values?.bannerInfo[0], 'bannerPC') || defaultImage}
                            onDelete={() =>
                              handleDeleteImage({
                                setFieldValue,
                                target: 'bannerInfo.0',
                                fields: ['bannerMobile', 'bannerPC'],
                                currentValue: values?.bannerInfo[0],
                              })
                            }
                            onChangeImage={event =>
                              handleChangeImage({
                                event,
                                setFieldValue,
                                target: 'bannerInfo.0',
                                fields: ['bannerMobile', 'bannerPC'],
                                currentValue: values?.bannerInfo[0],
                              })
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <LabelInput className="col-md-3 col-form-label">{'Ảnh đại diện'}</LabelInput>
                        <Col md={3}>
                          <UploadImage
                            height={100}
                            src={get(values?.mediafiles.images[0], 'mainImage') || defaultImage}
                            onDelete={() =>
                              handleDeleteImage({
                                setFieldValue,
                                target: 'mediafiles.images.0',
                                fields: ['subImage', 'mainImage'],
                                currentValue: values?.mediafiles.images[0],
                              })
                            }
                            onChangeImage={event =>
                              handleChangeImage({
                                event,
                                setFieldValue,
                                target: 'mediafiles.images.0',
                                fields: ['subImage', 'mainImage'],
                                currentValue: values?.mediafiles.images[0],
                              })
                            }
                          />
                        </Col>
                      </Row>
                    </BlockContent>
                    <ContainerActions>
                      <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.USER} />
                    </ContainerActions>
                  </UncontrolledCollapse>
                </Block>
                {/* End of General Info */}

                {/* Begin of Combo's Jewellery List */}
                <div className="d-flex align-items-center mt-2">
                  <>
                    <SelectAction
                      placeholder="Tác vụ"
                      value={action}
                      onChange={setAction}
                      options={optionActions}
                      classNamePrefix="select2-selection"
                      components={{
                        DropdownIndicator: DropDownIcon,
                        IndicatorSeparator: null,
                      }}
                    />
                    <ButtonPrimary fontWeight={500} width="75px" className="me-2" onClick={() => handleApplyAction(deleteAPI)}>
                      Áp dụng
                    </ButtonPrimary>
                    {filterElements.map((_f, index) => (
                      <FilterInput key={index} {..._f} onChange={setFilter} value={filter} />
                    ))}
                  </>
                </div>
                <ContentTable>
                  <CustomTable {...jewelleryTableProps} />
                </ContentTable>
                <div className="d-flex justify-content-end">
                  <Price>Tổng tiền: {parsePrice(calculateTotalPrice(jewelleries))} đ</Price>
                </div>
                {/* End of Combo's Jewellery List */}

                {/* Begin Table Jewellery */}
                <JewelleryManagement
                  comboId={id}
                  getRefetchAPI={handleRefetchAPI}
                  onSubmit={productCode => {
                    setFieldValue('productCode', productCode);
                    handleSubmit();
                  }}
                />
                {/* End Table Jewellery */}

                {/* Begin of SEO */}
                <Block style={{ marginTop: 16 }}>
                  <BlockHeader className="d-flex align-items-center justify-content-between" id="seo_field">
                    {seoFields.title}
                    <IconButton>
                      <ChevronUpIcon />
                    </IconButton>
                  </BlockHeader>
                  <UncontrolledCollapse toggler="#seo_field">
                    <BlockContent>
                      {seoFields.products.map(seo => {
                        return (
                          <Row key={`seoFields-${seo.name}`} className="align-items-center">
                            <FieldInput
                              {...seo}
                              error={get(touched, seo.name) || !!submitCount ? get(errors, seo.name) : null}
                              value={get(values.SEOInfo, seo.name)}
                              autoComplete="new-password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              // options={options[property.option]}
                              placeholder={seo.placeholder || seo.label}
                            />
                          </Row>
                        );
                      })}
                    </BlockContent>
                    <Row style={{ padding: 16 }} className="align-items-center">
                      <LabelInput className="col-form-label" style={{ width: '12.5%' }}>
                        {'Mô tả (description)'}
                      </LabelInput>
                      <Col>
                        <Input
                          value={get(values.SEOInfo, 'desc')}
                          type="textarea"
                          onChange={event => setFieldValue('SEOInfo.desc', event.target.value)}
                        />
                      </Col>
                    </Row>
                  </UncontrolledCollapse>
                </Block>
                <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.USER} />
                {/* End SEO */}
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

const ComboNameInput = ({ ref, autoFocus, error, name, ...rest }) => {
  return (
    <>
      <ComboName>
        <Input innerRef={ref} autoFocus={autoFocus} invalid={!!error} name={name} {...rest} />
      </ComboName>
      {error && (
        <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
          {error}
        </div>
      )}
    </>
  );
};

export default ComboItem;
