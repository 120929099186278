/* eslint-disable eqeqeq */
import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { cloneDeep, get } from 'lodash';

import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { ActionsItem, FieldInput, SeoBlock } from 'components/own';
import { useActionNotification } from 'hook/useContextSelector';
import { AuthenticationFeature } from 'constants/authentication';
import { getAPI, putAPI, uploadImage } from 'services/common';
import { E_COMMERCE_URL } from 'constants/common';
import { getChangedData } from 'utils/uti';

const initialState = {
  data: null,
};

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const NameLabel = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  color: #000000;
`;

const LinkRow = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  height: 22px;
  align-items: center;
  margin-bottom: 16px;
`;

const LinkTitle = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #000000;
`;

const LinkValue = styled.a`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  text-decoration-line: underline;

  color: #0063f7;
  margin-left: 4px;

  &:hover {
    color: #0063f7;
  }
`;

const OtherPage = () => {
  const { id } = useParams();
  const [{ data }, setState] = useState(initialState);
  const router = useHistory();
  const pushNotification = useActionNotification();

  useEffect(() => {
    getAPI(`page-setting-info?page=${id}`).then(res => {
      setState(
        produce(draft => {
          draft.data = res.data;
        })
      );
    });
  }, [id]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required('Vui lòng nhập tên trang.'),
      }),
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/page-settings');
  }, [router]);

  const onSave = useCallback(
    async values => {
      const changed = getChangedData(cloneDeep(values), data);
      if (!Object.keys(changed).length) return;
      if (changed.setting) {
        for (const iterator of changed?.setting?.productHighlight) {
          if (iterator?.image && typeof iterator?.image !== 'string') {
            const res = await uploadImage(iterator?.image);
            iterator.image = res?.url;
          }
        }
      }

      putAPI('page-setting-info?page=' + id, changed)
        .then(() => {
          pushNotification('success', 'Lưu thành công');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [data, id, pushNotification]
  );

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>{data.name}</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, submitCount, touched = {}, values = {} }) => (
          <>
            <Row className="mb-2 d-flex">
              <Col md={2} className="d-flex align-items-center">
                <NameLabel>Tên trang</NameLabel>
              </Col>
              <FieldInput
                inputMd={10}
                name="name"
                error={get(touched, 'name') || !!submitCount ? get(errors, 'name') : null}
                value={get(values, 'name')}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={'Tên trang'}
              />
            </Row>
            <LinkRow>
              <LinkTitle>Đường dẫn:</LinkTitle>
              <LinkValue target="_blank" href={`${E_COMMERCE_URL}${values?.link}`}>{`${E_COMMERCE_URL}${values?.link}`}</LinkValue>
            </LinkRow>
            <SeoBlock values={values} handleChange={handleChange} />

            <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.PRODUCT} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default OtherPage;
