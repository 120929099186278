import { memo } from 'react';

import Text from 'components/own/Text';

import { parseDate, propsAreEqual } from 'utils/uti';

const CellDate = ({ editMode, dataField, data, ...rest }) => {
  return <Text {...rest}>{data[dataField] ? parseDate(data[dataField]) : ''}</Text>;
};

export default memo(CellDate, propsAreEqual);
