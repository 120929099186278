import React from 'react';

const Checkbox = ({ id, listName, name, checked, onChange }) => {
  return (
    <div>
      <div className="form-check">
        <label className="form-check-label" htmlFor={`${listName}-${id}`} style={{ color: '#000' }}>
          {name}
        </label>
        <input
          className="form-check-input"
          type="checkbox"
          id={`${listName}-${id}`}
          checked={checked}
          onChange={e => onChange(e.target.checked)}
        />
      </div>
    </div>
  );
};

export default Checkbox;
