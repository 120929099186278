import { ControlTypes } from 'constants/field';
import { JewellTypeOptions, StockStatusOptions } from 'constants/options';
import { CellControlType } from 'constants/table';
import { JewelTypes } from 'constants/common';
import { AuthenticationFeature } from 'constants/authentication';

export const getJewelryNewInfo = () => ({
  buttonAddTitle: 'Thêm sản phẩm',
  feature: AuthenticationFeature.PRODUCT,
  columns: [
    {
      dataField: 'mediafiles.images.0.mainImage',
      text: 'Hình ảnh',
      controlType: CellControlType.Image,
    },
    {
      dataField: 'productCode',
      text: 'Mã sản phẩm',
      detailUrl: 'jewellery',
      state: 'new-jewellery',
    },
    {
      dataField: 'productName',
      text: 'Tên sản phẩm',
      detailUrl: 'jewellery',
      state: 'new-jewellery',
    },
    {
      dataField: 'inStockCount',
      text: 'Kho',
    },
    {
      dataField: 'price',
      text: 'Giá (VNĐ)',
      custom: 'price',
    },
    {
      dataField: 'newProductInfo',
      text: 'Sản phẩm mới',
      controlType: CellControlType.Status,
      custom: 'circle',
    },
    {
      dataField: 'type',
      text: 'Loại sản phẩm',
      render: value =>
        value === JewelTypes.Single ? 'Đơn' : value === JewelTypes.Couple ? 'Đôi' : value === JewelTypes.Customize ? 'Đính kèm kim cương' : '',
    },
    {
      dataField: 'productCategory',
      text: 'Danh mục',
      custom: 'array',
    },
    {
      dataField: 'newProductInfo.order',
      text: 'Thứ tự hiển thị',
    },
    {
      dataField: 'totalViews',
      text: 'Lượt',
    },
    {
      dataField: 'description',
      text: 'Mô tả',
    },
    {
      dataField: 'categoryInfo.mediafiles.image',
      text: 'Hình danh mục',
      controlType: CellControlType.Image,
    },
    {
      dataField: 'id',
      text: 'Thao tác',
      controlType: CellControlType.MenuAction,
      state: 'new-jewellery',
      detailUrl: 'jewellery',
    },
  ],
  columnTemplate: '0.5fr 1fr 1fr 0.55fr 1fr 0.55fr 0.55fr 0.7fr 0.8fr 0.7fr 0.5fr 0.5fr 0.55fr',
  filterElements: [
    {
      name: 'type',
      placeholder: 'Loại sản phẩm',
      controlType: ControlTypes.SELECT_PICKER,
      options: JewellTypeOptions,
      width: '130px',
    },
    {
      name: 'stockStatus',
      placeholder: 'Trạng thái kho',
      controlType: ControlTypes.SELECT_PICKER,
      options: StockStatusOptions,
      width: '130px',
    },
  ],
  extraCount: [
    {
      text: 'Tất cả',
      name: 'totalCount',
      keyword: 'isShowOnWeb',
      value: '',
    },
    {
      text: 'Hiển thị',
      name: 'totalShow',
      keyword: 'isShowOnWeb',
      value: true,
    },
    { text: 'Ẩn', name: 'totalHide', keyword: 'isShowOnWeb', value: false },
  ],
  selectable: true,
  title: 'Danh sách sản phẩm',
  editConfig: {
    url: 'jewellery',
    fields: [{ label: 'Tên sản phẩm', name: 'productName' }],
  },
  url: 'new-jewellery',
  actions: [
    { label: 'Ẩn', value: false, key: 'isShowOnWeb', url: 'jewellery' },
    { label: 'Hiện', value: true, key: 'isShowOnWeb', url: 'jewellery' },
  ],
});
