import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { Button, Input, Label, Row, UncontrolledCollapse } from 'reactstrap';
import { Col, Container } from 'reactstrap';
import styles from './TitleMeta.module.scss';

import { ChevronUpIcon } from 'components/icons';
import produce from 'immer';
import { useHistory } from 'react-router-dom';
import { getListSEO, updateListSEO } from 'services/SEO';
import { useActionNotification } from 'hook/useContextSelector';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #1f4173;
  margin-bottom: 17px;
`;
const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #1f4173;
`;

const BlockContent = styled(UncontrolledCollapse)`
  padding: 16px;
  display: block;
`;

const IconButton = styled.div`
  cursor: pointer;
`;
const initialState = { page: 1, total: 0, data: [], searchValue: '', selectedAction: null, limit: 10, loading: true };

const TitleMeta = () => {
  const [{ columns, data, loading, limit, page, searchValue }, setState] = useState(initialState);
  const router = useHistory();
  const pushNotification = useActionNotification();
  const ref = useRef();
  useEffect(() => {
    const callAPI = async () => {
      let query = 'limit=' + limit;
      if (page) query += '&page=' + page;
      if (searchValue) query += '&name=' + searchValue;
      try {
        const res = await getListSEO(query);
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = res.data || [];
            draft.total = res.data?.count || 0;
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.loading = false;
            draft.data = [];
            draft.total = 0;
          })
        );
      }
    };
    callAPI();
  }, [limit, page, searchValue]);

  const updateSEO = useCallback(() => {
    for (let index = 0; index <= 4; index++) {
      let id = document.getElementById('id_' + index).value;
      let dataUpdate = {
        title: document.getElementById('title_' + index).value,
        desc: document.getElementById('desc_' + index).value,
      };
      updateListSEO(id, dataUpdate)
        .then(res => {
          pushNotification('success', 'Update thành công ' + data[index]?.name);
        })
        .catch(error => {
          pushNotification('error', error.error);
        });
    }
  }, [pushNotification, data]);

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Cấu hình SEO & Meta</Title>
      </div>
      <div className={`page-content ${styles['blogs']} `}>
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  {data[0]?.name}
                  <IconButton id="pages">
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent className={`${styles['blogs__content']}`} defaultOpen toggler={`#pages`}>
                  <Row className="mb-3">
                    <Label htmlFor="blogsTitle" className="col-md-2 col-form-label">
                      Tiêu đề
                    </Label>
                    <Col md={10}>
                      <Input type="hidden" id="id_0" value={data[0]?.id}></Input>
                      <Input type="text" name="title" defaultValue={data[0]?.title} placeholder="" id="title_0" />
                      <span className={`${styles['blogs__span-info']}`}>Cụm từ đứng sau tiêu đề trang (vd: - Trang Sức VCR)</span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label htmlFor="pagesTitle" className="col-md-2 col-form-label">
                      Mô tả
                    </Label>
                    <Col md={10}>
                      <Input type="description" defaultValue={data[0]?.desc} placeholder={''} id="desc_0" />
                      <span className={`${styles['blogs__span-info']}`}>Mô tả mặt định các trang không có dữ liệu</span>
                    </Col>
                  </Row>
                </BlockContent>
              </Block>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  {data[1]?.name}
                  <IconButton id="pages">
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent className={`${styles['blogs__content']}`} defaultOpen toggler={`#pages`}>
                  <Row className="mb-3">
                    <Label htmlFor="blogsTitle" className="col-md-2 col-form-label">
                      Tiêu đề
                    </Label>
                    <Col md={10}>
                      <Input type="hidden" id="id_1" value={data[1]?.id}></Input>
                      <Input type="text" defaultValue={data[1]?.title} name="title_1" placeholder="" id="title_1" />
                      <span className={`${styles['blogs__span-info']}`}>Cụm từ đứng sau tiêu đề trang (vd: - Trang Sức VCR)</span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label htmlFor="title_1" className="col-md-2 col-form-label">
                      Mô tả
                    </Label>
                    <Col md={10}>
                      <Input type="desc_1" defaultValue={data[1]?.desc} placeholder={''} id="desc_1" />
                      <span className={`${styles['blogs__span-info']}`}>Mô tả mặt định các trang không có dữ liệu</span>
                    </Col>
                  </Row>
                </BlockContent>
              </Block>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  {data[2]?.name}
                  <IconButton id="pages">
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent className={`${styles['blogs__content']}`} defaultOpen toggler={`#pages`}>
                  <Row className="mb-3">
                    <Label htmlFor="blogsTitle" className="col-md-2 col-form-label">
                      Tiêu đề
                    </Label>
                    <Col md={10}>
                      <Input type="hidden" id="id_2" value={data[2]?.id}></Input>
                      <Input type="text" defaultValue={data[2]?.title} name="title_2" placeholder="" id="title_2" />
                      <span className={`${styles['blogs__span-info']}`}>Cụm từ đứng sau tiêu đề trang (vd: - Trang Sức VCR)</span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label htmlFor="title_2" className="col-md-2 col-form-label">
                      Mô tả
                    </Label>
                    <Col md={10}>
                      <Input type="text" defaultValue={data[2]?.desc} placeholder={''} id="desc_2" />
                      <span className={`${styles['blogs__span-info']}`}>Mô tả mặt định các trang không có dữ liệu</span>
                    </Col>
                  </Row>
                </BlockContent>
              </Block>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  {data[3]?.name}
                  <IconButton id="pages">
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent className={`${styles['blogs__content']}`} defaultOpen toggler={`#pages`}>
                  <Row className="mb-3">
                    <Label htmlFor="title_3" className="col-md-2 col-form-label">
                      Tiêu đề
                    </Label>
                    <Col md={10}>
                      <Input type="hidden" id="id_3" value={data[3]?.id}></Input>
                      <Input type="text" defaultValue={data[3]?.title} name="title_3" placeholder="" id="title_3" />
                      <span className={`${styles['blogs__span-info']}`}>Cụm từ đứng sau tiêu đề trang (vd: - Trang Sức VCR)</span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label htmlFor="desc_3" className="col-md-2 col-form-label">
                      Mô tả
                    </Label>
                    <Col md={10}>
                      <Input type="desc_3" defaultValue={data[3]?.desc} placeholder={''} id="desc_3" />
                      <span className={`${styles['blogs__span-info']}`}>Mô tả mặt định các trang không có dữ liệu</span>
                    </Col>
                  </Row>
                </BlockContent>
              </Block>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Block>
                <BlockHeader className="d-flex align-items-center justify-content-between">
                  {data[4]?.name}
                  <IconButton id="pages">
                    <ChevronUpIcon />
                  </IconButton>
                </BlockHeader>
                <BlockContent className={`${styles['blogs__content']}`} defaultOpen toggler={`#pages`}>
                  <Row className="mb-3">
                    <Label htmlFor="title_4" className="col-md-2 col-form-label">
                      Tiêu đề
                    </Label>
                    <Col md={10}>
                      <Input type="hidden" id="id_4" value={data[4]?.id}></Input>
                      <Input type="text" defaultValue={data[4]?.title} name="title_4" placeholder="" id="title_4" />
                      <span className={`${styles['blogs__span-info']}`}>Cụm từ đứng sau tiêu đề trang (vd: - Trang Sức VCR)</span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label htmlFor="desc_4" className="col-md-2 col-form-label">
                      Mô tả
                    </Label>
                    <Col md={10}>
                      <Input type="desc_4" defaultValue={data[4]?.desc} placeholder={''} id="desc_4" />
                      <span className={`${styles['blogs__span-info']}`}>Mô tả mặt định các trang không có dữ liệu</span>
                    </Col>
                  </Row>
                </BlockContent>
              </Block>
            </Col>
          </Row>
          <Row className={`${styles['blogs__submit--btn']} ${styles['blogs__submit--btn-bottom']}`}>
            <Col xl={2}>
              <Button color="warning" className="waves-effect me-1">
                <i className="ri-close-line align-middle me-2"></i>Hủy
              </Button>
            </Col>
            <Col xl={2}>
              <Button color="success" onClick={updateSEO} className="waves-effect waves-light me-1">
                <i className="ri-check-line align-middle me-2"></i>Lưu
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default TitleMeta;
