import { ChevronUpIcon } from 'components/icons';
import produce from 'immer';
import { cloneDeep, filter, includes } from 'lodash';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Input, UncontrolledCollapse } from 'reactstrap';
import { getAllPermissions } from 'services/role';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/uti';

const initialState = {
  matrix: [],
};

const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
`;

const BlockContent = styled(UncontrolledCollapse)`
  display: grid;
  padding: 16px;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 18px;
  pointer-events: ${({ $readOnly }) => ($readOnly ? 'none' : '')};
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const RowTitle = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;

  color: #1f4173;
`;

const isChecked = (values = [], code) => {
  // const module = code.split('_')[0];
  // const act = code.split('_')[1];
  return includes(values, code); // includes(values, `ALL_ALL`) || includes(values, code) || includes(values, `ALL_${act}`) || includes(values, `${module}_ALL`);
};

const PermissionMatrix = ({ values, handleChange, readOnly }) => {
  const [{ matrix }, setState] = useState(initialState);
  useEffect(() => {
    getAllPermissions().then(res => {
      setState(
        produce(draft => {
          draft.matrix = res.data;
        })
      );
    });
  }, []);

  const onClick = useCallback(
    e => {
      const isChecked = e.target.checked;
      const code = e.target.value;
      let currentValues = cloneDeep(values);

      if (isChecked) {
        const module = code.split('_')[0];
        const act = code.split('_')[1];
        if (code === 'ALL_ALL') {
          currentValues = [];
        } else if (module === 'ALL') {
          currentValues = filter(currentValues, _v => !includes(_v, '_' + act) && !includes(_v, 'ALL_ALL'));
        } else if (act === 'ALL') {
          currentValues = filter(currentValues, _v => !includes(_v, module + '_') && !includes(_v, 'ALL_ALL'));
        } else {
          currentValues = filter(
            currentValues,
            _v => !includes(_v, 'ALL_' + act) && !includes(_v, module + '_ALL') && !includes(_v, 'ALL_ALL')
          );
        }
        currentValues.push(code);
      } else {
        // if (includes(code, 'ALL_ALL')) {
        //   currentValues = [];
        // } else {
        // }
        currentValues = filter(currentValues, _v => !includes(_v, code));
      }

      handleChange({ target: { name: 'permission', value: currentValues } });
    },
    [handleChange, values]
  );

  return (
    <Block>
      <BlockHeader className="d-flex align-items-center justify-content-between">
        Quyền hạn
        <IconButton id="permission-matrix__toggle">
          <ChevronUpIcon />
        </IconButton>
      </BlockHeader>
      <BlockContent defaultOpen toggler={`#permission-matrix__toggle`} $readOnly={readOnly}>
        <>
          <RowTitle />
          <RowTitle className="d-flex align-items-center justify-content-center">Manage All</RowTitle>
          <RowTitle className="d-flex align-items-center justify-content-center">Có Thể View</RowTitle>
          <RowTitle className="d-flex align-items-center justify-content-center">Có thể thêm</RowTitle>
          <RowTitle className="d-flex align-items-center justify-content-center">Có thể chỉnh sửa</RowTitle>
          <RowTitle className="d-flex align-items-center justify-content-center">Có thể Xóa</RowTitle>
        </>
        {matrix.map(_d => (
          <React.Fragment key={_d.id}>
            <RowTitle>{_d.name}</RowTitle>
            {_d.list?.map(_p => (
              <div key={_p} className="d-flex align-items-center justify-content-center">
                <Input className="form-check-input" type="checkbox" value={_p} checked={isChecked(values, _p)} onChange={onClick} />
              </div>
            ))}
          </React.Fragment>
        ))}
      </BlockContent>
    </Block>
  );
};

export default memo(PermissionMatrix, propsAreEqual);
