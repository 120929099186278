import React, { useRef } from 'react';
import styled from 'styled-components';
import defaultAvatar from 'assets/images/default-avatar.png';

const AvatarImage = styled.img`
    cursor: pointer;
    padding: 5px;
    border: 1px solid #C4C4C4;
    &:hover {
        opacity: 0.7
  }
`;
const Avatar = ({ avatarSrc = defaultAvatar, onChangeImage }) => {
    const fileRef = useRef(null);

    return (
        <>
            <AvatarImage
                onClick={() => fileRef.current.click()}
                src={avatarSrc}
                alt="avatar"
                width={120}
                height={120}
            />
            <input onChange={onChangeImage} style={{ display: 'none' }} ref={fileRef} type={"file"} />
        </>
    )
}

export default Avatar;
