import { AuthenticationTypes } from 'constants/authentication';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Authentication } from '.';

const Actions = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(178px,max-content) );\
  grid-gap:12px;
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  width: 178px;
  height: 56px;

  background: ${({ type }) => (type === 'primary' ? '#007770' : '#ffffff')};
  /* Primary/Main Color */

  border: 1px solid #007770;
  box-sizing: border-box;
  border-radius: 4px;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;
  text-transform: uppercase;

  /* Primary/Main Color */

  color: ${({ type }) => (type === 'primary' ? '#fff' : '#007770')};

  i {
    margin-right: 7px;
    font-size: 18px;
  }
`;

const ActionsItem = ({ onCancel, onSaveTemp, onSave, feature, children }) => {
  const params = useParams();
  return (
    <Actions>
      {children}

      {onCancel && (
        <Button onClick={onCancel}>
          <i className="mdi mdi-close"></i>
          Hủy
        </Button>
      )}
      {onSaveTemp && (
        <Button onClick={onSaveTemp} feature={feature} type={params?.id === 'new' ? AuthenticationTypes.CREATE : AuthenticationTypes.EDIT}>
          <i className="mdi mdi-paperclip"></i>
          Lưu nháp
        </Button>
      )}
      {onSave && (
        <Authentication feature={feature} type={params?.id === 'new' ? AuthenticationTypes.CREATE : AuthenticationTypes.EDIT}>
          <Button type="primary" onClick={onSave}>
            <i className="mdi mdi-content-save-outline"></i>
            Lưu
          </Button>
        </Authentication>
      )}
    </Actions>
  );
};

export default ActionsItem;
