import axios from 'axios';

export const getAllLink404 = (query = '') => axios({ url: '404?' + query, method: 'GET' });
export const deleteLink404 = id => axios({ url: '404/' + id, method: 'DELETE' });
export const getAllLinkSiteMap = (query = '') => axios({ url: 'sitemaps?' + query, method: 'GET' });
export const getAllLinkRedirect = (query = '') => axios({ url: 'redirects?' + query, method: 'GET' });

export const createLinkRedirect = data => axios({ url: 'redirects', method: 'POST', data });

export const updateLinkRedirect = (id, data) => axios({ url: 'redirects/' + id, method: 'PUT', data });

export const getListSEO = (query = '') => axios({ url: 'SEO?' + query, method: 'GET' });

export const updateListSEO = (id, data) => axios({ url: 'SEO/' + id, method: 'PUT', data });

export const deleteBlog = id => axios({ url: 'blogs/' + id, method: 'DELETE' });

export const getAllCategory = (query = '') => axios({ url: 'blogType?' + query, method: 'GET' });

export const getAllTags = (query = '') => axios({ url: 'tags?' + query, method: 'GET' });
