import { omit } from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;
const Root = styled.div`
  position: absolute;
  top: 0;
`;

const Header = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  color: #1f4173;
`;

const QuickEdit = ({ data, header, style }) => {
  return (
    <Root>
      <Header>Chỉnh sửa nhanh</Header>
    </Root>
  );
};

export default QuickEdit;
