/* eslint-disable eqeqeq */
import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { cloneDeep, find, get, includes, isEqual, set } from 'lodash';
import { Accordion } from 'react-bootstrap';

import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { ActionsItem, FieldInput, SeoBlock } from 'components/own';
import { useActionNotification } from 'hook/useContextSelector';
import { AuthenticationFeature } from 'constants/authentication';
import { getAPI, putAPI, uploadImage } from 'services/common';
import { E_COMMERCE_URL } from 'constants/common';
import { ControlTypes } from 'constants/field';
import { getChangedData } from 'utils/uti';

const initialState = {
  categories: [],
  data: null,
  options: {},
  originalCategories: [],
};

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const SubTitle = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  color: #1f4173;
`;

const LinkRow = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr;
  height: 22px;
  align-items: center;
  margin-bottom: 16px;
`;

const LinkTitle = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #000000;
`;

const LinkValue = styled.a`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  text-decoration-line: underline;

  color: #0063f7;
  margin-left: 4px;

  &:hover {
    color: #0063f7;
  }
`;

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ $templateColumns }) => $templateColumns || '1fr'};
  grid-column-gap: 60px;
  grid-row-gap: 16px;
  background-color: #fff;
  border: 0;
`;

const Content = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ $templateColumns }) => $templateColumns || '1fr'};
  grid-column-gap: 60px;
  grid-row-gap: 16px;
  background-color: #fff;
  border: 0;
`;

// const AddCategory = styled.div`
//   background: #ffffff;
//   border: 1px dashed #8f90a6;
//   box-sizing: border-box;
//   border-radius: 4px;

//   font-family: 'Quicksand';
//   font-style: normal;
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 140%;
//   /* or 22px */

//   text-align: center;
//   text-transform: uppercase;

//   /* Dark / Dark 0 */

//   color: #1c1c28;

//   width: 178px;
//   height: 56px;

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   cursor: pointer;
// `;

const DashboardPage = () => {
  const [{ categories, data, originalCategories }, setState] = useState(initialState);

  const router = useHistory();
  const pushNotification = useActionNotification();

  useEffect(() => {
    Promise.all([getAPI(`page-setting-info?page=`), getAPI(`categories`)]).then(res => {
      setState(
        produce(draft => {
          draft.categories = cloneDeep(get(res, [1, 'data'], []));
          draft.originalCategories = cloneDeep(get(res, [1, 'data'], []));
          draft.data = get(res, [0, 'data']);
        })
      );
    });
  }, []);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required('Vui lòng nhập tên trang.'),
      }),
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/page-settings');
  }, [router]);

  const onSave = useCallback(
    async values => {
      const request = getChangedData(cloneDeep(values), data);
      if (request.setting) {
        for (const iterator of request?.setting?.productHighlight) {
          if (iterator?.image && typeof iterator?.image !== 'string') {
            const res = await uploadImage(iterator?.image);
            iterator.image = res?.url;
          }
        }

        if (!!request?.setting?.storeContactImage && typeof request?.setting?.storeContactImage !== 'string') {
          const res = await uploadImage(request?.setting?.storeContactImage);
          request.setting.storeContactImage = res?.url;
        }
      }

      putAPI('page-setting-info?page=', request)
        .then(() => {
          pushNotification('success', 'Lưu thành công');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [data, pushNotification]
  );

  const handleChangeCategory = useCallback(e => {
    let value = e.target.value;
    const name = e.target.name;

    if (includes(name, 'status')) {
      value = !!value ? -1 : 1;
    }

    setState(
      produce(draft => {
        set(draft.categories, name, value);
      })
    );
  }, []);

  const handleSaveCategory = useCallback(
    async values => {
      const request = [];
      for (const iterator of values) {
        const item = find(originalCategories, ['id', iterator.id]);
        if (isEqual(item, iterator)) continue;
        if (iterator.mediafiles?.image && typeof iterator.mediafiles?.image !== 'string') {
          const res = await uploadImage(iterator.mediafiles?.image);
          iterator.mediafiles.image = res?.url;
        }
        request.push(putAPI(`categories/${iterator.id}`, getChangedData(iterator, item)));
      }
      if (!request.length) return;

      Promise.all(request).then(res => {
        pushNotification('success', 'Lưu thành công');
      });
    },
    [originalCategories, pushNotification]
  );

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>{data.name}</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, submitCount, touched = {}, values = {} }) => (
          <>
            <Row className="mb-2 d-flex">
              <FieldInput
                inputMd={12}
                name="name"
                error={get(touched, 'name') || !!submitCount ? get(errors, 'name') : null}
                value={get(values, 'name')}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={'Tên trang'}
              />
            </Row>
            <LinkRow>
              <LinkTitle>Đường dẫn:</LinkTitle>
              <LinkValue target="_blank" href={`${E_COMMERCE_URL}${values?.link}`}>{`${E_COMMERCE_URL}${values?.link}`}</LinkValue>
            </LinkRow>
            <Accordion alwaysOpen defaultActiveKey={['info', 'category', 'store', 'seo']}>
              <Accordion.Item className="mb-3" eventKey="info">
                <BlockHeader className="d-flex align-items-center justify-content-between">Product Highlight</BlockHeader>
                <BlockContent $templateColumns="1fr 1fr">
                  <Col>
                    <Row className="mb-3">
                      <SubTitle>Hình 1</SubTitle>
                    </Row>
                    <Row className="mb-3">
                      <FieldInput
                        inputMd={6}
                        label="Hình"
                        controlType={ControlTypes.FILE}
                        accept="image/*"
                        name={`setting.productHighlight.0.image`}
                        value={get(values, 'setting.productHighlight.0.image')}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row className="mb-3">
                      <FieldInput
                        inputMd={6}
                        label="Tiêu đề"
                        name={`setting.productHighlight.0.title`}
                        value={get(values, 'setting.productHighlight.0.title')}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row className="mb-3">
                      <FieldInput
                        inputMd={6}
                        label="Link"
                        name={`setting.productHighlight.0.link`}
                        value={get(values, 'setting.productHighlight.0.link')}
                        onChange={handleChange}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row className="mb-3">
                      <SubTitle>Hình 2</SubTitle>
                    </Row>
                    <Row className="mb-3">
                      <FieldInput
                        inputMd={6}
                        label="Hình"
                        controlType={ControlTypes.FILE}
                        accept="image/*"
                        name={`setting.productHighlight.1.image`}
                        value={get(values, 'setting.productHighlight.1.image')}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row className="mb-3">
                      <FieldInput
                        inputMd={6}
                        label="Tiêu đề"
                        name={`setting.productHighlight.1.title`}
                        value={get(values, 'setting.productHighlight.1.title')}
                        onChange={handleChange}
                      />
                    </Row>
                    <Row className="mb-3">
                      <FieldInput
                        inputMd={6}
                        label="Link"
                        name={`setting.productHighlight.1.link`}
                        value={get(values, 'setting.productHighlight.1.link')}
                        onChange={handleChange}
                      />
                    </Row>
                  </Col>
                </BlockContent>
              </Accordion.Item>

              <Accordion.Item className="mb-3" eventKey="category">
                <BlockHeader className="d-flex align-items-center justify-content-between">Danh mục</BlockHeader>
                <BlockContent>
                  <Content $templateColumns="1fr 1fr">
                    {categories?.map((_cate, i) => (
                      <Col key={i} className="mb-4">
                        {/* <Row className="mb-3">
                          <FieldInput label="Danh mục" options={categories} value={get(categories, [i, 'id'])} />
                        </Row> */}
                        <Row className="mb-3">
                          <FieldInput
                            label="Ẩn"
                            controlType={ControlTypes.CHECKBOX}
                            options={categories}
                            value={get(categories, [i, 'status']) !== 1}
                            name={`${i}.status`}
                            onChange={handleChangeCategory}
                          />
                        </Row>

                        <Row className="mb-3">
                          <FieldInput label="Tiêu đề" value={get(categories, [i, 'name'])} readOnly />
                        </Row>
                        <Row className="mb-3">
                          <FieldInput label="Link" value={get(categories, [i, 'link'])} readOnly />
                        </Row>
                        <Row className="mb-3">
                          <FieldInput
                            inputMd={6}
                            label="Hình"
                            controlType={ControlTypes.FILE}
                            accept="image/*"
                            name={`${i}.mediafiles.image`}
                            value={get(categories, [i, 'mediafiles', 'image'])}
                            onChange={handleChangeCategory}
                          />
                        </Row>
                      </Col>
                    ))}
                  </Content>
                  <ActionsItem onSave={() => handleSaveCategory(cloneDeep(categories))} feature={AuthenticationFeature.PAGE} />
                </BlockContent>
              </Accordion.Item>
              <Accordion.Item className="mb-3" eventKey="store">
                <BlockHeader className="d-flex align-items-center justify-content-between">Tìm cửa hàng</BlockHeader>
                <BlockContent $templateColumns="1fr">
                  <Row className="mb-2">
                    <FieldInput
                      name="setting.storeContactText"
                      label="Đoạn văn"
                      error={get(touched, 'setting.storeContactText') || !!submitCount ? get(errors, 'setting.storeContactText') : null}
                      value={get(values, 'setting.storeContactText')}
                      controlType={ControlTypes.RICH_TEXT}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Row>
                  <Row className="mb-3">
                    <FieldInput
                      inputMd={6}
                      label="Banner"
                      controlType={ControlTypes.FILE}
                      accept="image/*"
                      name={`setting.storeContactImage`}
                      value={get(values, ['setting', 'storeContactImage'])}
                      onChange={handleChange}
                    />
                  </Row>
                  <Row>
                    <FieldInput
                      label="Link banner/button"
                      name={`setting.storeContactLink`}
                      value={get(values, ['setting', 'storeContactLink'])}
                      onChange={handleChange}
                    />
                  </Row>
                </BlockContent>
              </Accordion.Item>
            </Accordion>
            <SeoBlock values={values} handleChange={handleChange} />
            <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.PAGE} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default DashboardPage;
