import React, { useMemo, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Col, Row, UncontrolledCollapse, Label } from 'reactstrap';
import { useParams, useHistory } from 'react-router';
import produce from 'immer';
import { Formik } from 'formik';
import * as yup from 'yup';
import { get } from 'lodash';
import { ChevronUpIcon } from 'components/icons';
import { ActionsItem, FieldInput, CustomTable } from 'components/own';
import { getCustomerById, updateCustomerById } from 'services/customers';
import { getAllMemberships } from 'services/memberships';
import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { CellControlType } from 'constants/table';
import { parsePrice } from 'utils/uti';
import { useActionNotification } from 'hook/useContextSelector';
import defaultAvatar from 'assets/images/default-avatar.png';

import Avatar from './components/Avatar';
import CustomerSummaryItem from './components/CustomerSummaryItem';
import OrderTable from './components/OrderTable';
import { API_UPLOAD } from 'constants/common';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;
const CustomerSummary = styled.div`
  background-color: #fff;
  display: flex;
  margin-bottom: 16px;
`;

const Block = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const BlockHeader = styled.div`
  padding: 0 12px;
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  color: #1f4173;
  cursor: pointer;
`;

const BlockContent = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
`;
const IconButton = styled.div`
  cursor: pointer;
`;
const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
`;
const Category = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const ContentTable = styled.div`
  background-color: #fff;
`;
const ContainerActions = styled.div`
  background-color: #f1f5f7;
  padding-top: 16px;
`;

const CustomerItem = () => {
  const [state, setState] = useState({ data: null, options: { memberships: null } });
  const { data, options } = state;
  const { id } = useParams();
  const router = useHistory();
  const pushNotification = useActionNotification();
  const wishlistTableProps = useMemo(
    () => ({
      columnTemplate: '1fr 1fr 1fr 3fr 1fr 1fr',
      data: data?.wishlist,
      headers: [
        {
          dataField: 'order',
          text: 'No',
          custom: 'index',
        },
        {
          dataField: 'jewelleryInfo.mediafiles.images.0.mainImage ?? diamondInfo.generalInfo.mediafiles.images.0.mainImage',
          text: 'Hình',
          render: imgSrc => <img src={imgSrc} alt="imgSrc" width={50} height={50} />,
        },
        {
          dataField: 'jewelleryInfo.productCode ?? diamondInfo.generalInfo.productCode',
          text: 'Mã sản phẩm',
        },
        {
          dataField: 'jewelleryInfo.mainCategory',
          text: 'Tên',
          render: name => <div>{name ?? 'Kim cương'}</div>,
        },
        {
          dataField: 'createdAt',
          text: 'Ngày lưu',
          controlType: CellControlType.Date,
        },
        {
          dataField: 'jewelleryInfo.price ?? diamondInfo.price',
          text: 'Giá (VNĐ)',
          custom: 'price',
        },
      ],
      // headerBackground: '#F2F2F5',
      rowHeight: 83,
    }),
    [data?.wishlist]
  );
  useEffect(() => {
    getCustomerById(id).then(res => {
      res.statusCode === 200 &&
        setState(
          produce(draft => {
            draft.data = { ...res.data };
          })
        );
    });
  }, [id]);
  useEffect(() => {
    const callAPI = async () => {
      const res = await getAllMemberships();
      const status = [
        {
          value: 1,
          label: 'Hoạt động',
        },
        {
          value: -2,
          label: 'Xóa',
        },
      ];

      setState(
        produce(draft => {
          draft.options.memberships = res?.data.map(({ id, name, point, percentDiscount }) => ({
            value: id,
            label: name,
            point,
            percentDiscount,
          }));
          draft.options.status = status;
        })
      );
    };
    callAPI();
  }, []);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        // name: yup.string().required('Vui lòng nhập tên tài khoản.'),
        // userType: yup.string().required('Vui lòng chọn loại tài khoản.'),
        // password: data.id
        //     ? yup.string().matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z])[A-Za-z\d].{7,}$/, ' ')
        //     : yup
        //         .string()
        //         .required('Vui lòng nhập mật khẩu.')
        //         .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z])[A-Za-z\d].{7,}$/, 'Mật khẩu có ít nhất 7 ký tự và có chứa ký tự in hoa và số.'),
        // confirmPassword: yup.string().when('yup', {
        //     is: val => !!val && !!val.length,
        //     then: yup
        //         .string()
        //         .required('Vui lòng xác nhận mật khẩu.')
        //         .oneOf([yup.ref('yup')], 'Xác nhận mật khẩu và mật khẩu phải giống nhau.'),
        // }),
        // status: yup.string(),
        fullname: yup.string(),
        phone: yup
          .string()
          .nullable()
          .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Vui lòng nhập đúng số điện thoại'),
        email: yup.string().required('Vui lòng nhập địa chỉ email.').email('Email không hợp lệ.'),
      }),
    []
  );

  const fields = useMemo(
    () => ({
      id: 'customer-info-block',
      title: 'Thông tin khách hàng',
      top_blocks: [
        {
          label: 'Họ và tên',
          name: 'name',
        },
        {
          label: 'Số điện thoại',
          name: 'phone',
        },
        {
          label: 'Email',
          name: 'email',
        },
        {
          label: 'Hình đại diện',
          name: 'avatar',
        },
      ],
      bottom_blocks: [
        {
          label: 'Mật khẩu',
          name: 'password',
        },
        {
          label: 'Nhập lại mật khẩu',
          name: 'confirmPassword',
        },
        {
          label: 'Trạng thái',
          name: 'status',
          controlType: ControlTypes.SELECT_PICKER,
          option: 'status',
        },
        {
          label: 'Loại tài khoản',
          name: 'type',
          controlType: ControlTypes.SELECT_PICKER,
          option: 'memberships',
          isDisabled: true,
        },
      ],
    }),
    []
  );

  const onSave = useCallback(
    values => {
      updateCustomerById(values.id, values)
        .then(res => {
          pushNotification('success', 'Lưu thành công');
          router.replace('/customers');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [pushNotification, router]
  );
  const onCancel = useCallback(() => {
    router.replace('/customers');
  }, [router]);

  const handleChangeImage = useCallback(
    (event, setFieldValue) => {
      const image = event.currentTarget.files[0];
      const formData = new FormData();
      formData.append('file', image);

      fetch(API_UPLOAD, {
        method: 'post',
        body: formData,
      })
        .then(response => {
          new Response(response.body, { headers: { 'Content-Type': 'text/html' } })
            .text()
            .then(res => {
              const respJson = JSON.parse(res);
              setFieldValue('avatar', respJson.data.url);
            })
            .catch(error => {
              pushNotification('error', error);
            });
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [pushNotification]
  );

  if (!data) return <div />;
  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Chi tiết khách hàng</Title>
      </div>
      <CustomerSummary>
        <CustomerSummaryItem color="#1DBABA" label="Đơn hàng" value={data.totalOrderCount} borderRight />
        <CustomerSummaryItem color="#E57A00" label="Yêu thích" value={data.totalWishlist} borderRight />
        <CustomerSummaryItem color="#007770" label="Tổng đơn" value={parsePrice(data.totalOrderPrice) + ' VNĐ'} />
      </CustomerSummary>
      <Formik initialValues={data} onSubmit={onSave} validationSchema={validationSchema}>
        {({ errors = {}, handleSubmit, handleBlur, handleChange, setFieldValue, submitCount, touched = {}, values = {} }) => (
          <>
            <Block>
              <BlockHeader className="d-flex align-items-center justify-content-between" id="heo">
                {fields.title}
                <IconButton>
                  <ChevronUpIcon />
                </IconButton>
              </BlockHeader>
              <UncontrolledCollapse defaultOpen toggler="#heo">
                <BlockContent>
                  {fields.top_blocks.map(field => (
                    <Row
                      key={field.name}
                      className="align-items-center"
                      style={
                        field.name === 'avatar'
                          ? {
                              gridRowStart: 1,
                              gridRowEnd: 4,
                              gridColumnStart: 2,
                              gridColumnEnd: 2,
                            }
                          : {}
                      }
                    >
                      {field.name === 'avatar' ? (
                        <>
                          <LabelInput className="col-md-3 col-form-label">{field.label}</LabelInput>
                          <Col md={9}>
                            <Avatar
                              avatarSrc={get(values, field.name) ?? defaultAvatar}
                              onChangeImage={e => handleChangeImage(e, setFieldValue)}
                            />
                          </Col>
                        </>
                      ) : (
                        <FieldInput
                          {...field}
                          error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                          value={get(values, field.name)}
                          autoComplete="new-password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // options={options[field.option]}
                          placeholder={field.placeholder || field.label}
                        />
                      )}
                    </Row>
                  ))}
                </BlockContent>
                <BlockContent>
                  {fields.bottom_blocks.map(field => (
                    <Row key={field.name} className="align-items-center">
                      <FieldInput
                        {...field}
                        error={get(touched, field.name) || !!submitCount ? get(errors, field.name) : null}
                        value={get(values, field.name)}
                        autoComplete="new-password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        options={options[field.option]}
                        placeholder={field.placeholder || field.label}
                      />
                    </Row>
                  ))}
                </BlockContent>
                <ContainerActions>
                  <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.USER} />
                </ContainerActions>
              </UncontrolledCollapse>
            </Block>
          </>
        )}
      </Formik>
      <Category>Đơn hàng</Category>
      <ContentTable>
        <OrderTable orders={data?.orders} />
      </ContentTable>
      <Category>Yêu Thích</Category>
      <ContentTable>
        <CustomTable {...wishlistTableProps} />
      </ContentTable>
    </div>
  );
};

export default CustomerItem;
