import { forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { Input } from 'reactstrap';

import { FilterInput } from '../Filter';
import ButtonPrimary from '../ButtonPrimary';
import { useAddProductHook } from './useAddProductHook';
import { CustomTable, Paginate } from '../Table';
import ActionsItem from '../ActionsItem';
import { AuthenticationFeature } from 'constants/authentication';
import ButtonOutline from '../ButtonOutline';
import { DropDownIcon } from 'components/icons';

const SelectAction = styled(Select)`
  cursor: pointer;
  width: 180px;
  margin-right: 4px;
  font-size: 14px;
  .select2-selection__indicators {
    padding-right: 4px;
  }
`;

const ContentTable = styled.div`
  background-color: #fff;
  margin-top: 16px;
`;

const SearchBox = styled(Input)`
  width: 200px;
  height: 32px;
  background: #fafafc;
  input:after {
    border-bottom-width: 1px !important;
  }
`;

const Label = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  display: flex;
  align-items: center;

  /* Dark / Dark 0 */

  color: #1c1c28;
`;

const AddonProductTable = forwardRef(({ feature }, containerRef) => {
  const {
    columns,
    columnTemplate,
    other,
    selected,
    selectedFilterElements = [],
    filterElements = [],
    hidePaging,
    loading,
    ref,
    selectable,
    title,

    onChangePage,
    onClickFilter,
    onKeypressFilter,
    onOrder,
    onSelectFilter,
    onSelectRow,

    addToSelected,
    removeFromSelected,
    handleSubmit,
    onCancel,
  } = useAddProductHook(feature);
  useImperativeHandle(containerRef, () => ({
    getSelected: () => selected.data,
  }));

  return (
    <>
      <div className="d-flex align-items-center mt-2">
        <>
          <SelectAction
            placeholder="Tác vụ"
            value={{ label: `Xóa khỏi ${title}`, value: 1 }}
            options={[{ label: `Xóa khỏi ${title}`, value: 1 }]}
            classNamePrefix="select2-selection"
            components={{
              DropdownIndicator: DropDownIcon,
              IndicatorSeparator: null,
            }}
          />
          <ButtonPrimary fontWeight={500} width="75px" className="me-2" onClick={removeFromSelected}>
            Áp dụng
          </ButtonPrimary>
        </>
        {selectedFilterElements.map((_f, index) => (
          <FilterInput
            key={index}
            {..._f}
            onChange={e => onSelectFilter(e, 'selected')}
            value={selected.params[_f.name]}
            minDate={selected.params[_f.minDate]}
            maxDate={selected.params[_f.maxDate]}
          />
        ))}

        <div className="flex-1"></div>
      </div>
      <ContentTable className="mb-3">
        <CustomTable
          selectable={true}
          columnTemplate={columnTemplate}
          rowSelected={selected.selectedRow || []}
          onChecked={(id, isCheck) => onSelectRow(id, isCheck, 'selected')}
          data={selected.data || []}
          headers={columns}
          rowHeight={83}
        />
      </ContentTable>
      <ActionsItem onSave={handleSubmit} onCancel={onCancel} feature={AuthenticationFeature.PRODUCT} />
      <div className="d-flex align-items-center justify-content-end mt-3">
        <SearchBox innerRef={ref} type="text" className="form-control me-3" onKeyPress={onKeypressFilter} placeholder="Nhập keyword" />
        <ButtonOutline onClick={onClickFilter}>Tìm kiếm</ButtonOutline>
      </div>
      <div className="d-flex align-items-center mt-2">
        <SelectAction
          placeholder="Tác vụ"
          value={{ label: `Thêm vào ${title}`, value: 1 }}
          options={[{ label: `Thêm vào ${title}`, value: 1 }]}
          classNamePrefix="select2-selection"
          components={{
            DropdownIndicator: DropDownIcon,
            IndicatorSeparator: null,
          }}
        />
        <ButtonPrimary fontWeight={500} width="75px" className="me-2" onClick={addToSelected}>
          Áp dụng
        </ButtonPrimary>
        {filterElements.map((_f, index) => (
          <FilterInput
            key={index}
            {..._f}
            onChange={e => onSelectFilter(e, 'other')}
            value={other.params[_f.name]}
            minDate={other.params[_f.minDate]}
            maxDate={other.params[_f.maxDate]}
          />
        ))}

        <div className="flex-1"></div>
        <Label className="me-2">{other.total || 0} mục</Label>
        {!!other.total && !hidePaging && <Paginate page={other.page} total={other.total} itemPerPage={other.limit} onChange={onChangePage} />}
      </div>
      <ContentTable className="">
        <CustomTable
          selectable={selectable}
          columnTemplate={columnTemplate}
          rowSelected={other.selectedRow}
          onChecked={(id, isCheck) => onSelectRow(id, isCheck, 'other')}
          data={other.data}
          headers={columns}
          order={other.order}
          loading={loading}
          rowHeight={83}
          onOrder={onOrder}
          onRefresh={() => onClickFilter(true)}
        />
      </ContentTable>
    </>
  );
});

export default AddonProductTable;
