import React, { Component, Suspense } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes';
import AppRoute from './routes/route';

// layouts
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';

//Fake backend
import { setUpApi } from 'utils/service';
import { AuthenticationProvider } from 'context/Authentication';
import LoadingPage from 'components/own/LoadingPage';

setUpApi();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={<LoadingPage />}>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute layout={NonAuthLayout} key={idx} isAuthProtected={false} {...route} />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <AppRoute layout={AuthenticationProvider} key={idx} isAuthProtected={true} {...route} />
              ))}
              <Redirect from="*" to="/dashboard" />
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
