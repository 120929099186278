import axios from 'axios';

export const logIn = data =>
  axios({
    url: 'login',
    method: 'post',
    data,
  });

export const getCurrentInfo = () => axios({ url: 'info', method: 'GET' });

export const getAllUsers = query => axios({ url: 'users?' + query, method: 'GET' });

export const getUserById = id => axios({ url: 'users/' + id, method: 'GET' });

export const createUser = data => axios({ url: 'users', method: 'POST', data });

export const updateUser = (id, data) => axios({ url: 'users/' + id, method: 'PUT', data });

export const deleteUser = id => axios({ url: 'users/' + id, method: 'DELETE' });
