import React, { useCallback, useEffect, useState } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { Accordion } from 'react-bootstrap';
import { Col, Label, Row } from 'reactstrap';

import { ActionsItem, FieldInput } from 'components/own';
import { AddBlockButton } from 'components/icons';
import { cloneDeep, get } from 'lodash';
import { getAPI, putAPI, uploadImage } from 'services/common';
import { useActionNotification } from 'hook/useContextSelector';
import { Formik } from 'formik';
import { ControlTypes } from 'constants/field';
import { AuthenticationFeature } from 'constants/authentication';

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #1f4173;

  margin-bottom: 17px;
`;

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;

  background-color: #fff;

  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;

    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ templateColumn }) => templateColumn || '1fr'};
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const Block = styled(Accordion)`
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const SubItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
`;

const BlockSubItem = styled.div`
  display: grid;
  grid-row-gap: 8px;
`;

const IconButton = styled.div`
  cursor: pointer;
  color: #e53535;
  margin-right: 16px;
`;

const AddSubItem = styled.div`
  width: 100%;
  height: 100%;
  min-height: 112px;
  left: 16px;
  top: 16px;

  /* Dark/Dark 3 */

  border: 1px dashed #8f90a6;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const title = ['Về Vĩnh Cara', 'Khi Mua Online', 'Menu footer cột thứ 3', 'Menu footer cột thứ 4', 'Menu footer cột thứ 5'];
const menuFooter = [0, 1, 2, 3, 4];
const initialState = { data: null };
const initialBlock = { title: '', link: '' };
const SettingFooter = () => {
  const [{ data }, setState] = useState(initialState);
  const pushNotification = useActionNotification();

  const onSave = useCallback(
    async values => {
      const changed = cloneDeep(values);
      for (const key in changed.setting) {
        if (Object.hasOwnProperty.call(changed.setting, key)) {
          const iterator = changed.setting[key];
          if (iterator?.icon && typeof iterator?.icon !== 'string') {
            const res = await uploadImage(iterator?.icon);
            iterator.icon = res?.url;
          }
          // if (iterator?.iconActive && typeof iterator?.iconActive !== 'string') {
          //   const res = await uploadImage(iterator?.iconActive);
          //   iterator.iconActive = res?.url;
          // }
        }
      }
      for (const key in changed.setting?.menuFooter) {
        if (Object.hasOwnProperty.call(changed.setting?.menuFooter, key)) {
          const iterator = changed.setting?.menuFooter[key];
          if (iterator === undefined) {
            changed.setting.menuFooter[key] = {};
          }
        }
      }

      putAPI('general-info/3', changed)
        .then(() => {
          pushNotification('success', 'Lưu thành công');
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [pushNotification]
  );

  useEffect(() => {
    getAPI('general-info/3').then(res => {
      setState(
        produce(draft => {
          draft.data = get(res, ['data']);
        })
      );
    });
  }, []);
  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>Cấu hình Footer</Title>
      </div>
      <Formik initialValues={data} onSubmit={onSave}>
        {({ handleChange, handleSubmit, values }) => (
          <>
            <Block alwaysOpen defaultActiveKey={['info']}>
              <Accordion.Item eventKey={'info'}>
                <BlockHeader>Phone</BlockHeader>
                <BlockContent templateColumn="1fr 1fr">
                  <Label>Text</Label>
                  <Label>Icon</Label>
                  <Row>
                    <FieldInput value={get(values, 'setting.time.text')} label={'Giờ'} name={`setting.time.text`} onChange={handleChange} />
                  </Row>
                  <Row>
                    <FieldInput
                      previewIcon
                      inputMd={9}
                      value={get(values, 'setting.time.icon')}
                      name={`setting.time.icon`}
                      onChange={handleChange}
                      controlType={ControlTypes.FILE}
                    />
                  </Row>
                  <Row>
                    <FieldInput
                      value={get(values, 'setting.phone.text')}
                      label={'Số điện thoại'}
                      name={`setting.phone.text`}
                      onChange={handleChange}
                    />
                  </Row>
                  <Row>
                    <FieldInput
                      previewIcon
                      inputMd={9}
                      value={get(values, 'setting.phone.icon')}
                      name={`setting.phone.icon`}
                      onChange={handleChange}
                      controlType={ControlTypes.FILE}
                    />
                  </Row>
                  <Row>
                    <FieldInput value={get(values, 'setting.email.text')} label={'Email'} name={`setting.email.text`} onChange={handleChange} />
                  </Row>
                  <Row>
                    <FieldInput
                      previewIcon
                      inputMd={9}
                      value={get(values, 'setting.email.icon')}
                      name={`setting.email.icon`}
                      onChange={handleChange}
                      controlType={ControlTypes.FILE}
                    />
                  </Row>
                  <Row>
                    <FieldInput
                      value={get(values, 'setting.store.text')}
                      label={'Tìm cửa hàng'}
                      name={`setting.store.text`}
                      onChange={handleChange}
                    />
                  </Row>
                  <Row>
                    <FieldInput
                      previewIcon
                      inputMd={9}
                      value={get(values, 'setting.store.icon')}
                      name={`setting.store.icon`}
                      onChange={handleChange}
                      controlType={ControlTypes.FILE}
                    />
                  </Row>
                </BlockContent>
              </Accordion.Item>
            </Block>
            {menuFooter.map(i => (
              <Block alwaysOpen defaultActiveKey={[i]}>
                <Accordion.Item eventKey={i}>
                  <BlockHeader>{title[i]}</BlockHeader>
                  <BlockContent>
                    <Row>
                      <FieldInput
                        label="Ẩn"
                        controlType={ControlTypes.CHECKBOX}
                        name={`setting.menuFooter.${i}.isShow`}
                        value={get(values, ['setting', 'menuFooter', i, 'isShow'])}
                        onChange={handleChange}
                      />
                    </Row>
                    <div />
                    {i > 1 && (
                      <Row>
                        <FieldInput
                          label="Tiêu đề"
                          name={`setting.menuFooter.${i}.title`}
                          value={get(values, ['setting', 'menuFooter', i, 'list', 'title'])}
                          onChange={handleChange}
                        />
                      </Row>
                    )}
                    <SubItemContainer>
                      {get(values, ['setting', 'menuFooter', i, 'list'], []).map((_d, index) => (
                        <BlockSubItem key={index}>
                          <Row>
                            <Label className="col-md-10">Link text {index + 1}</Label>
                            <Col md={2}>
                              <IconButton
                                onClick={e => {
                                  e.preventDefault();
                                  handleChange({
                                    target: {
                                      name: `setting.menuFooter.${i}.list`,
                                      value: get(values, ['setting', 'menuFooter', i, 'list'], []).filter((_v, newI) => newI !== index),
                                    },
                                  });
                                }}
                              >
                                <i className="mdi mdi-trash-can-outline" />
                              </IconButton>
                            </Col>
                          </Row>
                          <Row>
                            <FieldInput
                              label="Tiêu đề"
                              name={`setting.menuFooter.${i}.list.${index}.title`}
                              value={get(values, ['setting', 'menuFooter', i, 'list', index, 'title'])}
                              onChange={handleChange}
                            />
                          </Row>
                          <Row>
                            <FieldInput
                              label="Link"
                              name={`setting.menuFooter.${i}.list.${index}.link`}
                              value={get(values, ['setting', 'menuFooter', i, 'list', index, 'link'])}
                              onChange={handleChange}
                            />
                          </Row>
                        </BlockSubItem>
                      ))}
                      <AddSubItem
                        onClick={() => {
                          const menuFooter = cloneDeep(get(values, ['setting', 'menuFooter', i, 'list'], []));
                          menuFooter.push({ ...initialBlock });
                          handleChange({ target: { name: `setting.menuFooter.${i}.list`, value: menuFooter } });
                        }}
                      >
                        <AddBlockButton />
                      </AddSubItem>
                    </SubItemContainer>
                  </BlockContent>
                </Accordion.Item>
              </Block>
            ))}
            <ActionsItem onSave={handleSubmit} feature={AuthenticationFeature.INFO} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default SettingFooter;
