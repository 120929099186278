import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import produce from 'immer';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';

import { cloneDeep, get, unset } from 'lodash';

import styled from 'styled-components';
import { Input, Label, Row } from 'reactstrap';
import { ActionsItem, ButtonOutline, ButtonPrimary, CommonManagement, CustomTable, FieldInput, Paginate } from 'components/own';
import { useActionNotification } from 'hook/useContextSelector';
import { AuthenticationFeature } from 'constants/authentication';
import { getAPI, postAPI, putAPI } from 'services/common';
import { getChangedData, parseDate } from 'utils/uti';
import { Accordion, Col } from 'react-bootstrap';
import { ControlTypes } from 'constants/field';
import { CardText } from 'reactstrap';
import styles from './CouponItem.module.scss';
import { createCoupon, getCouponById, updateCoupon } from 'services/coupon';
import { CellControlType } from 'constants/table';
import DatePicker from 'components/own/Filter/DatePicker';
import { AvatarDropIcon, DropDownIcon, LargerDropIcon } from 'components/icons';
import { getAllProducts } from 'services/products';
import Checkbox from 'pages/EditJewellery/components/Checkbox';
import { set } from 'lodash';
import moment from 'moment';
import { API_UPLOAD } from 'constants/common';

const columns = [
  {
    dataField: 'productCode',
    text: 'Mã sản phẩm',
    sort: true,
    controlType: CellControlType.TextNonAction,
    link: 'blog',
    linkPreview: '',
  },
  {
    dataField: 'productName',
    text: 'Tên sản phẩm',
    sort: true,
  },
  {
    dataField: 'inStockCount',
    text: 'Trạng thái',
    sort: true,
    controlType: CellControlType.Status,
    render: value => (value === 0 ? 'Hết hàng' : 'Còn hàng'),
  },
  {
    dataField: 'price',
    text: 'Giá (VNĐ)',
    sort: true,
  },
  {
    dataField: 'productCategory',
    text: 'Danh Mục',
    sort: true,
    custom: 'array',
  },
  {
    dataField: 'tags',
    text: 'Sản phẩm mới',
    sort: true,
    controlType: Checkbox,
  },
  {
    dataField: 'mainCategory',
    text: 'Loại sản phẩm',
    sort: true,
  },
  {
    dataField: 'publishAt',
    text: 'Giới tính',
    sort: true,
    controlType: CellControlType.gender,
  },
  {
    dataField: 'publishAt',
    text: 'Bộ sưu tập',
    sort: true,
    controlType: CellControlType.Date,
  },
];
const ContentTable = styled.div`
  background-color: #fff;
  margin-top: 16px;
`;
let title = 'Tạo mới Coupon';
const InitialLuxury = {
  code: '',
  type: 1,
  customerId: 0,
  gifts: [],
  limit: 0,
  status: 1,
  minimumRequirement: { value: 0 },
  startTime: new Date(),
  endTime: new Date(),
  createdBy: 0,
  userType: 1,
  discountPrice: {},
  discountPercent: {},
  couponType: 0,
  desc: null,
  showValue: null,
  giftText: null,
  createdAt: new Date(),
  updatedAt: new Date(),
  creator: null,
};

const optionActions = [
  { label: 'Outscope', value: 'Outscope' },
  { label: 'Outscope', value: 'Outscope' },
  { label: 'Outscope', value: 'Outscope' },
];

const initialState = {
  data: {},
  dataProduct: [],
  options: {},
  columns,
  loading: true,
  selectedAction: null,
  total: 0,
  page: 1,
  limit: 10,
  searchValue: '',
  discount: 0,
  couponType: 0,
  selectedRow: [],
  gifts: '',
  diamondDefault: [],
};

const options = [
  { value: 'Nhẫn nam', label: 'Nhẫn nam' },
  { value: 'Nhẫn nữ', label: 'Nhẫn nữ' },
];

const Title = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #1f4173;
  margin-bottom: 17px;
`;

const SearchBox = styled(Input)`
  width: 200px;
  height: 32px;
  background: #fafafc;
  input:after {
    border-bottom-width: 1px !important;
  }
`;

const BlockHeader = styled(Accordion.Header)`
  height: 44px;
  border-bottom: 1px solid #e4e4eb;
  background-color: #fff;
  .accordion-button {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #1f4173;
    background-color: transparent;
    padding: 1rem 8px;
    box-shadow: unset;
  }
`;

const BlockContent = styled(Accordion.Body)`
  padding: 16px;
  display: grid;
  grid-template-columns: ${({ $templateColumns }) => $templateColumns || '1fr'};
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  background-color: #fff;
  border: 0;
`;

const SideContent = styled.div`
  display: grid;
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  grid-template-columns: 1fr 1fr;
  background-color: #fff;
`;

const ContentTitle = styled.div`
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */
  color: #1f4173;
`;

const UserTypeContainer = styled.div`
  display: grid;
  grid-template-columns: 140px 140px 140px 140px 140px;
  .form-check-label {
    margin-left: 8px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */
    color: #000000;
  }
`;

const SelectAction = styled(Select)`
  cursor: pointer;
  width: 200px;
  margin-right: 4px;
  font-size: 14px;
  .select2-selection__indicators {
    padding-right: 4px;
  }
`;

const CouponItem = () => {
  const [
    {
      diamondDefault,
      jewelleryDefault,
      gifts,
      selectedRow,
      couponType,
      discount,
      data,
      dataProduct,
      loading,
      columns,
      selectedAction,
      total,
      page,
      limit,
      searchValue,
    },
    setState,
  ] = useState(initialState);
  const { id } = useParams();
  const router = useHistory();
  const pushNotification = useActionNotification();
  const columnTemplate = useMemo(() => '2fr 0.75fr 1fr 0.75fr 1fr 0.75fr 0.5fr 0.5fr 0.5fr', []);
  const ref = useRef();

  useEffect(() => {
    const callAPI = async () => {
      let query = 'limit=' + limit;
      if (page) query += '&page=' + page;
      if (searchValue) query += '&name=' + searchValue;
      let url = 'jewellery';
      try {
        const res = await getAllProducts(url, query);
        // console.log('khanh' + JSON.stringify(res.data.list));
        setState(
          produce(draft => {
            draft.loading = false;
            draft.dataProduct = res.data?.list || [];
            draft.total = res.data?.count || 0;
          })
        );
      } catch (error) {
        setState(
          produce(draft => {
            draft.loading = false;
            draft.dataProduct = [];
            draft.total = 0;
          })
        );
      }
    };
    callAPI();
  }, [limit, page, searchValue]);

  const onClickFilter = useCallback(() => {
    setState(
      produce(draft => {
        if (ref.current) draft.searchValue = ref.current.value;
      })
    );
  }, []);

  const onChangePage = useCallback(({ selected }) => {
    setState(
      produce(draft => {
        draft.page = selected + 1;
      })
    );
  }, []);
  const onChangeProduct = useCallback(({ name, value }) => {
    setState(
      produce(draft => {
        set(draft.data, name, value);
      })
    );
  }, []);
  const onSelectRow = useCallback(
    (id, isCheck) => {
      console.log('check' + id + 'id' + isCheck);
      setState(
        produce(draft => {
          if (isCheck) draft.selectedRow.push(id);
          else draft.selectedRow = draft.selectedRow.filter(_r => _r !== id);
        })
      );
      if (!!selectedRow) {
        let productCode = 'productCode';
        if (data.discountPrice != null) {
          if (data.discountPrice.jewellery != null) {
            productCode = 'discountPrice.jewellery.productCode';
          } else if (data.discountPrice.diamond != null) {
            productCode = 'discountPrice.diamond.productCode';
          } else {
            productCode = 'discountPrice.productCode';
          }
        } else if (data.discountPercent != null) {
          if (data.discountPercent.jewellery != null) {
            productCode = 'discountPercent.jewellery.productCode';
          } else if (data.discountPercent.diamond != null) {
            productCode = 'discountPercent.diamond.productCode';
          } else {
            productCode = 'discountPercent.productCode';
          }
        }
        setState(
          produce(draft => {
            set(draft.data, productCode, selectedRow);
          })
        );
      }
    },
    [data, selectedRow]
  );
  const couponTypeChange = useCallback(e => {
    setState(
      produce(draft => {
        draft.data.couponType = e.target.value;
        draft.couponType = e.target.value;
      })
    );
  }, []);
  console.log('couponType' + couponType);

  const onChangeHandle = useCallback(e => {
    console.log(e.target.name + e.target.value);
    setState(
      produce(draft => {
        if (!e.target.value) {
          unset(draft.data, e.target.name);
        } else {
          set(draft.data, e.target.name, e.target.value);
        }
      })
    );
  }, []);

  const onChangeHandleRadio = useCallback(e => {
    setState(
      produce(draft => {
        draft.data.isTotalOrder = !draft.data.isTotalOrder;
      })
    );
  }, []);
  console.log('isTotalOrder: ' + data.isTotalOrder);
  const onChangeHandleDiscount = useCallback(e => {
    console.log(e.target.name + e.target.value);
    setState(
      produce(draft => {
        set(draft.data, e.target.name, e.target.value);
        draft.discount = e.target.value;
      })
    );
  }, []);

  const handleDiamond = useCallback(
    e => {
      let result = [];
      let resultShow = [];
      let name = 'discountPrice.diamond.list';
      e.forEach(item => {
        result.push(item.value);
        resultShow.push({ value: item.value, label: item.value });
      });
      if (!!data?.discountPercent?.diamond?.value) {
        name = 'discountPercent.diamond.list';
      }
      setState(
        produce(draft => {
          set(draft.data, name, result);
          draft.diamondDefault = resultShow;
        })
      );
    },
    [data]
  );

  const handleJewellery = useCallback(
    e => {
      let result = [];
      let resultShow = [];
      let name = 'discountPrice.jewellery.list';
      e.forEach(item => {
        result.push(item.value);
        resultShow.push({ value: item.value, label: item.value });
      });
      if (!!data?.discountPercent?.jewellery?.value) {
        name = 'discountPercent.jewellery.list';
      }
      setState(
        produce(draft => {
          set(draft.data, name, result);
          draft.jewelleryDefault = resultShow;
        })
      );
    },
    [data]
  );

  const handleSelectGroup = useCallback(item => {
    setState(
      produce(draft => {
        draft.selectedAction = item;
      })
    );
  }, []);
  useEffect(() => {
    if (id === 'new') {
      setState(
        produce(draft => {
          draft.data = InitialLuxury;
        })
      );
    } else {
      title = 'Chỉnh Sửa coupon';
      getAPI('coupons/' + id).then(res => {
        console.log('khanh' + JSON.stringify(res.data));
        let productCode = [];
        if (res.data.discountPrice != null) {
          if (res.data.discountPrice.jewellery != null) {
            productCode = res.data.discountPrice.jewellery.productCode;
          } else if (res.data.discountPrice.diamond != null) {
            productCode = res.data.discountPrice.diamond.productCode;
          } else {
            productCode = res.data.discountPrice.productCode;
          }
        } else if (res.data.discountPercent != null) {
          if (res.data.discountPercent.jewellery != null) {
            productCode = res.data.discountPercent.jewellery.productCode;
          } else if (res.data.discountPercent.diamond != null) {
            productCode = res.data.discountPercent.diamond.productCode;
          } else {
            productCode = res.data.discountPercent.productCode;
          }
        }
        console.log('JewelleryList: ' + res.data?.discountPrice?.jewellery?.list || res.data?.discountPercent?.jewellery?.list);
        let jewelleryList = res.data?.discountPrice?.jewellery?.list || res.data?.discountPercent?.jewellery?.list || [];
        let jewelleryResult = [];
        if (!!jewelleryList) {
          jewelleryList?.forEach(item => {
            jewelleryResult.push({ value: item, label: item });
          });
        }
        console.log('diamond: ' + res.data?.discountPrice?.diamond?.list || res.data?.discountPercent?.diamond?.list);
        let diamondList = res.data?.discountPrice?.diamond?.list || res.data?.discountPercent?.diamond?.list || [];
        let diamondResult = [];
        if (!!jewelleryList) {
          diamondList?.forEach(item => {
            diamondResult.push({ value: item, label: item });
          });
        }

        setState(
          produce(draft => {
            draft.data = res.data;
            draft.selectedRow = productCode;
            console.log('discount:' + res?.data?.discountPrice != null);
            if (res?.data?.discountPrice != null) {
              draft.discount = 2;
            } else if (res?.data?.discountPercent != null) {
              draft.discount = 1;
            }
            draft.couponType = res?.data?.couponType;
            draft.jewelleryDefault = jewelleryResult;
            draft.diamondDefault = diamondResult;
          })
        );
      });
    }
  }, [id, discount]);
  console.log('diamondDefault:' + JSON.stringify(diamondDefault));
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        // code: yup.string().required('Vui lòng nhập tên trang.'),
        // startTime: yup.string().required('Vui lòng nhập đường dẫn trang.'),
        // userType: yup.string().required('Vui lòng nhập mã sản phẩm.'),
        // endTime: yup.string().required('Vui lòng chọn giới tính.'),
        //desc: yup.string().required('Vui lòng nhập nội dung mô tả'),
      }),
    []
  );

  const infoFields = useMemo(
    () => [
      { label: 'Mã Coupon', name: 'code', isRequired: true },
      { label: 'Ngày bắt đầu hiệu lực:', name: 'startTime', controlType: ControlTypes.DATE_PICKER },
      { label: 'Người tạo', readOnly: true, name: 'creator.name' },
      { label: 'Ngày kết thúc hiệu lực:', name: 'endTime', controlType: ControlTypes.DATE_PICKER },
      { label: 'Min đơn hàng (VNĐ)', name: 'minimumRequirement.value', controlType: ControlTypes.CURRENCY },
      {
        label: 'Tình trạng: ',
        name: 'status',
        controlType: ControlTypes.SELECT_PICKER,
        options: [
          {
            label: 'Đang áp dụng',
            value: 1,
          },
          {
            label: 'Không áp dụng',
            value: 2,
          },
        ],
      },
      { label: 'Nội dung mô tả coupon', name: 'desc', type: 'textarea', rows: 4 },
      { label: 'Giá trị hiển thị trên coupon', name: 'showValue', type: 'textarea', rows: 4 },
    ],
    []
  );

  const onCancel = useCallback(() => {
    router.replace('/coupons');
  }, [router]);

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return (
      [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-') +
      ' ' +
      [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
    );
  }

  const onSave = useCallback(
    async values => {
      let request = cloneDeep(data);
      request.userType = parseInt(request.userType);
      request.couponType = parseInt(request.couponType);
      request.startTime = formatDate(new Date(request.startTime));
      request.endTime = formatDate(new Date(request.endTime));
      request.createdAt = parseDate(request.createdAt);
      request.updatedAt = new Date();

      console.log('DataSave:' + JSON.stringify(request));
      // return;
      if (id === 'new') {
        createCoupon(request)
          .then(() => {
            pushNotification('success', 'Tạo thành công');
            router.replace('/coupons');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      } else {
        updateCoupon(id, request)
          .then(() => {
            pushNotification('success', 'Lưu thành công');
            router.replace('/coupons');
          })
          .catch(error => {
            pushNotification('error', error);
          });
      }

      // delete request.jewelleryInfo;
      // console.log('Request:' + JSON.stringify(request));

      // if (values.id) {
      //   request = getChangedData(request, data);
      //   putAPI('luxury-jewelleries/' + values.id, request)
      //     .then(() => {
      //       pushNotification('success', 'Lưu thành công');
      //       router.replace('/luxury-jewelleries');
      //     })
      //     .catch(error => {
      //       pushNotification('error', error);
      //     });
      // } else {
      //   postAPI('luxury-jewelleries', request)
      //     .then(() => {
      //       pushNotification('success', 'Tạo thành công');
      //       router.replace('/luxury-jewelleries');
      //     })
      //     .catch(error => {
      //       pushNotification('error', error);
      //     });
      // }
    },
    [id, pushNotification, router, data]
  );

  const activeFile = () => {
    // $('#uploadFile').trigger('click');
    document.getElementsByClassName('uploadFile')[0].click();
  };

  const handleAvatarChange = event => {
    let image = event.target.files[0];
    const formData = new FormData();

    // Update the formData object
    formData.append('file', image, image.name);
    formData.append('t', true);
    uploadImage(formData);
  };

  const uploadImage = useCallback(
    async data => {
      fetch(API_UPLOAD, {
        method: 'post',
        body: data,
      })
        .then(response => {
          new Response(response.body, { headers: { 'Content-Type': 'text/html' } })
            .text()
            .then(res => {
              const respJson = JSON.parse(res);
              setState(
                produce(draft => {
                  draft.data.gifts.push({ name: '', image: respJson?.data?.url });
                })
              );
            })
            .catch(error => {
              pushNotification('error', error);
            });
        })
        .catch(error => {
          pushNotification('error', error);
        });
    },
    [pushNotification]
  );

  if (!data) return <div />;

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <Title>{title}</Title>
      </div>
      <Formik
        initialValues={data}
        onSubmit={onSave}
        // validationSchema={validationSchema}
      >
        {({ handleSubmit, handleChange, setFieldValue, values = {} }) => (
          <>
            <Accordion alwaysOpen defaultActiveKey={['info']}>
              <Accordion.Item className="mb-3" eventKey="info">
                <BlockHeader className="d-flex align-items-center justify-content-between">Thông tin coupon</BlockHeader>
                <BlockContent>
                  <SideContent>
                    {infoFields.map((_f, i) => (
                      <Row key={i}>
                        <FieldInput onChange={onChangeHandle} {..._f} value={get(data, _f.name)} placeholder={_f.label} />
                      </Row>
                    ))}
                  </SideContent>
                  <ContentTitle className="mt-4 mb-2">Áp dụng cho thành viên:</ContentTitle>
                  <UserTypeContainer>
                    <div>
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="userType"
                        value={1}
                        defaultChecked={data?.userType}
                        defaultValue={data?.userType}
                        onChange={onChangeHandle}
                        id="defaultUserTypeCheck1"
                      />
                      <Label className="form-check-label" htmlFor="defaultUserTypeCheck1">
                        Tất cả
                      </Label>
                    </div>
                    <div>
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="userType"
                        value={2}
                        defaultChecked={data?.userType}
                        defaultValue={data?.userType}
                        onChange={onChangeHandle}
                        id="defaultUserTypeCheck2"
                      />
                      <Label className="form-check-label" htmlFor="defaultUserTypeCheck2">
                        Thành viên
                      </Label>
                    </div>
                  </UserTypeContainer>
                  <ContentTitle className="my-4">Áp dụng cho Loại sản phẩm</ContentTitle>
                  {/******************************************* Begin Row tổng đơn *******************************************/}
                  <Row>
                    <Col sm="12">
                      <CardText>
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="isTotalOrder"
                            onChange={onChangeHandleRadio}
                            checked={data.isTotalOrder === true}
                          />
                          <Label className="form-check-label">Tổng đơn</Label>
                        </div>
                      </CardText>
                    </Col>
                  </Row>
                  <div>
                    <Row className={`mb-3 ${styles['coupon__ml']}`}>
                      <span className="col-md-3 col-form-label">Loại coupon</span>
                      <Col md={8}>
                        <select
                          className="form-select"
                          id="couponType"
                          defaultValue=""
                          value={data?.couponType}
                          // onChange={e => setFieldValue('couponType', e.target.value)}
                          onChange={couponTypeChange}
                        >
                          <option value={0}>Chọn loại coupon</option>
                          <option value={1}>Giảm giá</option>
                          <option value={2}>Quà tặng</option>
                          <option value={3}>Giảm giá và quà tặng</option>
                        </select>
                      </Col>
                    </Row>

                    <div className={`${couponType == 2 ? styles['coupon__disabled'] : ''}`}>
                      <Row className={`mb-3 ${styles['coupon__ml']}`}>
                        <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                          Loại ưu đãi
                        </span>
                        <Col md={8}>
                          <select className="form-select" name="discount" value={discount || 0} onChange={onChangeHandleDiscount}>
                            <option value={0}>Chọn loại ưu đãi</option>
                            <option value={1}>Phần trăm giảm</option>
                            <option value={2}>Giá tiền giảm</option>
                          </select>
                        </Col>
                      </Row>
                      {/* <Row className={`mb-3 ${styles['coupon__ml']}`}>
                        <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                          Min tổng kim cương (VNĐ)
                        </span>
                        <Col md={4}>
                          <Input type="text" name="title" defaultValue={data?.title} id="couponTitle" />
                        </Col>
                      </Row> */}

                      <Row className={`mb-3 ${styles['coupon__ml']}`}>
                        <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                          Trị giá giảm
                        </span>
                        <Col md={8}>
                          <Input type="text" name="discountPercent.value" value={data?.discountPercent?.value} onChange={onChangeHandle} />
                        </Col>
                      </Row>
                      <Row className={`mb-3 ${styles['coupon__ml']}`}>
                        <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                          Hạn mức giảm tối đa (VND)
                        </span>
                        <Col md={8}>
                          <Input type="text" name="discountPercent.maximum" value={data?.discountPercent?.maximum} onChange={onChangeHandle} />
                        </Col>
                      </Row>
                      <Row className={`mb-3 ${styles['coupon__ml']}`}>
                        <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                          Giá tiền giảm (VND)
                        </span>
                        <Col md={8}>
                          <Input
                            type="text"
                            name="discountPrice.value"
                            defaultValue={data?.title}
                            value={data?.discountPrice?.value}
                            onChange={onChangeHandle}
                          />
                        </Col>
                      </Row>
                    </div>
                    <Row className={`mb-3 ${styles['coupon__ml']}`}>
                      {/* <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                        Nội dung quà tặng niếu ưu đãi là quà tặng
                      </span> */}
                      {/* <Col md={11}>
                        <Input type="textarea" rows="4" name="title" defaultValue={data?.title} id="couponTitle" />
                      </Col> */}
                    </Row>
                    {/* <Row className={`mb-3 ${styles['coupon__ml']}`}>
                      <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                        Số lượng quà tặng
                      </span>
                      <Col md={4}>
                        <Input type="text" name="title" defaultValue={data?.title} id="couponTitle" />
                      </Col>
                      <Col md={4}>
                        <Col sm="12" className={`mb-3 ${styles['coupon__ml']}`}>
                          <CardText>
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                name="blogsType"
                                //   id={item.id}
                                value=""
                                onChange=""
                                defaultChecked={true}
                              />
                              <Label className="form-check-label">Không giới hạn</Label>
                            </div>
                          </CardText>
                        </Col>
                      </Col>
                    </Row> */}
                  </div>
                  {/******************************************  End Row tổng đơn **********************************/}

                  {/******************************************  Begin Row Từng sản phẩm **********************************/}
                  <Row>
                    <Col sm="12">
                      <CardText>
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="isTotalOrder"
                            onChange={onChangeHandleRadio}
                            checked={data.isTotalOrder === false}
                          />
                          <Label className="form-check-label">Từng sản phẩm</Label>
                        </div>
                      </CardText>
                    </Col>
                  </Row>
                  <div>
                    {/************************  Begin Từng sản phẩm > Kim cương ********************/}
                    <Col sm="12" className={`mb-3 ${styles['coupon__ml']}`}>
                      <CardText>
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            // name="blogsType"
                            //   id={item.id}
                            // value={values.discountPercent?.diamond}
                            onChange={e => {
                              if (e.target.checked) {
                                setFieldValue('discountPercent.diamond', {
                                  // ...values?.discountPercent?.diamond
                                  value: '',
                                  maximum: '',
                                });
                                setFieldValue('discountPrice.diamond', {
                                  value: '',
                                });
                              } else {
                                setFieldValue('discountPercent.diamond', null);
                                setFieldValue('discountPrice.diamond', null);
                              }
                            }}
                            defaultChecked={true}
                          />
                          <Label className="form-check-label">Kim cương</Label>
                          <Row className={`mb-3 ${styles['coupon__ml']}`}>
                            <span className="col-md-3 col-form-label">Loại coupon</span>
                            <Col md={8}>
                              <select
                                className="form-select"
                                name="couponType"
                                value={data?.couponType}
                                onChange={couponTypeChange}
                                defaultValue=""
                              >
                                <option value={0}>Chọn loại coupon</option>
                                <option value={1}>Giảm giá</option>
                                <option value={2}>Quà tặng</option>
                                <option value={3}>Giảm giá và quà tặng</option>
                              </select>
                            </Col>
                          </Row>

                          <div className={`${couponType == 2 ? styles['coupon__disabled'] : ''}`}>
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                                Loại ưu đãi
                              </span>
                              <Col md={8}>
                                <select className="form-select" name="discount" value={discount || 0} onChange={onChangeHandleDiscount}>
                                  <option value={0}>Chọn loại ưu đãi</option>
                                  <option value={1}>Phần trăm giảm</option>
                                  <option value={2}>Giá tiền giảm</option>
                                </select>
                              </Col>
                            </Row>
                            {/* <Row className={`${styles['coupon__ml']}`}>
                              <Label htmlFor="couponTitle" className="col-md-3 col-form-label"></Label>
                              <Col md={8}>
                                <Label className="col-form-label">Ưu đãi khi không đăng nhập</Label>
                              </Col>
                            </Row> */}
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                                Trị giá giảm
                              </span>
                              <Col md={8}>
                                <Input
                                  type="text"
                                  name="discountPercent.diamond.value"
                                  value={data?.discountPercent?.diamond?.value}
                                  onChange={onChangeHandle}
                                />
                              </Col>
                            </Row>
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                                Hạn mức giảm tối đa (VND)
                              </span>
                              <Col md={8}>
                                <Input
                                  type="text"
                                  name="discountPercent.diamond.maximum"
                                  value={data?.discountPercent?.diamond?.maximum}
                                  onChange={onChangeHandle}
                                  id="diamond_maximum"
                                />
                              </Col>
                            </Row>
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                                Giá tiền giảm (VND)
                              </span>
                              <Col md={8}>
                                <Input
                                  type="text"
                                  name="discountPrice.diamond.value"
                                  value={data?.discountPrice?.diamond?.value}
                                  onChange={onChangeHandle}
                                />
                              </Col>
                            </Row>
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <span htmlFor="couponTitle" className="col-form-label">
                                Mã sản phẩm (Mỗi mã cách nhau dấu "," để trống sẽ áp dụng cho tất cả sản phẩm)
                              </span>
                              <Col md={11}>
                                <Input
                                  type="textarea"
                                  rows="4"
                                  name="discountPrice.diamond.productCode"
                                  value={data?.discountPrice?.diamond?.productCode || data?.discountPercent?.diamond?.productCode}
                                  onChange={onChangeHandle}
                                />
                              </Col>
                            </Row>
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <Col>
                                <CardText>
                                  <Row className={`${styles['blogs__submit--btn']}`}>
                                    <span htmlFor="couponTitle" className="col-form-label">
                                      Doanh mục sản phẩm
                                    </span>
                                    <Col md={11}>
                                      <Select
                                        isMulti
                                        name="diamondDefault"
                                        onChange={handleDiamond}
                                        value={diamondDefault}
                                        className="basic-multi-select"
                                        options={options}
                                      />
                                    </Col>
                                    <Col md={1}> </Col>
                                  </Row>
                                </CardText>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </CardText>
                    </Col>
                    {/************************  End Từng sản phẩm > Kim cương ********************/}

                    {/************************  Begin Từng sản phẩm > TSV ********************/}
                    <Col sm="12" className={`mb-3 ${styles['coupon__ml']}`}>
                      <CardText>
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="blogsType"
                            //   id={item.id}
                            value=""
                            onChange={e => {
                              if (e.target.checked) {
                                // setFieldValue('discountPrice.jewellery', {
                                //     value: '',
                                // });
                                setFieldValue('discountPrice', {
                                  jewellery: {
                                    value: '',
                                  },
                                });
                              } else {
                                setFieldValue('discountPercent.jewellery', null);
                                setFieldValue('discountPrice.jewellery', null);
                              }
                            }}
                            defaultChecked={true}
                          />
                          <Label className="form-check-label">TSV</Label>
                          <Row className={`mb-3 ${styles['coupon__ml']}`}>
                            <span className="col-md-3 col-form-label">Loại coupon</span>
                            <Col md={8}>
                              <select
                                className="form-select"
                                name="couponType"
                                value={data?.couponType}
                                onChange={couponTypeChange}
                                defaultValue=""
                              >
                                <option value={0}>Chọn loại coupon</option>
                                <option value={1}>Giảm giá</option>
                                <option value={2}>Quà Tặng</option>
                                <option value={3}>Giảm giá + quà</option>
                              </select>
                            </Col>
                          </Row>

                          <div className={`${couponType == 2 ? styles['coupon__disabled'] : ''}`}>
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                                Loại ưu đãi
                              </span>
                              <Col md={8}>
                                <select className="form-select" name="discount" value={discount || 0} onChange={onChangeHandleDiscount}>
                                  <option value={0}>Chọn loại ưu đãi</option>
                                  <option value={1}>Phần trăm giảm</option>
                                  <option value={2}>Giá tiền giảm</option>
                                </select>
                              </Col>
                            </Row>
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                                Trị giá giảm
                              </span>
                              <Col md={8}>
                                <Input
                                  type="text"
                                  name="discountPercent.jewellery.value"
                                  value={values?.discountPercent?.jewellery?.value}
                                  onChange={onChangeHandle}
                                />
                              </Col>
                            </Row>
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                                Hạn mức giảm tối đa (VND)
                              </span>
                              <Col md={8}>
                                <Input
                                  type="text"
                                  name="discountPercent.jewellery.maximum"
                                  value={data?.discountPercent?.jewellery?.maximum}
                                  onChange={onChangeHandle}
                                />
                              </Col>
                            </Row>
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                                Giá tiền giảm (VND)
                              </span>
                              <Col md={8}>
                                <Input
                                  type="text"
                                  name="discountPrice.jewellery.value"
                                  value={data?.discountPrice?.jewellery?.value}
                                  onChange={onChangeHandle}
                                />
                              </Col>
                            </Row>
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <span htmlFor="couponTitle" className="col-form-label">
                                Mã sản phẩm (Mỗi mã cách nhau dấu "," để trống sẽ áp dụng cho tất cả sản phẩm)
                              </span>
                              <Col md={11}>
                                <Input
                                  type="textarea"
                                  rows="4"
                                  name="discountPrice.jewellery.productCode"
                                  value={data?.discountPrice?.jewellery?.productCode || data?.discountPercent?.jewellery?.productCode}
                                  onChange={onChangeHandle}
                                />
                              </Col>
                            </Row>
                            <Row className={`mb-3 ${styles['coupon__ml']}`}>
                              <Col>
                                <CardText>
                                  <Row className={`${styles['blogs__submit--btn']}`}>
                                    <span htmlFor="couponTitle" className="col-form-label">
                                      Doanh mục sản phẩm
                                    </span>
                                    <Col md={11}>
                                      <Select
                                        isMulti
                                        className="basic-multi-select"
                                        onChange={handleJewellery}
                                        value={jewelleryDefault}
                                        options={options}
                                      />
                                    </Col>
                                    <Col md={1}> </Col>
                                  </Row>
                                </CardText>
                              </Col>
                            </Row>
                          </div>
                          {/* ---------------------------------------------------------- */}
                          {/* <Row className={`mb-3 ${styles['coupon__ml']}`}>
                            <Col>
                              <CardText>
                                <div className="form-check">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="blogsType"
                                    //   id={item.id}
                                    value=""
                                    onChange=""
                                    defaultChecked={true}
                                  />
                                  <Label className="form-check-label">All</Label>
                                </div>
                              </CardText>
                            </Col>
                          </Row> */}
                          {/* --------------------------------------------------------------- */}
                          {/* <Row className={`mb-3 ${styles['coupon__ml']}`}>
                            <Col>
                              <CardText>
                                <div className="form-check">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="blogsType"
                                    //   id={item.id}
                                    value=""
                                    onChange=""
                                    defaultChecked={true}
                                  />
                                  <Label className="form-check-label">Mã sản phẩm</Label>
                                </div>
                                <Col md={11}>
                                  <Input type="textarea" rows="4" name="title" defaultValue={data?.title} id="couponTitle" />
                                </Col>
                              </CardText>
                            </Col>
                          </Row> */}
                          {/* ------------------------------------------------------------------ */}

                          {/* ------------------------------------------------------------------ */}
                          {/* <Row className={`mb-3 ${styles['coupon__ml']}`}>
                            <Col>
                              <CardText>
                                <div className="form-check">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="blogsType"
                                    //   id={item.id}
                                    value=""
                                    onChange=""
                                    defaultChecked={true}
                                  />
                                  <Label className="form-check-label">Bộ sưu tập</Label>
                                </div>
                                <Row className={`${styles['blogs__submit--btn']}`}>
                                  <Col md={11}>
                                    <Select isMulti className="basic-multi-select" options={options} />
                                  </Col>
                                  <Col md={1}> </Col>
                                </Row>
                              </CardText>
                            </Col>
                          </Row> */}
                          {/* ------------------------------------------------------------------ */}
                          {/* <Row className={`mb-3 ${styles['coupon__ml']}`}>
                            <Col>
                              <CardText>
                                <div className="form-check">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="blogsType"
                                    //   id={item.id}
                                    value=""
                                    onChange=""
                                    defaultChecked={true}
                                  />
                                  <Label className="form-check-label">Bộ trang sức</Label>
                                </div>
                                <Row className={`${styles['blogs__submit--btn']}`}>
                                  <Col md={11}>
                                    <Select isMulti className="basic-multi-select" options={options} />
                                  </Col>
                                  <Col md={1}> </Col>
                                </Row>
                              </CardText>
                            </Col>
                          </Row> */}
                        </div>
                        <div className={`${couponType == 1 ? styles['coupon__disabled'] : ''}`}>
                          <Row className={`${styles['coupon__ml']}`}>
                            <Label htmlFor="couponTitle" className="col-md-3 col-form-label"></Label>
                            <Col md={11}>
                              <Label className="col-form-label">Thông tin quà tặng (Loại coupon: quà tặng, giảm giá và quà tặng)</Label>
                            </Col>
                          </Row>
                          <Row className={`mb-3 ${styles['coupon__ml']}`}>
                            <span htmlFor="couponTitle" className="col-md-3 col-form-label">
                              Nội dung quà tặng niếu ưu đãi là quà tặng
                            </span>
                            <Col md={11}>
                              <Input
                                type="textarea"
                                rows="4"
                                name="giftText"
                                defaultValue={data?.giftText}
                                value={values?.giftText}
                                onChange={onChangeHandle}
                              />
                            </Col>
                          </Row>
                          <Row className={`mb-3 ${styles['coupon__ml']}`}>
                            <span htmlFor="couponGiftName" className="col-md-3 col-form-label">
                              Số lượng quà tặng
                            </span>
                            <Col md={6}>
                              <Input type="text" name="limit" defaultValue={data?.limit} value={values?.limit} onChange={onChangeHandle} />
                            </Col>
                            <Col md={2} className="form-check">
                              <Input className="form-check-input mr-1" name="limit" type="checkbox" value={0} id="defaultCheck1" />
                              <Label className="form-check-label ml-1" htmlFor="defaultCheck1">
                                Không giới hạn
                              </Label>
                            </Col>
                          </Row>
                          {/* <Row className={`mb-3 ${styles['coupon__ml']}`}>
                        <span htmlFor="couponGiftTetx" className="col-md-3 col-form-label">
                          Mô tả qùa tặng
                        </span>
                        <Col md={8}>
                          <Input
                            type="text"
                            name="giftText"
                            defaultValue={data?.giftText}
                            value={data?.giftText}
                            onChange={onChangeHandle}
                            id="giftText"
                          />
                        </Col>
                      </Row> */}
                          <Row className={`mb-3 ${styles['coupon__ml']}`}>
                            <span htmlFor="couponGiftTetx" className="col-md-3 col-form-label">
                              Hình ảnh quà tặng
                            </span>
                            <Col md={8} className={`${styles['coupon__gift-img']}`}>
                              {data?.gifts?.map((_f, i) => (
                                <>
                                  <div key={_f + i}>
                                    <div className={`${styles['coupon__gift-img-item']}`} onClick={activeFile}>
                                      <img className="rounded" src={_f?.image} alt="" width="100" height="auto" />
                                    </div>
                                    <Input type="file" accept="image/*" className="uploadFile" name="" onChange={handleAvatarChange} />
                                  </div>
                                </>
                              ))}
                              <div onClick={activeFile}>
                                <AvatarDropIcon />
                                <Input type="file" accept="image/*" className="uploadFile d-none" name="" onChange={handleAvatarChange} />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </CardText>
                    </Col>
                    {/************************  Begin Từng sản phẩm > TSV ********************/}
                  </div>
                  {/******************************************  End Row Từng sản phẩm **********************************/}
                </BlockContent>
              </Accordion.Item>
            </Accordion>
            <ActionsItem onSave={onSave} onCancel={onCancel} feature={AuthenticationFeature.PRODUCT} />
            {/* <Accordion>
              <div className="d-flex align-items-center justify-content-end">
                <SearchBox innerRef={ref} type="text" className="form-control me-3" placeholder="Nhập keyword" />
                <ButtonOutline onClick={onClickFilter}>Tìm kiếm</ButtonOutline>
              </div>
              <div className="d-flex align-items-center mt-3">
                <SelectAction
                  placeholder="Thêm vào bộ sưu tập"
                  value={selectedAction}
                  onChange={handleSelectGroup}
                  options={optionActions}
                  classNamePrefix="select2-selection"
                  components={{
                    DropdownIndicator: DropDownIcon,
                    IndicatorSeparator: null,
                  }}
                />
                <ButtonPrimary fontWeight={500} className="me-2">
                  Áp dụng
                </ButtonPrimary>
                <SelectAction
                  placeholder="Chọn Kho"
                  value={selectedAction}
                  onChange={handleSelectGroup}
                  options={optionActions}
                  classNamePrefix="select2-selection"
                  components={{
                    DropdownIndicator: DropDownIcon,
                    IndicatorSeparator: null,
                  }}
                />
                <SelectAction
                  placeholder="Lọc theo loại sản phẩm"
                  value={selectedAction}
                  onChange={handleSelectGroup}
                  options={optionActions}
                  classNamePrefix="select2-selection"
                  components={{
                    DropdownIndicator: DropDownIcon,
                    IndicatorSeparator: null,
                  }}
                />
                <SelectAction
                  placeholder="Lọc theo trạng thái kho"
                  value={selectedAction}
                  onChange={handleSelectGroup}
                  options={optionActions}
                  classNamePrefix="select2-selection"
                  components={{
                    DropdownIndicator: DropDownIcon,
                    IndicatorSeparator: null,
                  }}
                />

                <div className="flex-1"></div>
                <Label className="me-2">{total} mục</Label>
                <Paginate page={page} total={total} itemPerPage={limit} onChange={onChangePage} />
              </div>
              <ContentTable className="">
                <CustomTable
                  selectable={true}
                  columnTemplate={columnTemplate}
                  // rowSelected={selectedRow}
                  onChecked={onSelectRow}
                  data={dataProduct}
                  headers={columns}
                  feature={AuthenticationFeature.COUPON}
                  loading={loading}
                  rowHeight={83}
                  onRefresh={() => onClickFilter(true)}
                />
              </ContentTable>
            </Accordion> */}
            <CommonManagement module={'coupon-product'} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default CouponItem;
