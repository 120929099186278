import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { CellControlType } from 'constants/table';

export const getCustomerInfo = () => ({
  buttonAddTitle: '',
  feature: AuthenticationFeature.USER,
  columns: [
    {
      dataField: 'code',
      text: 'Mã thẻ',
    },
    {
      dataField: 'avatar',
      text: 'Ảnh',
      controlType: CellControlType.Image,
    },
    {
      dataField: 'name',
      text: 'Tên khách hàng',
      detailUrl: 'customers',
    },
    // {
    //   dataField: 'card',
    //   text: 'Hạng thẻ',
    // },
    {
      dataField: 'phone ?? lastOrderInfo.phone',
      text: 'Số điện thoại',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'updatedAt',
      text: 'Ngày update',
      controlType: CellControlType.Date,
    },
    {
      dataField: 'defaultAddress.address ?? lastOrderInfo.address',
      text: 'Địa chỉ',
    },
    {
      dataField: 'action',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'customers',
      deleteUrl: 'customers',
    },
  ],
  columnTemplate: '100px 100px 200px 150px 200px 150px 1fr 100px',
  filterElements: [
    {
      label: 'Ngày',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'dateFrom',
      controlType: ControlTypes.DATE_PICKER,
    },
    {
      label: '-',
      controlType: ControlTypes.LABEL,
    },
    {
      name: 'dateTo',
      controlType: ControlTypes.DATE_PICKER,
    },
    // {
    //   name: 'card',
    //   placeholder: 'Hạng thẻ',
    //   controlType: ControlTypes.SELECT_PICKER,
    //   options: [],
    //   width: '100px',
    // },
  ],
  selectable: true,
  title: 'Danh sách khách hàng',
  url: 'customers',
});

export const getOrderState = (value, type) => {
  switch (value) {
    case -1:
      if (type === 'render') return 'Đơn hủy';
      if (type === 'background') return 'rgba(232, 0, 0, 0.1)';
      if (type === 'color') return '#E80000';
      break;
    case 1:
      if (type === 'render') return 'Mới';
      if (type === 'background') return 'rgba(85, 87, 112, 0.1)';
      if (type === 'color') return '#555770';
      break;
    case 2:
      if (type === 'render') return 'Đang xử lý';
      if (type === 'background') return 'rgba(6, 194, 112, 0.1)';
      if (type === 'color') return '#06C270';
      break;
    case 3:
      if (type === 'render') return 'Giao/giữ hàng';
      if (type === 'background') return 'rgba(253, 172, 66, 0.1)';
      if (type === 'color') return '#FDAC42';
      break;
    case 4:
      if (type === 'render') return 'Xong';
      if (type === 'background') return 'rgba(0, 119, 112, 0.1)';
      if (type === 'color') return '#007770';
      break;
    default:
      break;
  }
};
