import { AuthenticationFeature } from 'constants/authentication';
import { CellControlType } from 'constants/table';

export const getCategoryInfo = () => ({
  buttonAddTitle: 'Thêm danh mục',
  feature: AuthenticationFeature.PRODUCT,
  columns: [
    {
      dataField: 'mediafiles.image',
      text: 'Hình đại diện',
      controlType: CellControlType.Image,
    },
    {
      dataField: 'name',
      text: 'Tên danh mục',
      controlType: CellControlType.TextAction,
    },
    {
      dataField: 'type',
      text: 'TSV/KC',
      render: value => (value === 1 ? 'TSV' : 'KC'),
    },
    {
      dataField: 'status',
      text: 'Trạng thái',
      controlType: CellControlType.Status,
    },
    {
      dataField: 'link',
      text: 'Đường dẫn',
    },
    {
      dataField: 'createdAt',
      text: 'Ngày tạo',
      sort: true,
      controlType: CellControlType.Date,
    },
    {
      dataField: 'action',
      text: 'Thao tác',
      controlType: CellControlType.Action,
      detailUrl: 'categories',
      deleteUrl: 'categories',
    },
  ],
  columnTemplate: '100px 1fr 100px 200px 150px 100px 100px',
  filterElements: [],
  selectable: true,
  title: 'Danh sách danh mục',
  url: 'categories',
});
