import { AuthenticationFeature } from 'constants/authentication';
import { ControlTypes } from 'constants/field';
import { ShowHideOptions } from 'constants/options';
import { CellControlType } from 'constants/table';

export const getComboInfo = () => ({
  buttonAddTitle: 'Thêm bộ trang sức',
  feature: AuthenticationFeature.PRODUCT,
  actions: [
    { label: 'Hiển thị', value: 1, key: 'status' },
    { label: 'Không hiển thị', value: -1, key: 'status' },
  ],
  columns: [
    {
      dataField: 'name',
      text: 'Bộ trang sức',
      detailUrl: 'combo',
      controlType: CellControlType.TextAction,
    },
    {
      dataField: 'status',
      text: 'Trạng thái',
      controlType: CellControlType.Status,
      render: value => (value === 1 ? 'Đang hiển thị' : 'Không hiển thị'),
      color: value => (value === 1 ? '#007770' : '#555770'),
      background: value => (value === 1 ? 'rgba(0, 119, 112, 0.1)' : 'rgba(85, 87, 112, 0.1)'),
    },
    {
      dataField: 'createdAt',
      text: 'Ngày tạo',
      sort: true,
      controlType: CellControlType.Date,
    },
  ],
  columnTemplate: '1fr 200px 200px',
  filterElements: [
    {
      name: 'status',
      placeholder: 'Trạng thái',
      controlType: ControlTypes.SELECT_PICKER,
      options: ShowHideOptions,
      width: '130px',
    },
  ],
  selectable: true,
  title: 'Danh sách bộ trang sức',
  url: 'combo',
});
