import { memo } from 'react';
import { Col, Label } from 'reactstrap';
import styled from 'styled-components';
import { propsAreEqual } from 'utils/uti';
import Flatpickr from 'react-flatpickr';

const LabelInput = styled(Label)`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
  b {
    margin-left: 4px;
    color: #e63535;
  }
`;

const TimePicker = styled(Flatpickr)`
  cursor: pointer;

  font-size: 14px;
  background: #fff;

  width: ${({ width }) => width || '100%'};
  height: 40px;
  padding: 0 8px;
  border-radius: 8px;

  border: 1px solid #c7c9d9;
  box-sizing: border-box;
  border-radius: 2px;
`;

const InputTimePicker = ({ error, touched, isRequired, label, name, inputMd = 9, onChange, value, ...rest }) => {
  return (
    <>
      {label && (
        <LabelInput className="col-md-3 col-form-label d-flex align-items-center">
          {label} {isRequired && <b>*</b>}
        </LabelInput>
      )}
      <Col md={inputMd}>
        <TimePicker
          {...rest}
          className="me-2"
          name={name}
          value={value}
          onChange={(e, newV) => {
            onChange({ target: { name, value: newV } });
          }}
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
            time_24hr: true,
          }}
        />
        {error && (
          <div className={'invalid-tooltip'} style={{ display: 'block' }} name="validate">
            {error}
          </div>
        )}
      </Col>
    </>
  );
};

export default memo(InputTimePicker, propsAreEqual);
