import axios from 'axios';

export const getAllRoles = (query = '') => axios({ url: 'user-types?' + query, method: 'GET' });

export const getRoleById = id => axios({ url: 'user-types/' + id, method: 'GET' });

export const createRole = data => axios({ url: 'user-types', method: 'POST', data });

export const updateRole = (id, data) => axios({ url: 'user-types/' + id, method: 'PUT', data });

export const deleteRole = id => axios({ url: 'user-types/' + id, method: 'DELETE' });

export const getAllPermissions = () => axios({ url: 'permissions', method: 'GET' });
